import api from './Api';
import {AxiosPromise} from "axios";

class Preferences {
    public load(): AxiosPromise {
        return api.post('users/preferences/load');
    }

    public save(preferences: object[]): AxiosPromise {
        return api.post('users/preferences/save', preferences);
    }

    public setLocale(locale: string) {
        return api.post('users/preferences/setLocale', {locale});
    }

    public setPlant(werk_seq: number) {
        return api.post('users/preferences/setPlant', {werk_seq});
    }
}

const preferencesService = new Preferences();
export default preferencesService;
