
const lieferanten = [
    {
        path: '/administration/stammdaten/lieferanten',
        name: 'Lieferanten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_lieferanten" */ './Grid/Lieferanten.vue')
    },
    {
        path: '/administration/stammdaten/lieferanten/filter',
        name: 'Lieferanten Filter',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_lieferanten" */ './Grid/Filters/Lieferanten.vue')
    },
    {
        path: '/administration/stammdaten/lieferanten/detail/:lieferant?',
        name: 'Lieferant',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_lieferanten" */ './Details/Lieferant.vue')
    }

];

export default lieferanten;