
import { Component, Prop, Vue } from 'vue-property-decorator';
import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";
import * as _ from 'lodash';
import TokenUser, {TokenUserParams} from "../../TokenUser/TokenUser.vue";

@Component({
    components: {TokenUser}
})
export default class BenutzerSelect extends InputSelect {

    get tokenDisabled(): boolean {

        if (((this as any).value && !this.isMultiselect) || !this.hasTokenParams || (this as any).isReadonly) {
            return true;
        }

        return false;
    }

    get hasTokenParams(): boolean {

        if (!this.tokenParams) { return false; }

        if (!this.tokenParams.lieferant_seq) { return false; }

        return true;
    }

    get extraCss(): string {
        if (this.tokenParams) {
            return "ui action input";
        }

        if ((this as any).hasExtraLabel) {
            return (this as any).extra;
        }

        return "";
    }

    @Prop({default: "/inforum/lists/benutzer"}) public remote: string;
    @Prop({default: true}) public clearable: boolean;
    @Prop({default: true}) public searchable: boolean;
    @Prop({default: 'user_id'}) public remotePrimary: string;
    @Prop({default: null}) public tokenParams: TokenUserParams;
    @Prop({default: 2}) public remoteMinCharacters: number;

    protected template: any = _.template('<%= name %><br><small><%= email %></small>');

    protected getOptionTemplate(option): any {
        const html = this.template(option);
        return html;
    }

    protected openToken() {
        (this.$refs.tokenuser as TokenUser).open();
    }

    protected tokenUser(user_id) {
        this.$emit('input', user_id);

    }
}
