
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from "lodash";

@Component({
})
export default class LazyTabs extends Vue {
    @Prop({default: null}) public startIndex: any;

    public tabs: any[] = [];
    public segments: any[] = [];

    public addTab(tab: any) {
        tab.$on('click', this.tabChange);

        if ((!this.tabs.length && !this.startIndex) || tab.index === this.startIndex) {
            tab.setActive(true);
        }

        this.tabs.push(tab);
    }

    public addSegment(segment: any) {
        if ((!this.segments.length && !this.startIndex) || segment.index === this.startIndex) {
            segment.setActive(true);
        }

        this.segments.push(segment);
    }

    public tabChange(tab: any) {
        _.each(this.tabs, (item) => {
            item.setActive(tab.index === item.index);
        });

        _.each(this.segments, (item) => {
            item.setActive(tab.index === item.index);
        });
    }

}
