
import { Component, Prop, Vue } from 'vue-property-decorator';
import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";
import _ from "lodash";
import i18n from '@/framework/locale';

@Component({})
export default class LieferantenSelect extends InputSelect {
    @Prop({default: "/inforum/lists/lieferanten"}) public remote: string;
    @Prop({default: true}) public clearable: boolean;
    @Prop({default: true}) public searchable: boolean;
    @Prop({default: 'lieferant'}) public remotePrimary: string;
    @Prop({default: 2}) public remoteMinCharacters: number;

    protected template: any =  _.template(
        `<div class="option">
            <%= name %>
            <br>
            <span>
                <small>
                    <b><%= label_duns %></b>
                    <%= duns %>
                </small>
            </span>
            <br>
            <span>
                <small>
                    <b><%= label_lieferanten_nr %></b>
                    <%= lieferanten_nr %>
                </small>
            </span>
            <br>
            <span>
                <small>
                    <b><%= label_adresse %></b>
                    <%= lieferanten_adresse %>
                </small>
            </span>
        </div>`
    );

    protected getOptionTemplate(option): any {
        option.label_duns = i18n.t('duns');
        option.label_lieferanten_nr = i18n.t('lieferanten_nr');
        option.label_adresse = i18n.t('adresse');

        if (!option.duns && !option.lieferanten_nr) { return null; }

        return this.template(option);
    }
}
