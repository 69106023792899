import {GridWatcher} from "@/framework/extjs/grid/watcher/GridWatcher";
import vStore from "@/app/store";
import * as _ from "lodash";

export class PermissionWatcher extends GridWatcher {
    public init(comp: any): Promise<boolean> {
        if (!comp.resource) {
            return Promise.resolve(false);
        }
        return vStore.dispatch('permissions/loadPermission', [comp.resource]);
    }

    public onWatch(comp: any, value: any) {
        if (!comp.resource) {
            return false;
        }
        const getters = vStore.getters;
        const isAllowed = getters['permissions/isAllowed'];
        const viewModel = comp.getViewModel();

        if (comp && viewModel) {
            _.each(viewModel.getData(), (config, ref) => {
                if (!comp.resource || !config || !config.default || !config.permissions) {

                    if (config && config.default !== undefined && !config.default) {
                        viewModel.set(ref + '.visible', false);
                    }
                    return;
                }

                viewModel.set(ref + '.visible', isAllowed(comp.resource, config.permissions));
            });
        }
    }
}
