import ReklamationOpenDetail from "@/app/pages/Lieferantenreklamation/Grid/OpenDetail";

const lieferantenreklamation = [
    {
        path: '/lieferantenreklamation',
        name: 'Reklamationen',
        meta: {
            requiresAuth: true,
            resource: "liefrekl"
        },
        component: () => import(/* webpackChunkName: "lieferantenreklamation" */ './Grid/Reklamationen.vue'),
        children: [
            {
                path: 'open/:reklamation_seq',
                beforeEnter: ReklamationOpenDetail
            }
        ]
    },
    {
        path: '/lieferantenreklamation/detail/:reklamation_seq?/:fw_ownership?',
        name: 'Reklamation',
        meta: {
            requiresAuth: true,
            resource: "liefrekl"
        },
        component: () => import(/* webpackChunkName: "lieferantenreklamation" */ './Details/Reklamation.vue'),
    },
    {
        path: '/Lieferantenreklamation/filter',
        name: 'ReklamationFilter',
        meta: {
            requiresAuth: true,
            resource: "liefrekl"
        },
        component: () => import(/* webpackChunkName: "lieferantenreklamation" */ './Grid/Filters/Reklamationen.vue')
    }

];

export default lieferantenreklamation;
