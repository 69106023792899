import i18n from "@/framework/locale";
import _ from "lodash";

/**
 * That's a base class for the {@link Ext.grid.plugin.FilterBar} plugin
 * and it's shared by both toolkits.
 * @private
 */
Ext.define('FW.grid.plugin.MultieditBar', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.fwmultiedit',
    config: {
        hidden: true,
        height: 42,
        headerListeners: null,
        gridListeners: null,
        grid: null,
        bar: null
    },

    headerListeners: {
        columnshow: 'onColumnShow',
        columnhide: 'onColumnHide',
        // add: 'onColumnAdd',
        // remove: 'onColumnRemove',
        columnschanged: 'onColumnsChanged',
        afterlayout: 'onHeaderLayout'
    },

    recalcTask: undefined,
    recalcCt: undefined,

    /**
     *  `"both"` (the default) - The plugin is added to both grids
     *  `"top"` - The plugin is added to the containing Panel
     *  `"locked"` - The plugin is added to the locked (left) grid
     *  `"normal"` - The plugin is added to the normal (right) grid
     *
     * @private
     */
    lockableScope: 'both',

    multieditBarBaseCls: 'fw-multiediting-ct',

    init(grid) {
        this.setGrid(grid);
    },

    initializeMultieditColumns(columns) {
        const len = columns.length;
        const bar = this.getBar();

        for (let i = 0; i < len; i++) {
            let field: any = this.createField(columns[i]);
            bar.add(field);
        }

    },

    destroy() {
        let me: any = this;
        me.setGrid(null);
        me.setBar(null);
        FW.grid.plugin.MultieditBar.superclass.constructor.apply(this, []);
    },

    /**
     * Show MDV bar
     */
    showMultieditBar() {
        const me = this;
        if (this.isDestroyed) {
            return;
        }

        me.getBar().show();
        me.getBar().getScrollable().syncWithPartners();
    },

    /**
     * Hide MDV bar
     */
    hideMultieditBar() {
        if (this.isDestroyed) {
            return;
        }

        this.getBar().hide();
    },

    showMultieditBarPartners() {
        this.showMultieditBar();
        this.lockingPartner.showMultieditBar();
    },

    hideMultieditBarPartners() {
        this.hideMultieditBar();
        this.lockingPartner.hideMultieditBar();
    },

    setupGridFunctions() {
        const me = this;
        const grid = me.getGrid();

        if (grid) {
            if (grid.isLocked) {
                const mainGrid = grid.ownerGrid;
                mainGrid.showMultieditBar = Ext.bind(me.showMultieditBarPartners, me);
                mainGrid.hideMultieditBar = Ext.bind(me.hideMultieditBarPartners, me);
            }

            grid.showMultieditBar = Ext.bind(me.showMultieditBar, me);
            grid.hideMultieditBar = Ext.bind(me.hideMultieditBar, me);
        }
    },

    getViewModel(grid) {
        return grid.getViewModel() ? grid.getViewModel() : grid.ownerGrid.getViewModel();
    },

    unsetupGridFunctions(grid) {
        if (grid) {
            grid.showMultieditBar = grid.hideMultieditBar = null;
        }
    },

    updateGrid(grid, oldGrid) {
        const me = this;
        const gridListeners = me.getGridListeners();
        const headerListeners = me.getHeaderListeners();

        me.listenersGrid = Ext.destroy(me.listenersGrid);
        me.unsetupGridFunctions(oldGrid);

        if (grid) {
            if (gridListeners) {
                me.listenersGrid = grid.on(Ext.apply({
                    scope: me,
                    destroyable: true
                }, gridListeners));
            }

            me.setupGridFunctions();
            me.createMultieditBar();
        }

        me.listenersHeader = Ext.destroy(me.listenersHeader);
        if (grid && headerListeners) {
            me.listenersHeader = grid.headerCt.on(Ext.apply(headerListeners, {
                scope: me,
                destroyable: true
            }));
        }
    },

    createMultieditBar() {
        const me: any = this;
        let bar = me.getGrid().addDocked({
            weight: 100,
            height: me.height,
            xtype: 'container',
            baseCls: me.multieditBarBaseCls,
            hidden: me.getHidden(),
            layout: {
                type: 'hbox',
                align: 'stretch'
            },
            childEls: ['innerCt'],
            scrollable: {
                x: false,
                y: false
            },
            listeners: {
                afterrender: 'onBarRender',
                scope: me
            }
        })[0];

        bar.addCls([Ext.baseCSSPrefix + 'grid-header-ct']);

        me.setBar(bar);
    },

    getItemId(column) {
        return column?.dataIndex?.replace('.', '-');
    },

    getDataIndex(column) {
        let dataIndex = column.dataIndex;
        if (dataIndex) {
            dataIndex = _.split(dataIndex, '.').pop();
        }

        return dataIndex;
    },

    adjustMultieditBarSize() {
        const bar = this.getBar();
        const headerCt = this.getGrid().headerCt;
        let width = 0;

        if (bar.rendered) {
            width = bar.innerCt.getWidth();

            if (headerCt.tooNarrow) {
                width += Ext.getScrollbarSize().width;
            }

            bar.innerCt.setWidth(width);
        }
    },

    getGridColumns() {
        return this.getGrid().columnManager.getColumns();
    },

    getField(column) {
        try {
            this.getBar().items.each((field) => {
                if (field.column == column) {
                    throw field;
                }
            })
        } catch (found) {
            return found;
        }

        return null;
    },

    createField(column) {
        const me = this;

        const editor = column.editor;
        const dataIndex: any = me.getDataIndex(column);
        let nullable: boolean = false;
        let widget: any = null;
        let id = me.getItemId(column) ? ('multiedit-' + me.getItemId(column)) : ('multiedit-' + column.id);

        /**
         * wenn Fields bereits initialisiert dann nicht noch einmal erstellen
         */
        if (_.indexOf(this.getBar().items.keys, id) !== -1) {
            return _.find(this.getBar().items.items, ['id', id]);
        }

        // Sanity Check, wenn wir eine Komponente erzeugen wollen, welche noch existiert (aus altem Grid oder vor State Reset), dann destroyen wir die Komponente
        if (Ext.getCmp(id)) {
            Ext.getCmp(id).destroy();
        }

        let cfg: any = {
            xtype: 'component',
            hidden: column.getHidden(),
            id: id
        };
        if (!editor) {
            cfg.cls = 'fw-multiediting-nofilter';
            cfg.height = me.height;
        } else {
            nullable = editor.nullable || false;
            if (!dataIndex) {
                Ext.raise('dataIndex is not defined for column', column);
            }

            let dBP = 'value';
            if (editor.xtype) {
                dBP = Ext.ClassManager.getByAlias('widget.' + editor.xtype).prototype.defaultBindProperty;
            }

            if (editor.xclass) {
                dBP = Ext.ClassManager.get(editor.xclass).prototype.defaultBindProperty;
            }

            const clean = /[{}]/g;
            const binding = {};
            binding[dBP] = '{multiediting.data.' + dataIndex + '}';
            this.getViewModel(me.grid).set(binding[dBP].replace(clean, ''), '');

            if (nullable) {
                binding['readOnly'] = '{multiediting.data.' + dataIndex + '_null}'
                this.getViewModel(me.grid).set(binding['readOnly'].replace(clean, ''), false);
            }

            cfg = Ext.apply({}, {bind: binding, allowBlank: true}, editor);
            cfg.id = id;

            if (editor.bind) {
                if (Ext.isString(editor.bind)) {
                    throw new Error('Kein Stringbind für Editor allowed');
                }
                Ext.apply(cfg.bind, editor.bind);
            }
        }

        if (editor && nullable) {
            cfg = Ext.apply(cfg, {flex: 1});
            cfg = {
                xtype: 'fieldcontainer',
                layout: 'hbox',
                padding: 0,
                hidden: cfg.hidden,
                items: [
                    cfg,
                    {
                        xtype: 'checkbox',
                        autoEl: {
                            tag: 'div',
                            'data-qtip': i18n.t('setnull').toString()
                        },
                        bind: {
                            value: '{multiediting.data.' + dataIndex + '_null}'
                        }
                    }
                ]
            };
        }

        widget = Ext.widget(cfg);
        widget.column = column;
        return widget;
    },

    // onColumnAdd(header, column, index) {
    //     let field = this.getField(column);
    //     if (!field) {
    //         field = this.createField(column);
    //     }
    //     this.getBar().insert(index, field);
    //     this.adjustMultieditBarSize();
    // },


    recalc: function() {
        const ct = this.recalcCt;
        const me = this;
        const bar = me.getBar();
        const columns = ct.getGridColumns();
        Ext.suspendLayouts();
        bar.removeAll(false)
        me.initializeMultieditColumns(columns);
        Ext.resumeLayouts(true);
    },

    onColumnsChanged: function(ct) {
        this.recalcCt = ct;
        if(!this.recalcTask) {
            this.recalcTask = new Ext.util.DelayedTask(this.recalc, this);
        }

        this.recalcTask.delay(50);
    },

    resizeMultiedit() {
        Ext.suspendLayouts();
        this.getBar().items.each((i) => {
            this.resizeField(i);
        });
        Ext.resumeLayouts(true);
    },

    resizeField(field, width) {
        const column = field.column;
        if (field && field.rendered && column && column.rendered) {
            if (width != null) {
                field.setWidth(width);
            } else {
                field.setWidth(column.getWidth());
            }

            field.setHidden(column.getHidden());
        }
    },

    // onColumnRemove() {
    //     this.adjustMultieditBarSize();
    // },

    onColumnShow(header, column) {
        const field = this.getField(column);
        if (field) {
            field.setHidden(false);
        }
    },

    onColumnHide(header, column) {
        const field = this.getField(column);
        if (field) {
            field.setHidden(true);
        }
    },

    onBarRender(bar) {
        const grid = this.getGrid();
        const columns = grid.columnManager.getColumns();
        grid.getView().getScrollable().addPartner(bar.getScrollable(), 'x');
        this.initializeMultieditColumns(columns);
    },

    onHeaderLayout() {
        this.resizeMultiedit();
        this.adjustMultieditBarSize();
    }
});
