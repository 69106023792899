import VueI18n from "vue-i18n";
import "moment-timezone";
import Vue from "vue";
import VueLocallization, {localDate} from '@/framework/plugins/Localization';


localDate.init(process.env.VUE_APP_DEFAULT_LOCALE, process.env.VUE_APP_DEFAULT_TIMEZONE);

Vue.use(VueI18n);
Vue.use(VueLocallization);
const locale = localDate.locale;

const i18n = new VueI18n({
    locale,
    fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
    silentTranslationWarn: process.env.VUE_APP_LOCALE_SILENT_WARNINGS === 'false' ? false : true
});
export default i18n;
