const rollen = [
    {
        path: '/administration/stammdaten/rollen',
        name: 'Rollen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_rollen" */ './Grid/Rollen.vue')
    },
    {
        path: '/administration/stammdaten/rollen/details/:rolle_seq?',
        name: 'Rolle',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_rollen" */ './Details/Rolle.vue')
    }
];

export default rollen;

