import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/app/pages/Login/Login.vue';
import IFrame from '@/framework/components/Modal/IFrame.vue';
import store from '@/app/store';
import {iResource} from "@/framework/stores/Permissions";
import _ from "lodash";
import auswertungen from "@/app/pages/Auswertungen/router";
import users from "@/app/pages/Users/router";
import verantwortliche from "@/app/pages/Verantwortliche/router";
import lieferanten from "@/app/pages/Stammdaten/Lieferanten/router";
import regionen from "@/app/pages/Stammdaten/Regionen/router";
import waehrungen from "@/app/pages/Stammdaten/Waehrungen/router";
import zertifikate from "@/app/pages/Zertifikate/router";
import lieferantenreklamation from "@/app/pages/Lieferantenreklamation/router";
import problemloesung from "@/app/pages/Problemloesung/router";
import kundenreklamation from "@/app/pages/Kundenreklamation/router";
import logs from "@/app/pages/Logs/router";
import registration from "@/app/pages/Registration/router";
import permissionrequest from "@/app/pages/PermissionRequest/router";
import apcu from "@/app/pages/Apcu/router";
import redis from "@/app/pages/Redis/router";
import passwordrequest from "@/app/pages/PasswordRequest/router";
import gruppen from "@/app/pages/Stammdaten/Gruppen/router";
import verantwortungsbereiche from "@/app/pages/Stammdaten/Verantwortungsbereiche/router";
import projekte from "@/app/pages/Stammdaten/Projekte/router";
import werke from "@/app/pages/Stammdaten/Werke/router";
import emailvorlagen from "@/app/pages/Stammdaten/Emailvorlagen/router";
import aduser from "@/app/pages/ActiveDirectory/AdUser/router";
import news from "@/app/pages/News/router";
import mails from "@/app/pages/Mails/router";
import downloads from "@/app/pages/Downloads/router";
import strukturen from "@/app/pages/Stammdaten/Strukturen/router";
import statussets from "@/app/pages/Stammdaten/Statussets/router";
import aktivitaeten from "@/app/pages/Aktivitaeten/router";
import besprechungen from "@/app/pages/Besprechungen/router";
import tags from "@/app/pages/Stammdaten/Tags/router";
import rollen from "@/app/pages/Stammdaten/Rollen/router";
import reminders from "@/app/pages/Reminder/router";
import vokabular from "@/app/pages/Vokabular/router";
import auditbereiche from "@/app/pages/Stammdaten/Auditbereiche/router";
import auditarten from "@/app/pages/Stammdaten/Auditarten/router";
import auditrollen from "@/app/pages/Stammdaten/Auditrollen/router";
import auditkategorien from "@/app/pages/Stammdaten/Auditkategorien/router";
import auditsysteme from "@/app/pages/Stammdaten/Auditsysteme/router";
import checklisten from "@/app/pages/Stammdaten/Checklisten/router";
import audits from "@/app/pages/Audits/router";
import berechtigungen_rollen from "@/app/pages/Stammdaten/Berechtigungen/Rollen/router";
import berechtigungen_rechte from "@/app/pages/Stammdaten/Berechtigungen/Rechte/router";
import commoditycodes from "@/app/pages/Stammdaten/Commoditycodes/router";
import communications from "@/app/pages/Communications/router";
import reviews from "@/app/pages/Reviews/router";
import reviewarten from "@/app/pages/Stammdaten/Reviewarten/router";
import teile from "@/app/pages/Stammdaten/Teile/router";
import produktgruppen from "@/app/pages/Stammdaten/Produktgruppen/router";
import meilensteine from "@/app/pages/Stammdaten/Meilensteine/router";
import abteilungen from "@/app/pages/Stammdaten/Abteilungen/router";
import selbstauskuenfte from "@/app/pages/Selbstauskuenfte/router";
import anfragen from "@/app/pages/Anfragen/router";
import bemusterungenStammdaten from "@/app/pages/Stammdaten/Bemusterungen/router";
import Maintenance from "@/app/pages/Home/Maintenance.vue";
import sap from "@/app/pages/Sap/router";
import kpi from "@/app/pages/Kpi/router";
import kpitree from "@/app/pages/Stammdaten/Kpi/router";
import kunden from "@/app/pages/Stammdaten/Kunden/router";
import bemusterungen from "@/app/pages/Bemusterungen/router";
import verteiler from "@/app/pages/Stammdaten/Verteiler/router";
import fehlerkataloge from "@/app/pages/Stammdaten/Fehlerkataloge/router";
import fehlerorte from "@/app/pages/Stammdaten/Fehlerorte/router";
import stichworte from "@/app/pages/Stammdaten/Stichworte/router";
import NewsPage from "@/app/pages/Home/News.vue";
import lims_stammdaten from "@/app/pages/Stammdaten/Lims/router";
import lims from "@/app/pages/Lims/router";

Vue.use(Router);

// @ts-ignore
const router = new Router({
    mode: 'hash',
    linkActiveClass: "", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes: [
        {
            // wenn route nicht gefunden wird
            path: '*',
            redirect: '/'
        },
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './pages/Home/Home.vue'),
        },

        {
            path: '/news',
            name: 'News',
            component: NewsPage
        },
        {
            path: '/test',
            name: 'Test',
            component: () => import(/* webpackChunkName: "test" */ './pages/Home/Test.vue'),
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/loginas',
            name: 'LoginAs',
            component: () => import(/* webpackChunkName: "loginas" */ './pages/Login/LoginAs.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "profile" */ './pages/Profile/Profile.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/iframe/:url',
            name: 'IFrame',
            component: IFrame
        },
        {
            path: '/maintenance',
            name: 'Maintenance',
            component: Maintenance

        },
        ...selbstauskuenfte,
        ...teile,
        ...produktgruppen,
        ...reviewarten,
        ...reviews,
        ...commoditycodes,
        ...communications,
        ...berechtigungen_rechte,
        ...audits,
        ...auditsysteme,
        ...auditkategorien,
        ...auditrollen,
        ...auditarten,
        ...auditbereiche,
        ...reminders,
        ...tags,
        ...rollen,
        ...aktivitaeten,
        ...besprechungen,
        ...statussets,
        ...strukturen,
        ...downloads,
        ...news,
        ...mails,
        ...aduser,
        ...emailvorlagen,
        ...werke,
        ...projekte,
        ...verantwortungsbereiche,
        ...redis,
        ...apcu,
        ...auswertungen,
        ...users,
        ...verantwortliche,
        ...lieferanten,
        ...regionen,
        ...waehrungen,
        ...gruppen,
        ...zertifikate,
        ...lieferantenreklamation,
        ...problemloesung,
        ...kundenreklamation,
        ...logs,
        ...registration,
        ...permissionrequest,
        ...passwordrequest,
        ...vokabular,
        ...checklisten,
        ...berechtigungen_rollen,
        ...meilensteine,
        ...abteilungen,
        ...anfragen,
        ...bemusterungenStammdaten,
        ...sap,
        ...kpi,
        ...kpitree,
        ...kunden,
        ...bemusterungen,
        ...verteiler,
        ...fehlerkataloge,
        ...fehlerorte,
        ...stichworte,
        ...lims_stammdaten,
        ...lims,
    ]
});


router.beforeEach((to, from, next) => {

    store.commit('application/lastRoute', to.fullPath);

    if (!store.getters['application/initialized']) {
        return;
    }

    if (to.path !== '/profile' && store.getters['authentication/loggedIn'] && store.getters['authentication/profileUpdate']) {
        next({
            path: '/profile',
            query: {redirect: to.fullPath}
        });
        return;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters['authentication/loggedIn']) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

class PermissionsGuard {

    public route(to, from, next) {

        if (!to.meta.resource || store.getters['permissions/isAdmin']) {
            next();
            return;
        }

        store.dispatch('permissions/loadPermission', [to.meta.resource]).then(() => {
            const resource: iResource = store.getters['permissions/getResource'](to.meta.resource);

            if (!resource || _.indexOf(resource.permission, 'select') === -1) {
                next(from.fullPath);
                return;
            }

            next();
        });
    }

}

export const permission = new PermissionsGuard();
export default router;
