import '../../data/SelectionModel';
declare var FW: any;



Ext.define('FW.form.field.Tag', {
    extend: 'Ext.form.field.Tag',
    alternateClassName: 'FW.form.Tag',
    alias: ['widget.fwtag'],

    minChars: 0,
    queryParam: 'query',
    queryMode: 'remote',

    displayField: 'name',
    valueField: 'value',

    typeAhead: true,

    url: "",

    store: {
        xclass: 'FW.data.ApiStore',
        model: 'FW.data.SelectionModel',
        proxy: {
            type: 'api',
            url: '',
            reader: {
                type: 'json',
                rootProperty: 'data.body.results',
                successProperty: 'data.body.success'
            },
        }
    },

    constructor(cfg) {
        if (!cfg) {
            cfg = {};
        }

        if (!cfg.url) {
            throw new Error('Url parameter missing');
        }

        this.store.proxy.url = cfg.url;

        FW.form.field.ComboBox.superclass.constructor.apply(this, [cfg]);
    }

});
