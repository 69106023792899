Ext.define('FW.grid.mixins.RowEdit', {
    extend: 'FW.grid.mixins.CellEdit',

    beforeConstructor(cfg) {
        Ext.apply(cfg, {
            plugins: {
                rowediting: {
                    clicksToMoveEditor: 1,
                    autoCancel: false
                }
            },
            controller: 'fweditgrid',
        });

    }

});
