import router from "@/app/router";
import * as _ from "lodash";
import swal from "sweetalert2";
import i18n from "@/framework/locale";

class CopyDetaillinkClass {
    public handle(url: any, scope: any): any {
        const hostname: string = window.location.href.replace(window.location.hash, '');
        const title = i18n.t('copy_link_title').toString();
        this.showLink(title, hostname + '#' + url, (scope.$el as any));
    }

    public showLink(title, url, target) {
        swal({
            type: 'info',
            title,
            customClass: 'link',
            input: 'textarea',
            inputAttributes: {
                readonly: 'readonly',
                rows: '2'
            },
            inputValue: url,
            confirmButtonText: i18n.t('copy_and_close').toString(),
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            focusCancel: true,
            backdrop: false,
            target,
            preConfirm: (login) => {
                navigator.clipboard.writeText(url);
            },
        });
    }

    public getUrl(baseUrl: string, scope: any): any {
        const r = router;
        const result = r.match(baseUrl);
        const regex = /^\/\w+/;
        const match = result.fullPath.match(regex);

        if (!match) {
            return "";
        }

        const child = this.getChildRoute(match[0]);
        if (!child) {
            return "";
        }
        let url = match[0] + '/' + child.path;

        _.forEach(_.keys(result.params), (param) => {
            url = url.replace(':' + param, _.get(result.params, param));
        })

        return url;
    }

    public hasDetailLink(scope: any):boolean {
        return this.getChildRoute(scope) !== "";
    }

    public getChildRoute(path): any {
        const r = router;

        const children = _.find(r.options.routes, ['path', path])?.children;

        if (!children) {
            return undefined;
        }
        return _.find(children, function(o) { return o.beforeEnter });
    }
}

const CopyDetailLink = new CopyDetaillinkClass();
export default CopyDetailLink;