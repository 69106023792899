import './plugin/TreeStateful';
import './controller/Tree';
import watcher from "@/app/components/DefaultTreeWatcher";
import * as _ from "lodash";
import FilterWatcher from "@/framework/extjs/watcher/FilterWatcher";

declare var FW: any;

Ext.define('FW.tree.Panel', {
    extend: 'Ext.tree.Panel',
    controller: 'fwtree',
    plugins: ['treestateful', 'statereset'], // TreeStateful plugin
    region: 'west',
    split: true,
    minWidth: 300,
    width: 300,
    rootVisible: false,
    reference: 'tree',

    watcher: [],

    viewModel: {},


    constructor(cfg) {
        if (!cfg) {
            cfg = {};
        }

        this.watcher = [
            ...watcher,
            ...this.watcher,
            ...this.convertWatchedStore(this.watchedStore)
        ];

        const columns = cfg.columns || _.map(this.columns, _.clone);

        if (columns.length) {
            cfg.columns = columns;
        }


        FW.tree.Panel.superclass.constructor.apply(this, [cfg]);

        if (!this.getStore().isEmptyStore) {
            this.initStore();
        }
    },

    convertWatchedStore(filter) {
        if (!filter) {
            return [];
        }

        const retval: any = [];

        _.each(filter, (value, key) => {
            const obj = new Object();
            obj[key] = new FilterWatcher({filter: value});
            retval.push(obj);
        });

        return retval;
    },

    initStore() {
        const controller = this.lookupController();
        this.getStore().on('exception', 'onException');
        controller.watchFilter();
    },

    listeners: {
        destroy: {
            single: true,
            fn(panel, eOpts) {
                if (panel.resizeEventHandler) {
                    Ext.destroy(panel.resizeEventHandler);
                }
            }
        }
    }

});
