import moment from "moment";

Ext.define('FW.grid.column.TargetDateColumn', {
    extend: 'Ext.grid.column.Date',
    alias: ['widget.targetdatecolumn'],
    alternateClassName: 'FW.grid.TargetDateColumn',

    config: {
        enddateIndex: null,
        warningDays: 1
    },

    renderer(value, metaData, record, rowIndex, colIndex, store, view) {
        const diff = moment(value).diff(moment(), 'days');

        if (metaData.column.enddateIndex) {
            const finished = record.get(metaData.column.enddateIndex);

            if (finished) {
                return FW.grid.column.TargetDateColumn.superclass.defaultRenderer.apply(this, [value, metaData, record, rowIndex, colIndex, store, view]);
            }
        }

        if (diff >= 0 && diff <= metaData.column.warningDays) {
            metaData.tdCls = 'warning';
        } else if (diff < 0) {
            metaData.tdCls = 'negative';
        }

        return FW.grid.column.TargetDateColumn.superclass.defaultRenderer.apply(this, [value, metaData, record, rowIndex, colIndex, store, view]);
    }
});
