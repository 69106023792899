Ext.define('FW.grid.column.StepsColumn', {
    extend: 'Ext.grid.column.Template',
    alias: ['widget.stepscolumn'],
    alternateClassName: 'FW.grid.StepsColumn',
    tpl: '<tpl for="."><i data-qtip="{tooltip}" class="{step}"></i></tpl>',
    variableRowHeight: true,
    sortable: false,
    filter: false,
    minWidth: 210,
    tdCls: 'fw-grid-steps-cell',

    defaultRenderer: function(value, meta, record) {
        var data = record.get(this.dataIndex);
        return this.tpl.apply(data);
    },
});
