const origsetValue = Ext.grid.plugin.filterbar.filters.List.prototype.setValue;

Ext.override(Ext.grid.plugin.filterbar.filters.List, {

    setValue: function (value) {
        const me: any = this;
        const operator: any = me.getOperator();
        const emptyOp: any = (operator === 'empty' || operator === 'nempty');

        if (!Ext.isEmpty(value) && !emptyOp) {
            const displayField: any = me.getField().getDisplayField();
            let displayValue: any = me.getField().getSelectedRecord()?.get(displayField);

            if (!displayValue) {
                displayValue = me.getField().rawValue;
            }

            me.filter.setDisplayValue(displayValue);
        }

        origsetValue.apply(this, arguments);
    }
});
