import api from './Api';
import {AxiosPromise} from "axios";
import Vue from 'vue';

class Permissions {
    public load(resources: string[]): AxiosPromise {
        return api.post('users/permissions/permissions', {
            resources
        });
    }

    public loadNavigation(): AxiosPromise {
        return api.post('users/navigation/index');
    }
}

const permissionService = new Permissions();
export default permissionService;
