
    import { Component, Prop } from 'vue-property-decorator';
    import InputSelect from '@/framework/components/Form/Elements/Select/InputSelect.vue';

    @Component({})
    export default class TeileSelect extends InputSelect {
        @Prop({ default: '/inforum/lists/teile' }) public remote: string;
        @Prop({ default: true }) public clearable: boolean;
        @Prop({ default: true }) public searchable: boolean;
        @Prop({ default: 'teil_seq' }) public remotePrimary: string;
    }
