import {Component, Prop, Vue} from 'vue-property-decorator';
import _ from "lodash";
import readonly from "@/framework/mixins/Readonly";

@Component({
    mixins: [readonly],
    watch: {
        focus: 'setFocus',
    }
})
export default class BaseElement extends Vue {

    get validationObj(): any {
        if (this.error.length) {
            return {
                $error: this.error,
                validated: false
            };
        }

        return this.validation;
    }

    get elementSettings(): any {
        const vm = this;
        const defaults = {};

        _.keys(this.settings).forEach((key) => {
            defaults[key] = this.settings[key];
        });

        return defaults;
    }

    get extraCss(): string {
        if (this.hasExtraLabel) {
            return this.extra;
        }

        return "";
    }

    get hasExtraLabel() {
        return !!this.$slots.left || !!this.$slots.right;
    }

    get isDisabled(): boolean {
        return this.disabled !== undefined && this.disabled !== false;
    }

    get isPlain(): boolean {
        return this.plain !== undefined && this.plain !== false;
    }

    get isFocus(): boolean {
        return this.focus !== undefined && this.focus !== false;
    }

    get isHideLabel(): boolean {
        return this.hideLabel !== undefined && this.hideLabel !== false;
    }

    get name(): string {
        return new Date().getTime().toString();
    }

    get isClearable(): boolean {
        return this.clearable !== undefined && this.clearable !== false;
    }

    @Prop() public label: string;
    // @Prop({default: false}) public readonly: boolean;
    @Prop({default: false}) public disabled: boolean;
    @Prop() public tooltip: string;
    @Prop({default: false}) public plain: boolean;
    @Prop({default: false}) public focus: boolean;
    @Prop({default: () => ({})}) public settings: any;
    @Prop({default: ''}) public placeholder: string;
    @Prop({default: false}) public hideLabel: boolean;
    @Prop({default: 'ui right labeled input'}) public extra: string;
    @Prop({default: ''}) public error: string;
    @Prop({default: ''}) public autocomplete: string;
    @Prop({default: false}) public clearable: boolean;


    public validation: any = {
        validated: false,
        $error: ''
    };

    public hint: string = '';

    protected parentReadonly: boolean = false;

    public mounted() {
        this.$nextTick(() => {
            this.setFocus(this.isFocus);
            this.init();
        });
    }

    public setFocus(focus?: boolean) {
        // TODO: Needed?
    }

    public setHint(hint) {
        this.hint = hint;
    }

    protected init() {
        return;
    }

    public clear(e: Event) {
        e?.stopPropagation();
        this.$emit('input', null);
    }
}

