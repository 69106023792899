
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
import {Response} from "@/framework/services/Response";
import FileInterface, {DefaultFile, FileUpload} from "./FileInterface";
import FilePreview from "@/framework/components/Form/Elements/Preview/FilePreview.vue";
import FileProgress from "@/framework/components/Form/Elements/Progress/FileProgress.vue";
import Modal from "@/framework/components/Modal/Modal.vue";

import $ from "jquery";
import * as _ from "lodash";



@Component({
    model: {
        prop: 'value',
        event: 'change'
    },

    components: {FilePreview, FileProgress, Modal}

})
export default class InputMultiFile extends BaseElement {
    get hasFiles() {
        return !!this.value && this.value.length > 0;
    }
    @Prop() public value!: FileInterface[];
    @Prop() public remote!: string;
    @Prop({default: ""}) public accept: string;
    @Prop({default: true}) public allowlink: boolean;
    @Prop({default: () => ({})}) public param: any;

    protected dropable: boolean = false;

    protected files: FileUpload[] = [];
    protected loading: boolean = false;
    protected defaultFile: FileInterface = DefaultFile;
    protected modalSettigns: any = {
        modal: {
            detachable: false
        }
    };

    get linkButton(): boolean {
        return !!(this.link && this.linkText);
    }

    get fileIcon(): string {
        return this.dropable ? "cloud arrow up icon" : "file circle plus outline icon";
    }


    protected link: string = "";
    protected linkText: string = "";

    public updateValue() {
        const files_ref = (this.$refs as any).input.files;
        if (!files_ref) { return false; }

        _.forEach(files_ref, this.addFile);
        (this.$refs as any).input.value = null;
    }

    protected uploadText(file): any {
        return file.uploadError ? file.uploadError : this.$t('uploading_files');
    }

    protected openFileDialog() {
        (this.$refs as any).input.click();
    }

    protected preview(file: FileInterface): string {
        if (!file.preview) { return this.defaultFile.preview; }

        return file.preview;
    }

    protected removeFile(file: FileInterface) {
        if (!file) { return false; }

        const retval = _.differenceBy(this.value, [file], 'file_id');
        this.$emit('change', retval);
    }

    public addFile(file: File) {
        const f = new FileUpload(file);
        this.files.push(f);
        this.$emit('files', this.files);
        this.submitFile(f);
    }

    protected completeUpload(fileUpload: FileUpload) {
        fileUpload.complete = true;
        this.files = _.differenceBy(this.files, [fileUpload], 'uid');
        this.$emit('files', this.files);
    }

    protected submitFile(fileUpload: FileUpload) {
        const formData = new FormData();

        if (_.isObject(this.param)) {
            _.forIn(this.param, (value, key) => {
                formData.set(key, value);
            })
        }

        formData.append('file', fileUpload.file);
        fileUpload.uploadError = "";

        this.$http.post(this.remote,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },

                onUploadProgress: ( progressEvent ) => {
                    fileUpload.uploadPercentage = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );

                }
            }
        ).then((data) => {
            const resp = Response.factory(data).dispatch();
            let file = null;

            if (resp.data()) {
                file = resp.data();
            }

            const files = this.value;

            if (!_.isArray(files)) {
                throw new Error('binded value is not an array - check php property initialize');
            }

            if (file !== null) {
                files.push(file);
            }
            this.completeUpload(fileUpload);
            this.$forceUpdate();
            this.$emit('change', files);
        }).catch((error) => {
            const resp = Response.factory(error);
            fileUpload.uploadError = resp.data();
        });

    }

    protected onAddLink() {
        (this.$refs.modal as any).hide();
        const obj: any = {
            link: this.link,
            text: this.linkText
        };

        const data = new Blob([JSON.stringify(obj)], {
            type: "text/link",
        });
        const file = new FileUpload(data as any);

        this.link = "";
        this.linkText = "";

        this.submitFile(file);
    }

    protected showModal() {
        const context = $((this.$parent as any).$el);
        (this.$refs.modal as any).show({context, autofocus: true});
    }

    protected onHideModal() {
        (this.$refs.modal as any).hide();
    }


    public onDrop($event) {
        if ((this as any).isReadonly) {
            $event.preventDefault();
            $event.cancelBubble = true;
            return false;
        }

        if ($event.dataTransfer.items.length > 0) {

            _.forEach($event.dataTransfer.items, (item: any) => {
                if (item.kind === 'file') {
                    this.addFile(item.getAsFile());
                }
            });
        }
        this.dropable = false;

        $event.preventDefault();
    }

    public onDragOver($event) {

        if ((this as any).isReadonly) {
            $event.preventDefault();
            $event.cancelBubble = true;
            return false;
        }

        this.dropable = true;
    }

    public onDragLeave($event) {
        this.dropable = false;
    }

}
