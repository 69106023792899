import {DirectiveOptions} from "vue";

const textWidth = (_text: any): number => {
    const fakeEl = $('<span>').hide().appendTo(document.body).text(_text).css({whiteSpace: "pre"});
    const width = fakeEl.width();
    fakeEl.remove();
    if (width) {
        return width;
    }

    return 0;
};

const applyPopup = (el, binding, vnode) => {
    const jEl: any = $(el);
    jEl.popup('destroy');
    let content: any = el.innerHTML;
    if (el.value) {
        content = el.value;
    }

    if (content) {
        if (jEl && jEl.width() < textWidth(content)) {
            vnode.context.$nextTick(() => {
                $(el).popup({
                    delay: {
                        show: 800,
                        hide: 0
                    },
                    content
                });
            });
        }
    } else {
        return;
    }
};

const directive: DirectiveOptions = {
    bind: (el, binding, vnode) => {
        if(binding.value === true) {
            applyPopup(el, binding, vnode);
        }
    },
    inserted: (el, binding, vnode) => {
        if(binding.value === true) {
            applyPopup(el, binding, vnode);
        }
    },
    update: (el, binding, vnode) => {
        if(binding.value === true) {
            applyPopup(el, binding, vnode);
        }
    },
    unbind: (el) => {
        ($(el) as any).popup('destroy');
    }
};

export default directive;
