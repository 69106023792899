const origcreateFilter = Ext.grid.plugin.filterbar.filters.Base.prototype.createFilter;

Ext.override(Ext.grid.plugin.filterbar.filters.Base, {

    config: {
        updateBuffer: 1000
    },

    createFilter(config, key) {
        config.type = config.type ? config.type : this.type;
        return origcreateFilter.apply(this, [config, key]);
    },

    getDataIndex() {

        if (!this.dataIndex && this.getColumn().sortIndex) {
            this.dataIndex = this.getColumn().sortIndex;
        }

        return this.dataIndex;
    }

});
