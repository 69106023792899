
const projekte = [
    {
        path: '/administration/stammdaten/projekte',
        name: 'Projekte',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_projekte" */ './Grid/Projekte.vue')
    },
    {
        path: '/administration/stammdaten/projekte/details/:projekt?',
        name: 'Projekt',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_projekte" */ './Details/Projekt.vue')
    }
];

export default projekte;