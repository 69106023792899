
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import ProjekteSelect from "./ProjekteSelect.vue";
    import * as _ from 'lodash';

    @Component({})
    export default class ProjekteASILSelect extends ProjekteSelect {
        protected template: any = _.template('<%= name %> (<small>ASIL: <%= asil %></small>)');

        protected getOptionTemplate(option): any {
            const html = this.template(option);
            return html;
        }
    }
