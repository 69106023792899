import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import {ProblemeGridConfig} from "@/app/pages/Problemloesung/Grid/Config";
import i18n from "@/framework/locale";

export default function ProblemloesungOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.problem_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(ProblemeGridConfig.id, to.params.problem_seq),
            title: i18n.t(ProblemeGridConfig.title),
            url: '/problemloesung/detail/' + to.params.problem_seq
        }));
    }
}