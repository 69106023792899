import {Component, Vue} from 'vue-property-decorator';
import {mapActions, mapState} from "vuex";
import PlantSelection from '@/framework/components/Navigation/PlantSelection/PlantSelection.vue';
import LanguageSelection from './LanguageSelection/LanguageSelection.vue';
import Messages from './Messages.vue';
import DropdownMenu from '@/framework/components/Navigation/DropdownMenu/DropdownMenu.vue';
import UserMenu from '@/framework/components/Navigation/UserMenu/UserMenu.vue';
import SupplierInfo from './SupplierInfo.vue';
import LoginMenu from "@/framework/components/Navigation/LoginMenu/LoginMenu.vue";

@Component({
    computed: {
        ...mapState('authentication', ['status', 'user']),
        ...mapState('preferences', ['settings']),
        ...mapState('permissions', ['admin', 'supplier', 'navigation']),
        ...mapState('application', ['version', 'maintenance']),
    },

    components: {
        PlantSelection,
        LanguageSelection,
        Messages,
        UserMenu,
        DropdownMenu,
        SupplierInfo,
        LoginMenu
    },

    methods: {
        ...mapActions('preferences', ['setLocale', 'set']),
    }

})
export default class Navigation extends Vue {
    public getUrl(url: string): string {
        return '/iframe' + (!url.startsWith('/') ? '/' : '') + encodeURIComponent(url);
    }

    public isActive(routerlink: string):boolean {
        if (!routerlink) {
            return false;
        }

        return this.$route.path.indexOf(routerlink) === 0;
    }
}
