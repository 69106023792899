import './Panel';
import i18n from "@/framework/locale";
import {dirtySwal, errorSwal, savedSwal} from "@/framework/util/Swals";
import _ from "lodash";
import router from "@/app/router";
import Unload from "@/framework/services/Unload";

declare var FW: any;

Ext.define('FW.grid.controller.EditPanel', {
    extend: 'FW.grid.controller.Panel',
    alias: 'controller.fweditgrid',


    initViewModel() {
        const view = this.getView();

        let unload: boolean = false;

        if (view.getViewModel()) {

            const viewmodel = view.getViewModel();

            const fn = router.beforeEach((to, from, next) => {
                if (viewmodel && viewmodel.data && viewmodel.get('gridStore.dirty')) {
                    dirtySwal().then((res) => {
                        if (res.dismiss) {
                            fn();
                            Unload.clear();
                            next();
                        } else {
                            next(false);
                        }
                    });
                } else {
                    fn();
                    Unload.clear();
                    next();
                }

            });

            view.getViewModel().bind('{gridStore.dirty}', (value) => {
                if (value) {
                    unload = true;
                    Unload.register((event) => {
                        const msg = i18n.t('dirty_confirm_msg').toString();
                        event.preventDefault();
                        event.returnValue = msg;
                        return msg;
                    });
                } else if (unload) {
                    Unload.clear();
                }
            });
        }
    },

    onSaveButtonClick() {
        const view = this.getView();
        const store = view.getStore();

        const rejectedRecs = store.getRejectRecords();
        if (rejectedRecs.length) {

            let rec: any = null;

            _.forEach(rejectedRecs, (record, index, modifiedArray) => {
                if (!record.isValid()) {
                    rec = record;
                }
            });

            if (rec && rec.validation) {
                let col = -1;

                _.forEach(rec.validation.data, (value, key) => {

                    if (value !== true) {
                        const header = view.getColumnManager().getHeaderByDataIndex(key);
                        if(header) {
                            const headerIndex = header.fullColumnIndex;
                            col = (col == -1 || col > headerIndex) ? headerIndex : col;
                        }
                    }
                });

                if (col > -1) {
                    setTimeout(() => {
                        view.findPlugin('cellediting').startEditByPosition(
                            new Ext.grid.CellContext(view.getView()).setPosition(rec, col)
                        );
                    }, 0);

                    return false;
                }
            }
            view.setSelection(null);
        }



        store.on('beforesync', () => {
            view.mask(i18n.t('speichern'));
        }, this, {single: true});


        store.sync({
            callback: () => {
                view.unmask();
            },
            success: () => {
                savedSwal();
            },
            failure: (batch, options) => {
                if (batch && _.isObject(batch.operations[0]) && (batch.operations[0] as any).getError) {
                    errorSwal(i18n.t('fehler').toString(), (batch.operations[0] as any).getError());
                }
            }
        });
    },

    createModel() {
        return {};
    },

    onNewButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const rec = store.createModel(this.createModel());

        store.insert(0, rec);
        view.findPlugin('cellediting').startEditByPosition(
            new Ext.grid.CellContext(view.getView()).setPosition(rec, view.startEditColumnIndex || 2)
        );

    },

    onCopyButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const sel = view.getSelection();

        const newRecs: any[] = [];
        _.forEach(sel, (item: any) => {
            const rec = item.copy(null);
            store.insert(0, rec);
            newRecs.push(rec);
        });

        view.setSelection(newRecs);
    },

    onCancelButtonClick() {
        const view = this.getView();
        const store = view.getStore();

        view.findPlugin('cellediting').cancelEdit();
        store.rejectChanges();
        store.reload();
    },

    onBeforeEdit(editor, context) {
        const view = this.getView();

        if (!view.getViewModel().get('saveBtn.visible')) {
            return false;
        }
        view.getViewModel().set('editRecord', context.record);
    },

    onEdit(editor, context) {
        const originalValue: string = _.isString(context.value) && _.isNull(context.originalValue) ? '' : context.originalValue;

        if (context.value !== originalValue) {
            const view = this.getView();
            const store = view.getStore();
            view.getViewModel().set('gridStore.dirty', true);
            store.fireEvent('datachanged', store);
        }
    },

    onCancelEdit(editor, context) {
        this.onEdit(editor, context);
    },

    onValidateEdit(editor, context) {
        const originalValue: string = _.isString(context.value) && _.isNull(context.originalValue) ? '' : context.originalValue;

        if (context.value === originalValue) {
            context.cancel = true;
            return false;
        }

        if (context && context.column && context.column.displayIndex && (context.column.getEditor() instanceof FW.form.field.ComboBox || context.column.getEditor() instanceof FW.form.field.Tag)) {
            const displayValue = context.column.getEditor().getDisplayValue();
            context.record.set(context.column.displayIndex, displayValue);
        }

        if (context && (!context.column.getEditor().allowBlank && context.column.getEditor().allowBlank !== undefined) && !context.value) {
            return false;
        }

    },

    onBeforePageing(toolbar, page) {
        const view = this.getView();
        const dirty = view.getViewModel().get('gridStore.dirty');

        if (dirty) {
            dirtySwal().then((res) => {
                if (res.dismiss) {
                    view.getViewModel().set('gridStore.dirty', false);
                    view.getStore().loadPage(page);
                }
            });

            return false;
        }

    },

    onException(grid, response, operation) {

    }


});
