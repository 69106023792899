import store from '@/app/store';
import Task from '@/framework/components/Taskbar/Task';
import WindowManager from '@/framework/util/WindowManager';
import { AnfragenGridConfig }  from '@/app/pages/Anfragen/Grid/Config';
import i18n from '@/framework/locale';

export function AnfrageOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.anfrage_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(AnfragenGridConfig.id, to.params.anfrage_seq),
            title: i18n.t(AnfragenGridConfig.title),
            url: '/anfragen/auktion/' + to.params.anfrage_seq,
        }));
    }
}

export function AngebotOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.anfrage_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(AnfragenGridConfig.id, to.params.anfrage_seq),
            title: i18n.t(AnfragenGridConfig.title),
            url: '/angebote/detail/' + to.params.anfrage_seq,
        }));
    }
}
