
const aduser = [
    {
        path: '/administration/activedir/aduser',
        name: 'AdUser',
        meta: {
            requiresAuth: true,
            resource: "activedir"
        },
        component: () => import(/* webpackChunkName: "activedir_aduser" */ './Grid/Adusers.vue')
    },
];

export default aduser;