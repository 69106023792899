import Vue from 'vue';
import preferencesService from "@/framework/services/Preferences";
import _ from 'lodash';
import moment from "moment";
import {checkReactive} from "@/app/store";
import {Response} from "@/framework/services/Response";
import i18n from "@/framework/locale";

export const initialState = {
    settings: {
        locale: process.env.VUE_APP_DEFAULT_LOCALE!,
        zeitzone: process.env.VUE_APP_DEFAULT_TIMEZONE!
    }
};

export const preferences = {
    namespaced: true,
    state: initialState,
    actions: {
        getPreferences({commit, rootGetters}) {
            const loggedIn = rootGetters['authentication/loggedIn'];

            if (!loggedIn) {
                return false;
            }

            return preferencesService.load().then(
                (response) => {
                    if (response && response.data && response.data.body) {
                        commit('preferencesChange', response.data.body);
                    }

                },
                (error) => {
                    return [];
                }
            );

        },

        setPlant({dispatch, commit, rootGetters}, werk_seq) {
            const loggedIn = rootGetters['authentication/loggedIn'];

            if (!loggedIn) {
                return false;
            }

            preferencesService.setPlant(werk_seq).then((data) => {
                const resp = Response.factory(data).dispatch();
                commit('setPlant', resp.data());
            });
        },

        setLocale({dispatch, commit}, locale) {
            commit('setLocale', locale);
        },

        setTimezone({dispatch, commit}, locale) {
            commit('setTimezone', locale);
        },

        set({dispatch, commit}, {key, value}) {
            commit('set', {key, value});
        },
        unset({commit}, key: any) {
            commit('unset', key);
        },
        reset({commit}) {
            commit('reset');
        },
        change({commit}, data) {
            commit('preferencesChange', data);
        }
    },
    getters: {
        getPlant: (state) => {
            return state.settings.werk_seq;
        },

        getLocale: (state) => {
            return state.settings.locale;
        },

        getTimezone: (state) => {
            return state.settings.zeitzone;
        },

        get: (state) => (key) => {
            return state.settings[key];
        },

        plant: (state, getters, rootGetters) => {
            const plants = rootGetters.authentication.user ? rootGetters.authentication.user.plants : null;
            const werk_seq = state.settings.werk_seq;

            if (!plants || !werk_seq || !plants[werk_seq]) {
                return i18n.t('werk');
            }

            return plants[werk_seq];
        }
    },
    mutations: {
        preferencesChange(state: any, settings: any) {
            state.settings = _.merge({}, initialState.settings, settings); // Merge mit initialState
            if (settings.sprache) {
                moment.locale(settings.sprache);
            }
        },
        setLocale(state: any, locale: any) {
            state.settings.locale = locale;
            preferencesService.setLocale(locale).then(() => {
                (document.location as any).reload();
            });
        },
        setTimezone(state: any, timezone: any) {
            state.settings.zeitzone = timezone;
            preferencesService.save([{zeitzone: timezone}]);
        },
        setPlant(state: any, werk_seq: any) {
            state.settings.werk_seq = werk_seq;
        },

        set(state: any, {key, value}) {
            checkReactive(state.settings, key);
            Vue.set(state.settings, key, value);
            preferencesService.save([_.set({}, key, value)]);
        },
        unset(state: any, {key}) {
            Vue.delete(state.settings, key);

            preferencesService.save([_.set({}, key, null)]);
        },
        reset(state: any) {
            const locale = state.settings.locale;
            // const zeitzone = state.settings.zeitzone;
            _.assign(state, initialState, {settings: {locale}});
        }
    }
};
