
const emailvorlagen = [
    {
        path: '/administration/stammdaten/emailvorlagen',
        name: 'Emailvorlagen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_emailvorlagen" */ './Grid/Emailvorlagen.vue')
    },
    {
        path: '/administration/stammdaten/emailvorlagen/details/:email_vorlage_seq?',
        name: 'Emailvorlage',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_emailvorlagen" */ './Details/Emailvorlage.vue')
    }
];

export default emailvorlagen;