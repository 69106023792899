Ext.override(Ext.data.field.Boolean, {
    trueRe: /^\s*(?:true|yes|on|1|j)\s*$/i,
});


Ext.define('FW.data.field.Boolean', {
    extend: 'Ext.data.field.Boolean',
    alias: [
        'data.field.fw_bool',
        'data.field.fw_boolean'
    ],

    return: {
        true: 'j',
        false: 'n'
    },

    serialize(v) {
        if (typeof v !== 'boolean') {
            return v;
        }

        return v === true ? this.return.true : this.return.false;
    }
});
