const meilensteine = [
    {
        path: '/administration/stammdaten/meilensteine',
        name: 'Meilensteine',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_meilensteine" */ './Grid/Meilensteine.vue')
    },
];

export default meilensteine;

