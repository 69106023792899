
const strukturen = [
    {
        path: '/administration/stammdaten/strukturen',
        name: 'Strukturen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_strukturen" */ './Grid/Strukturen.vue')
    },
    {
        path: '/administration/stammdaten/strukturen/details/:struktur_seq?',
        name: 'Struktur',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_strukturen" */ './Details/Struktur.vue')
    }
];

export default strukturen;

