import '../../data/SelectionModel';

declare var FW: any;


/**
 *  Beispiel Abhängige Comboboxen
 *  Viewmodel notwendig
 *
 * items: [{
        xtype: 'combo',
        fieldLabel: 'Country',
        reference: 'country',
        displayField: 'name',
        valueField: 'name',
        publishes: 'value',
        store: {
            type: 'countries'
        }
    },{
        xtype: 'combo',
        fieldLabel: 'State',
        displayField: 'state',
        valueField: 'abbrev',
        queryMode: 'remote',
        forceSelection: true,
        bind: {
            visible: '{country.value}',
            filters: {
                property: 'country',
                value: '{country.value}'
            }
        },
        store: {
            type: 'country-states'
        }
    }]
 *
 *
 */


Ext.define('FW.form.field.ComboBox', {
    extend: 'Ext.form.field.ComboBox',
    alternateClassName: 'FW.form.ComboBox',
    alias: ['widget.fwcombobox', 'widget.fwcombo'],

    minChars: 0,
    queryParam: 'query',
    queryMode: 'remote',

    displayField: 'name',
    valueField: 'value',

    typeAhead: true,
    forceSelection: true,
    autoLoadOnValue: true,

    url: "",

    store: {
        xclass: 'FW.data.ApiStore',
        model: 'FW.data.SelectionModel',
        proxy: {
            type: 'api',
            url: '',
            extraParams: {},
            reader: {
                type: 'json',
                rootProperty: 'data.body.results',
                successProperty: 'data.body.success'
            },
        },
    },

    config: {
        extraParams: null
    },

    setExtraParams(v) {
        this.store.proxy.setExtraParams(v);
    },

    constructor(cfg) {
        if (!cfg) {
            cfg = {};
        }

        if (!cfg.url) {
            throw new Error('Url parameter missing');
        }

        this.store.proxy.url = cfg.url;

        if (cfg.extraParams) {
            this.store.proxy.extraParams = cfg.extraParams;
        }

        FW.form.field.ComboBox.superclass.constructor.apply(this, [cfg]);

        this.getStore().on('beforeload', (extstore, operation) => {

            const isLoaded = extstore.getCount() > 0 || extstore.isLoaded();
            const pendingLoad = extstore.hasPendingLoad();

            if (this.autoLoadOnValue && !isLoaded && !pendingLoad) {
                let params: any = operation.getParams() || {};
                params.id = this.value;
                operation.setParams(params);
            }

        });
    }

});
