
    import {Component, Prop} from 'vue-property-decorator';
    import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";
    import SelectInterface from "@/framework/components/Form/Elements/Select/SelectInterface";
    import * as _ from "lodash";

    @Component({})
    export default class WerkSelect extends InputSelect {
        @Prop({default: true}) public clearable: boolean;
        @Prop({default: true}) public searchable: boolean;
        @Prop({
            // Default Werte für Werk Select kommt aus User seine Werke
            default() {
                const retval: SelectInterface[] = [];

                if ((this as any).$store.state.authentication && ((this as any).$store as any).state.authentication.user) {
                    _.forEach((this as any).$store.state.authentication.user.plants, (value, key) => {
                        retval.push({
                            text: value,
                            name: value,
                            value: parseInt((key as any), 10)
                        });
                    });
                }

                return retval;
            }
        }) public options: any[];
    }
