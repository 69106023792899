Ext.define('FW.calendar.NavigationToolbar', {
    extend: 'Cal.widget.NavigationToolbar',

    alias: [
        'widget.calendarnavigationtoolbar'
    ],

    createButtons() {
        const buttons = FW.calendar.NavigationToolbar.superclass.createButtons.apply(this, arguments);
        const addButton = buttons.pop();
        buttons.push({
            xtype: "button",
            iconCls: "x-fa fa-sync",
            focusable: false,
            handler: "onReload",
            bind: {
                disabled: '{readOnly}'
            }
        });
        buttons.push(addButton);
        return buttons;

    }
});
