import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import DropdownMenu from "@/framework/components/Navigation/DropdownMenu/DropdownMenu.vue";
import _ from "lodash";
import Task from "@/framework/components/Taskbar/Task";

@Component({
    computed: {
        ...mapState('tasks', ['tasks']),
    },


    methods: {
        ...mapActions('tasks', ['open', 'remove', 'closeAll', 'scroll']),
        ...mapGetters('tasks', ['getActiveTask', 'isScrollable'])
    },


    components: {DropdownMenu}
})
export default class Taskbar extends Vue {
    @Prop({default: true}) public scrollbutton: boolean;

    get hidden(): boolean {
        return (this as any).tasks.length === 0;
    }

    public remove: any;
    public closeAll: any;
    public tasks: any;
    public getActiveTask: any;

    public mounted() {
    }

    protected buttonCss(task: Task): string {

        if (task.dirty && this.hasWindow(task.windowId)) {
            return 'button red';
        }

        if (this.getActiveTask() && task.taskId == this.getActiveTask().taskId) {
            return 'button primary';
        }

        return 'basic button black';
    }


    protected hasWindow(windowId: string): boolean {
        if (!windowId) {
            return false;
        }

        const comp = Ext.getCmp(windowId);

        if (!comp) {
            return false;
        }

        return true;
    }

    protected closeTasks() {

        this.closeAll().then(() => {
            _.forEach(this.tasks, (task) => {
                if (task && task.windowId && !this.hasWindow(task.windowId)) {
                    this.remove(task);
                }
            });

        });

    }
}
