var render, staticRenderFns
import script from "./Widget.vue?vue&type=script&lang=ts&"
export * from "./Widget.vue?vue&type=script&lang=ts&"
import style0 from "./Widget.vue?vue&type=style&index=0&id=6028daa6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6028daa6",
  null
  
)

export default component.exports