const teile = [
    {
        path: '/administration/stammdaten/teile',
        name: 'Teile',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_teile" */ './Grid/Teile.vue')
    },
];

export default teile;

