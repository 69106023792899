declare var FW: any;

Ext.define('FW.panel.Panel', {
    extend: 'Ext.panel.Panel',
    reference: 'panel',
    referenceHolder: true,
    autoHeight: true,

    plugins: ['panelresize'],
});
