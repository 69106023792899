Ext.define('Ext.data.operation.Export', {
    extend: 'Ext.data.operation.Read',
    alias: 'data.operation.export',
    action: 'export',

    responseType: 'blob',

    doExecute() {
        return this.getProxy().export(this);
    },

    getParams() {
        return {exports: this.exports || [], timeout: 60000 * 10}; // timeout durchreichen
    }
});
