Ext.define('FW.picker.DateRange', {
    extend: 'Ext.picker.Date',
    alias: 'widget.datePickerRange',

    config: {
        startDate: null,
        endDate: null
    },

    isDateRange: true,
    isDragging: false,

    initComponent () {
        FW.picker.DateRange.superclass.initComponent.apply(this, arguments);
        this.on({
            mouseover: {
                element: 'el',
                fn: 'onMouseOverEnhanced',
                delegate: 'td.x-datepicker-cell',
                scope: this
            }
        });
    },

    handleDateClick (e, t) {

        var me = this,
            handler = me.handler;

        e.stopEvent();

        if (!me.disabled && t.dateValue && !Ext.fly(t.parentNode).hasCls(me.disabledCellCls)) {
            me.setValue(new Date(t.dateValue));

        }

        if (this.isDateRange) {
            if (this.isDragging) {
                this.isDragging = false;
                var startCell = this.getCellByValue(this.startCell)
                if (startCell) {
                    Ext.get(startCell).addCls(this.selectedCls)
                }
                this.setEndDate(new Date(this.getCellDateValue(this.activeCell)))
                this.updateDateRange(this.startCell, this.endCell);

                //select event auslösen

                me.fireEvent('select', me, me.value);

                if (handler) {
                    Ext.callback(handler, me.scope, [me, me.value], null, me, me);
                }


                // event handling is turned off on hide
                // when we are using the picker in a field
                // therefore onSelect comes AFTER the select
                // event.
                me.onSelect();

            } else {
                this.setStartDate(new Date(this.getCellDateValue(this.activeCell)))
                this.isDragging = true;
                this.updateDateRange(this.getCellDateValue(), -1);
            }
        }
    },

    getCellByValue (value) {
        var cells = this.cells.elements;
        for (var i = 0; i < cells.length; i++) {
            var cell = cells[i]
            if (cell.firstChild.dateValue === value) {
                return cell;
            }
        }
    },

    onMouseOverEnhanced (e, target, eOpts) {
        if (this.isDragging) {
            this.updateDateRange(this.getCellDateValue(), this.getCellDateValue(target));
        }
    },

    updateDateRange (startValue, endValue) {
        var cells = this.cells.elements;
        var selectedCls = this.selectedCls;
        for (var i = 0; i < cells.length; i++) {
            let cell: any = Ext.fly(cells[i])
            var dateValue = this.getCellDateValue(cells[i]);
            if (dateValue !== startValue && (dateValue < startValue || dateValue > endValue)) {
                cell.removeCls(selectedCls)
            } else {
                cell.addCls(selectedCls)
            }
        }
    },

    getCellDateValue (cell) {
        return cell && cell.firstChild.dateValue || this.startCell;
    },

    getDateRange () {
        return {
            start: this.getStartDate(),
            end: this.getEndDate()
        }
    },

    fullUpdate (date) {
        let me: any = this;
        let cells: any = this.cells.elements;
        for (var i = 0; i < cells.length; i++) {
            let cell: any = cells[i]
            let dateValue: any = this.getCellDateValue(cells[i]);
            cell.setAttribute('id', 'cell-' + dateValue);
        }

        FW.picker.DateRange.superclass.fullUpdate.apply(this, arguments);
        Ext.asap(() => {
            if (me.isDateRange && me.endCell) {
                me.updateDateRange(me.startCell, me.endCell)
            }
        })
    },

    updateStartDate (value) {
        this.startCell = value?.getTime()
        this.publishState('startDate', value);
    },

    updateEndDate (value) {
        this.endCell = value?.getTime()
        this.publishState('endDate', value);
    }
});


/**
 *
 * {
        xtype: 'datePickerEnhanced',
        minDate: new Date(),
        bind: {
            startDate: '{theStart}',
            endDate: '{theEnd}'
        }
 */
