const communications = [
    {
        path: '/communications',
        name: 'communications',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "communications" */ './Grid/Communications.vue'),
    },
];

export default communications;
