
const werke = [
    {
        path: '/administration/stammdaten/werke',
        name: 'Werke',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_werke" */ './Grid/Werke.vue')
    },
    {
        path: '/administration/stammdaten/werke/details/:werk_seq?',
        name: 'Werk',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_werke" */ './Details/Werk.vue')
    }
];

export default werke;