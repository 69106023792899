const auditrollen = [
    {
        path: '/administration/stammdaten/auditrollen',
        name: 'Auditrollen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_auditrollen" */ './Grid/Auditrollen.vue')
    },
];

export default auditrollen;

