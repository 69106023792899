
import {Component, Prop} from 'vue-property-decorator';
import {base} from "@/framework/services/Api";
import FileInterface, {DefaultFile} from "../Input/FileInterface";
import Base from "./Base";

@Component({})
export default class FilePreview extends Base {
    get size() {
        return this.file.size / 1048576;
    }

    get hasExtra() {
        return this.extra.length > 0;
    }

    get hasFile() {
        if ((this.file === undefined || this.file === null)) {
            return false;
        }

        return !!this.file.file_id;
    }

    get isLink() {
        return this.file.type === 'l';
    }

    get hasPreview(): boolean {
        return this.preview !== DefaultFile.preview;
    }

    get preview() {
        if (!this.file.preview) {
            return DefaultFile.preview;
        }

        return base + this.file.preview;
    }

    get previewIcon() {
        const name = this.file.name;
        if (/\.pdf$/.test(name)) {
            return "file outline pdf";
        }

        if (/\.xls(x)?$/.test(name)) {
            return "file outline excel";
        }

        if (/\.doc(x)?$/.test(name)) {
            return "file outline word";
        }

        if (/\.xml$/.test(name)) {
            return "file code outline";
        }

        if (/\.csv$/.test(name)) {
            return "file csv outline";
        }

        if (/\.ppt(x)?$/.test(name)) {
            return "file powerpoint outline";
        }

        if (/\.zip$/.test(name)) {
            return "file outline archive";
        }

        if (/\.gz$/.test(name)) {
            return "file outline archive";
        }

        return "file outline";
    }

    get url(): string {
        if (this.isLink) {
            return this.file.url;
        }
        return base + this.file.url;
    }

    @Prop() public file!: FileInterface;
    @Prop({default: ""}) public extra: string;

    public mounted() {
        const a = ($('a', this.$el) as any);
        if (a.width() > a.parent().width()) {
            a.popup({
                content: this.file.name
            });
        }
    }

    public destroy() {
        this.$emit('destroy', this.file);
    }
}
