import CockpitOpenDetail from "@/app/pages/Auswertungen/Cockpit/Grid/OpenDetail";

const auswertungen = [
    {
        path: '/auswertungen/cockpit',
        name: 'cockpit',
        component: () => import(/* webpackChunkName: "cockpit" */ './Cockpit/Grid/Cockpit.vue'),
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        children: [
            {
                path: 'open/:lieferant_werk_seq',
                beforeEnter: CockpitOpenDetail
            }
        ]
    },
    {
        path: '/auswertungen/cockpit/filter',
        name: 'cockpit Filter',
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        component: () => import(/* webpackChunkName: "cockpit" */ './Cockpit/Grid/Filters/Cockpit.vue')
    },
    {
        path: '/auswertungen/cockpit/detail/:lieferant_werk_seq?',
        name: 'cockpit detail',
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        component: () => import(/* webpackChunkName: "cockpit" */ './Cockpit/Details/Cockpit.vue')
    },
    {
        path: '/meinepunkte',
        name: 'meinepunkte',
        component: () => import(/* webpackChunkName: "meinepunkte" */ './Meinepunkte/Grid/Meinepunkte.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/auswertungen/charts',
        name: 'charts',
        component: () => import(/* webpackChunkName: "charts" */ './Charts/Charts.vue'),
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        }
    },
    {
        path: '/auswertungen/jahresbewertung',
        name: 'jahresbewertung',
        component: () => import(/* webpackChunkName: "charts" */ './Jahresbewertung/Grid/Bewertungen.vue'),
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        }
    },
    {
        path: '/auswertungen/jahresbewertung/filter',
        name: 'jahresbewertung_filter',
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        component: () => import(/* webpackChunkName: "charts" */ './Jahresbewertung/Grid/Filters/Filters.vue')
    },
];

export default auswertungen;
