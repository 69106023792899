import BesprechungenOpenDetail from "@/app/pages/Besprechungen/Grid/OpenDetail";


const besprechungen = [
    {
        path: '/besprechungenlieferant',
        name: 'Besprechungen Grid View',
        meta: {
            requiresAuth: true,
            resource: "besprechungen"
        },
        component: () => import(/* webpackChunkName: "besprechungengridview" */ './Grid/BesprechungenGridView.vue'),
        children: [
            {
                path: 'open/:besprechung_seq',
                beforeEnter: BesprechungenOpenDetail
            }
        ]
    },
    {
        path: '/besprechungen',
        name: 'Besprechungen',
        meta: {
            requiresAuth: true,
            resource: "besprechungen"
        },
        component: () => import(/* webpackChunkName: "besprechungen" */ './Grid/Besprechungen.vue'),
        children: [
            {
                path: 'open/:besprechung_seq',
                beforeEnter: BesprechungenOpenDetail
            }
        ]
    },
    {
        path: '/besprechungen/kalender',
        name: 'Besprechungen Kalender',
        meta: {
            requiresAuth: true,
            resource: "besprechungen"
        },
        component: () => import(/* webpackChunkName: "besprechungen" */ './Calendar/Besprechungen.vue')
    },
    {
        path: '/besprechungen/detail/:besprechung_seq?/:fw_ownership?',
        name: 'Besprechung',
        meta: {
            requiresAuth: true,
            resource: "besprechungen"
        },
        component: () => import(/* webpackChunkName: "besprechung" */ './Details/Besprechung.vue')
    },
    {
        path: '/besprechungen/detailcalendar/:besprechung_seq?/:fw_ownership?',
        name: 'Besprechung Calendar',
        meta: {
            requiresAuth: true,
            resource: "besprechungen",
            btnConfig: {
                destroy: true,
                save: true,
                custom: false
            }
        },
        component: () => import(/* webpackChunkName: "besprechung" */ './Details/Besprechung.vue')
    },
    {
        path: '/besprechungen/new/:strukturelement_seq?/:von?/:bis?/:ganztaegig?',
        name: 'Besprechung Neu',
        meta: {
            requiresAuth: true,
            resource: "besprechungen",
            btnConfig: {
                destroy: true,
                save: true,
                custom: false
            }
        },
        component: () => import(/* webpackChunkName: "besprechung" */ './Details/Besprechung.vue')
    },
    {
        path: '/besprechungen/filter',
        name: 'Besprechung Filter',
        meta: {
            requiresAuth: true,
            resource: "besprechungen"
        },
        component: () => import(/* webpackChunkName: "besprechungen_filter" */ './Grid/Filters/Besprechungen.vue')
    }
];

export default besprechungen;
