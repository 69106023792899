
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import FileInterface from "../Elements/Input/FileInterface";
    import {base} from "../../../services/Api";

    @Component({
        model: {
            prop: 'dateianlagen',
            event: 'change'
        }
    })
    export default class Dateianlagen extends Vue {
        @Prop({default: () => ([])}) public dateianlagen: FileInterface[];
        @Prop({default: ''}) public direction: string;

        protected isLink(file: FileInterface): boolean {
            return file.type === 'l';
        }

        protected url(file: FileInterface) {
            if (this.isLink(file)) {
                return file.url;
            }
            return base + file.url;
        }

        protected get extraClasses(): string {
            return this.direction;
        }
    }
