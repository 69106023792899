// Import FW Ext Komponentne
import '@/framework/extjs/data/operation/Expand';
import '@/framework/extjs/data/operation/Custom';
import '@/framework/extjs/data/operation/Export';
import '@/framework/extjs/data/operation/Multiedit';
import '@/framework/extjs/data/operation/Sort';
import '@/framework/extjs/data/proxy/Api';
import '@/framework/extjs/data/ApiStore';
import '@/framework/extjs/data/GridStore';
import '@/framework/extjs/data/TreeStore';
import '@/framework/extjs/data/TreeGridStore';
import '@/framework/extjs/data/field/Date';
import '@/framework/extjs/data/field/Boolean';
import '@/framework/extjs/data/field/Number';
import '@/framework/extjs/grid/controller/Panel';
import '@/framework/extjs/grid/controller/EditPanel';
import '@/framework/extjs/grid/controller/MultiEdit';
import '@/framework/extjs/grid/Panel';
import '@/framework/extjs/grid/Tree';
import '@/framework/extjs/grid/Expand';
import '@/framework/extjs/grid/filters/main';
import '@/framework/extjs/grid/column/main';
import '@/framework/extjs/grid/toolbar/Filter';
import '@/framework/extjs/grid/plugin/RowExpander';
import '@/framework/extjs/grid/plugin/ExpandWidget';
import '@/framework/extjs/grid/plugin/Views';
import '@/framework/extjs/grid/plugin/Export';
import '@/framework/extjs/grid/plugin/StateReset';
import '@/framework/extjs/grid/plugin/FilterSave';
import '@/framework/extjs/grid/plugin/MultiEdit';
import '@/framework/extjs/grid/template/FWTemplate';
import '@/framework/extjs/grid/feature/Grouping';
import '@/framework/extjs/util/Filter';
import '@/framework/extjs/util/Format';
import '@/framework/extjs/data/ApiStore';
import '@/framework/extjs/data/Model';
import '@/framework/extjs/data/TreeModel';
import '@/framework/extjs/picker/Date';
import '@/framework/extjs/picker/DateRange';
import '@/framework/extjs/tree/Panel';
import '@/framework/extjs/grid/mixins/CellEdit';
import '@/framework/extjs/grid/mixins/RowEdit';
import '@/framework/extjs/grid/mixins/MultiEdit';
import '@/framework/extjs/form/field/ComboBox';
import '@/framework/extjs/form/field/Tag';
import '@/framework/extjs/form/field/DateRange';
import '@/framework/extjs/panel/Panel';
import '@/framework/extjs/grid/toolbar/filters/main';
import "@/framework/extjs/panel/Resize";
import "@/framework/extjs/button/Button";
import '@/framework/extjs/grid/plugin/ColumnTip';
import '@/framework/extjs/grid/plugin/filterbar/FilterBar';

Ext.tip.QuickTipManager.init();

Ext.state.Manager.setProvider(Ext.create('Ext.state.LocalStorageProvider', {
}));
