import i18n from '@/framework/locale';
import * as _ from 'lodash';

Ext.define('FW.grid.plugin.Export', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.gridexport',

    mixins: [
        'Ext.util.StoreHolder'
    ],

    id: 'gridexport',

    filterCls: Ext.baseCSSPrefix + 'grid-exporter-column',

    menuFilterText: 'export',

    showMenu: true,

    menuColsIcon: 'x-far fa-file-excel',

    stateId: undefined,

    init(grid) {
        const me = this;
        let store;
        let headerCt;

        me.grid = grid;
        me.grid.addStateEvents(['exportcolumn']);

        store = grid.store;
        headerCt = grid.headerCt;

        this.headerCtListeners = headerCt.on({
            destroyable: true,
            scope: this,
            add: this.onAdd,
            menucreate: this.onMenuCreate
        });

        // if menu is already created before filter is initialized
        if (headerCt.menu && !headerCt.menu.destroyed) {
            this.onMenuCreate(headerCt, headerCt.menu);
        }

        me.gridListeners = grid.on({
            destroyable: true,
            scope: me,
            reconfigure: me.onReconfigure,
            columnmove: me.updateStore
        });

        if (store.isEmptyStore) {
            return;
        }

        me.bindStore(store);
        me.initColumns();
        me.updateStore();

    },

    /**
     * Creates the Filter objects for the current configuration.
     * Reconfigure and on add handlers.
     * @private
     */
    initColumns() {
        const grid = this.grid;
        let i = 0;
        let initExportColumns = false;
        let item;
        const items = grid.columnManager.getColumns();
        const itemsLn = items.length;

        this.columns = [];

        if (!this.exportColumns) {
            this.exportColumns = [];
            initExportColumns = true;
        }


        for (; i < itemsLn; i++) {
            item = items[i];

            if ((item.exportIndex || item.dataIndex) && item.export !== false) {
                this.columns.push(item);
                if (initExportColumns) {
                    this.exportColumns.push({
                        property: item.exportIndex || item.dataIndex,
                        exp: true,
                        displayIndex: item.displayIndex,
                        dataIndex: item.dataIndex
                    });
                } else if (_.find(this.exportColumns, ['property', item.exportIndex || item.dataIndex]) === undefined) {
                    this.exportColumns.push({
                        property: item.exportIndex || item.dataIndex,
                        exp: true,
                        displayIndex: item.displayIndex,
                        dataIndex: item.dataIndex
                    });
                }
            }
        }

    },

    getState() {
        return this.exportColumns;
    },

    applyState(state) {
        if (state) {
            this.exportColumns = state;
        }
    },

    onAdd(headerCt, column, index) {
        return;
    },

    /**
     * @private
     * Handle creation of the grid's header menu.
     */
    onMenuCreate(headerCt, menu) {
        const me = this;

        if (me.headerMenuListeners) {
            Ext.destroy(me.headerMenuListeners);
            me.headerMenuListeners = null;
        }

        me.headerMenuListeners = menu.on({
            beforeshow: me.onMenuBeforeShow,
            destroyable: true,
            scope: me
        });
    },

    /**
     * @private
     * Handle showing of the grid's header menu. Sets up the filter item and menu
     * appropriate for the target column.
     */
    onMenuBeforeShow(menu) {
        const me = this;
        let menuItem;
        let parentTable;
        let parentTableId;

        if (me.showMenu) {
            // In the case of a locked grid, we need to cache the 'Filters' menuItem for each grid since
            // there's only one Filters instance. Both grids/menus can't share the same menuItem!
            if (!me.exportMenuItem) {
                me.exportMenuItem = {};
            }

            // Don't get the owner panel if in a locking grid since we need to get the unique filterMenuItem key.
            // Instead, get a ref to the parent, i.e., lockedGrid, normalGrid, etc.
            parentTable = menu.up('tablepanel');
            parentTableId = parentTable.id;

            menuItem = me.exportMenuItem[parentTableId];

            if (!menuItem || menuItem.destroyed) {
                menuItem = me.createMenuItem(menu, parentTableId);
            }

        }
    },

    createMenuItem(menu, parentTableId) {
        const me = this;
        let item;

        // only add separator if there are other menu items
        if (menu.items.length) {
            me.sep = menu.add('-');
        }

        item = menu.add({
            itemId: 'export',
            text: i18n.t(me.menuFilterText),
            iconCls: me.menuColsIcon,
            menu: {
                items: me.getColumnMenu()
            },
            hideOnClick: false
        });

        return (me.exportMenuItem[parentTableId] = item);
    },

    getColumnMenu() {
        const me = this;
        const menuItems: any[] = [];
        let i: number = 0;
        let item;
        const items = this.columns;
        const itemsLn = items.length;
        let menuItem;

        for (; i < itemsLn; i++) {
            item = items[i];

            if (!(item.exportIndex || item.dataIndex)) {
                continue;
            }

            const checked = _.find(this.exportColumns, ['property', item.exportIndex || item.dataIndex]);
            menuItem = new Ext.menu.CheckItem({
                text: item.menuText || item.text,
                checked: checked ? checked.exp : true,
                hideOnClick: false,
                dataIndex: item.dataIndex,
                checkHandler: this.onColumnCheckChange,
                scope: this
            });
            menuItems.push(menuItem);
        }
        // Prevent creating a submenu if we have no items
        return menuItems.length ? menuItems : null;
    },

    onColumnCheckChange(checkItem, checked) {
        const column = _.find(this.columns, ['dataIndex', checkItem.dataIndex]);

        if (!column) {
            return;
        }

        const item = _.find(this.exportColumns, ['property', column.exportIndex || column.dataIndex]);

        if (item) {
            item.exp = checked;
            this.grid.fireEventArgs('exportcolumn', [this, item]);
            this.updateStore();
        }
    },

    destroy() {
        const me = this;
        const exportMenuItem = me.exportMenuItem;
        let item;

        Ext.destroy(me.headerCtListeners, me.gridListeners, me.headerMenuListeners);

        me.bindStore(null);
        me.sep = Ext.destroy(me.sep);

        for (item in exportMenuItem) {
            if (item) {
                exportMenuItem[item].destroy();
            }

        }

        // this.callParent();
    },

    onUnbindStore(store) {
        this.store = null;
    },

    onBindStore(store, initial, propName) {
        this.store = store;
    },


    getHeaders() {
        return this.grid.view.headerCt.columnManager.getColumns();
    },


    onReconfigure(grid, store, columns, oldStore) {
        const changed = oldStore !== store;

        if (store && changed) {
            this.bindStore(store);
        }

        this.initColumns();
        this.updateStore();
    },

    updateStore() {
        const store = this.getStore();
        if (!store) {
            return;
        }

        const exportColumns = {};
        const grid = this.grid;
        const columns = grid.columnManager.getColumns();

        const getPathFromColumns = (column, path: any[]) => {
            if (column.ownerCt && column.ownerCt.$className === 'Ext.grid.column.Column') {
                getPathFromColumns(column.ownerCt, path);
            }
            path.push(column.text);
            return path;
        }

        _.each(this.columns, (column) => {
            const eC = _.find(this.exportColumns, (o) => {
                return (column.exportIndex || column.dataIndex) === o.property;
            });
            if (eC && eC.exp) {
                const path: any[] = [];
                getPathFromColumns(column, path);
                exportColumns[grid.columnManager.getHeaderIndex(column)] = (_.merge({}, eC, {text: column.text, path}));
            }
        });

        store.setExports(_.toArray(exportColumns));
    }

});
