import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import {AuditsGridConfig} from "@/app/pages/Audits/Grid/Config";
import i18n from "@/framework/locale";

export default function AuditsOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.audit_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(AuditsGridConfig.id, to.params.audit_seq),
            title: i18n.t(AuditsGridConfig.title),
            url: '/audits/detail/' + to.params.audit_seq
        }));
    }
}
