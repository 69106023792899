import '@/framework/extjs/grid/controller/Base';

Ext.define('FW.tree.controller.Tree', {
    extend: 'FW.grid.controller.Base',
    alias: 'controller.fwtree',

    onExpandAll() {
        this.getView().expandAll(() => {
            const id = this.getView().stateful && this.getView().getStateId();
            if (id) {
                Ext.state.Manager.set(id + '_nodes', []);
            }
        }, this);
    },

    onCollapseAll() {
        this.getView().collapseAll(() => {
            const id = this.getView().stateful && this.getView().getStateId();
            if (id) {
                Ext.state.Manager.set(id + '_nodes', []);
            }
        }, this);
    },

    saveFilter(f) {
        return null;
    },

    clearFilter() {
        return null;
    }
});
