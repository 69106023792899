import GridConfig from "@/framework/extjs/grid/Config";

export const BesprechungenGridConfig: GridConfig = {
    id: 'Besprechungen',
    title: 'besprechungen',
};

export const BesprechungenTreeConfig: GridConfig = {
    id: 'BesprechungenTree',
    title: 'struktur'
};
