import watcher from "@/app/components/DefaultGridWatcher";
import * as _ from "lodash";
import vStore from "@/app/store";
import './controller/Calendar';
import './data/CalendarStore';
import './Toolbar';
import './ResourceFilter';
import i18n from "@/framework/locale";
import FilterWatcher from "@/framework/extjs/watcher/FilterWatcher";

declare var FW: any;
declare var Sch: any;
declare var Cal: any;

Ext.define('FW.calendar.Panel', {
    extend: 'Cal.panel.Calendar',

    /**
     * Die Resource zu der dieser Grid gehört (für Permissions Abfragen)
     */
    resource: undefined,

    viewModel: {
        data: {
            date: new Date(),
            mode: 'month',
            newBtn: {
                default: true,
                permissions: ['insert'],
                visible: true,
            }
        },
        formulas: {
            // @ts-ignore
            filterdate: function (get) {
                return Ext.Date.format(get('date'), 'Y-m-d');
            }
        },
        stores: {
            eventStore: {
                xclass: 'FW.Calendar.data.CalendarStore',
                listeners: {
                    load: "onStoreLoad",
                    write: "onStoreWrite",
                    failure: "onStoreFailure",
                }

            },
            resource: {
                type: 'calendarresourcestore',
                storeId: 'resource',
                data: [
                    {Id: 1, Name: i18n.t("calendar")},
                ]
            },
        }
    },

    controller: 'fwcalendar',

    filterSearchText: true,
    reference: 'grid',
    referenceHolder: true,
    reserveScrollbar: true,
    eventTplClass: 'Cal.template.Event',

    watcher: [],
    plugins: ['panelresize'],

    bind: {
        eventStore: '{eventStore}',
        resourceStore: '{resource}',
        readOnly: '{!newBtn.visible}'
    },

    convertWatchedStore(filter) {
        if (!filter) {
            return [];
        }

        const retval: any = [];

        _.each(filter, (value, key) => {
            const obj = new Object();
            obj[key] = new FilterWatcher({filter: value});
            retval.push(obj);

        });

        return retval;
    },

    constructor() {
        this.watcher = [
            ...watcher,
            ...this.watcher,
            ...this.convertWatchedStore(this.watchedStore)
        ];
        FW.calendar.Panel.superclass.constructor.apply(this, arguments);
    },

    initComponent() {
        FW.calendar.Panel.superclass.initComponent.apply(this, arguments);

        const getFilter = vStore.getters["filter/get"];
        const savedFilter = getFilter(this.getId());
        const index = _.findIndex(savedFilter, ['property', 'datum']);

        if (index > -1) {
            savedFilter.splice(index, 1);
            vStore.dispatch('filter/set', {
                key: this.getId(),
                value: savedFilter
            });
        }

        this.setDate(new Date());

        this.addDocked([{
            xtype: 'filtertoolbar',
            enableSearchtext: this.filterSearchText,
            dock: 'top',
            enableFilterButton: false,
            padding: '6 0 6 6',
        }]);

        const controller = this.lookupController();
        // controller.initWatchers();

        if (!this.getStore() || this.getStore().isEmptyStore) {
            return;
        }
        controller.watchFilter();
    },

    getEventTpl() {
        const me:any = this;

        if (!me.eventTpl || !me.eventTpl.isTemplate) {
            const cfg:any = {
                xclass: me.eventTplClass,
                getExtraCls: me.getEventTplExtraCls,
                getExtraClsScope: me,
                bodyRenderer: me.eventRenderer,
                bodyRendererScope: me.eventRendererScope
            };
            if (me.eventBodyTpl) {
                cfg.bodyTpl = me.eventBodyTpl;
            }
            me.eventTpl = Ext.create(Ext.apply(cfg, me.eventTpl));
        }
        return me.eventTpl;
    },


    getStore() {

        if (!this.getEventStore()) {
            return Ext.getStore('ext-empty-store');
        }

        return this.getEventStore();
    },

    getResourceFilter() {
        let me = this;
        if (me.resourceFilter !== false && !me.resourceFilter.isComponent) {
            me.resourceFilter = Ext.create(Ext.apply({
                xtype: 'fw_calendarresourcefilter',
                dock: 'left',
                resourceStore: me.resourceStore
            }, me.resourceFilter));
        }
        return me.resourceFilter;
    },

    onNavigationToolbarClickCreateNew() {
        const controller = this.lookupController();
        controller.onNewButtonClick();
    },

    onNavigationToolbarModeChange(cmp, mode) {
        const controller = this.lookupController();
        controller.setMode(mode);
    },
    onNavigationToolbarClickPrevious() {
        const controller = this.lookupController();
        controller.shiftPrevious();
    },
    onNavigationToolbarClickNext() {
        const controller = this.lookupController();
        controller.shiftNext();
    },
    onNavigationToolbarClickToday() {
        const controller = this.lookupController();
        controller.setDate(new Date());
    },

    setDate(date) {
        this.getViewModel().set('date', date);
        FW.calendar.Panel.superclass.setDate.apply(this, arguments);
    },

    listeners: {


        scheduledblclick: "onNewCalendarClick",
        eventdblclick: "onDetailButtonClick",
        eventdrop: "onEventDrop",
        beforeeventdropfinalize: "onConfirmEventDrop",
        beforeeventresizefinalize: "onConfirmEventResize",
        dragcreateend: "onDragCreateEvent",
        eventpartialresize: "onEventpartialResize",
        beforeeventdrag: "onBeforeeventdrag",

        schedulecontextmenu() {
            return false;
        },

        eventcontextmenu() {
            return false;
        },

        day_timeheaderclick(column, startDate, endDate, e, eOpts) {
            return false;
        },


        eventstorechange(cal, newStore, oldStore) {
            if (!newStore || newStore.isEmptyStore || newStore == oldStore) {
                return;
            }

            const controller = cal.lookupController();
            controller.unwatchFilter();
            controller.watchFilter();

            newStore.on('beforeload', 'onStoreBeforeLoad');

            cal.fireEvent('storechange', cal, newStore, oldStore);
        },


        beforestatesave(grid, state, eOpts) {
            if (state.storeState) {
                delete state.storeState.filters;
            }
        }
    }
});


Ext.override(Cal.util.DragCreator, {

    onDragEnd(event) {
        let me = this;
        let DATE = Sch.util.Date;
        let date = this.view.getDateFromPosition(event.pageX, event.pageY) || this.currentDateAtCursor;
        if (date) {
            date = DATE.setDateToMidnight(date);
            var start = DATE.min(date, this.startDate);
            var end = DATE.max(date, this.startDate);
            var isValid = this.validatorFn.call(this.validatorFnScope || this, null, start, end);
            this.createContext = {
                start: start,
                end: end,
                resourceRecord: null,
                e: event,
                isValid: isValid,
                finalize: function(doCreate) {
                    me.finalize(doCreate);
                }
            };
            if (this.fireEvent('beforedragcreatefinalize', this.view, this.createContext, event, null) !== false) {
                this.finalize(isValid);
            }
        } else {
            this.view.clearHighlightedDays(this.draggingCls);
        }
    },

});


Ext.override(Cal.util.DragResize, {

    getNewStartEndDates() {
        const DATE = Sch.util.Date;
        let start = this.eventClone.getStartDate();
        let end = this.eventClone.getEndDate();
        // Correct start time and end time corresponding to multi day event
        const originalEnd = new Date(end.getTime());
        // DATE.setDateToMidnight(start);
        // DATE.setDateToMidnight(end);
        if (DATE.compareWithPrecision(end, originalEnd) !== 0) {
            end = DATE.add(end, DATE.DAY, 1);
        }
        // EOF correction
        let swap = false;
        const shift = this.currentCellIndexAtCursor - this.startCellIndex;
        if (this.startResizing) {
            start = DATE.add(start, DATE.DAY, shift);
            if (start >= end) {
                start = DATE.add(start, DATE.DAY, 1);
                swap = true;
            }
        }
        if (this.endResizing) {
            end = DATE.add(end, DATE.DAY, shift);
            if (end <= start) {
                end = DATE.add(end, DATE.DAY, -1);
                swap = true;
            }
        }
        const from = swap ? end : start;
        const to = swap ? start : end;
        return {
            start: from,
            end: to
        };
    }
});
