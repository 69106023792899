const berechtigungen_rechte = [
    {
        path: '/administration/stammdaten/berechtigungen/rechte',
        name: 'Berechtigungen Rechte',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "berechtigungen_rechte" */ './Grid/Rechte.vue')
    },
    {
        path: '/administration/stammdaten/berechtigungen/rechte/details/:modul?',
        name: 'Berechtigungen Recht',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "berechtigungen_rechte" */ './Details/Recht.vue')
    },
];

export default berechtigungen_rechte;
