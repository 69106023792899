import {Component, Vue, Prop} from 'vue-property-decorator';
import Modal from "@/framework/components/Modal/Modal.vue";
import * as _ from "lodash";
import {Response} from "@/framework/services/Response";
import {types} from "@/framework/util/validator/validators";
import apiMixin from "@/framework/mixins/Api";
import swal from "sweetalert2";
import $ from "jquery";

export interface TokenUserParams {
    lieferant_seq: number|null;
    lieferantenname: string|null;
}


@Component({
    mixins: [apiMixin],
    api: {
        checkemail: '/users/token/checkemail',
        create: '/users/token/create',
    },
    components: {Modal}
})
export default class TokenUser extends Vue {

    get messageHeader(): string {

        return this.$t('fehler').toString();

    }

    get extraCss(): string {
        if (this.checkLoading === true) {
            return "ui icon input loading";
        }

        return "ui icon input";
    }

    get extraIcon(): string {
        if (this.checked === true) {
            return "green check icon";
        }

        if (this.checked === false) {
            return "red exclamation triangle icon";
        }

        return "search icon";
    }

    get inputEmail() {
        return _.debounce(this.checkEmail, 300);
    }

    get isDisabled(): boolean {
        return !this.checked;
    }

    get tokenFor(): string {
        return this.$t('token_for').toString() + " " + (this.params.lieferantenname ? this.params.lieferantenname : this.$t('ausgewaehlten_lieferanten'));
    }
    @Prop({default: null}) public params: TokenUserParams;

    protected email: string = "";
    protected checked: boolean|null = null;
    protected checkLoading: boolean = false;
    protected createLoading: boolean = false;
    protected error: string = "";
    protected errorMsg: string = "";
    protected btnCancel: string = "abbrechen";
    protected tokenCreated: boolean = false;

    public open() {
        this.email = "";
        this.checked = null;
        this.checkLoading = false;
        this.errorMsg = "";
        this.tokenCreated = false;
        this.btnCancel = 'abbrechen';

        const context = 'body';
        (this.$refs.modal as any).show({context, autofocus: false, detachable: true});
    }

    public hide() {
        (this.$refs.modal as any).hide();
    }

    public onApprove() {
        if (!this.checked) { return; }

        const me = this;

        swal({
            target: $(me.$el) as any,
            title: this.$t('token_create').toString(),
            showCancelButton: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            backdrop: false,
            onOpen: () => {
                swal.showLoading();

                me.createToken().then((user_id) => {
                    me.tokenCreated = true;
                    me.errorMsg = "";
                    me.btnCancel = 'schiessen';

                    swal( {
                        target: $(me.$el) as any,
                        title: me.$t('token_create_success').toString(),
                        type: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                        backdrop: false,
                        onClose: () => {
                            me.$emit('input', user_id);
                            (me.$refs.modal as any).hide();
                        }
                    });

                }).catch(() => {
                    me.errorMsg = me.$t('token_create_error').toString();
                    me.tokenCreated = false;

                    swal( {
                        target: $(me.$el) as any,
                        title: me.errorMsg,
                        type: "error",
                        timer: 1500,
                        showConfirmButton: false,
                        backdrop: false
                    });
                });

            }
        });




    }

    protected createToken(): Promise<any> {

        if (!this.checked) { return Promise.reject(false); }

        this.createLoading = true;
        return this.$http.post(this.$api.create, {
            email: this.email,
            lieferant_seq: this.params.lieferant_seq
        }).then((data) => {
            this.createLoading = false;
            const resp = Response.factory(data).dispatch();
            const user_id = resp.data();

            if (!user_id) {
                return Promise.reject(false);
            }

            return user_id;

        }).catch(() => {
            this.createLoading = false;
            return Promise.reject(false);
        });
    }

    protected checkEmail() {

        if (this.email.length === 0) {
            this.checked = null;
            return;
        }

        if (!types.email(this.email)) {
            this.error = this.$t('validation_type').toString().replace('{rule}', 'Email');
            this.checked = false;
            return;
        }

        this.checkLoading = true;
        this.$http.post(this.$api.checkemail, {
            email: this.email,
            lieferant_seq: this.params.lieferant_seq
        }).then((data) => {
            const resp = Response.factory(data).dispatch();
            this.checked = resp.data();
            this.checkLoading = false;
            if (this.checked === false) {
                this.error = this.$t('token_email_check_nok').toString();
            } else {
                this.error = "";
            }
        }).catch(() => {
            this.checkLoading = false;
            this.error = this.$t('token_email_check_nok').toString();
        });

    }


}
