import * as _ from 'lodash';

Ext.define('FW.tree.plugin.TreeStateful', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.treestateful',
    id: 'treestateful',

    init(view) {
        const me = this;
        const id = view.stateful && view.getStateId();

        if (!id) {
            return;
        }

        view.on("afteritemexpand", this.afteritemexpand, this);
        view.on("afteritemcollapse", this.afteritemcollapse, this);
        view.on("storechange", this.storechange, this);

        view.getStore().on("load", me.applyStates, this, {
            single: false
        });
    },

    storechange(grid, newStore, oldStore) {
        const me = this;
        newStore.on("load", me.applyStates, this, {
            single: false
        });
    },

    afteritemexpand(node, index, item) {
        const me = this;
        const id = me.cmp.stateful && me.cmp.getStateId();
        let state = Ext.state.Manager.get(id + '_nodes', []);

        if (!_.isArray(state)) {
            state = [];
        }
        state.push(node.getId());

        Ext.state.Manager.set(id + '_nodes', _.uniq(state));
    },

    afteritemcollapse(node) {
        const me = this;
        const id = me.cmp.stateful && me.cmp.getStateId();
        const state = Ext.state.Manager.get(id + '_nodes', null);

        if (!state) {
            return;
        }

        const index = _.indexOf(state, node.getId());

        if (index === -1) {
            return;
        }

        state.splice(index, 1);

        Ext.state.Manager.set(id + '_nodes', state);
    },

    applyStates(store) {
        const me = this;
        const id = me.cmp.stateful && me.cmp.getStateId();
        const state = Ext.state.Manager.get(id + '_nodes', []);

        if (!_.isArray(state)) {
            return;
        }

        _.forEach(state, (i) => {
            const node = store.getNodeById(i);

            if (node) {
                node.bubble((n) => {
                    n.expand();
                });
            }
        });
    }
});
