const vokabular = [
    {
        path: '/administration/vokabular',
        name: 'Vokabular',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_regionen" */ './Grid/Vokabular.vue')
    },
    {
        path: '/administration/vokabular/filter',
        name: 'Vokabular Filter',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "aktivitaeten_filter" */ './Grid/Filters/Vokabular.vue')
    }
];

export default vokabular;
