import SelbstauskunftOpenDetail from '@/app/pages/Selbstauskuenfte/Grid/OpenDetail';

const selbstauskuenfte = [
    {
        path: '/selbstauskuenfte',
        name: 'Selbstauskuenfte',
        meta: {
            requiresAuth: true,
            resource: "selbstauskunft"
        },
        component: () => import(/* webpackChunkName: "Selbstauskuenfte" */ './Grid/Selbstauskuenfte.vue'),
        children: [
            {
                path: 'open/:auskunft_seq',
                beforeEnter: SelbstauskunftOpenDetail,
            },
        ],
    },
    {
        path: '/selbstauskuenfte/selbstauskunft/:auskunft_seq?',
        name: 'Selbstauskunft',
        meta: {
            requiresAuth: true,
            resource: "selbstauskunft"
        },
        component: () => import(/* webpackChunkName: "SelbstauskuenfteSelbstauskunft" */ './Details/Selbstauskunft.vue')
    },
    {
        path: '/selbstauskuenfte/detail/:auskunft_seq?',
        name: 'SelbstauskunftDetail',
        meta: {
            requiresAuth: true,
            resource: "selbstauskunft"
        },
        component: () => import(/* webpackChunkName: "SelbstauskuenfteDetail" */ './Details/Detail.vue')
    },
];

export default selbstauskuenfte;
