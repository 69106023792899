
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({

})
export default class LazyTab extends Vue {
    @Prop() public index!: any;

    public active: boolean = false;

    public mounted() {
        try {
            (this.$parent as any).addTab(this);
        } catch (e) {
            throw new Error('LazyTab was placed outside of tab component');
        }
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public click() {
        this.$emit('click', this);
    }
}
