
    import {Component, Prop, Watch} from 'vue-property-decorator';
    import BaseElement from "../BaseElement";
    import _ from "lodash";
    import TreeitemInterface from "./TreeitemInterface";
    import Axios, {CancelTokenSource} from "axios";

    @Component({
        watch: {
            options: 'onOptionsChange',
            value: 'onValueChange',
            param: {
                deep: true,
                handler() {
                    (this as any).paramChange.apply(this);
                }
            },
            isReadonly(readonly) {
                if (readonly) {
                    (this as any).collapse();
                }
            }
        }
    })
    export default class InputTree extends BaseElement {
        @Prop() public options: any[];
        @Prop() public value: any;
        @Prop({default: false}) public multiselect: boolean;
        @Prop({default: false}) public searchable: boolean;
        @Prop() public remote: string;
        @Prop({default: ""}) public param: any;
        @Prop({default: false}) public autoWidthMenu: boolean;
        @Prop({default: "right"}) public menuExpandDirection: string;
        @Prop({default: "value"}) public valueFieldName: string;
        @Prop({default: "text"}) public textFieldName: string;
        @Prop({default: "text"}) public displayFieldName: string;
        @Prop({default: "children"}) public childrenFieldName: string;
        // Name des Primary Keys
        @Prop({default: "value"}) public remotePrimary: string;
        protected expanded: boolean = false;
        protected valueInitialization: boolean = false;
        protected remoteLoaded: boolean = false;
        protected remoteValueLoading: boolean = false;
        protected treeData: TreeitemInterface[] = [];
        protected searchData: any[] = [];
        protected selection: TreeitemInterface[] = [];
        // Search Logic
        protected searchFocused: boolean = false;
        protected searchString: string = "";
        protected searchStringBouncer: any;
        protected searchQueryActive: boolean = false;
        protected searchCancelToken: CancelTokenSource | null = null;
        protected isReadonly: any; // Typescript Anpassung - isReadonly kommt aus einem Mixin

        get isLoading(): boolean {
            return this.remote ? ((this.expanded && !this.remoteLoaded) || this.remoteValueLoading) : false;
        }

        get isMultiselect(): boolean {
            return this.multiselect !== undefined && this.multiselect !== false;
        }

        get isSearchFocused(): boolean {
            return this.searchFocused;
        }

        get isSearchable(): boolean {
            return !this.isReadonly && this.searchable !== undefined && this.searchable !== false;
        }

        get isAutoWidthMenu(): boolean {
            return this.autoWidthMenu;
        }

        get isActive(): boolean {
            return (this.isSearching || this.expanded);
        }

        get isSearching(): boolean {
            return (this.searchString.length > 0);
        }

        get isSearchQuery(): boolean {
            return this.searchQueryActive;
        }

        get isExpanded(): boolean {
            return this.expanded;
        }

        get isClearable(): boolean {
            if (this.isMultiselect) {
                return false;
            }

            return this.clearable !== false;
        }

        get hasValue(): boolean {
            return this.selection && this.selection.length > 0;
        }

        public mounted(): void {
            this.valueInitialization = true;
            this.initTree();
            if (!this.remote) {
                // Keine Remotedefinition -> kein laden notwendig
                this.remoteLoaded = true;
            }
            this.valueInitialization = false;

            if (this.getSelected().length === 0 && this.value && !this.remoteLoaded) {
                this.onValueChange();
            }


            window.addEventListener('click', this.onDocClick);
            window.addEventListener('blur', this.onDocClick);
        }

        public destroyed(): void {
            window.removeEventListener('click', this.onDocClick);
            window.removeEventListener('blur', this.onDocClick);
        }

        public setSelected(selected?: TreeitemInterface[]): void {
            if (selected !== undefined) {
                // deselect current
                const recursiveDeselect = (node) => {
                    if (node) {
                        node.selected = false;
                        if (node.children) {
                            _.each(node.children, (c) => {
                                recursiveDeselect(c);
                            });
                        }
                    }
                };
                _.each(this.treeData, recursiveDeselect);

                _.each(selected, (s) => {
                    s.selected = true;
                });
                this.selection = selected;
            } else {
                this.selection = [];
            }
        }

        public updateValue() {
            const selected = this.getSelected();
            this.setSelected(selected);
            this.populateValue();
        }

        public initValue() {
            if ((!_.isArray(this.value) && this.value) || (_.isArray(this.value) && this.value.length > 0)) {
                this.setSelectedByValue(this.value);
                this.syncSelectionWithTree();
            }
        }

        public onOptionsChange() {
            if (this.options) {
                this.valueInitialization = true;
                this.remoteLoaded = false;
                this.initTree();
                this.valueInitialization = false;
            }
        }

        public isSearchItemSelected(item): boolean {
            const selection = this.selection;
            let isSelected: boolean = false;
            if (_.isArray(this.selection)) {
                try {
                    _.each(this.selection, (i) => {
                        if (i[this.valueFieldName] === item[this.valueFieldName]) {
                            throw true;
                        }
                    });
                } catch (found) {
                    isSelected = (found as any);
                }
            }

            return isSelected;
        }

        protected onDocClick(event) {
            return this.collapseIf(event);
        }

        protected onEnterKey() {
            let selected = $('.item.focused', this.$el);
            if (selected && selected.length === 0) {
                selected = $('.item:hover', this.$el);
            }

            if (selected && selected.length > 0) {
                const result = selected[0];
                const id = $(result).data('value');
                if (id && this.searchData) {
                    const item = _.find(this.searchData, [this.valueFieldName, id]);
                    if (item) {
                        this.onSearchItemSelect(item);
                    }
                }
            }
        }

        protected onArrowDownKey() {
            const selected = $('.item.focused', this.$el);
            $('.item', this.$el).removeClass('focused');
            if (selected && selected.length > 0) {
                const next = selected.next('.item');
                if (next && next.length) {
                    next.addClass('focused');
                    return;
                }
            }
            $('.item:first', this.$el).addClass('focused');
        }

        protected onArrowUpKey() {
            const selected = $('.item.focused', this.$el);
            $('.item', this.$el).removeClass('focused');
            if (selected && selected.length > 0) {
                const next = selected.prev('.item');
                if (next && next.length) {
                    next.addClass('focused');
                    return;
                }
            }
            $('.item:last', this.$el).addClass('focused');
        }

        protected onKeyDown(e: KeyboardEvent) {
            if (e.code === "ArrowDown" || e.code === "ArrowUp" || e.code === "Enter" || e.code === "NumpadEnter") {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                    return this.onEnterKey();
                }
                e.stopPropagation();
                e.preventDefault();
                // Keyboard Navigation
                return e.code === "ArrowUp" ? this.onArrowUpKey() : this.onArrowDownKey();
            }
        }

        @Watch('searchString')
        protected onSearch() {
            if (!this.searchStringBouncer) {
                this.searchStringBouncer = _.debounce(() => {
                    if (this.isSearching) {
                        const param = _.merge({}, this.param, {query: this.searchString});
                        this.searchQueryActive = true;
                        this.searchData = [];

                        if (this.searchCancelToken) {
                            this.searchCancelToken.cancel();
                        }
                        this.searchCancelToken = Axios.CancelToken.source();
                        this.$http.post(this.remote, param, {cancelToken: this.searchCancelToken.token}).then((r) => {
                            this.searchQueryActive = false;
                            this.searchData = r.data.body;
                            this.searchCancelToken = null;
                        }).catch(() => {
                            // Cancel Request nothing to do
                        });
                    } else {
                        this.searchQueryActive = false;
                        this.searchData = [];
                        this.searchCancelToken = null;
                    }
                }, 500);
            }

            this.searchStringBouncer();
        }

        protected initTree() {
            let treeData = _.clone(this.options);

            if (!treeData || (_.isObject(treeData) && _.isEmpty(treeData))) {
                treeData = [];
            }

            if (!_.isArray(treeData)) {
                treeData = [treeData];
            }
            this.treeData = treeData;
            (this.$refs.jsTree as any).initializeData(this.treeData);

            this.expand();
            this.collapse();
            if (!this.valueInitialization) {
                this.updateValue();
            } else {
                this.initValue();
            }
        }

        protected removeItem(item: TreeitemInterface) {
            item.selected = false;
            this.updateValue();
        }

        protected getSelected(item?: TreeitemInterface): TreeitemInterface[] {
            let sel: TreeitemInterface[] = [];

            if (!item) {
                if (!this.$refs.jsTree || !(this.$refs.jsTree as any).data) {
                    // Wenn der Tree noch keine Daten hat, prüfe this.treeData
                    if (this.selection) {
                        this.selection.forEach((i) => {
                            if (i.selected !== false) {
                                sel.push(i);
                            }
                        });
                    }

                    return sel;
                }
                (this.$refs.jsTree as any).data.forEach((i) => {
                    sel = _.concat(sel, this.getSelected(i));
                });

                return sel;
            }

            if (item.selected === true) {
                sel.push(item);
            }

            if (item.children) {
                item.children.forEach((child) => {
                    sel = _.concat(sel, this.getSelected(child));
                });
            }

            return sel;
        }

        protected onItemClick(): void {
            this.updateValue();
        }

        protected onSearchItemSelect(item): void {
            // Finish search
            const value = item[this.valueFieldName];
            if (this.isMultiselect) {
                const remove = this.isSearchItemSelected(item);
                if (remove) {
                    // remove From Selection
                    _.remove(this.selection, (i: any) => {
                        return item[this.valueFieldName] === i[this.valueFieldName];
                    });
                }

                const sel: any[] = _.map(this.selection, (i: any) => {
                    return i[this.valueFieldName];
                });
                if (!remove) {
                    sel.push(value);
                }
                this.setSelectedByValue(sel);
            } else {
                this.setSelectedByValue(value);
            }
            this.searchString = "";
            this.searchData = [];
            this.searchQueryActive = false;
            this.populateValue();
            this.collapse();

            this.$nextTick(() => {
                this.syncSelectionWithTree();
            });
        }

        protected populateValue() {
            const selectedValues: any[] = _.map(this.selection, (item: any) => {
                if (!item) {
                    return null;
                }
                return item[this.valueFieldName];
            });

            if (!this.value && selectedValues.length === 0) {
                this.$emit('input', null);

                if (!this.isMultiselect && this.expanded) {
                    this.collapse();
                }
                return;
            }

            if (selectedValues.length === 0) {
                this.$emit('input', null);
            } else if (this.isMultiselect) {
                this.$emit('input', selectedValues);
            } else {
                this.$emit('input', _.head(selectedValues));
            }

            const displayedValues: any[] = _.map(this.selection, (item: any) => {
                if (!item) {
                    return null;
                }

                return {
                    disabled: false,
                    name: item[this.displayFieldName],
                    text: item[this.displayFieldName],
                    value: item[this.valueFieldName]
                };
            });
            this.$emit('select', displayedValues);

            // Next Tick -> Ansonsten endlosschleife
            this.$nextTick(() => {
                this.remoteValueLoading = false;
                this.valueInitialization = false;
            });

            if (!this.isMultiselect && this.expanded) {
                this.collapse();
            }
        }

        protected loadRemote(): Promise<any> {
            return this.$http.post(this.remote, this.param).then((r) => {
                this.remoteLoaded = true;
                this.treeData = r.data.body;
                (this.$refs.jsTree as any).initializeData(this.treeData);
                if (this.value) {
                    this.$nextTick(() => {
                        this.syncSelectionWithTree();
                    });
                }
            }).catch(() => {
                this.remoteLoaded = true;
            });
        }

        protected expand(): void {
            if (!this.valueInitialization && this.remote && !this.remoteLoaded) {
                this.treeData = [];
                this.loadRemote();
            }
            this.expanded = true;
        }

        public paramChange(value, oldvalue) {
            if (this.remote) {
                this.remoteLoaded = false;
            }
        }

        protected syncSelectionWithTree() {
            const sel: any[] = _.map(this.selection, (item: any) => {
                return item[this.valueFieldName];
            });

            _.each(sel, (value) => {
                const selected = this.findItem(value);
                if (selected) {
                    selected.selected = true;

                    const path = _.split(selected.path, '|');
                    path.forEach((id) => {
                        const item = this.findItem(id);
                        if (item && item !== selected && item.opened !== true) {
                            item.opened = true;
                        }
                    });
                }
            });
        }

        protected collapseIf(event) {
            const $target = $(event.target);
            const inModule = ($target.closest((this.$refs as any).treeElement).length > 0);

            const callback = this.collapse;
            if (!inModule) {
                this.searchFocused = false;
                callback();
                return true;
            } else {
                return false;
            }
        }

        protected collapse(): void {
            this.expanded = false;
            this.searchFocused = false;
            this.searchString = "";
            this.searchQueryActive = false;
        }

        protected focusSearch(event): void {
            if (this.isSearchable && this.$refs.toggler !== event.target) {
                this.searchFocused = true;
                this.$nextTick(() => {
                    $((this.$refs.searchField as any)).focus();
                });
            } else {
                this.toggle();
            }
        }

        protected toggleOrClear(): void {
            if (this.isClearable && this.hasValue) {
                this.clear();
            } else {
                this.toggle();
            }
        }

        protected toggle(): void {
            if (!(this as any).isReadonly) {
                this.expanded ? this.collapse() : this.expand();
            } else {
                this.collapse();
            }
        }

        public clear(): void {
            if (this.isClearable) {
                this.$emit('input', null);
            }
        }

        protected findItem(value?: string, searchitem?: TreeitemInterface): TreeitemInterface | undefined {
            let found;
            if (!value) {
                value = this.value;
            }

            if (value) {
                const children = !searchitem ? this.treeData : searchitem.children;
                const idx = _.findIndex(children, [this.valueFieldName, value]);
                if (idx === -1) {
                    _.find(children, (item): any => {
                        if (value && item[this.valueFieldName].toString() === value.toString()) {
                            found = item;
                        } else {
                            found = this.findItem(value, item);
                        }

                        return !!found;
                    });
                } else {
                    if (children) {
                        found = children[idx];
                    }
                }
            }

            return found;
        }

        protected setSelectedByValue(value) {
            if (!_.isArray(value)) {
                value = [value];
            }

            const searched: any[] = value;
            const found: any[] = [];
            _.each(value, (v) => {
                if (v !== null) {
                    const searchedValue = this.findItem(v);
                    if (searchedValue) {
                        found.push(searchedValue);
                    }
                }
            });

            if (searched.length === found.length || !this.remote) {
                this.valueInitialization = true;
                this.setSelected(found);
                this.populateValue();
            } else {
                // Mindestens ein Wert wurde nicht gefunden -> remote laden
                const params = _.merge({}, this.param, {});
                if (this.isMultiselect) {
                    params[this.remotePrimary] = value;
                } else {
                    params[this.remotePrimary] = value[0];
                }
                this.remoteValueLoading = true;
                this.$http.post(this.remote, params).then((r) => {
                    const selected = r.data.body;
                    if (selected !== false) {
                        this.setSelected(selected);
                        this.populateValue();
                    }
                }).catch(() => {
                    this.remoteValueLoading = false;
                });
            }

            return true;
        }

        protected onValueChange(newValue?, oldValue?) {
            if (!this.value && !this.remote && this.isClearable) {
                this.setSelected();
                this.populateValue();
                this.selection = [];
                this.valueInitialization = false;
                return false;
            }

            if (!this.remote || this.expanded === true || this.valueInitialization === true || this.remoteValueLoading === true) {
                return false;
            }

            if (newValue && oldValue) {
                if (!_.isArray(newValue)) {
                    newValue = [newValue];
                }

                if (!_.isArray(oldValue)) {
                    oldValue = [oldValue];
                }

                const a = _.clone(newValue);
                const b = _.clone(oldValue);

                if (!_.isEmpty(_.xor(a.sort(), b.sort()))) {
                    this.setSelectedByValue(this.value);
                    return true;
                }
            } else if (this.value) {
                this.setSelectedByValue(this.value);
                return true;
            }

            if (!this.value) {
                this.setSelected();
                this.populateValue();
                this.valueInitialization = false;
            }
            return false;
        }
    }
