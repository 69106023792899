import GridConfig from "@/framework/extjs/grid/Config";

export const AuditsGridConfig: GridConfig = {
    id: 'Audits',
    title: 'audits',
};

export const MassnahmenGridConfig: GridConfig = {
    id: 'Massnahmen',
    title: 'massnahmen',
};
