import vStore from "@/app/store";
import i18n from '@/framework/locale';
import * as _ from 'lodash';
import watcher from "@/app/components/DefaultGridWatcher";
import "./toolbar/Paging";
import FilterWatcher from "@/framework/extjs/watcher/FilterWatcher";

declare var FW: any;

Ext.define('FW.grid.Panel', {
    extend: 'Ext.grid.Panel',
    requires: ['Ext.grid.filters.Filters'],

    stateful: true,
    columnLines: true,

    defaultLock: false,
    defaultDetailColumnIcon: "<span class='x-fa fa-pencil-alt'></span>",

    selModel: {
        type: 'checkboxmodel',
        checkOnly: true
    },

    controller: 'fwgrid',

    viewModel: {
        data: {
            newBtn: {
                default: true,
                permissions: ['insert'],
                visible: true,
                disabled: false
            },
            saveBtn: {
                default: false,
                permissions: ['update'],
                visible: false,
                disabled: false
            },
            deleteBtn: {
                default: true,
                permissions: ['delete'],
                visible: true,
                disabled: false
            },
            exportBtn: {
                default: true,
                permissions: ['export'],
                visible: true,
                disabled: false
            },
            importBtn: {
                default: false,
                permissions: ['import'],
                visible: false,
                disabled: false
            },
            filterBtn: {
                default: false,
                visible: false,
                disabled: false
            },
            copyBtn: {
                default: false,
                permissions: ['insert'],
                visible: false,
                disabled: false
            },
            myFilterBtn: {
                default: false,
                visible: false,
                disabled: false
            },
        }
    },

    /**
     * kommen in den konstruktor rein
     * Permission Watcher
     */
    watcher: [],

    newBtnText: 'neu',
    saveBtnText: 'speichern',
    deleteBtnText: 'loeschen',
    copyBtnText: 'kopieren',
    editBtnText: 'edit',
    filterBtnText: 'erweitert',
    myFilterBtnText: 'meinepunkte',
    exportBtnText: 'export',
    importBtnText: 'import',
    cancelBtnText: 'verwerfen',

    newBtnTooltip: false,
    saveBtnTooltip: false,
    deleteBtnTooltip: false,
    editBtnTooltip: false,
    filterBtnTooltip: false,
    cancelBtnTooltip: false,
    copyBtnTooltip: false,
    myFilterBtnTooltip: 'meine_punkte',

    /**
     * Detail Route aus VUE App
     */
    detailRoute: '',
    detailTitle: 'detailtitle',

    /**
     * Selkrit Route aus VUE App
     */
    filterRoute: '',
    filterTitle: 'filter',
    filterSearchText: true,

    importRoute: '',
    importTitle: 'import',

    /**
     * Die Resource zu der dieser Grid gehört (für Permissions Abfragen)
     */
    resource: undefined,

    /**
     * FilterSave Plugin
     */
    enableFilterSave: false,

    /**
     * FWPagingToolbar - showAll Steuerung
     */
    showAllButton: true,

    /**
     * Row Expander aktivieren
     */
    enableRowExpander: false,
    rowExpanderTemplate: new Ext.XTemplate('{expandData}'),
    reference: 'grid',
    referenceHolder: true,
    reserveScrollbar: true,
    autoHeight: true,

    /**
     * filter-tag-intput autofocus
     */

    filterbarAutoFocus: true,

    plugins: {},

    applyState(state) {
        FW.grid.Panel.superclass.applyState.apply(this, [state]);

        if (state) {
            if (state.hasOwnProperty('storeState') && state.storeState.hasOwnProperty('filters')) {
                delete state.storeState.filters;
            }
            Ext.apply(this, state);
        }
    },

    leaving() {
        console.log('leaving');
    },

    getNewButton(cfg) {
        return {
            reference: "grid.buttons.new",
            text: this.newBtnText,
            tooltip: this.newBtnTooltip || this.newBtnText,
            bind: {
                disabled: '{newBtn.disabled}',
                hidden: '{!newBtn.visible}'
            },
            cls: 'extjs button primary',
            handler: 'onNewButtonClick',
            sort: 10
        };
    },

    getDeleteButton(cfg) {
        return {
            reference: "grid.buttons.delete",
            text: this.deleteBtnText,
            tooltip: this.deleteBtnTooltip || this.deleteBtnText,
            cls: 'extjs button red',
            handler: 'onDeleteButtonClick',
            bind: {
                disabled: '{!grid.selection || deleteBtn.disabled}',
                hidden: '{!deleteBtn.visible}',
            },
            sort: 20
        };
    },

    getFilterButton(cfg) {
        return {
            reference: "grid.buttons.filter",
            text: this.filterBtnText,
            tooltip: this.filterBtnTooltip || this.filterBtnText,
            iconCls: 'x-fa fa-filter',
            cls: 'extjs button default',
            handler: 'onFilterButtonClick',
            bind: {
                hidden: '{!filterBtn.visible}',
                disabled: '{filterBtn.disabled}',
            }
        };
    },

    getMyFilterButton(cfg) {
        return {
            reference: "grid.buttons.myfilter",
            text: this.myFilterBtnText,
            tooltip: this.myFilterBtnTooltip || this.myFilterBtnText,
            iconCls: 'x-fa fa-user',
            cls: 'extjs button default',
            handler: 'onMyFilterButtonClick',
            bind: {
                hidden: '{!myFilterBtn.visible}',
                disabled: '{myFilterBtn.disabled}',
            }
        };
    },

    getExportButton(cfg) {
        return {
            reference: "grid.buttons.export",
            iconCls: 'x-fa fa-download',
            text: this.exportBtnText,
            tooltip: this.exportBtnTooltip || this.exportBtnText,
            cls: 'extjs button default',
            handler: 'onExportButtonClick',
            bind: {
                hidden: '{!exportBtn.visible}',
                disabled: '{exportBtn.disabled}',
            },
            sort: 40
        };
    },

    getImportButton(cfg) {
        return {
            reference: "grid.buttons.import",
            iconCls: 'x-fa fa-upload',
            text: this.importBtnText,
            tooltip: this.importBtnTooltip || this.importBtnText,
            cls: 'extjs button default',
            handler: () => {
                this.lookupController().onWindowButtonClick.call(this.lookupController(), 'import', this.importTitle, this.importRoute);
            },
            bind: {
                hidden: '{!importBtn.visible}',
                disabled: '{importBtn.disabled}',
            },
            sort: 50
        };
    },

    getDetailButton(cfg) {
        return {
            xtype: 'actioncolumn',
            text: this.defaultDetailColumnIcon,
            width: 40,
            menuDisabled: true,
            sortable: false,
            hideable: false,
            align: 'center',
            stateId: '_detail',
            locked: this.defaultLock,

            items: [{
                iconCls: 'x-fa fa-edit',
                tooltip: this.editBtnText,
                handler: 'onDetailButtonClick'
            }]
        };
    },

    initPlugins(cfg, store) {
        const eRE = cfg.enableRowExpander || this.enableRowExpander;
        if (eRE) {
            this.plugins['fwrowexpander'] = {
                rowBodyTpl: cfg.rowExpanderTemplate ? cfg.rowExpanderTemplate : this.rowExpanderTemplate
            };
        } else {
            if (this.plugins && this.plugins['fwrowexpander']) {
                delete this.plugins['fwrowexpander'];
            }
        }

        if (this.plugins) {
            if (store.proxy.api.export) {
                this.plugins['gridexport'] = {};
            } else {
                delete this.plugins['gridexport'];
            }
            // this.plugins['gridfilters'] = {};
            this.plugins['statereset'] = {};
            this.plugins['gridfilterbar'] = {
                hidden: true
            };

            if (cfg.enableFilterSave || this.enableFilterSave) {
                this.plugins['filtersave'] = {};
            }
        }

        if (cfg.plugins) {
            cfg.plugins = _.merge(cfg.plugins, {panelresize: {}}, this.plugins);
        } else {
            cfg.plugins = _.merge({panelresize: {}}, this.plugins);
        }
    },

    convertWatchedStore(filter) {
        if (!filter) {
            return [];
        }

        const retval: any = [];

        _.each(filter, (value, key) => {
            const obj = new Object();
            obj[key] = new FilterWatcher({filter: value});
            retval.push(obj);

        });

        return retval;
    },


    constructor(cfg) {
        const me = this;
        if (!cfg) {
            cfg = {};
        }


        this.watcher = [
            ...watcher,
            ...this.watcher,
            ...this.convertWatchedStore(this.watchedStore)
        ];

        this.selkritTitle = i18n.t(this.selkritTitle);
        this.detailTitle = i18n.t(this.detailTitle);
        this.newBtnText = i18n.t(this.newBtnText);
        this.deleteBtnText = i18n.t(this.deleteBtnText);
        this.editBtnText = i18n.t(this.editBtnText);
        this.saveBtnText = i18n.t(this.saveBtnText);
        this.cancelBtnText = i18n.t(this.cancelBtnText);
        this.copyBtnText = i18n.t(this.copyBtnText);
        this.filterBtnText = i18n.t(this.filterBtnText);
        this.exportBtnText = i18n.t(this.exportBtnText);
        this.importBtnText = i18n.t(this.importBtnText);
        this.emptyText = i18n.t('grid_empty_text');
        this.myFilterBtnText = i18n.t(this.myFilterBtnText);

        let buttonConfig: any = {};

        if ((cfg.viewModel && cfg.viewModel.data)) {
            buttonConfig = cfg.viewModel.data;

        } else if ((this.config.viewModel && this.config.viewModel.data)) {
            buttonConfig = this.config.viewModel.data;
        }

        if ((cfg.detailRoute || this.detailRoute) && (cfg.columns || this.columns)) {
            const columns = cfg.columns || _.map(this.columns, _.clone);
            columns.unshift(this.getDetailButton(cfg));
            cfg.columns = columns;
        } else {
            cfg.columns = cfg.columns || this.columns;
        }


        if (!cfg.tbar) {
            cfg.tbar = [];
        }

        this.config.viewModel.stores = this.config.viewModel.stores ? this.config.viewModel.stores : {};

        const store = cfg.store || this.config.viewModel.stores.gridStore || this.store;

        try {
            if (((_.isObject(buttonConfig.newBtn) && buttonConfig.newBtn.default) || buttonConfig.newBtn === true)) {
                cfg.tbar.push(this.getNewButton(cfg));
            }
        } catch (err) {
            // Keine create API angegeben
        }

        try {
            if (((_.isObject(buttonConfig.saveBtn) && buttonConfig.saveBtn.default) || buttonConfig.saveBtn === true)) {

                const mixin = this.mixins.celledit || this.mixins.rowedit || this.mixins.multiedit;

                if (mixin.buttons) {
                    mixin.buttons(this, cfg);
                }

            }
        } catch (err) {
            // Keine create API angegeben
        }

        try {
            if (((_.isObject(buttonConfig.deleteBtn) && buttonConfig.deleteBtn.default) || buttonConfig.deleteBtn === true)) {
                cfg.tbar.push(this.getDeleteButton(cfg));
            }
        } catch (err) {
            console.error(err);
            // Keine destroy API angegeben
        }

        this.initFilterButton(cfg, buttonConfig);

        try {
            if (((_.isObject(buttonConfig.exportBtn) && buttonConfig.exportBtn.default) || buttonConfig.exportBtn === true)) {
                cfg.tbar.push(this.getExportButton(cfg));

            }
            if (((_.isObject(buttonConfig.importBtn) && buttonConfig.importBtn.default) || buttonConfig.importBtn === true)) {
                cfg.tbar.push(this.getImportButton(cfg));

            }
        } catch (err) {
            console.error(err);
        }

        if (cfg.tbar.length > 0) {
            cfg.tbar = _.sortBy(cfg.tbar, ['sort']);
        }

        this.initPlugins(cfg, store);
        this.initFilterToolbar(cfg, buttonConfig);

        if (cfg.tbar.length === 0) {
            delete cfg.tbar;
        }

        FW.grid.Panel.superclass.constructor.apply(this, [cfg]);

        if (store.proxy.api.expand) {
            this.getView().on('expandbody', 'onExpandRow');
        }

        if (!this.getStore().isEmptyStore) {
            this.initStore();
        }


    },

    initFilterButton(cfg: any, buttonConfig: any) {
        // if (((_.isObject(buttonConfig.filterBtn) && buttonConfig.filterBtn.default) || buttonConfig.filterBtn === true)) {
        //     cfg.tbar.push(this.getFilterButton(cfg));
        // }
    },


    initFilterToolbar(cfg: any, buttonConfig: any) {
        const filterToolbar: any = {
            id: this.id + '_filtertoolbar',
            xtype: 'filtertoolbar',
            enableSearchtext: this.filterSearchText,
            extFilterButton: []
        };

        if (((_.isObject(buttonConfig.filterBtn) && buttonConfig.filterBtn.default) || buttonConfig.filterBtn === true)) {
            filterToolbar.extFilterButton.push(this.getFilterButton(cfg));
        }

        if (((_.isObject(buttonConfig.myFilterBtn) && buttonConfig.myFilterBtn.default) || buttonConfig.myFilterBtn === true)) {
            filterToolbar.extFilterButton.push(this.getMyFilterButton(cfg));
        }

        cfg.tbar.push(filterToolbar);
    },



    initStore() {
        const controller = this.lookupController();
        this.getStore().on('exception', 'onException');
        controller.watchFilter();
    },

    initComponent() {
        FW.grid.Panel.superclass.initComponent.apply(this, arguments);

        // this.addDocked([{
        //     xtype: 'filtertoolbar',
        //     enableSearchtext: this.filterSearchText,
        //     dock: 'top'
        // }]);

        this.addDocked([{
            xtype: 'fwpagingtoolbar',
            displayInfo: true,
            showAllButton: this.showAllButton,
            dock: 'bottom',
            refreshText: i18n.t('neu_laden').toString(),
            listeners: {
                beforechange: "onBeforePageing"
            }
        }]);
    },

    listeners: {

        storechange(grid, newStore, oldStore) {
            if (!newStore || newStore.isEmptyStore || newStore == oldStore) {
                return;
            }

            const controller = grid.lookupController();
            newStore.on('exception', 'onException');
            controller.unwatchFilter();
            controller.watchFilter();
        },


        beforeedit: "onBeforeEdit",
        edit: "onEdit",
        canceledit: "onCancelEdit",
        validateedit: "onValidateEdit",

        beforestatesave(grid, state, eOpts) {
            if (state.storeState) {
                delete state.storeState.filters;
            }
        },


        afterrender ( grid, eOpts ) {
            if (grid.filterbarAutoFocus) {
                setTimeout(() => {
                    const filtertoolbar: any = Ext.getCmp(grid.id + '_filtertoolbar');

                    if (filtertoolbar && filtertoolbar._tagField) {
                        filtertoolbar._tagField.focus()
                    }

                }, 200);
            }
        }

    }
});
