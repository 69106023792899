const fehlerkataloge = [
    {
        path: '/administration/stammdaten/fehlerkataloge',
        name: 'Fehlerkataloge',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "stammdaten_fehlerkataloge" */ './Grid/Fehlerkataloge.vue'),
    },
];

export default fehlerkataloge;
