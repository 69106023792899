
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import $ from 'jquery';
    import _ from 'lodash';

    @Component({
        mounted() {
            $(this.$el).menu(
                {
                    direction   : 'auto',
                    keepOnScreen: true
                }
            );
        }
    })
    export default class DropdownMenu extends Vue {

        @Prop() private routerlink: string|string[];

        get isActive() {
            if (!this.routerlink) {
                return false;
            }

            if (_.isArray(this.routerlink)) {
                let found: boolean = false;

                _.forEach(this.routerlink, (routerlink) => {
                    found = this.$route.path.indexOf(routerlink) === 0;
                    if (found) {
                        return false;
                    }
                })

                return found;
            } else {
                return this.$route.path.indexOf(this.routerlink) === 0;
            }



        }
    }
