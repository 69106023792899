import './CalendarModel';
import _ from "lodash";

declare var FW: any;

Ext.define('FW.Calendar.data.CalendarStore', {
    extend: 'Cal.data.EventStore',

    autoLoad: true,
    autoSync: true,
    pageSize: 0,
    asynchronousLoad: true,
    remoteFilter: true,
    model: 'FW.Calendar.data.CalendarModel',
    controller: 'fwcalendar',

    proxy: {
        type: 'api'
    },

    dirty: false,

    constructor(cfg) {
        FW.Calendar.data.CalendarStore.superclass.constructor.apply(this, arguments);

        if (cfg && cfg.idProperty) {
            this.model.idProperty = this.model.prototype.idProperty = cfg.idProperty;
        }

        this.exports = [];
    },

    sort(field, direction, mode) {
        if (!field) {
            return;
        }

        FW.Calendar.data.CalendarStore.superclass.sort.apply(this, arguments);
    },

    isDirty() {
        return this.dirty === true;
    },

    sync(options) {
        if (!options || !options.failure) {

            options = _.isObject(options) ? options : {};

            options.failure = (batch, options) => {
                this.fireEvent('failure', batch, options);
            };
        }

        FW.Calendar.data.CalendarStore.superclass.sync.apply(this, [options]);
    }
});
