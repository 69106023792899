import * as _ from "lodash";
import moment from "moment";

Ext.define('FW.grid.plugin.filterbar.filters.DateRange', {
    extend: 'Ext.grid.plugin.filterbar.filters.Date',
    alias: 'grid.filterbar.daterange',

    fieldDefaults: {
        xtype: 'daterangefield'
    },

    config: {
        operators: ['==', '!=', '>', '>=', '<', '<=', 'empty', 'nempty', 'between']
    },


    constructor(config) {
        Ext.grid.plugin.filterbar.Operator.addOperator('between', 'x-operator-between', Ext.grid.plugin.filterbar.Operator.prototype.operatorsTextMap.between ?? 'Between');
        FW.grid.plugin.filterbar.filters.DateRange.superclass.constructor.apply(this, arguments);
    },

    getSerializer() {
        return (data) => {

            if (this.isDateRange()) {
                let value = data.value;

                if (value && value !== true) {
                    data.value = value;
                }
                return;
            }

            let value = data.value;

            if (_.isString(value)) {
                const tmp = new Date(value);
                if (!isNaN(tmp.getFullYear())) {
                    value = tmp;
                }
            }

            if (value && value !== true) {
                const dateFormat = 'Y-m-d';
                data.value = Ext.Date.format(value, dateFormat);
            }
        };
    },

    initFilter(config) {

        FW.grid.plugin.filterbar.filters.DateRange.superclass.initFilter.apply(this, arguments);

        if (this.getOperator() === 'between') {
            this.filter.setType('daterange');
        } else {
            this.filter.setType('date');
        }

    },



    onOperatorChange(field, operation) {
        FW.grid.plugin.filterbar.filters.DateRange.superclass.onOperatorChange.apply(this, arguments);

        if (this.isDateRange()) {
            this.filter.setType('daterange');
        } else {
            this.filter.setType('date');
        }

        if (this.value && ((operation !== 'between' && _.isArray(this.value)) || (operation === 'between' && !_.isArray(this.value)))) {
            field.suspendEvents();
            field.setValue(null);
            field.resumeEvents(true);
            this.setValue(null);
        }

    },

    isDateRange() {
        return this.getOperator() === 'between';
    },


    onValueChange(field, value) {

        if (!this.isDateRange()) {
            FW.grid.plugin.filterbar.filters.DateRange.superclass.onValueChange.apply(this, arguments);
            return;
        }


        var me = this,
            updateBuffer = me.updateBuffer;

        if (field.isValid()) {
            if (value === me.value) {
                return;
            }

            let values: any[] = [];
            if (_.isArray(value)) {
                _.forEach(value, (v) => {
                    if (v) {
                        const date = [
                            v.getFullYear(),
                            v.getMonth(),
                            v.getDate()
                        ];
                        values.push(moment(date).format('YYYY-MM-DD'));
                    }
                });

                value = values;
            }

            if (updateBuffer) {
                me.task.delay(updateBuffer, null, null, [value]);
            } else {
                me.setValue(value);
            }
        }
    }
});
