import AktivitaetOpenDetail from "@/app/pages/Aktivitaeten/Grid/OpenDetail";


const aktivitaeten = [
    {
        path: '/aktivitaetenlieferant',
        name: 'Aktivitaeten Grid View',
        meta: {
            requiresAuth: true,
            resource: "aktivitaeten"
        },
        component: () => import(/* webpackChunkName: "aktivitaetengridview" */ './Grid/AktivitaetenGridView.vue'),
        children: [
            {
                path: 'open/:aktivitaet_seq',
                beforeEnter: AktivitaetOpenDetail
            }
        ]
    },
    {
        path: '/aktivitaeten',
        name: 'Aktivitaeten',
        meta: {
            requiresAuth: true,
            resource: "aktivitaeten"
        },
        component: () => import(/* webpackChunkName: "aktivitaeten" */ './Grid/Aktivitaeten.vue'),
        children: [
            {
                path: 'open/:aktivitaet_seq',
                beforeEnter: AktivitaetOpenDetail
            }
        ]
    },
    {
        path: '/aktivitaeten/detail/:aktivitaet_seq?/:fw_ownership?',
        name: 'Aktivitaet',
        meta: {
            requiresAuth: true,
            resource: "aktivitaeten"
        },
        component: () => import(/* webpackChunkName: "aktivitaet" */ './Details/Aktivitaet.vue')
    },

    {
        path: '/aktivitaeten/new/:strukturelement_seq?',
        name: 'Aktivitaet Neu',
        meta: {
            requiresAuth: true,
            resource: "aktivitaeten"
        },
        component: () => import(/* webpackChunkName: "aktivitaet" */ './Details/Aktivitaet.vue')
    },
    {
        path: '/aktivitaeten/filter',
        name: 'Aktivitaet Filter',
        meta: {
            requiresAuth: true,
            resource: "aktivitaeten"
        },
        component: () => import(/* webpackChunkName: "aktivitaeten_filter" */ './Grid/Filters/Aktivitaeten.vue')
    }
];

export default aktivitaeten;
