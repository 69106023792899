
    import {Component, Prop} from 'vue-property-decorator';
    import BaseElement from "@/framework/components/Form/Elements/BaseElement";
    import RadioInterface from "./RadioInterface";
    import _ from 'lodash';

    @Component({
        watch: {
            value() {
                (this as any).radio = (this as any).value;
            }
        }
    })
    export default class InputRadioGroup extends BaseElement {
        get uniqueId(): any {
            if (!this.uid) {
                this.uid = InputRadioGroup.UniqueId++;
            }
            return this.uid;
        }

        get isInline(): boolean {
            return this.inline !== undefined && this.inline !== false;
        }

        public static UniqueId: number = 0;

        @Prop() public options: any[];
        @Prop() public value!: any;
        @Prop({default: false}) public inline: boolean;
        protected uid: number;
        protected radio: any = "";
        protected dropdown: any;

        public getId(r: RadioInterface): string {
            return this.uniqueId + '_' + _.map(r, (v, k) => {
                return v;
            }).join('_');
        }

        public isChecked(r: RadioInterface): boolean {
            return this.value === r.value;
        }

        public updateValue(r) {
            this.radio = r.value;
            this.$emit('input', this.radio);
        }
    }
