import i18n from "@/framework/locale";
import * as _ from "lodash";

Ext.define('FW.grid.column.CurrencyColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.currencycolumn'],
    alternateClassName: 'FW.grid.CurrencyColumn',
    filterType: 'number',
    producesHTML: false,

    config: {
        currency: 'EUR'
    },


    defaultRenderer(value) {
        if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value)) {
            return "";
        }
        return i18n.n(value, this.currency);
    }
});
