Ext.define('FW.grid.plugin.ColumnTip', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.fwcolumntip',

    init(grid) {
        const view = grid.getView();
        grid.on('render', (g) => {
            grid._columnTip = Ext.create('Ext.tip.ToolTip', {
                target: view.getId(),
                delegate: view.itemSelector + ' .tip',
                trackMouse: true,
                listeners: {
                    beforeshow: (tip) => {
                        const record = view.getRecord(tip.triggerElement);
                        const columns = view.getHeaderCt().getVisibleGridColumns();
                        if (columns) {
                            const column = columns[tip.triggerElement.cellIndex];
                            const value = record.get(column.dataIndex);
                            let display: any = value;
                            if (column.renderer) {
                                display = column.renderer(value, {}, record, 0, 0);
                            }

                            if (display && display.length > 0) {
                                tip.update(display);
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }

                    }
                }
            });
        });

        grid.on('destroy', (g) => {
            Ext.destroy(grid._columnTip);
        });
    }
});
