import _ from "lodash";

export default class BaseItem {

    get uniqueId(): any {
        return this[this.uniqueIdName];
    }

    get uniqueIdName(): string {
        return "";
    }

    public static UniqueId: number = 0;

    public static create <T extends BaseItem >(type: (new () => T)): T {
        return new type().setUniqueId() as T;
    }

    public isPersistent = false;
    public fw_dirty = false;

    public copy(item: any) {
        _.merge(this, item);
        return this;
    }

    public compare(item: BaseItem) {
        return (this.uniqueId === item.uniqueId);
    }

    public clone <T extends BaseItem >(type: (new () => T)): T {
        return new type().copy(this);
    }

    protected setUniqueId(): any {
        if (!this[this.uniqueIdName]) {
            this[this.uniqueIdName] = --BaseItem.UniqueId;
        }
        return this;
    }

}
