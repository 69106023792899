
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";

    @Component({})
    export default class KundenSelect extends InputSelect {
        @Prop({default: "/inforum/lists/kunden"}) public remote: string;
        @Prop({default: true}) public clearable: boolean;
        @Prop({default: true}) public searchable: boolean;
        @Prop({default: 'kunde'}) public remotePrimary: string;
    }
