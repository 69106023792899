
import {Component, Prop, Vue} from "vue-property-decorator";
import LazyTabs from "@/framework/components/Form/Tab/LazyTabs.vue";


interface WideSettings {
    left: string,
    right: string
}

@Component({
})
export default class VerticalTabs extends LazyTabs {
    @Prop({
        default: () => {
            return {
                left: 'four wide',
                right: 'twelve wide'
            };
        }
    }) public wide: WideSettings;

}
