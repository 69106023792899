
const kpitree = [
    {
        path: '/administration/stammdaten/kpitree',
        name: 'KPI Tree',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_kpi" */ './Grid/Kpi.vue')
    }
];

export default kpitree;
