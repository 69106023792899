import {Component, Prop, Vue} from 'vue-property-decorator';
import readonly from "@/framework/mixins/Readonly";

@Component({
    mixins: [readonly]
})
export default class Base extends Vue {

    @Prop({default: false}) public disabled: boolean;

    get isDisabled(): boolean {
        return this.disabled !== undefined && this.disabled !== false;
    }

}

