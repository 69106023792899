import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {CockpitGridConfig} from "@/app/pages/Auswertungen/Cockpit/Grid/Config";

export default function CockpitOpenDetail(to, from, next) {
    next();
    if (to.params.lieferant_werk_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(CockpitGridConfig.id, to.params.lieferant_werk_seq),
            title: i18n.t(CockpitGridConfig.title),
            url: '/auswertungen/cockpit/detail/' + to.params.lieferant_werk_seq
        }));
    }
}