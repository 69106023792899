import AuditsOpenDetail from "@/app/pages/Audits/Grid/OpenDetail";
import MassnahmenOpenDetail from "@/app/pages/Audits/Grid/MassnahmenOpenDetail";


const audits = [
    {
        path: '/audits',
        name: 'Audits',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "audits" */ './Grid/Audits.vue'),
        children: [
            {
                path: 'open/:audit_seq',
                beforeEnter: AuditsOpenDetail
            }
        ]
    },
    {
        path: '/audits/detail/:audit_seq?/:fw_ownership?',
        name: 'Audit',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "audit" */ './Details/Audit.vue')
    },
    {
        path: '/audits/filter',
        name: 'Audits Filter',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "audits_filter" */ './Grid/Filters/Audits.vue')
    },
    {
        path: '/audits/massnahmen',
        name: 'Audits Massnahmen',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "auditsmassnahmen" */ './Grid/Massnahmen.vue'),
        children: [
            {
                path: 'open/:feststellung_seq',
                beforeEnter: MassnahmenOpenDetail
            }
        ]
    },
    {
        path: '/audits/massnahmen/detail/:feststellung_seq?/:fw_ownership?',
        name: 'Audits Massnahme',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "auditsmassnahme" */ './Details/Massnahme.vue')
    },
    {
        path: '/audits/kalender',
        name: 'Audits Kalender',
        meta: {
            requiresAuth: true,
            resource: "audit"
        },
        component: () => import(/* webpackChunkName: "audit" */ './Calendar/Audits.vue')
    },
    {
        path: '/audits/detailcalendar/:audit_seq?/:fw_ownership?',
        name: 'Audits Calendar',
        meta: {
            requiresAuth: true,
            resource: "audit",
            btnConfig: {
                destroy: true,
                save: true,
                custom: false
            }
        },
        component: () => import(/* webpackChunkName: "audit" */ './Details/Audit.vue')
    },
    {
        path: '/audits/new/:datum?',
        name: 'Audit Neu',
        meta: {
            requiresAuth: true,
            resource: "audit",
            btnConfig: {
                destroy: true,
                save: true,
                custom: false
            }
        },
        component: () => import(/* webpackChunkName: "audit" */ './Details/Audit.vue')
    },
];

export default audits;
