
import {Component, Vue} from 'vue-property-decorator';
import {mapActions, mapState, mapGetters} from "vuex";
import Widget from '@/framework/components/Portal/Widget.vue';
import {base} from "@/framework/services/Api";

@Component({
    api: {
        load: '/inforum/startseite/news',
    },

    computed: {
        ...mapState('permissions', ['admin', 'supplier', 'resources', 'navigation']),
        ...mapState('authentication', ['user']),
        ...mapState('preferences', ['settings']),
        ...mapGetters('preferences', {
            werk: 'getPlant'
        }),
    },
    components: {

    },

    watch: {
        'settings.werk_seq': 'settingsChange',
    },

    mounted() {
        (this as any).load();
    }
})
export default class News extends Widget {
    protected werk: any;

    public settingsChange() {
        (this as any).load();
    }

    public getTitle(news): string {
        if (news.werk_seq) {
            return this.$t('werk_news') + ': ' + news.titel;
        }

        return news.titel;
    }

    public getUrl(url): string {
        return base + url;
    }
}
