
const regionen = [
    {
        path: '/administration/stammdaten/regionen',
        name: 'Regionen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_regionen" */ './Grid/Regionen.vue')
    },
    {
        path: '/administration/stammdaten/regionen/details/:region_seq?',
        name: 'Region',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_regionen" */ './Details/Region.vue')
    }
];

export default regionen;