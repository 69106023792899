import * as _ from 'lodash';

const origSetValue = Ext.picker.Date.prototype.setValue;
Ext.override(Ext.picker.Date, {

    setValue(value) {
        if (_.isString(value)) {
            const tmp = new Date(value);
            if (!isNaN(tmp.getFullYear())) {
                value = tmp;
            }
        }

        return origSetValue.apply(this, [value]);
    }
});
