import {localDate} from "@/framework/plugins/Localization";
import {encode} from "html-entities";

Ext.define('FW.toolbar.filters.date', {
    extend: 'FW.toolbar.filters.base',


    render(label) {
        const operation = this.operator(this.getFilter().getOperator());
        const value = this.getFilter().getValue() ? localDate.toDate(this.getFilter().getValue()) : '';
        return `<span class="fw filter date"><span class="label">${ label }</span><span class="operation">${ operation }</span><span class="value">${ encode(value) }</span></span>`;
    },



});
