declare var FW: any;

Ext.define('FW.data.TreeGridStore', {
    extend: 'FW.data.TreeStore',

    load: function (options) {
        let node = options && options.node;
        if (!node) {
            node = this.getRoot()
        }

        if (node) {
            node.set('loading', false);
        }
        return FW.data.TreeGridStore.superclass.load.apply(this, arguments);
    }
});
