const kunden = [
    {
        path: '/administration/stammdaten/kunden',
        name: 'Kunden',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_kunden" */ './Grid/Kunden.vue')
    },
];

export default kunden;

