import {localDate} from "@/framework/plugins/Localization";

Ext.define('FW.grid.column.DateTimeColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.datetimecolumn'],
    alternateClassName: 'FW.grid.DateTimeColumn',
    config: {
        filterType: 'daterange',
    },

    minWidth: 210,
    producesHTML: false,
    dateOrDatetime: false,


    defaultRenderer(value) {
        if (!value) {
            return '';
        }

        const date = new Date(value);

        if (this.dateOrDatetime && date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
            return localDate.toDate(value);
        }


        return localDate.toDateTime(value);
    }
});
