import RegistrationOpenDetail from "@/app/pages/Registration/Grid/OpenDetail";

const registration = [
    {
        path: '/registration',
        name: 'Registration',
        component: () => import(/* webpackChunkName: "registration" */ './Registration.vue')
    },
    {
        path: '/administration/registration',
        name: 'Registrierungen',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "registrationadmin" */ './Grid/Registration.vue'),
        children: [
            {
                path: 'open/:registration_id',
                beforeEnter: RegistrationOpenDetail
            }
        ]
    },
    {
        path: '/administration/registration/detail/:registration_id?',
        name: 'Registrierung Detail',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "registrationadmin" */ './Details/Registration.vue')
    },
    {
        path: '/administration/registration/filter',
        name: 'Registrierung Filter',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "registrationadmin" */ './Grid/Filters/Registration.vue')
    },
];

export default registration;
