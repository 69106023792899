const waehrungen = [
    {
        path: '/administration/stammdaten/waehrungen',
        name: 'Waehrungen',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "Waehrungen" */ './Grid/Waehrungen.vue'),
    },
];

export default waehrungen;
