const tags = [
    {
        path: '/administration/stammdaten/tags',
        name: 'Tags',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_tags" */ './Grid/Tags.vue')
    },
    {
        path: '/administration/stammdaten/tags/details/:tag_id?',
        name: 'Tag',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_tags" */ './Details/Tag.vue')
    }
];

export default tags;

