declare var FW: any;

Ext.define('FW.data.GridStore', {
    extend: 'FW.data.ApiStore',
    requires: [
        'Ext.data.*',
    ],
    autoLoad: false,
    autoLoadOnFilterEnd: true
});
