import ZertifikateFreigabeOpenDetail from "@/app/pages/Zertifikate/GridFreigabe/OpenDetail";

const zertifikate = [
    {
        path: '/zertifikate',
        name: 'Zertifikate',
        meta: {
            requiresAuth: true,
            resource: "zertifikate"
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './Grid/Zertifikate.vue')
    },
    {
        path: '/zertifikate/filter',
        name: 'Zertifikate Filter',
        meta: {
            requiresAuth: true,
            resource: 'zertifikate',
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './Grid/Filters/Zertifikate.vue')
    },
    {
        path: '/zertifikate/keine',
        name: 'Zertifikate_keine',
        meta: {
            requiresAuth: true,
            resource: 'zertifikate',
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './GridKeineZertifikate/KeineZertifikate.vue')
    },
    {
        path: '/zertifikate/keine/filter',
        name: 'Zertifikate_keine Filter',
        meta: {
            requiresAuth: true,
            resource: 'zertifikate',
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './GridKeineZertifikate/Filters/KeineZertifikate.vue')
    },
    {
        path: '/zertifikate/freigabe',
        name: 'Zertifikate_freigabe',
        meta: {
            requiresAuth: true,
            resource: "zertifikate"
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './GridFreigabe/ZertifikateFreigabe.vue'),
        children: [
            {
                path: 'open/:zertifikat_seq',
                beforeEnter: ZertifikateFreigabeOpenDetail
            }
        ]
    },
    {
        path: '/zertifikate/freigabe/filter',
        name: 'Zertifikate_freigabe Filter',
        meta: {
            requiresAuth: true,
            resource: 'zertifikate',
        },
        component: () => import(/* webpackChunkName: "zertifikate" */ './GridFreigabe/Filters/ZertifikateFreigabe.vue')
    },
    {
        path: '/zertifikate/freigabe/detail/:zertifikat_seq?',
        name: 'Zertifikate_freigabe_detail',
        meta: {
            requiresAuth: true,
            resource: "zertifikate"
        },
        component: () => import(/* webpackChunkName: "stammdaten_lieferanten" */ './Details/Freigabe.vue')
    },
];

export default zertifikate;