
const abteilungen = [
    {
        path: '/administration/stammdaten/abteilungen',
        name: 'Abteilungen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_abteilungen" */ './Grid/Abteilungen.vue')
    }
];

export default abteilungen;
