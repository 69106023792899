import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import {AktivitaetenGridConfig} from "@/app/pages/Aktivitaeten/Grid/Config";
import i18n from "@/framework/locale";

export default function AktivitaetOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.aktivitaet_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(AktivitaetenGridConfig.id, to.params.aktivitaet_seq),
            title: i18n.t(AktivitaetenGridConfig.title),
            url: '/aktivitaeten/detail/' + to.params.aktivitaet_seq
        }));
    }
}
