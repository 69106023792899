import vStore from "@/app/store";
import WindowManager from "@/framework/util/WindowManager";
import Task from "@/framework/components/Taskbar/Task";
import swal from "sweetalert2";
import i18n from "@/framework/locale";
import {Response} from "@/framework/services/Response";
import * as _ from "lodash";
import $ from "jquery";
import {VerweisTask} from "@/app/components/Verweise/Verweis";
import {deletedSwal, errorSwal} from "@/framework/util/Swals";
import {saveAs} from "file-saver";
import './Base';
import {FilterConfig} from "@/framework/components/Grid/FilterData";

declare var FW: any;

Ext.define('FW.grid.controller.Panel', {
    extend: 'FW.grid.controller.Base',
    alias: 'controller.fwgrid',

    _activeWindows: {},

    constructor() {
        this._activeFilterWindow = null;
        FW.grid.controller.Panel.superclass.constructor.apply(this, arguments);
    },

    onFilterButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const id = WindowManager.generateID(view.getId(), 'filter');

        if (_.has(this.activeWindows, id)) {
            _.get(this.activeWindows, id).show(null, () => {
                const win = this._activeFilterWindow;
                const filters = store.getFilters();
                const comp = win.vueComponent;
                const initial: any[] = [];
                filters.each((f) => {
                    initial.push(f);
                });
                comp.$emit('fwinitfilter', initial);
            });
            return;
        }


        WindowManager.modal(new Task({
            taskId: id,
            title: i18n.t(view.filterTitle),
            url: view.filterRoute,
            minimizable: false,
            config: {
                // cls: 'x-window-filter',
                fwFullscreen: false,
                maximized: false,
                scrollable: 'y',
                minWidth: 800,
                minHeight: 400,
                modal: true,
                // bodyPadding: 15,
                tools: [],
                closeAction: 'hide'
            }
        })).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent) {
                _.set(this.activeWindows, id, win);
                const filters = store.getFilters();
                const comp = win.vueComponent;

                comp.load().then(() => {
                    const initial: any[] = [];
                    filters.each((f) => {
                        initial.push(f);
                    });
                    comp.$emit('fwinitfilter', initial);
                    comp.$on('fwfilter', (f) => {
                        // get current Filters
                        if (f && f.length > 0) {
                            f = f.map((filter) => {
                                if (filter.getCurrentConfig) {
                                    const current = Ext.applyIf(filter.getCurrentConfig(), filter.config);
                                    delete current.filterFn;

                                    return current;
                                } else {
                                    return filter;
                                }
                            });
                            store.getFilters().replaceAll(f);
                        } else {
                            store.getFilters().removeAll();
                        }
                    });
                }).catch((err) => {
                    console.error(err);
                });
            }
        });
    },

    onMyFilterButtonClick() {
        const myFilter: FilterConfig = {
            property: 'mypoints',
            type: 'base',
            label: i18n.t('meinepunkte').toString()
        };

        const extFilter = new Ext.util.Filter(myFilter);
        extFilter.setId('mypoints');

        const view = this.getView();
        const store = view.getStore();
        store.addFilter(extFilter);
    },

    onVerweisButtonClick(grid, rowIndex, colIndex, item, e, record, row) {
        const rec = record;
        const task = VerweisTask.getTask(rec.getData());
        if (!task) {
            return;
        }
        vStore.dispatch('tasks/open', task);
    },

    onDetailButtonClick(grid, rowIndex, colIndex, item, e, record, row) {
        const view = this.getView();
        const store = view.getStore();
        const rec = record;
        const detailRoute = new Ext.XTemplate(view.detailRoute).apply(rec);
        const task = new Task({
            taskId: WindowManager.generateID(view.getId(), rec.getId()),
            title: view.detailTitle,
            url: detailRoute
        });

        vStore.dispatch('tasks/open', task).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent && !win.vueComponent.gridConfig) {
                const comp = win.vueComponent;
                comp.$on('fwreload', () => {
                    store.reload();
                });
            }
        });
    },

    createModel() {
        return {};
    },

    onNewButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const rec = store.createModel(this.createModel());
        const detailRoute = new Ext.XTemplate(view.newRoute).apply({data: rec.getData({serialize:true})});
        const task = new Task({
            taskId: WindowManager.generateID(view.getId(), 'new' + Math.random()),
            title: view.detailTitle,
            url: detailRoute
        });


        vStore.dispatch('tasks/open', task).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent && !win.vueComponent.gridConfig) {
                const comp = win.vueComponent;
                comp.$on('fwreload', () => {
                    store.reload();
                });
            }
        });
    },

    onDeleteButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const sel = view.getSelection();
        swal({
            title: i18n.t('loeschen').toString(),
            text: i18n.t('confirm').toString(),
            type: "question",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: i18n.t('ja').toString(),
            cancelButtonText: i18n.t('nein').toString()
        }).then((result) => {
            if (result && result.value) {
                view.mask(i18n.t('loeschen').toString());
                store.remove(sel);
                store.sync({
                    callback: (res) => {
                        view.unmask();
                        store.reload();
                        if (res.exception === true) {
                            swal({
                                text: i18n.t('loeschen_error').toString(),
                                type: "error",
                            });
                        } else {
                            deletedSwal();
                        }
                    }
                });
            }
        });
    },

    onExpandRow(row, record) {
        $(Ext.get(row).query('.x-grid-rowbody')).html('<div class="ui active centered inline small loader"></div>');
        const view = this.getView();
        const operation = view.getStore().createOperation('expand', {
            internalCallback: (op) => {
                $(Ext.get(row).query('.x-grid-rowbody')).html('');
                const retval = this.onExpandLoad.apply(this, [op, record, row]);
                this.getView().rowExpanderTemplate.fireEvent('afterrender', this, record._expander);
                return retval;
            },
            params: record.data
        });
        operation.execute();
    },

    getExportFilename() {
        const view = this.getView();
        if (view.exportFilename) {
            if (_.isFunction(view.exportFilename)) {
                return view.exportFilename.call(view);
            } else {
                return view.exportFilename;
            }
        }

        return 'export.xlsx';
    },

    onExpandLoad(operation, record, row) {
        if (operation.wasSuccessful()) {
            const resp = Response.factory(operation.getResponse());

            if (!record.hasOwnProperty('_expander')) {
                record._expander = {};
            }

            record._expander.expandData = resp.body;
            this.getView().getView().refreshNode(record);
        }
    },

    onExportButtonClick() {
        const view = this.getView();
        const store = view.getStore();

        const options: any = {};

        if (store.getRemoteFilter()) {
            const filters = store.getFilters(false);
            if (filters && filters.getCount()) {
                options.filters = filters.getRange();
            }
        }

        if (store.getRemoteSort()) {
            const sorters = store.getSorters(false);
            if (sorters && sorters.getCount()) {
                options.sorters = sorters.getRange();
            }
        }

        options.exports = store.getExports();

        const button = this.lookup('grid.buttons.export');
        let operation = Ext.apply({
            internalScope: store,
            callback: (res, op) => {
                if (button) {
                    button.setIconCls('x-fa fa-download');
                    button.setDisabled(false);
                }
                this.onExportComplete(res, op);
            },
            scope: this
        }, options);

        operation = store.createOperation('export', operation);
        if (button) {
            button.setIconCls('x-fa fa-spinner fa-spin');
            button.setDisabled(true);
        }
        operation.execute();
    },

    onExportComplete(res, op) {
        if (res.exception === true) {
            swal({
                text: i18n.t('export_error').toString(),
                type: "error",
            });
        } else {
            if (op.getResponse() && op.getResponse().data) {
                saveAs(op.getResponse().data, this.getExportFilename());
            }
        }
    },

    onBeforePageing(toolbar, page) {

    },

    onException(grid, response, operation) {
        const view = this.getView();
        const store = view.getStore();

        store.removeAll();
    },

    onSort(node, data, overModel, dropPosition, eOpts) {
        const records: any[] = [];

        _.forEach(data.records, (item) => {
            records.push(item.getData());
        });

        const view = this.getView();
        view.mask(i18n.t('sortieren').toString());
        const operation = view.getStore().createOperation('sort', {
            internalCallback: (op) => {
                view.unmask();
                view.getStore().reload();
            },
            params: {
                records,
                position: overModel.getData(),
                sort: dropPosition
            }
        });
        operation.execute();

    },


    onWindowButtonClick(windowID, title, url) {
        const view = this.getView();
        const store = view.getStore();
        const id = WindowManager.generateID(view.getId(), windowID);

        if (_.has(this.activeWindows, id)) {
            _.get(this.activeWindows, id).show();
            return;
        }


        WindowManager.modal(new Task({
            taskId: id,
            title: i18n.t(title),
            url: url,
            minimizable: false,
            config: {
                fwFullscreen: false,
                maximized: false,
                scrollable: 'y',
                minWidth: 800,
                minHeight: 400,
                modal: true,
                tools: [],
                closeAction: 'hide'
            }
        })).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent) {
                _.set(this.activeWindows, id, win)
            }

            const comp = win.vueComponent;
            comp.$on('fwreload', () => {
                store.reload();
            });
        });
    },

});
