import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {KundenreklamationenGridConfig} from "@/app/pages/Kundenreklamation/Grid/Config";

export default function KundenreklamationOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.kundenreklamation_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(KundenreklamationenGridConfig.id, to.params.kundenreklamation_seq),
            title: i18n.t(KundenreklamationenGridConfig.title),
            url: '/kundenreklamation/detail/' + to.params.kundenreklamation_seq
        }));
    }
}