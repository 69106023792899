
import {Component, Prop, Vue} from 'vue-property-decorator';
import InputText from "@/framework/components/Form/Elements/Input/InputText.vue";
import _ from 'lodash';

@Component({})
export default class InputTextarea extends InputText {
    @Prop({default: 5}) public rows: number;
    @Prop() public pastelabel: string;
    @Prop({default: true}) public autogrow: boolean;

    get isAutogrow(): boolean {
        return this.autogrow !== undefined && this.autogrow !== false;
    }

    get cpTooltip(): any {
        return this.$t('copy_paste_tooltip');
    }

    get pasteLabel(): any {
        return this.$t('copy_past_into') + ' ' + this.$t(this.pastelabel);
    }

    public updateValue() {
        (this as any).$emit('input', (this as any).$refs.input.value);
    }

    public paste($event) {

        if ((this as any).isReadonly) {
            $event.preventDefault();
            $event.cancelBubble = true;
            return false;
        }

        const data: any = $event.clipboardData;
        if (data.files.length > 0) {
            const item: any = _.find(data.items, ['kind', 'file']);

            if (!item) {
                return true;
            }

            $event.preventDefault();
            $event.cancelBubble = true;

            this.$emit('pastefile', item.getAsFile());
        } else {
            return true;
        }

    }
}
