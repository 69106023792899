Ext.define('FW.form.field.DateRange', {
    extend: 'Ext.form.field.Date',
    alias: 'widget.daterangefield',

    editable: true,
    selectable: false,
    clearFilterText: 'clear Filter',

    config: {
        range: false
    },

    triggers: {
        clear: {
            hidden: true,
            hideOnReadOnly: false,
            cls: 'x-form-clear-trigger fw-daterange-clear-trigger',
            weight: -2,
            tooltip: '',
            handler() {
                console.log(this);
                (this as any).fireEvent('change', this, null);
                (this as any).getPicker().hide();
                (this as any).setRawValue(null);
                (this as any).triggers.clear.setVisible(false);
            }
        }
    },

    onExpand() {
        let value:any = this.rawDate;
        this.picker.setValue(Ext.isDate(value) ? value : (_.isArray(value) ? Ext.Date.parseDate(value[1], 'Y-m-d') : this.createInitialDate()));
    },


    initComponent() {
        Ext.form.field.Text.superclass.initComponent.apply(this, arguments);
        this.on('operatorchange', this.onOperatorChange);
        this.triggers.clear.tooltip = this.clearFilterText;
    },

    onOperatorChange(field, operator) {
      this.setRange(operator === 'between');
    },


    updateRange(v, oldv) {
        if (v !== oldv && this.picker) {
            this.getPicker().hide();
            Ext.destroy(this.picker);
            this.picker = null;
        }

        if (!v) {
            this.triggers?.clear?.setVisible(false);
        }

        this.setEditable(!v);

    },

    setValue(v) {
        this.triggers.clear.setVisible(!!v);

        if (!this.getRange()) {
            return FW.form.field.DateRange.superclass.setValue.apply(this, arguments);
        }


        var me = this,
            utilDate = Ext.Date,
            rawDate,
            oldVal = me.lastValue;
        me.lastValue = me.rawDateText;
        me.lastDate = me.rawDate;

        Ext.form.field.Text.superclass.setValue.apply(this, arguments);

        // TODO: bei single date picker schluss

        me.suspendEvent('change');
        me.rawDate = _.map(v, (d) => {
            return me.formatDate(me.parseDate(d), 'Y-m-d');
        });
        me.rawDateText = _.join(_.map(v, (d) => {
            return me.formatDate(me.parseDate(d));
        }), ' - ');
        me.setRawValue(me.rawDateText);
        let startDate;
        let endDate;
        if (v && v.length > 0) {
            startDate = v[0];
            if (!Ext.isDate(startDate)) {
                startDate = Ext.Date.parseDate(startDate, 'Y-m-d')
            }
        }

        if (v && v.length > 1) {
            endDate = v[1];
            if (!Ext.isDate(endDate)) {
                endDate = Ext.Date.parseDate(endDate, 'Y-m-d')
            }
        }

        if (startDate && endDate) {
            me.getPicker().setStartDate(startDate);
            me.getPicker().setEndDate(endDate);
            if (me.getPicker().rendered) {
                me.getPicker().updateDateRange(startDate.getTime(), endDate.getTime());
            }
        } else if (startDate) {
            me.getPicker().setStartDate(startDate);
        } else {
            me.getPicker().setStartDate(null);
            me.getPicker().setEndDate(null);
            if (me.getPicker().rendered) {
                me.getPicker().updateDateRange(null, null);
            }
        }
        me.resumeEvent('change');

        // Checking if there is a value change
        // If yes, trigger select event
        if (!me.destroyed && me.didValueChange(me.rawDateText, oldVal)) {
            me.fireEvent('select', me, v);
        }
    },

    setRawValue(value) {
        if (!this.getRange()) {
            return FW.form.field.DateRange.superclass.setRawValue.apply(this, arguments);
        }
        // Aufruf Base Field Verhalten!!
        Ext.form.field.Text.superclass.setRawValue.apply(this, arguments);
    },

    getErrors(value) {

        if (!this.getRange()) {
            return FW.form.field.DateRange.superclass.getErrors.apply(this, arguments);
        }

        const me = this;
        value = me.rawDate;
        if (_.isArray(value)) {
            let errors: any = [];
            _.forEach(value, (v) => {
                const subErrors: any = FW.form.field.DateRange.superclass.getErrors.apply(this, [v]);
                Ext.Array.merge(errors, subErrors);
            });
            return errors;
        } else {
            return FW.form.field.DateRange.superclass.getErrors.apply(this, [value]);
        }
    },

    rawToValue(rawValue) {

        if (!this.getRange()) {
            return FW.form.field.DateRange.superclass.rawToValue.apply(this, arguments);
        }

        return rawValue;

    },

    createPicker() {

        if (!this.getRange()) {
            return FW.form.field.DateRange.superclass.createPicker.apply(this, arguments);
        }

        const me = this;
        const format = Ext.String.format;

        // Create floating Picker BoundList. It will acquire a floatParent by looking up
        // its ancestor hierarchy (Pickers use their pickerField property as an upward link)
        // for a floating component.
        return new FW.picker.DateRange({
            id: me.id + '-picker',
            pickerField: me,
            floating: true,
            preventRefocus: true,
            hidden: true,
            minDate: me.minValue,
            maxDate: me.maxValue,

            disabledDatesRE: me.disabledDatesRE,
            disabledDatesText: me.disabledDatesText,
            ariaDisabledDatesText: me.ariaDisabledDatesText,
            disabledDays: me.disabledDays,
            disabledDaysText: me.disabledDaysText,
            ariaDisabledDaysText: me.ariaDisabledDaysText,
            format: me.format,
            showToday: me.showToday,
            startDay: me.startDay,
            minText: format(me.minText, me.formatDate(me.minValue)),
            ariaMinText: format(me.ariaMinText, me.formatDate(me.minValue, me.ariaFormat)),
            maxText: format(me.maxText, me.formatDate(me.maxValue)),
            ariaMaxText: format(me.ariaMaxText, me.formatDate(me.maxValue, me.ariaFormat)),
            listeners: {
                scope: me,
                //select: me.onSelect,   // erst auslösen wenn der Range komplett ausgewählt ist
                select: (picker, value) => {
                    if (value && !me.getPicker().getStartDate()) {
                        me.setValue([
                            value, value
                        ]);
                        me.fireEvent('change', me, [
                            value, value
                        ]);
                    } else {
                        me.setValue([
                            me.getPicker().getStartDate(),
                            me.getPicker().getEndDate()
                        ]);
                        me.fireEvent('change', me, [
                            me.getPicker().getStartDate(),
                            me.getPicker().getEndDate()
                        ]);
                    }
                    me.getPicker().hide();
                },
                tabout: me.onTabOut
            },
            keyNavConfig: {
                esc() {
                    me.inputEl.focus();
                    me.collapse();
                }
            }
        });
    },
})
