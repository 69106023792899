

Ext.override(Ext.util.Filter, {

    config: {
        type: "base",

        displayValue: null
    }
});
