
Ext.define('FW.grid.column.ColorColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.colorcolumn'],
    alternateClassName: 'FW.grid.ColorColumn',
    filter: false,
    renderer(value, metaData, record, rowIndex, colIndex, store, view) {
        return '<div class="x-colorcolumn-cell" style="background-color:#' + value + ';" >&nbsp;</div>';
    },
});
