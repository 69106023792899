
import {Component, Prop, Vue} from 'vue-property-decorator';
import FileInterface, {DefaultFile, FileUpload} from "../Input/FileInterface";
import Base from "./Base";
import $ from "jquery";

@Component({
    watch: {
        "file.uploadPercentage" : "updateProgress",
        "file.complete" : "complete"
    }

})
export default class FileProgress extends Base {
    get uploadText(): any {
        return this.file.uploadError ? this.file.uploadError : this.$t('uploading_files');
    }

    @Prop() public file!: FileUpload;
    @Prop({default: "1s"}) public duration: string;

    protected updateProgress(progress) {
        ($((this.$refs.progress as any)) as any).progress({
            percent: progress
        });
    }

    protected complete(complete) {
        if (complete) {
            ($((this.$refs.progress as any)) as any)
                .transition({
                    animation  : 'fade',
                    duration   : this.duration,
                    onComplete : () => {
                        this.$emit("complete", this.file);
                    }
                });
        } else {
            ($((this.$refs.progress as any)) as any).progress('reset');
        }
    }
}
