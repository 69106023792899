
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
import { Chrome } from 'vue-color';

@Component({
    components: {
        'chrome-picker': Chrome
    }
})
export default class InputColor extends BaseElement {
    @Prop() public value!: string;

    public get vColor(): string {
        return this.value ? this.value  : "";
    }

    public get color(): string {
        return this.value ? this.value  : "#0000000d";
    }

    public mounted() {
        $((this.$refs.button as any))
            .popup({
                popup : ($((this.$refs as any).popup) as any),
                on    : 'click',
                position : 'bottom left',
            })
        ;
    }

    public updateValue(value) {
        (this as any).$emit('input', value.hex);
    }

}
