import vStore from "@/app/store";

declare var FW: any;

Ext.define('FW.panel.Resize', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.panelresize',

    init(comp) {
        comp.on({
            beforerender: {
                single: true,
                fn(panel, eOpts) {
                    let panelEl;
                    if (panel.renderTo) {
                        if (Ext.isString(panel.renderTo)) {
                            panelEl = $('#' + panel.renderTo);
                        } else {
                            panelEl = $(panel.renderTo);
                        }
                        if (panelEl && panelEl.length) {
                            panel.height = panelEl.height();
                        }
                    }
                }

            },
            afterlayout: {
                single: true,
                fn(panel, eOpts) {
                    vStore.watch((state) => {
                        return (state as any).tasks.tasks;
                    }, (value) => {
                        setTimeout(() => {
                            Ext.GlobalEvents.fireEvent('resize');
                        }, 100);
                    });

                    let panelEl;
                    if (panel.renderTo) {
                        if (Ext.isString(panel.renderTo)) {
                            panelEl = $('#' + panel.renderTo);
                        } else {
                            panelEl = $(panel.renderTo);
                        }
                        setTimeout(() => {
                            Ext.GlobalEvents.fireEvent('resize');
                        }, 500);
                        panel.resizeEventHandler = Ext.on('resize', () => {
                            if (panel.renderTo && Ext.get(panel.id) && panel.body.isVisible(true)) {
                                panel.setHeight(panelEl.height());
                                panel.setWidth(panelEl.width());
                            }
                        }, panel, {buffer: 50, destroyable: true});
                    }
                    Ext.GlobalEvents.fireEvent('resize');
                }
            },
            destroy: {
                single: true,
                fn(panel, eOpts) {
                    if (panel.resizeEventHandler) {
                        Ext.destroy(panel.resizeEventHandler);
                    }
                }
            }


        });
    }
});
