const produktgruppen = [
    {
        path: '/administration/stammdaten/produktgruppen',
        name: 'Produktgruppen',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "stammdaten_produktgruppen" */ './Grid/Produktgruppen.vue'),
    },
];

export default produktgruppen;
