const verantwortliche = [
    {
        path: '/administration/verantwortliche',
        name: 'Verantwortliche',
        meta: {
            requiresAuth: true,
            resource: 'benutzer',
        },
        component: () => import(/* webpackChunkName: "verantwortliche" */ './Verantwortliche.vue'),
    },

];

export default verantwortliche;
