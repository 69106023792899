
import {Component, Prop} from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
import _ from "lodash";
import SelectInterface from "../Select/SelectInterface";

@Component({
})
export default class InputList extends BaseElement {
    @Prop() public options: SelectInterface[];
    @Prop() public value!: any[];
    @Prop({default: "ui middle aligned selection celled list"}) public css: string;

    public isSelected(item): any {
        return _.indexOf(this.value, item.value) > -1;
    }

    public onChange(value, item: SelectInterface) {
        const list = _.clone(this.value);

        if (value) {
            list.push(item.value);
        } else {
            const index = _.indexOf(list, item.value);

            if (index > -1) {
                list.splice(index, 1);
            }

        }



        this.$emit('input', list);
    }
}
