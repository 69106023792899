import _ from "lodash";
import {encode} from "html-entities";


Ext.define('FW.toolbar.filters.list', {
    extend: 'FW.toolbar.filters.base',

    config: {
        filter: null
    },

    render(label) {
        const filter = this.getFilter();
        const value = _.isArray(filter.getDisplayValue()) ? _.join(filter.getDisplayValue(), ', ') : filter.getDisplayValue();
        const operation = this.operator(this.getFilter().getOperator());
        return `<span class="fw filter list"><span class="label">${ label }</span><span class="operation">${ operation }</span><span class="value">${ encode(value) }</span></span>`;
    },

    operator(o) {
        return ':';
    }

});
