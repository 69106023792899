
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
import {Response} from "@/framework/services/Response";
import {base} from "@/framework/services/Api";
import FileInterface, {DefaultFile, FileUpload} from "./FileInterface";
import FilePreview from "@/framework/components/Form/Elements/Preview/FilePreview.vue";
import FileProgress from "@/framework/components/Form/Elements/Progress/FileProgress.vue";
import Modal from "@/framework/components/Modal/Modal.vue";
import * as _ from "lodash";

@Component({
    model: {
        prop: 'value',
        event: 'change'
    },

    components: {FilePreview, FileProgress, Modal},

})
export default class InputFile extends BaseElement {

    get hasFile() {
        if ((this.value === undefined || this.value === null)) { return false; }

        return !!this.value.file_id;
    }

    get getFile(): FileInterface {
        if (this.hasFile) { return this.value; }

        return this.defaultFile;
    }

    get preview(): string {
        if (!this.getFile.preview) { return this.defaultFile.preview; }

        return this.getFile.preview;
    }

    get url(): string {
        return base + this.getFile.url;
    }

    get linkButton(): boolean {
        return !!(this.link && this.linkText);
    }

    get fileIcon(): string {
        return this.dropable ? "cloud arrow up icon" : "file circle plus outline icon";
    }


    @Prop() public value!: FileInterface;
    @Prop() public remote!: string;
    @Prop() public accept: string;
    @Prop({default: true}) public allowlink: boolean;
    @Prop({default: () => ({})}) public param: any;

    // @ts-ignore
    protected file: FileUpload = null;
    protected loading: boolean = false;
    protected uploadPercentage: number = 0;
    protected defaultFile: FileInterface = DefaultFile;
    protected uploadError: string | null = null;

    protected dropable: boolean = false;

    protected modalSettigns: any = {
        btnOK: 'fileupload_add_link',
        modal: {
            detachable: false
        }
    };

    protected link: string = "";
    protected linkText: string = "";

    public updateValue() {
        this.file = new FileUpload((this.$refs as any).input.files[0]);
        this.submitFile();
    }


    protected openFileDialog() {
        (this.$refs as any).input.click();
    }

    protected removeFile() {
        this.$emit('change', null);
    }

    protected completeUpload(fileUpload: FileUpload) {
        this.file.complete = true;
        this.loading = false;
    }

    protected submitFile() {
        const formData = new FormData();
        if (_.isObject(this.param)) {
            _.forIn(this.param, (value, key) => {
                formData.set(key, value);
            })
        }
        formData.append('file', this.file.file);
        this.loading = true;
        this.$http.post(this.remote,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },

                onUploadProgress: ( progressEvent ) => {
                    this.file.uploadPercentage = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );
                }
            }
        ).then((data) => {
            const resp = Response.factory(data).dispatch();
            let file = null;

            if (resp.data()) {
                file = resp.data();
            }

            this.completeUpload(this.file);
            this.$emit('change', file);
        }).catch((error) => {
            const resp = Response.factory(error);
            this.file.uploadError = resp.data();
            this.$emit('change', null);
            this.loading = false;
        });

    }

    protected onAddLink() {
        (this.$refs.modal as any).hide();
        const obj: any = {
            link: this.link,
            text: this.linkText
        };

        const data = new Blob([JSON.stringify(obj)], {
            type: "text/link",
        });
        this.file = new FileUpload(data as any);

        this.link = "";
        this.linkText = "";

        this.submitFile();
    }

    protected showModal() {
        const context = $((this.$parent as any).$el);
        (this.$refs.modal as any).show({context, autofocus: true});
    }

    protected onHideModal() {
        (this.$refs.modal as any).hide();
    }


    public onDrop($event) {
        if ((this as any).isReadonly) {
            $event.preventDefault();
            $event.cancelBubble = true;
            return false;
        }

        if ($event.dataTransfer.items.length > 0) {

            const item: any = _.find($event.dataTransfer.items, ['kind', 'file']);

            if (item) {
                this.file = new FileUpload(item.getAsFile());
                this.submitFile();
            }

        }
        this.dropable = false;

        $event.preventDefault();
    }

    public onDragOver($event) {

        if ((this as any).isReadonly) {
            $event.preventDefault();
            $event.cancelBubble = true;
            return false;
        }

        this.dropable = true;
    }

    public onDragLeave($event) {
        this.dropable = false;
    }

}
