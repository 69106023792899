const berechtigungen_rollen = [
    {
        path: '/administration/stammdaten/berechtigungen/rollen',
        name: 'Berechtigungen Rollen',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "stammdaten_regionen" */ './Grid/Rollen.vue')
    },
];

export default berechtigungen_rollen;
