import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import {authentication} from "@/framework/stores/Authentication";
import {preferences} from "@/framework/stores/Preferences";
import {messages} from "@/framework/stores/Messages";
import {permissions} from "@/framework/stores/Permissions";
import {tasks} from "@/framework/stores/Tasks";
import {filter} from "@/framework/stores/Filter";
import {application} from "@/framework/stores/Application";
import * as _ from "lodash";

Vue.use(Vuex);

// Prüfen ob die Property im angegebenen Pfad reactive ist
export const checkReactive = (state, path) => {
    let owner = {};
    let pKey = '';
    let property = state;

    _.split(path, '.').forEach((p) => {
        owner = property;
        pKey = p;
        property = property[p];
    });

    const descriptor = Object.getOwnPropertyDescriptor(owner, pKey);
    if (!descriptor || !descriptor.get) {
        (Vue as any).util.defineReactive(owner, pKey, property);
    }
};

export const checkReactiveRecursive = (obj) => {
    if (_.isObject(obj)) {
        Object.keys(obj).forEach((k) => {
            checkReactive(obj, k);
            if (_.isObject(obj[k])) {
                checkReactiveRecursive(obj[k]);
            }
        });
    }
};

// Plugin um den eingesetzten State in einen Vuex Store in Reactive properties zu konvertieren
const replaceStateReactiveCheckPlugin = (vuexstore) => {
    const origReplaceState = Vuex.Store.prototype.replaceState;
    vuexstore.replaceState = (state) => {
        checkReactiveRecursive(state);
        origReplaceState.call(vuexstore, state);
    };
};

const store = new Vuex.Store({
    modules: {
        authentication,
        messages,
        preferences,
        permissions,
        tasks,
        filter,
        application
    },
    plugins: [
        replaceStateReactiveCheckPlugin,
        (new VuexPersistence({
            modules: ['authentication', 'preferences', 'permissions', 'tasks', 'filter', 'messages']
        })).plugin
    ]
});

window.addEventListener('storage', (event) => {
    if (event.key === 'fwlogout') {
        if (event.newValue) {
            localStorage.removeItem('fwlogout');
            (document as any).location.reload(true);
        }
    }
});

export default store;