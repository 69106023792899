const bemusterungenStammdaten = [
    {
        path: '/administration/stammdaten/bemusterungen/arten',
        name: 'Bemusterungenarten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_bemusterungen" */ './Arten/Grid/Arten.vue')
    },
    {
        path: '/administration/stammdaten/bemusterungen/ablagestellen',
        name: 'Ablagestellen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_bemusterungen" */ './Ablagestellen/Grid/Ablagestellen.vue')
    },
    {
        path: '/administration/stammdaten/bemusterungen/btvs',
        name: 'Btvs',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_bemusterungen" */ './Btvs/Grid/Btvs.vue')
    },
    {
        path: '/administration/stammdaten/bemusterungen/btvs/detail/:btv_seq?',
        name: 'Btv',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_bemusterungen" */ './Btvs/Details/Btv.vue')
    },
];

export default bemusterungenStammdaten;
