import api from './Api';
import {AxiosPromise} from "axios";
import {MessageInterface} from "@/framework/components/Messages/Message/MessageInterface";


class Messages {
    public load(): AxiosPromise {
        return api.post('users/messages/load');
    }

    public remove(message: MessageInterface): AxiosPromise {
        return api.post('users/messages/remove', message);
    }

    public read(message: MessageInterface): AxiosPromise {
        return api.post('users/messages/read', message);
    }

}

const messagesService = new Messages();
export default messagesService;
