import {staticValues} from "@/framework/plugins/StaticValues";

Ext.define('Ext.grid.plugin.filterbar.filters.YesNo', {
    extend: 'Ext.grid.plugin.filterbar.filters.List',
    alias: 'grid.filterbar.yesno',

    constructor(cfg) {
        if (!cfg.options) {
            cfg.options = [
                ['j', staticValues.yes], ['n', staticValues.no]
            ];
        }
        Ext.grid.plugin.filterbar.filters.YesNo.superclass.constructor.apply(this, [cfg]);
    }
});
