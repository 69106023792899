// tslint:disable
import Task from "@/framework/components/Taskbar/Task";
import router from '@/app/router';
import Vue from 'vue';
import Router, {Route} from 'vue-router';
import swal from 'sweetalert2';
import Unload from "@/framework/services/Unload";
import vstore from "@/app/store";
import CopyDetailLink from "@/framework/components/Detail/CopyDetailLink";
import * as _ from "lodash";

class WindowManager {

    public generateID(viewID, dataID): string {
        return viewID + '-' + dataID;
    }

    public open(task: Task, dispatch: any): Promise<Task> {
        if (!this.show(task)) {
            const vm = window['app'];
            Ext.getBody().mask(vm.$t ? vm.$t('load_view') : "");
            return this.create(task, dispatch);
        }

        return Promise.resolve(task);
    }

    public modal(task: Task): Promise<Task> {
        return this.create(task, null);
    }

    public show(task: Task): boolean {
        let win = Ext.getCmp(task.windowId);
        if (win) {
            win.show();
            return true;
        }

        return false;
    }

    public hide(task: Task): boolean {
        let win = Ext.getCmp(task.windowId);
        if (win) {
            win.hide();
            return true;
        }

        return false;
    }

    public close(task: Task): boolean {
        if (!task) {
            return false;
        }

        let win = Ext.getCmp(task.windowId);
        if (win) {
            win.close();
            return true;
        }

        return false;
    }

    protected create(task: Task, dispatch?: any): Promise<Task> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let route = this.resolve(task);
                const resolveFn = (resolvedComp) => {
                    const vueComp = Vue.extend(resolvedComp.default);
                    const winComp = new vueComp({
                        parent: window['app'],
                        router: new Router()
                    });

                    (winComp as any)._routerRoot._route = route;
                    task.dirty = false;
                    task.openUrl = !_.isUndefined(task.openUrl) ? task.openUrl : CopyDetailLink.getUrl(task.url, winComp); // undefined ist der initialwert - String wenn schon einmal getUrl aufgerufen wurde

                    const win = Ext.create('FW.window.Modal', Ext.apply({
                        fwFullscreen: true
                    }, task.config, {
                        task: task,
                        vueComponent: winComp,
                        title: task.title,
                        html: '<div></div>',
                        minimizable: (task.minimizable === false) ? false : true,
                        closeToolText: winComp.$t('tool_modal_close'),
                        tools: [
                            {
                                iconCls: 'x-fa fa-paperclip',
                                hidden: !task.openUrl,
                                tooltip: winComp.$t('tool_copy_link'),
                                handler: function (event, toolEl, panelHeader) {
                                    winComp.$emit('fwcopylink', task.openUrl);
                                }

                            },
                            {
                                type: 'refresh',
                                tooltip: winComp.$t('tool_reload_data'),
                                handler: function (event, toolEl, panelHeader) {
                                    winComp.$emit('fwreloaddetail');
                                }
                            },
                            {
                                // type: 'left',
                                iconCls: 'x-fa fa-angle-left',
                                tooltip: winComp.$t('tool_modal_left'),
                                hidden: Ext.getBody().getWidth() < 2000,
                                callback: function (panel, tool, event) {
                                    panel.setWidth(Math.floor(Ext.getBody().getWidth(true) / 2));
                                    panel.setPosition(0);
                                    panel.updateLayout();
                                    $(winComp.$el).find('.ui.fixed.menu').css('left', 0);
                                }
                            },
                            {
                                // type: 'right',
                                iconCls: 'x-fa fa-angle-right',
                                tooltip: winComp.$t('tool_modal_right'),
                                hidden: Ext.getBody().getWidth() < 2000,
                                callback: function (panel, tool, event) {
                                    panel.setWidth(Math.ceil(Ext.getBody().getWidth(true) / 2));
                                    panel.setPosition(Math.floor(Ext.getBody().getWidth(true) / 2));
                                    panel.updateLayout();
                                    $(winComp.$el).find('.ui.fixed.menu').css('left', 'initial');
                                }
                            },

                            {
                                type: 'maximize',
                                tooltip: winComp.$t('tool_modal_maximize'),
                                callback: function (panel, tool, event) {
                                    panel.maximize();
                                    panel.setWidth(Ext.getBody().getWidth(true));
                                    panel.setPosition(0);
                                    panel.updateLayout();
                                    $(winComp.$el).find('.ui.fixed.menu').css('left', 0);
                                }
                            }],


                        listeners: {
                            close: function (panel) {
                                if (panel.closeAction === 'hide') { return; }
                                winLoadmask.destroy();
                                if (win.closeCallBack) win.closeCallBack();
                                winComp.$emit('fwafterclose');
                                if (dispatch !== null) {
                                    dispatch('remove', task);
                                }
                                winComp.$destroy();
                            },

                            beforeclose: function (win) {
                                if (task.dirty) {
                                    swal({
                                        type: 'warning',
                                        title: winComp.$t('confirm').toString(),
                                        html: winComp.$t('dirty_confirm_msg').toString(),
                                        confirmButtonText: winComp.$t('dirty_confirm_ok').toString(),
                                        cancelButtonText: winComp.$t('dirty_confirm_cancel').toString(),
                                        showCancelButton: true,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                        allowOutsideClick: false,
                                        focusCancel: true,
                                        backdrop: true

                                    }).then((res) => {
                                        if (res.dismiss) {
                                            task.dirty = false;
                                            win.close();
                                        } else {
                                            win.closeCallBack = undefined;
                                        }
                                    });

                                    return false;
                                }
                            },

                            render: function () {
                                var el = $('#' + (this as any).id + ' .x-window-body div');
                                winComp.$emit('fwmask');
                                preventMountedHide = false;
                                winComp.$mount(el[0] as any);
                                (winComp.$parent as any)._inactive = false;
                            },

                            afterrender: function () {
                                Ext.getBody().unmask();
                            },

                            resize: function (that, width, height) {
                                $(winComp.$el).find('.ui.fixed.menu').width(that.body.getWidth() - 2);

                                if (that.fwFullscreen) {
                                    that.setHeight(Ext.getBody().getHeight(true) - that.getTaskbarHeight());
                                }

                            },

                            show() {
                                winComp.$emit('fwshow');
                            },

                            hide() {
                                winComp.$emit('fwhide');
                            },

                            restore: function (that) {
                                $(winComp.$el).find('.ui.fixed.menu').css('left', 0);
                            },
                            fwscroll: function () {
                                winComp.$emit('fwscroll');
                            },
                            focusenter: function () {
                                winComp.$emit('fwfocus', (this as any).id);
                            }
                        }
                    }));

                    let winLoadmaskMsg: string = "";
                    let winLoadmask: any = null;
                    let preventMountedHide: boolean = false;
                    winComp.$nextTick(() => {
                        if (!preventMountedHide && winLoadmask) {
                            winLoadmask.hide();
                        }
                    });

                    winComp.$on('fwtitle', (title) => {
                        win.setTitle(title);
                        win.task.title = title;
                        vstore.dispatch('tasks/update', win.task);
                    });

                    winComp.$on('fwicon', (icon) => {
                        win.task.iconCls = icon;
                        vstore.dispatch('tasks/update', win.task);
                    });

                    winComp.$on('fwtooltip', (tooltip) => {
                        win.task.tooltip = tooltip;
                        vstore.dispatch('tasks/update', win.task);
                    });

                    winComp.$on('fwmask', (msg) => {
                        preventMountedHide = true;
                        if (!winLoadmask) {
                            winLoadmask = new Ext.LoadMask({
                                // msg: winComp.$t('loading'),
                                target: win,
                                useTargetEl: true,
                                // msgWrapCls: "x-mask-msg ui active centered loader"
                            });
                            winLoadmaskMsg = winLoadmask.msg;
                        }

                        winLoadmask.msg = msg ? msg : winLoadmaskMsg;
                        winLoadmask.show();
                    });
                    winComp.$on('fwunmask', () => {
                        winLoadmask.hide();
                    });
                    winComp.$on('fwdirty', (isDirty) => {
                        task.dirty = isDirty;
                        if (isDirty) {
                            Unload.register((event) => {
                                const msg = winComp.$t('dirty_confirm_msg').toString();
                                event.preventDefault();
                                event.returnValue = msg;
                                return msg;
                            });
                        } else {
                            Unload.clear();
                        }
                    });
                    winComp.$on('fwclose', (cb) => {
                        win.closeCallBack = cb;
                        winLoadmask.hide();
                        if (win.closeAction === 'hide') {
                            win.hide();
                        } else {
                            win.close();
                        }
                    });

                    win.show();

                    task.windowId = win.id;
                    resolve(task);
                };

                let comp = this.getComponent(route);
                if (comp) {
                    // Keine direkte VueComponent sonder ein Webchunk
                    if (comp.hasOwnProperty('component') && comp instanceof Function) {
                        resolveFn.call(this, {default: comp});
                    } else {
                        comp = comp().then(resolveFn);
                    }
                } else {
                    reject("component not found");
                    Ext.getBody().unmask();
                }
            }, 0);
        });
    }

    protected resolve(task: Task): Route {
        return router.resolve(task.url).route;
    }

    protected getComponent(route: Route): any {
        let comp = route.matched.shift();
        return (comp) ? comp.components.default : null;
    }

}

export default new WindowManager();
