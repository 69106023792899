const auditkategorien = [
    {
        path: '/administration/stammdaten/auditkategorien',
        name: 'Auditkategorien',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_auditkategorien" */ './Grid/Auditkategorien.vue')
    },
];

export default auditkategorien;

