export default interface FileInterface {
    name: string;
    size: number;
    preview: string;
    url: string;
    file_id: any;
    type: string;
}

export class FileUpload {

    get hasError(): boolean {
        return !!this.uploadError;
    }

    get uniqueId(): number {
        if (!this.uid) {
            this.uid = FileUpload.UniqueId++;
        }
        return this.uid;
    }

    public static UniqueId = 1;

    public uid: number = 0;
    public file: File;
    public uploadPercentage: number = 0;
    public uploadError: string = "";
    public complete: boolean = false;

    public constructor(file: File) {
        this.file = file;
        this.uid = this.uniqueId;
    }


}

export const DefaultFile: FileInterface = {
    name: "",
    size: 0,
    file_id: null,
    url: "",
    preview: "./assets/square-image.png",
    type: 'f'
};
