
const redis = [
    {
        path: '/administration/redis',
        name: 'redis',
        component: () => import(/* webpackChunkName: "redis" */ './Grid/Redis.vue'),
        meta: {
            requiresAuth: true,
            resource: "redis"
        }
    },
    {
        path: '/administration/redis/info',
        name: 'redis_info',
        meta: {
            requiresAuth: true,
            resource: "redis"
        },
        component: () => import(/* webpackChunkName: "redis" */ './Details/Info.vue'),
    },
    {
        path: '/administration/redis/detail/:data_key?',
        name: 'redis_detail',
        meta: {
            requiresAuth: true,
            resource: "redis"
        },
        component: () => import(/* webpackChunkName: "redis" */ './Details/Details.vue'),
    },

];

export default redis;