const origCreateColumnFilter = Ext.grid.plugin.filterbar.FilterBar.prototype.createColumnFilter;
Ext.override(Ext.grid.plugin.filterbar.FilterBar, {
    config: {
        headerListeners: {
            add: Ext.emptyFn,
            remove: Ext.emptyFn,
            columnschanged: 'onColumnsChanged'
        }
    },

    recalcTask: undefined,
    recalcCt: undefined,

    recalc: function() {
        const ct = this.recalcCt;
        const me = this;
        const bar = me.getBar();
        const columns = ct.getGridColumns();
        Ext.suspendLayouts();
        bar.removeAll(false)
        Ext.Array.forEach(columns, function (col) {
            if (col.getFilterType() && col.getFilterType().getField) {
                const filter = col.getFilterType();
                me.syncVisibility(filter, col);
                bar.add(filter.getField());
            } else {
                const filter = me.createColumnFilter(col);
                me.syncVisibility(filter, col);
                bar.add(filter.getField());
            }
        });
        Ext.resumeLayouts(true);
        me.adjustFilterBarSize();
    },

    onColumnsChanged: function (ct) {

        this.recalcCt = ct;
        if(!this.recalcTask) {
            this.recalcTask = new Ext.util.DelayedTask(this.recalc, this);
        }

        this.recalcTask.delay(50);
    },

    syncVisibility(filter, column) {
        const columnVisible: boolean = column.hideable === false ? true : !column.hidden;
        const visible: boolean = (column.isSubHeader ? !column.hiddenAncestor && columnVisible : columnVisible);

        if (filter.getField()) {
            filter.getField()[visible ? 'show' : 'hide']();
            filter.getField().column = column;
        }
    },

    createColumnFilter: function (column) {
        const filter = origCreateColumnFilter.apply(this, [column]);
        this.syncVisibility(filter, column);
        return filter;
    }
});
