import api from './Api';
import {AxiosPromise} from "axios";

class Authentication {
    public login(username: string, password: string): AxiosPromise {
        return api.post('authentication/index/login', {
            username,
            password
        });
    }

    public sso() {
        window.location.href = 'authentication/index/loginsaml';
    }

    public loginAs(user_id: number): AxiosPromise {
        return api.post('authentication/index/loginas', {
            user_id,
        });
    }

    public logout(): AxiosPromise {
        return api.post('authentication/index/logout');
    }

    public start(): AxiosPromise {
        return api.post('authentication/index/start');
    }

    public authdata(): AxiosPromise {
        return api.post('authentication/index/authdata');
    }

    public webauthnGetArgs(): AxiosPromise {
        return api.post('authentication/webauthn/getargs');
    }

    public webauthnLogin(authenticatorAttestationResponse: any): AxiosPromise {
        return api.post('authentication/webauthn/login', authenticatorAttestationResponse);
    }
}

const authService = new Authentication();
export default authService;
