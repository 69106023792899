declare var FW: any;

Ext.define('FW.grid.Expand', {
    extend: 'Ext.grid.Panel',
    alias: ['widget.expand'],

    autoLoad: false,

    expandConfig: {
        model: "Ext.data.Model",
        api: null
    },
    disableSelection: true,
    enableColumnHide: false,
    enableColumnMove: false,
    enableLocking: false,
    border: true,
    // hidden: true,
    hideHeaders: false,

    cls: 'fw-grid-expand',

    listeners: {
        afterlayout: {
            single: true,
            fn(panel, eOpts) {
                panel.resizeEventHandler = Ext.on('resize', () => {
                    panel.syncHeight();
                    const panelEl = $(panel.el.dom).closest('.x-grid-rowbody');
                    if (panelEl) {
                        panel.setWidth(panelEl.width());
                    }
                }, panel, {buffer: 50, destroyable: true});
            }
        },

        destroy: {
            single: true,
            fn(panel, eOpts) {
                if (panel.resizeEventHandler) {
                    Ext.destroy(panel.resizeEventHandler);
                }
            }
        }
    },

    syncHeight() {
        const me: any = this;
        const rows = $(me.el.dom).find('.x-grid-item');
        let gridHeightBody: any = 0;
        Array.from(rows).forEach((row: any) => {
            gridHeightBody += row.offsetHeight;
        });
        me.setHeight(gridHeightBody + 60);
    },

    constructor(cfg) {
        const me = this;
        if (!cfg) {
            cfg = {};
        }

        const expandModel = cfg.expandConfig.model || this.expandConfig.model;
        const expandApi = cfg.expandConfig.api || this.expandConfig.api;

        const model = Ext.create(expandModel);
        const idProperty = model.idProperty;

        Ext.destroy(model);

        cfg.viewModel = {
            formulas: {
                extraParams: {
                    bind: '{record}',
                    get() {
                        return {
                            id: (this as any).get('record.' + idProperty)
                        };
                    }
                }
            },


            stores: {
                store: {
                    xclass: 'FW.data.ApiStore',
                    // model: expandModel,
                    remoteSort: false,

                    proxy: {
                        type: 'api',
                        api: {
                            read: expandApi
                        },
                        extraParams: '{extraParams}'
                    },

                    listeners: {
                        load: (that, records, successful, operation, eOpts) => {
                            if (records.length > 0) {
                                me.setHideHeaders(false);
                                me.setMinHeight(0);
                            } else {
                                me.setHideHeaders(true);
                                me.hide();
                            }

                            me.syncHeight();
                        }
                    }
                },
            }
        };

        FW.grid.Expand.superclass.constructor.apply(this, [cfg]);
    }
});
