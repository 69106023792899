
const apcu = [
    {
        path: '/administration/apcu',
        name: 'apcu',
        component: () => import(/* webpackChunkName: "apcu" */ './Grid/Apcu.vue'),
        meta: {
            requiresAuth: true,
            resource: "apcu"
        }
    },
    {
        path: '/administration/apcu/detail',
        name: 'apcu_info',
        meta: {
            requiresAuth: true,
            resource: "apcu"
        },
        component: () => import(/* webpackChunkName: "apcu" */ './Details/Info.vue'),
    },

];

export default apcu;