import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import {ReklamationenGridConfig} from "@/app/pages/Lieferantenreklamation/Grid/Config";
import i18n from "@/framework/locale";

export default function ReklamationOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.reklamation_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(ReklamationenGridConfig.id, to.params.reklamation_seq),
            title: i18n.t(ReklamationenGridConfig.title),
            url: '/lieferantenreklamation/detail/' + to.params.reklamation_seq
        }));
    }
}