
    import {Component, Vue} from 'vue-property-decorator';
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";


    @Component({
        computed: {
            ...mapState('application', {maintenance: 'maintenance'})
        },

        methods: {
            ...mapActions('authentication', ['resetLogin']),
        },

        watch: {
            'maintenance': 'onMaintenanceChange',
        }
    })
    export default class Maintenance extends Vue {
        public resetLogin: any;
        public maintenance: any;

        public mounted(): void {

            if (this.maintenance) {
                this.resetLogin();
            } else {
                this.$router.replace('/');
            }

        }

        public onMaintenanceChange(newValue, oldValue) {
            if (newValue === false && oldValue) {
                this.$router.replace('/');
            }
        }
    }

