const fehlerorte = [
    {
        path: '/administration/stammdaten/fehlerorte',
        name: 'Fehlerorte',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "stammdaten_fehlerorte" */ './Grid/Fehlerorte.vue'),
    },
];

export default fehlerorte;
