import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {ReviewPunkteGridConfig} from "@/app/pages/Reviews/Review/Config";

export default function ReviewpunktOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.reviewpunkt_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(ReviewPunkteGridConfig.id, to.params.reviewpunkt_seq),
            title: i18n.t(ReviewPunkteGridConfig.title),
            url: '/reviews/punkt/' + to.params.reviewpunkt_seq
        }));
    }
}
