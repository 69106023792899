import Vue from 'vue';
import {checkReactive} from "@/app/store";
import _ from "lodash";

const initialState: { filter: { [key: string]: { [key: string]: any } } } = {
    filter: {
    }
};

export const filter = {
        namespaced: true,
        state: initialState,
        actions: {
            set({dispatch, commit}, {key, value}) {
                commit('set', {key, value});
            },
            unset({commit}, key: any) {
                commit('unset', key);
            },
            reset({commit}) {
                commit('reset');
            }
        },
        getters: {
            get: (state) => (key) => {
                if (_.isUndefined(state.filter[key])) {
                    Vue.set(state.filter, key, []);
                }
                return state.filter[key];
            }
        },
        mutations: {
            set(state: any, {key, value}) {
                checkReactive(state.filter, key);
                Vue.set(state.filter, key, value);
            },
            unset(state: any, key) {
                Vue.delete(state.filter, key);
            },
            reset(state: any) {
                state.filter = {};
            }
        }
    }
;
