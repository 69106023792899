// @ts-ignore
import "@babel/polyfill";
import 'vue-class-component/hooks';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import App from '@/app/App.vue';
import router from './app/router';
import store from './app/store';
import * as $ from 'jquery';
// import '../semantic/semantic.min.css';
import VueImg from 'v-img';
import '@/framework/extjs/main';
import initExtLocale from '@/framework/extjs/locale';
import '@/framework/extjs/calendar/Calendar';
import * as moment from "moment";
import '@/framework/components/Form/Elements';
import '@/framework/directives/directives';
import '@/app/components/Components';
import i18n from '@/framework/locale';
import api, {injectAPI} from './framework/services/Api';
import VJstree from 'vue-jstree';
import VueScrollTo from 'vue-scrollto';
import VueStaticValues from "@/framework/plugins/StaticValues";
import browserDetect from "vue-browser-detect-plugin";
import VueApexCharts from 'vue-apexcharts';
import 'eligrey-classlist-js-polyfill';
import VueFramework from "@/framework/plugins/Framework";

// Expose globals for semantic ui js
declare var window: any;
window['$'] = window['jQuery'] = $;
window['moment'] = moment;

const semantic = require('../semantic/semantic.min.js');

injectAPI($.api);

// initialize Vue Plugins
Vue.config.productionTip = false;
Vue.use(VueAxios, api);
Vue.use(require('vue-moment'), {
    moment
});
Vue.use(VJstree);
Vue.use(VueImg);

Vue.use(VueScrollTo, {
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
Vue.use(VueStaticValues);
Vue.use(browserDetect);
Vue.use(VueApexCharts);
Vue.use(VueFramework);

const translations = require('./assets/i18n.json');
Object.keys(translations).forEach((lang) => {
    const langSettings = translations[lang];
    const messages = langSettings.messages;
    const datetimeformat = langSettings.datetimeformat;
    const numberformat = langSettings.numberformat;

    i18n.setLocaleMessage(lang, messages);
    i18n.setDateTimeFormat(lang, datetimeformat);
    i18n.setNumberFormat(lang, numberformat);
});

initExtLocale().then(() => {
    window['app'] = new Vue({
        i18n,
        router,
        store,
        mounted() {
            const app = this;

            if (this.$browserDetect.isIE) {
                window.addEventListener("hashchange",
                    function() {
                        const currentPath = window.location.hash.slice(1);
                        if (app.$route.path !== currentPath) {
                            app.$router.replace(currentPath);
                        }
                    },
                    false);
            }
        },
        render: (h) => h(App),
    }).$mount('#app');
});


