

import {Component, Prop, Vue} from 'vue-property-decorator';
import i18n from "../../../../locale";


import Editor from '@tinymce/tinymce-vue';


let def_settings: any = {
    language: i18n.locale,
    menubar: 'edit insert format table',

    plugins: 'advlist autolink lists link searchreplace visualblocks code fullscreen table paste code wordcount',
    toolbar: 'undo redo |  bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat',
    fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
    paste_data_images: true,
    branding: false,
    statusbar: true,
    height: 250
}

@Component({
    components: {
        'tinymce-vue': Editor,
    },
})
export default class WysiwygEditor extends Vue {
    @Prop({default: ''}) public value: String;
    @Prop({default: () => ({})}) public settings: Object;
    @Prop({default: 250}) public height: Number;
    @Prop({default: false}) public readonly: Boolean;


    get useSettings(): any {

        let st = {
            ...def_settings,
            ...this.settings,
        }
        return st
    }

    public onInit() {

    }

    public getEditor() {
        if (this.$refs.edr) {
            return (this.$refs.edr as any).editor;
        }
        return null;
    }

    remove(ele) {
        ele.parentNode.removeChild(ele)
    }


}
