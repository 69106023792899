import { render, staticRenderFns } from "./InputCheckbox.vue?vue&type=template&id=164a199a&scoped=true&"
import script from "./InputCheckbox.vue?vue&type=script&lang=ts&"
export * from "./InputCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./InputCheckbox.vue?vue&type=style&index=0&id=164a199a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164a199a",
  null
  
)

export default component.exports