
const passwordrequest = [
    {
        path: '/passwordrequest',
        name: 'Passwordrequest',
        component: () => import(/* webpackChunkName: "passwordrequest" */ './PasswordRequest.vue')
    },

    {
        path: '/passwordrequest/password/:token',
        name: 'Passwordchange',
        component: () => import(/* webpackChunkName: "passwordrequest" */ './PasswordChange.vue'),
        props: true
    }

];

export default passwordrequest;
