
    import {Component, Prop} from 'vue-property-decorator';
    import BaseElement from "@/framework/components/Form/Elements/BaseElement";
    import _ from 'lodash';
    import WysiwygEditor from "@/framework/components/Form/Elements/Wysiwyg/WysiwygEditor.vue";

    @Component({
        model: {
            prop: 'value',
            event: 'input'
        },

        components: {WysiwygEditor},

    })
    export default class InputWysiwyg extends BaseElement {
        @Prop({default: ""}) public value!: string | number;

        get id(): any {
            return 'wysiwyg_' + Math.random().toString(36).substr(2, 9);
        }

        get wsettings(): any {
            return {
                menubar: false,
                content_css: "assets/wysiwyg.css",
                toolbar: this.elementSettings.toolbar1 ? this.elementSettings.toolbar1 : 'undo redo |  bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist checklist | removeformat',
                plugins: this.elementSettings.plugins ? this.elementSettings.plugins : 'advlist autolink lists link searchreplace visualblocks code fullscreen table paste code wordcount',
                ...this.elementSettings.options
            };
        }

        get inputValue(): any {
            if (!this.value) {
                return "";
            }

            return this.value;
        }

        set inputValue(value: any) {
            this.value = value;
        }

    }
