import {Component, Vue} from 'vue-property-decorator';
import _ from 'lodash';
import {mapActions, mapState, mapGetters} from "vuex";
import DropdownMenu from "@/framework/components/Navigation/DropdownMenu/DropdownMenu.vue";

@Component({
    computed: {
        ...mapState('authentication', ['status', 'user']),
        ...mapState('preferences', ['settings']),
        ...mapGetters('preferences', ['getPlant'])
    },


    methods: {
        ...mapActions('preferences', ['setLocale', 'setPlant']),
    },

    components: {
        DropdownMenu
    },
})
export default class PlantSelection extends Vue {
    public user: any;
    public settings: any;
    public setPlant: any;
    public getPlant: any;


    get plantName(): any {
        return this.getPlant ? this.user.plants[this.getPlant] : this.$t('werk_auswaehlen');
    }

    get plants(): any[] {
        const retval: any[] = [];

        _.forEach(this.user.plants, (value, key) => {
            retval.push({name: value, value: parseInt(key, 10)});
        });
        return retval;
    }

    get plant(): any {
        return this.getPlant;
    }

    set plant(value: any) {
        if (!value) {
            return;
        }
        this.setPlant(value);
    }

    public isSelected(value: any) {
        return _.toNumber(value) == _.toNumber(this.getPlant);
    }
}
