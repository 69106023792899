import moment from "moment";

class Localization {
    public locale: string;
    public _timezone: string;

    get timezone(): any {
        return this._timezone ? this._timezone : process.env.VUE_APP_DEFAULT_TIMEZONE;
    }

    set timezone(value) {
        this._timezone = value;
    }

    public init(locale: any, timezone: any) {
        this.setLocale(locale);
        this.setTimezone(timezone);
    }

    public setLocale(locale: any) {
        this.locale = locale;
        moment.locale(this.locale);
    }

    public setTimezone(timezone: any) {
        this.timezone = timezone;
        moment.tz.setDefault(this.timezone);
    }


    public toDate(data: any) {
        return moment(data).format('L');
    }

    public toDateTime(data: any) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data).format('L LT');
        }

        return moment.utc(data).tz(this.timezone).format('L LT');
    }


    public toMomentTz(data: any) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data);
        }

        const timezone: any = this.timezone ? this.timezone : process.env.VUE_APP_DEFAULT_TIMEZONE;

        return moment.utc(data).tz(this.timezone);
    }

    public toMoment(data: any) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data);
        }
        return moment.utc(data);
    }

    public toMomentUTC(data: any) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data);
        }
        return moment(data).tz(this.timezone).utc();
    }

    public parseDateTime(data: any) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data, 'L LT');
        }
        return moment(data, 'L LT').tz(this.timezone).utc();
    }

    public parseDate(data: any, format: string = 'L LT', strict: boolean = false) {
        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data, format, strict);
        }
        return moment(data, format, strict).tz(this.timezone).utc();
    }



    public toTime(data: any) {

        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data).format('LT');
        }

        return moment.utc(data).tz(this.timezone).format('LT');
    }

    public moment(data: any = undefined) {
        data = data ? data : undefined;

        if (process.env.VUE_APP_NO_TIMEZONE) {
            return moment(data);
        }

        return moment(data).tz(this.timezone);
    }


    public inputMask(datetime: boolean = false) {
        let mask: string = "";
        if (datetime) {
            mask = moment().localeData().longDateFormat('L') + ' ' + moment().localeData().longDateFormat('LT');
        } else {
            mask = moment().localeData().longDateFormat('L');
        }

        mask = mask.replaceAll('D', '#').replaceAll('M', '#').replaceAll('Y', '#').replaceAll('H', '#').replaceAll('m', '#');
        return mask;
    }

}

export const localDate = new Localization();

const VueLocalization = {

    install(Vue, options) {
        Vue.prototype.$l_d = (data) => {
            if (!data || data.length === 0) {
                return '';
            }
            return localDate.toDate(data);
        };

        Vue.prototype.$l_dt = (data) => {
            if (!data || data.length === 0) {
                return '';
            }
            return localDate.toDateTime(data);
        };

        Vue.prototype.$l_t = (data) => {
            if (!data || data.length === 0) {
                return '';
            }
            return localDate.toTime(data);
        };

        Vue.prototype.$locale = localDate;

    }
};

export default VueLocalization;
