import api from "@/framework/services/Api";
import {Response} from "@/framework/services/Response";
import {workers} from "@/framework/services/Workers";

export const initialState = {
    version: process.env.VERSION,
    prevVersion: process.env.VERSION,
    loading: false,
    maintenance: false,
    initialized: false,
    lastRoute: "/",
};

export const application = {
    namespaced: true,
    state: initialState,

    getters: {
        version: (state) => {
            return state.version;
        },

        initialized: (state) => {
            return state.initialized;
        },

        lastRoute: (state) => {
            return state.lastRoute;
        },
    },
    actions: {
        startWorker({dispatch, commit}) {
            dispatch('checkVersion');
            workers.version = setInterval(() => {
                dispatch('checkVersion');
            }, ((process.env.VUE_APP_VERSIONCHECK_TIMEOUT! as any) * 1000 * 60));

        },

        stopWorker() {
            clearInterval(workers.messages);
            workers.version = null;
        },

        checkVersion({commit, state, rootState}) {
            return api.post('app/index/index').then((data) => {

                const version = Response.factory(data).dispatch().data();

                if (version && version !== state.version) {
                    commit('changeVersion', version);
                }

                commit('maintenance', false);

            }).catch((err) => {
                console.log(err);
            });
        },

        rerollVersion({commit, state, rootState}) {
            commit('rerollVersion');
        }
    },
    mutations: {
        changeVersion(state: any, version: any) {
            state.prevVersion = state.version;
            state.version = version;
        },
        rerollVersion(state: any) {
            state.version = state.prevVersion;
        },
        loading(state: any, loading: boolean) {
            state.loading = loading;
        },

        maintenance(state: any, value: boolean) {
            state.maintenance = value;
        },

        initialized(state: any, value: boolean) {
            state.initialized = value;
        },

        lastRoute(state: any, value: boolean) {
            state.lastRoute = value;
        }



    }

};
