import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {RegistrationsGridConfig} from "@/app/pages/Registration/Grid/Config";

export default function RegistrationOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.registration_id) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(RegistrationsGridConfig.id, to.params.registration_id),
            title: i18n.t(RegistrationsGridConfig.title),
            url: '/administration/registration/detail/' + to.params.registration_id
        }));
    }
}