import swal from "sweetalert2";
import {AxiosError, AxiosResponse} from "axios";
import _ from "lodash";

export interface ResponseFlags {
    readonly?: boolean;
    readonlyUpdateFields?: string[];
}

export interface ResponseInterface {
    class: any;
    code: any;
    body: any;
    notifications: NotificationInterface[];
    permissions: any[];
    selections: any[];
    flags: ResponseFlags;

    data(): any;
    warningMessages(): string[];
    errorMessages(): string[];
    successMessages(): string[];

}

export interface NotificationInterface {
    class: any;
    message: any;
}

class ResponseFactory {

    public factory(response: AxiosResponse<ResponseInterface> | AxiosError): AbstractResponse {
        if (!response) {
            return new DataResponse({});
        }
        if ('response' in response) {
            response = response.response as AxiosResponse<ResponseInterface>;
            return new ErrorResponse(response.data);
        }

        response = response as AxiosResponse<ResponseInterface>;

        if (!response.data.class) {
            return new DataResponse(response.data);
        }

        switch (response.data.class) {
            case 'DataResponse': {
                return new DataResponse(response.data);
            }

            case 'SelectionResponse': {
                return new SelectionResponse(response.data);
            }

            case 'ExtGridResponse': {
                return new ExtGridResponse(response.data);
            }

            default: {
                return new DataResponse(response.data);
            }
        }
    }
}

export class AbstractResponse implements ResponseInterface {
    public class = "DataResponse";
    public code = 200;
    public body = undefined;
    public notifications = [];
    public permissions = [];
    public selections = [];
    public flags: ResponseFlags = {};

    constructor(data: any) {
        if (data.body !== undefined) {
            this.body = data.body;
        }
        if (data.code) {
            this.code = data.code;
        }
        if (data.notifications) {
            this.notifications = data.notifications;
        }
        if (data.permissions) {
            this.permissions = data.permissions;
        }
        if (data.selections) {
            this.selections = data.selections;
        }

        if (data.flags) {
            this.flags = data.flags;
        }
    }

    public dispatch(): ResponseInterface {
        return this;
    }

    public data(): any {
        return this.body;
    }

    public warningMessages(): string[] {
        const messages: string[] = [];

        _.forEach(this.notifications, (notification: NotificationInterface) => {
            if (notification.class === 'Warning') {
                messages.push(notification.message);
            }
        });

        return messages;
    }

    public errorMessages(): string[] {
        const messages: string[] = [];

        _.forEach(this.notifications, (notification: NotificationInterface) => {
            if (notification.class === 'Error') {
                messages.push(notification.message);
            }
        });

        return messages;
    }

    public successMessages(): string[] {
        const messages: string[] = [];

        _.forEach(this.notifications, (notification: NotificationInterface) => {
            if (notification.class === 'Success') {
                messages.push(notification.message);
            }
        });

        return messages;
    }

}

export class DataResponse extends AbstractResponse {

}

export class SelectionResponse extends AbstractResponse {
    constructor(data: any) {
        super(data);
        if (data.body.results) {
            this.body = data.body.results;
        }
    }
}

export class SemanticUIResponse extends AbstractResponse {

}

export class ErrorResponse extends AbstractResponse {
    public dispatch(): ResponseInterface {

        swal(
            '',
            this.body,
            'error'
        );

        return this;
    }
}


export class ExtGridResponse extends AbstractResponse {
    public total: number = 0;

    constructor(data: any) {
        super(data);
        if (data.body.data) {
            this.body = data.body.data;
        }

        this.total = data.body.total ? data.body.total : 0;
    }
}

export const Response = new ResponseFactory();
