
    import {Component, Prop, Watch} from 'vue-property-decorator';
    import BaseElement from "../BaseElement";

    @Component({
    })
    export default class InputCheckbox extends BaseElement {
        @Prop() declare public plain: boolean;
        @Prop() public value!: any;
        @Prop({default: true}) public on!: any;
        @Prop({default: false}) public off: any;
        @Prop({default: false}) public indeterminate: any;
        @Prop() public type: any;
        @Prop() public checkboxlabel: any;

        get getCheckboxLabel(): string {
            return this.checkboxlabel ? this.$t(this.checkboxlabel).toString() : "";
        }

        get getType(): string {
            if (this.type) {
                return this.type === 'checkbox' ? '' : this.type;
            }

            if (process.env.VUE_APP_DEFAULT_CHECKBOX) {
                return process.env.VUE_APP_DEFAULT_CHECKBOX;
            }

            return "toggle";
        }

        get isPlain(): boolean {
            return this.plain !== undefined;
        }

        get isRadio(): boolean {
            return this.type === 'radio';
        }

        get isArray() {
            return Array.isArray(this.value);
        }

        get isChecked() {
            if (this.isRadio && this.value) {
                return this.value === this.on;
            }

            if (this.isArray) {
                return this.value.includes(this.on);
            }

            return this.value === this.on;
        }

        @Watch('value')
        protected onValueChange() {
            if (this.indeterminate !== false) {
                (this.$refs.input as any).indeterminate = (this.value !== this.on && this.value !== this.off);
            }
        }

        protected updateValue(e) {
            const checked = e.target.checked;

            if (this.isRadio && this.value) {
                this.$emit('input', this.on);
            } else if (this.isArray) {
                if (checked) {
                    this.$emit('input', this.value.concat([this.on]));
                } else {
                    this.$emit('input', this.value.filter((v) => v !== this.on));
                }
            } else {
                this.$emit('input', checked === true ? this.on : this.off === undefined ? null : this.off);
            }
        }
    }
