
const statussets = [
    {
        path: '/administration/stammdaten/statussets',
        name: 'Statussets',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_statussets" */ './Grid/Statussets.vue')
    },
    {
        path: '/administration/stammdaten/statussets/details/:status_set_seq?',
        name: 'Statusset',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_statussets" */ './Details/Statusset.vue')
    }
];

export default statussets;

