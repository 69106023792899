import {Vue} from "vue-property-decorator";
import Focus from "@/framework/directives/Focus";
import Nl2br from "@/framework/directives/Nl2br";
import Dirty from "@/framework/directives/Dirty";
import Popup from "@/framework/directives/Popup";
import AutoGrow from "@/framework/directives/AutoGrow";
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
Vue.directive('focus', Focus);
Vue.directive('nl2br', Nl2br);
Vue.directive('dirty', Dirty);
Vue.directive('popup', Popup);
Vue.directive('autogrow', AutoGrow);
