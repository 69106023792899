Ext.override(Ext.button.Button, {
    setTooltip(tooltip, initial) {
        let me = this,
            targetEl = me.el;

        if (me.rendered) {
            if (!initial || !tooltip) {
                me.clearTip();
            }
            if (tooltip) {
                if (Ext.quickTipsActive && Ext.isObject(tooltip)) {
                    Ext.tip.QuickTipManager.register(Ext.apply({
                        target: targetEl.id
                    }, tooltip));

                    me.tooltip = tooltip;
                } else {
                    targetEl.dom.setAttribute(me.getTipAttr(), tooltip);
                }

                me.currentTooltipEl = targetEl;
            }
        } else {
            me.tooltip = tooltip;
        }

        return me;
    }

});
