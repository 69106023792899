import i18n from "@/framework/locale";

declare let FW: any;

Ext.define('FW.toolbar.Paging', {
    extend: 'Ext.toolbar.Paging',
    xtype: 'fwpagingtoolbar',

    config: {
        showAll: false,
        showAllButton: true
    },

    publishes: 'showAll',

    getPagingItems() {
        const items = FW.toolbar.Paging.superclass.getPagingItems.apply(this);
        const me = this;

        if (this.showAllButton !== false) {
            items.push('-');
            items.push({
                itemId: 'all',
                xtype: 'button',
                enableToggle: true,
                tooltip: i18n.t('btn_show_all'),
                overflowText: i18n.t('btn_show_alltext'),
                iconCls: 'x-fas fa-bars',
                disabled: me.store.isLoading(),
                toggleHandler: me.doAll,
                scope: me
            });
        }

        return items;
    },

    updateInfo() {
        const me = this;
        const displayItem = me.child('#displayItem');
        const store = me.store;
        const pageData = me.getPageData();
        let count;
        let msg;

        if (displayItem) {
            count = store.getCount();

            if (count === 0) {
                msg = me.emptyMsg;
            } else if (this.showAll) {
                msg = Ext.String.format(
                    me.displayMsg,
                    pageData.fromRecord,
                    pageData.total,
                    pageData.total
                );
            } else {
                msg = Ext.String.format(
                    me.displayMsg,
                    pageData.fromRecord,
                    pageData.toRecord,
                    pageData.total
                );
            }

            displayItem.setText(msg);
        }
    },

    onLoad() {
        const me = this;
        let pageData;
        let currPage;
        let pageCount;
        let afterText;
        let count;
        let isEmpty;
        let item;

        count = me.store.getCount();
        isEmpty = count === 0;

        if (!isEmpty && !this.showAll) {
            pageData = me.getPageData();
            currPage = pageData.currentPage;
            pageCount = pageData.pageCount;

            // Check for invalid current page.
            if (currPage > pageCount) {
                // If the surrent page is beyond the loaded end,
                // jump back to the loaded end if there is a valid page count.
                if (pageCount > 0) {
                    me.store.loadPage(pageCount);
                } else {
                    // If no pages, reset the page field.
                    me.getInputItem().reset();
                }

                return;
            }

            afterText = Ext.String.format(me.afterPageText, isNaN(pageCount) ? 1 : pageCount);
        } else if (!isEmpty && this.showAll) {
            currPage = 1;
            pageCount = 1;
            afterText = Ext.String.format(me.afterPageText, 1);
        } else {
            currPage = 0;
            pageCount = 0;
            afterText = Ext.String.format(me.afterPageText, 0);
        }

        Ext.suspendLayouts();
        item = me.child('#afterTextItem');

        if (item) {
            item.update(afterText);
        }

        item = me.getInputItem();

        if (item) {
            item.setDisabled(isEmpty).setValue(currPage);
        }

        me.setChildDisabled('#first', currPage === 1 || isEmpty || this.showAll);
        me.setChildDisabled('#prev', currPage === 1 || isEmpty || this.showAll);
        me.setChildDisabled('#next', currPage === pageCount || isEmpty || this.showAll);
        me.setChildDisabled('#last', currPage === pageCount || isEmpty || this.showAll);
        me.setChildDisabled('#refresh', false);
        me.setChildDisabled('#all', isEmpty);

        me.updateInfo();

        Ext.resumeLayouts(true);

        if (!me.calledInternal) {
            me.fireEvent('change', me, pageData || me.emptyPageData);
        }
    },

    doAll() {
        if (this.fireEvent('beforechange', this, '-1') !== false) {
            this.showAll = !this.showAll;

            this.store.proxy.setExtraParam('nolimit', this.showAll ? 1 : 0);
            this.store.reload();

            return true;
        }

        return false;
    }
});
