import BemusterungOpenDetail from "@/app/pages/Bemusterungen/Grid/OpenDetail";

const bemusterungen = [
    {
        path: '/bemusterungen',
        name: 'Bemusterungen',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_bemusterungen" */ './Grid/Bemusterungen.vue'),
        children: [
            {
                path: 'open/:bemusterung_seq',
                beforeEnter: BemusterungOpenDetail
            }
        ]
    },
    {
        path: '/bemusterungen/teile',
        name: 'BemusterungenTeile',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_teile" */ './Grid/Teile.vue')
    },

    {
        path: '/bemusterungen/anlagenew/:norm_seq/:teil_seq?/:fw_ownership?',
        name: 'BemusterungenAnlageNeu',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_anlage" */ './Details/Anlage.vue')
    },
    {
        path: '/bemusterungen/anlage/:bemusterung_seq?/:fw_ownership?',
        name: 'BemusterungenAnlage',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_anlage" */ './Details/Anlage.vue')
    },
    {
        path: '/bemusterungen/normen/:teil_seq?',
        name: 'Bemusterungen Normen',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_normen" */ './Grid/Einstellungen/NormenForm.vue')
    },
    {
        path: '/bemusterungen/detail/:bemusterung_seq/:fw_ownership?',
        name: 'Bemusterung',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_bemusterung" */ './Details/Bemusterung.vue')
    },
    {
        path: '/bemusterungen/auswertungen',
        name: 'BemusterungenAuswertungen',
        meta: {
            requiresAuth: true,
            resource: "bemusterungen"
        },
        component: () => import(/* webpackChunkName: "bemusterungen_bemusterungen" */ './Auswertungen/Charts.vue'),
    },
];

export default bemusterungen;

