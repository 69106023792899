
const kpi = [
    {
        path: '/kpi/softfacts',
        name: 'softfacts',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpi" */ './Softfacts/Grid/Softfacts.vue')
    },
    {
        path: '/kpi/softfacts/details/:softfact_seq?',
        name: 'softfactsdetail',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpi" */ './Softfacts/Details/Softfact.vue')
    },
    {
        path: '/kpi/bewertungen',
        name: 'kpi_bewertungen',
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        component: () => import(/* webpackChunkName: "kpi" */ './Bewertungen/Grid/KpiBewertungen.vue')
    },

    {
        path: '/kpi/bewertungen/filter',
        name: 'kpi_bewertungenFilter',
        meta: {
            requiresAuth: true,
            resource: "auswertungen"
        },
        component: () => import(/* webpackChunkName: "kpi" */ './Bewertungen/Grid/Filters/KpiBewertungen.vue')
    },

    {
        path: '/kpi/base',
        name: 'kpi_base',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './Base/Grid/KpiBase.vue')
    },

    {
        path: '/kpi/sap',
        name: 'kpi_sap',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './Sap/Grid/Kpi.vue')
    },

    {
        path: '/kpi/bewertungenjahr',
        name: 'bewertungenjahr',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './Jahr/Grid/Bewertungen.vue')
    },

    {
        path: '/kpi/bewertungenjahr/filter',
        name: 'kpi_bewertungen_jahrFilter',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './Jahr/Grid/Filters/Filters.vue')
    },

    {
        path: '/kpi/bewertungenlieferanten',
        name: 'bewertungenlieferanten',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './SchlechtesteLieferanten/Grid/Grid.vue')
    },

    {
        path: '/kpi/bewertungenlieferanten/filter',
        name: 'bewertungenlieferantenFilter',
        meta: {
            requiresAuth: true,
            resource: "kpi"
        },
        component: () => import(/* webpackChunkName: "kpiadmin" */ './SchlechtesteLieferanten/Grid/Filters/Filters.vue')
    },
];

export default kpi;
