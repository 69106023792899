const stichworte = [
    {
        path: '/administration/stammdaten/stichworte',
        name: 'Stichworte',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "stammdaten_stichworte" */ './Grid/Stichworte.vue'),
    },
];

export default stichworte;
