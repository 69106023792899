import vStore, {checkReactive} from "@/app/store";
import * as _ from "lodash";
import {Watcher} from "@/framework/extjs/watcher/Watcher";
import FilterWatcher from "@/framework/extjs/watcher/FilterWatcher";
import StoreWatcher from "@/framework/extjs/watcher/StoreWatcher";

declare var FW: any;

Ext.define('FW.grid.controller.Base', {
    extend: 'Ext.app.ViewController',

    _initialFiltersLoaded: false,
    _watchersUnsubscriber: [],

    init() {
        FW.grid.controller.Base.superclass.init.apply(this, arguments);
        this.initWatchers();
    },


    destroy() {
        this.unwatchFilter();
        this.destroyWatchers();
        FW.grid.controller.Base.superclass.destroy.apply(this, arguments);
    },

    constructor(config) {
        this._initialFiltersLoaded = false;
        this._watchersUnsubscriber = [];

        FW.grid.controller.Base.superclass.constructor.apply(this, [config]);
    },

    initWatchers(storeWatchers: boolean = false) {
        const v = this.getView();

        const getters = vStore.getters;
        if (v.watcher) {
            _.each(v.watcher, (configs) => {
                _.each(configs, (watcher: Watcher, key) => {
                    if (watcher instanceof StoreWatcher && !storeWatchers) {
                        return;
                    }

                    if (!(watcher instanceof StoreWatcher) && storeWatchers) {
                        return;
                    }

                    if (!getters[key]) {
                        checkReactive(vStore.state, key);
                    }

                    watcher.initConfig();
                    watcher.init(v).then(() => {
                        const cb = (value) => {
                            if (_.isFunction(watcher.onWatch)) {
                                watcher.onWatch.apply(watcher, [v, value]);
                            }
                        };

                        if (!this._watchersUnsubscriber) {
                            this._watchersUnsubscriber = [];
                        }
                        this._watchersUnsubscriber.push({
                            watcher, cb: vStore.watch((state) => {
                                let value: any = state;
                                if (getters[key]) {
                                    value = getters[key];
                                } else {
                                    _.split(key, '.').forEach((path) => {
                                        value = value[path];
                                    });
                                }
                                return value;
                            }, cb, watcher.getConfig())
                        });
                    });

                    watcher.onLaunch(v);

                });
            });
        }
    },

    destroyWatchers() {
        if (this._watchersUnsubscriber) {
            _.each(this._watchersUnsubscriber, (item) => {
                item.cb();
            });
        }

        this._watchersUnsubscriber = [];
    },

    initFilter() {
        const getFilter = vStore.getters["filter/get"];
        let savedFilter = getFilter(this.getView().getId());

        if (savedFilter === undefined) {
            savedFilter = [];
        }

        const colmanager: any = this.getView().getVisibleColumnManager();
        const initfilters: any[] = [];

        _.forEach(savedFilter, (filter: any) => {
            let column: any = colmanager.getHeaderByDataIndex(filter.property);
            if (column && column.filterType?.createFilter) {
                initfilters.push(column.filterType.createFilter(filter));
            } else {
                initfilters.push(filter);
            }
        });

        this.getView().getStore().setFilters(initfilters);

        if (!this.getView().getStore().isLoaded() &&  !this.getView().getStore().isLoading()) {
            this.getView().getStore().load();
        }

    },

    initGridFilters() {
        const gridfilters = this.getView().getPlugin('gridfilters') || this.getView().getPlugin('gridfilterbar');
        const filters = this.getView().getStore().getFilters();

        if (!gridfilters) {
            return false;
        }
        const columns = this.getView().columnManager.getColumns();

        if (!columns) {
            return false;
        }

        _.forEach(columns, (column) => {
            const filter: any = column.filterType || column.filter;

            if (!filter || !filter.isGridFilter || !filter.setActive || filter.type === 'none') {
                return;
            }

            if (filter.filter && filter.filter.getId) {
                const f = filters.getByKey(filter.filter.getId());
                if (f) {
                    filter.filter.setDisplayValue(f.getDisplayValue());
                    filter.setValue(f.getValue());
                    // wird nicht mehr benötigt
                    // filter.setActive(true);
                }
            } else if (_.isObject(filter.filter)) {
                filter.setActive(false);
                _.each(filter.filter, (item) => {
                    const f = filters.getByKey(item.getId());
                    if (f) {
                        item.setValue(f.getValue());
                        item.setDisplayValue(f.getDisplayValue());
                        filter.setActive(true);
                    }
                });
            } else {
                filter.setActive(false);
            }
        });

    },

    watchFilter() {
        this.initFilter();
        this.initGridFilters();
        this.getView().getStore().on('filterchange', (collection, filters) => {
            const gridfilters = this.getView().getPlugin('gridfilters') || this.getView().getPlugin('gridfilterbar');
            if (gridfilters) {
                const columns = this.getView().columnManager.getColumns();
                const len = columns.length;
                let i;
                let column;
                let filter;

                // We start with filters defined on any columns.
                for (i = 0; i < len; i++) {
                    column = columns[i];
                    filter = column.filterType ||column.filter;

                    if (filter && filter.isGridFilter && filter.setColumnActive) {

                        if (filter.filter && filter.filter.getId) {
                            if (_.findIndex(filters, ['_id', filter.filter.getId()]) >= 0) {
                                filter.setColumnActive(true);
                            }
                        } else if (_.isObject(filter.filter)) {
                            filter.setColumnActive(false);
                            _.each(filter.filter, (item) => {
                                if (_.findIndex(filters, ['_id', item.getId()]) >= 0) {
                                    filter.setColumnActive(true);
                                }
                            });
                        } else {
                            filter.setColumnActive(false);
                        }

                    }
                }
            }
            this.saveFilter(filters);
        });


        this.initWatchers(true);
    },

    unwatchFilter() {
        if (this._watchersUnsubscriber) {

            this._watchersUnsubscriber = _.remove(this._watchersUnsubscriber, (item: any) => {
                if (item.watcher instanceof FilterWatcher) {
                    item.cb();
                    return false;
                }
                return true;
            });
        }
    },

    saveFilter(f) {
        const filters: any[] = [];
        f.forEach((filter) => {
            if (filter.temporary) {
                return true;
            }
            if (filter.getCurrentConfig) {
                const cF = Ext.applyIf(filter.getCurrentConfig(), filter.config);
                Ext.apply(cF, filter.serialize());

                _.each(cF, (v, key) => {
                    if (_.isFunction(v)) {
                        delete cF[key];
                    }
                });
                filters.push(cF);
            } else {
                filters.push(filter);
            }
        });

        vStore.dispatch('filter/set', {
            key: this.getView().getId(),
            value: filters
        });
    },

    clearFilter() {
        const filters: any[] = [];
        this.getView().getStore().getFilters().each((filter) => {
            if (filter.getCurrentConfig) {
                filter = Ext.applyIf(filter.getCurrentConfig(), filter.config);
            }

            if (filter.locked) {
                filters.push(filter);
            }
        });

        if (filters.length > 0) {
            vStore.dispatch('filter/set', {
                key: this.getView().getId(),
                value: filters
            });
        } else {
            vStore.dispatch('filter/unset', this.getView().getId());
        }
    },

    onException(grid, response, operation) {
    }

});
