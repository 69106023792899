import swal, {SweetAlertResult} from "sweetalert2";
import i18n from "@/framework/locale";
import _ from "lodash";

export function confirmSwal(title: string, html: string, btnOk?: string, btnCancel?: string, target?: string): Promise<SweetAlertResult> {
    return swal({
        type: 'warning',
        title,
        html,
        confirmButtonText: btnOk ? btnOk : i18n.t('ok').toString(),
        cancelButtonText: btnCancel ? btnCancel : i18n.t('abbrechen').toString(),
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: false,
        target: target ? target : 'body'
    });
}

export function questionSwal(title: string, html: string, btnOk?: string, btnCancel?: string, target?: string): Promise<SweetAlertResult> {
    return swal({
        type: 'question',
        title,
        html,
        confirmButtonText: btnOk ? btnOk : i18n.t('ok').toString(),
        cancelButtonText: btnCancel ? btnCancel : i18n.t('abbrechen').toString(),
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: false,
        target: target ? target : 'body'
    });
}

export function sureSwal(target?: string): Promise<SweetAlertResult> {
    return swal({
        type: 'warning',
        title: i18n.t('confirm').toString(),
        confirmButtonText: i18n.t('ok').toString(),
        cancelButtonText: i18n.t('abbrechen').toString(),
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: false,
        target: target ? target : 'body'
    });
}

export function savedSwal(target?: string): Promise<SweetAlertResult> {
    return swal({
        title: i18n.t('gespeichert').toString(),
        timer: 1500,
        type: 'success',
        showConfirmButton: false,
        target: target ? target : 'body'
    });
}

export function deletedSwal(target?: string): Promise<SweetAlertResult> {
    return swal({
        title: i18n.t('loeschen_success').toString(),
        timer: 1500,
        type: 'success',
        showConfirmButton: false,
        target: target ? target : 'body'
    });
}

export function errorSwal(title: string, html: string, target?: string): Promise<SweetAlertResult> {
    return swal({
        type: 'error',
        title,
        html,
        confirmButtonText: i18n.t('ok').toString(),
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        backdrop: false,
        target: target ? target : 'body'
    });
}


export function successSwal(text: string, target?: string): Promise<SweetAlertResult> {
    return swal({
        title: i18n.t(text).toString(),
        timer: 1500,
        type: 'success',
        showConfirmButton: false,
        target: target ? target : 'body'
    });
}

export function dirtySwal(target?: string): Promise<SweetAlertResult> {
    return swal({
        type: 'warning',
        title: i18n.t('confirm').toString(),
        html: i18n.t('dirty_confirm_msg').toString(),
        confirmButtonText: i18n.t('dirty_confirm_ok').toString(),
        cancelButtonText: i18n.t('dirty_confirm_cancel').toString(),
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: true,
        target: target ? target : 'body'
    });
}

export function inputSelectSwal(title: string, select: [], placeholder: string, required: string, btnOk?: string, btnCancel?: string, target?: string): Promise<SweetAlertResult> {
    const list = {};

    _.forEach(select, (item: any) => {
        list[item.value] = item.name;
    });

    return swal({
        title,
        input: 'select',
        inputOptions: list,
        inputPlaceholder: placeholder,
        showCancelButton: true,
        confirmButtonText: btnOk ? btnOk : i18n.t('ok').toString(),
        cancelButtonText: btnCancel ? btnCancel : i18n.t('abbrechen').toString(),
        target: target ? target : 'body',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: false,
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve(null);
                } else {
                    resolve(required);
                }
            });
        }
    });
}


export function inputTextareaSwal(title: string, placeholder: string, required: string, btnOk?: string, btnCancel?: string, target?: string): Promise<SweetAlertResult> {
    return swal({
        title,
        input: 'textarea',
        inputPlaceholder: placeholder,
        showCancelButton: true,
        confirmButtonText: btnOk ? btnOk : i18n.t('ok').toString(),
        cancelButtonText: btnCancel ? btnCancel : i18n.t('abbrechen').toString(),
        target: target ? target : 'body',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: false,
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve(null);
                } else {
                    resolve(required);
                }
            });
        }
    });
}

export interface SwalOptions {
    title: string,
    html: string
}
