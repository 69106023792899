Ext.define('FW.grid.plugin.RowExpander', {
    extend: 'Ext.grid.plugin.RowExpander',
    alias: 'plugin.fwrowexpander',

    getRowBodyContentsFn(rowBodyTpl) {
        const me = this;
        return (rowValues) => {
            rowBodyTpl.owner = me;
            if (rowValues.record._expander && rowValues.record._expander.expandData) {
                const data = rowValues.record._expander;
                return rowBodyTpl.apply(data);
            }
            return '';
        };
    }
});