
    import {Component, Vue} from 'vue-property-decorator';
    import News from './News/News.vue';
    import {mapActions, mapState} from "vuex";
    import Cockpit from './Lieferant/Cockpit.vue';
    import '@/framework/extjs/grid/Panel';
    import Meinepunkte from "@/app/pages/Auswertungen/Meinepunkte/Grid/Meinepunkte.vue";

    @Component({
        computed: {
            ...mapState('permissions', ['admin', 'supplier', 'resources', 'navigation']),
            ...mapState('authentication', ['status', 'user']),
        },
        components: {
            News
        }
    })
    export default class NewsPage extends Vue {

    }
