import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";

import i18n from "@/framework/locale";
import {BemusterungenGridConfig} from "@/app/pages/Bemusterungen/Grid/Config";

export default function BemusterungOpenDetail(to, from, next) {
    if (to.params.bemusterung_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(BemusterungenGridConfig.id, to.params.bemusterung_seq),
            title: i18n.t(BemusterungenGridConfig.title),
            url: '/bemusterungen/detail/' + to.params.bemusterung_seq
        }));
        next();
    }
}
