
const news = [
    {
        path: '/administration/news',
        name: 'NewsGrid',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "news" */ './Grid/News.vue')
    },
    {
        path: '/administration/news/details/:news_seq?',
        name: 'NewsDetail',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "news" */ './Details/News.vue')
    }
];

export default news;
