import router from "@/app/router";
import {dirtySwal} from "@/framework/util/Swals";
import Unload from "@/framework/services/Unload";
import i18n from "@/framework/locale";
import * as _ from "lodash";

Ext.define('FW.grid.mixins.CellEdit', {
    extend: 'Ext.Mixin',

    mixinConfig: {
        before: {
            constructor: "beforeConstructor"
        },

        after: {
            constructor: "afterConstructor"
        }
    },


    beforeConstructor(cfg) {
        let controller = 'fweditgrid';
        let ct: any = null;

        if (_.isString(this.config.controller)) {
            ct = Ext.create('controller.' + this.config.controller);
        } else if (this.config.controller.hasOwnProperty('xclass')) {
            ct = Ext.create(this.config.controller.xclass);
        }
        if (ct instanceof FW.grid.controller.EditPanel) {
            controller = this.config.controller;
        }

        Ext.destroy(ct);
        ct = null;


        Ext.apply(cfg, {
            plugins: {
                cellediting: {
                    clicksToEdit: 1
                }
            },
            controller,
        });

    },

    afterConstructor(cfg) {

        this.on('storechange', (grid, newStore, oldStore) => {
            if (!newStore || newStore.isEmptyStore || newStore == oldStore) {
                return;
            }

            newStore.on('datachanged', (store) => {
                let dirty = false;

                try {
                    _.forEach(store.getData().items, (item) => {
                        if (item.isDirty() || item.isPhantom()) {
                            dirty = true;
                            return false;
                        }
                    });
                } catch (e) {
                    console.error(e);
                } finally {
                    store.dirty = dirty;
                }
            });
        }, this);
    },

    buttons(cmp, cfg) {

        cfg.tbar.push({
            reference: "grid.buttons.copy",
            text: cmp.copyBtnText,
            tooltip: cmp.copyBtnTooltip || cmp.copyBtnText,
            bind: {
                disabled: '{!grid.selection || copyBtn.disabled}',
                hidden: '{!copyBtn.visible}',
            },
            cls: 'extjs button default',
            handler: 'onCopyButtonClick',
            sort: 10
        });

        cfg.tbar.push({
            reference: "grid.buttons.save",
            text: cmp.saveBtnText,
            tooltip: cmp.saveBtnTooltip || cmp.saveBtnText,
            bind: {
                disabled: '{!gridStore.dirty || gridStore.loading || saveBtn.disabled}',
                hidden: '{!saveBtn.visible}'
            },
            cls: 'extjs button secondary',
            handler: 'onSaveButtonClick',
            sort: 11
        });

        cfg.tbar.push({
            reference: "grid.buttons.cancel",
            text: cmp.cancelBtnText,
            tooltip: cmp.cancelBtnTooltip || cmp.cancelBtnText,
            bind: {
                disabled: '{!gridStore.dirty || gridStore.loading || saveBtn.disabled}',
                hidden: '{!saveBtn.visible}'
            },
            cls: 'extjs button secondary',
            handler: 'onCancelButtonClick',
            sort: 12
        });

        cfg.tbar.push({
            xtype: 'tbseparator',
            sort: 14,
            bind: {
                hidden: '{!deleteBtn.visible && !exportBtn.visible && !filterBtn.visible}'
            }
        });

    }

});
