Ext.override(Ext.util.Filter, {

    /**
     * Returns this filter's state.
     * @return {Object}
     */
    getState: function() {
        let config: any = this.getInitialConfig(),
            result: any = {},
            name: any;
        for (name in config) {
            // We only want the instance properties in this case, not inherited ones,
            // so we need hasOwnProperty to filter out our class values.
            if (config.hasOwnProperty(name)) {
                result[name] = config[name];
            }
        }
        delete result.root;
        result.value = this.getValue();
        result.operator = this.getOperator();
        result.type = this.getType();
        result.displayValue = this.getDisplayValue();
        return result;
    },
});
