import {staticValues} from "@/framework/plugins/StaticValues";

Ext.define('FW.grid.column.YesNoColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.yesnocolumn'],
    alternateClassName: 'FW.grid.YesNoColumn',
    defaultFilterType: 'yesno',
    filterType: 'yesno',
    producesHTML: false,


    defaultRenderer(value) {
        if (value === true) {
            value = 'j';
        }

        if (value === false) {
            value = 'n';
        }

        // evaluates `value` to append either `person' or `people`
        return staticValues.yesno(value);
    }
});
