
import {Component, Prop} from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
import $ from 'jquery';
import _ from "lodash";
// @ts-ignore
import hash from "hash-it";

@Component({
    mounted() {
        const that = (this as any);
        that.dropdown = $(that.$refs.comboboxElement).combobox(that.elementSettings);
        if (that.isEmpty) {
            that.initOptions = false;
        }

        if (that.options) {
            that.optionsChange();
        }

        this.$nextTick(() => {
            if (that.value !== null && that.value !== undefined) {
                that.initValue();
            }
        });

        that.currentParam = hash((this as any).param);
    },

    watch: {
        param: {
            deep: true,
            handler() {
                (this as any).paramChange.apply(this);
            }
        },
        options: "optionsChange",
        value: "valueChange",
        dependency: "dependencyChange"
    }
})
export default class InputSelect extends BaseElement {
    get elementSettings(): any {
        const vm = this;
        let context: any = $(this.$el).closest('.x-window-body');

        if (context.length === 0) {
            context = window;
        }

        const defaults = {
            clearable: this.isClearable,
            forceSelection: this.isforceSelection,
            fireOnInit: false,
            saveRemoteData: false,
            apiSettings: this.apiSettings,
            fullTextSearch: vm.searchMode,
            minCharacters: vm.remote ? vm.remoteMinCharacters : vm.minCharacters,
            throttle: 300,
            allowAdditions: this.isallowAdditions,
            hideAdditions: !this.isallowAdditions,

            onShow: () => {
                // Wenn wir ein Show aufrufen, dann wird der initOptions Parameter resetted
                this.initOptions = false;
            },

            onHide: () => {
                // Wenn bei der Suche kein Result gefunden wird, dann wird beim nächsten mal öffnen eine Suche ohne Suchtext ausgeführt
                if (this.isRemote && this.noResults) {
                    this.noResults = false;
                    this.dropdown.combobox('remove message');
                }

            },

            onNoResults: () => {
                if (this.isRemote) {
                    this.noResults = true;
                }
                return true;
            },

            context,

            onOptionCreate(option) {
                return vm.getOptionTemplate(option);
            },

            onAdd(addedValue, addedText, $addedChoice) {
                vm.$emit('add', {value: addedValue, record: vm.dropdown.combobox('get record', addedValue)});
            },

            onRemove(removedValue, removedText, $removedChoice) {
                vm.$emit('remove', {value: removedValue});
            },

            onChange(value, text, $choice) {
                if (value) {
                    vm.noResults = false;
                }

                if (!vm.initOptions && !vm.isDisabled) {
                    if (vm.isMultiselect) {
                        const values = value.length ? _.map(_.split(value, ','), (v) => {
                            return vm.convert(v);
                        }) : [];

                        vm.preventValueChange = !_.isEqual(values, vm.value);

                        vm.$emit('input', values);

                        values.forEach((v) => {
                            const item = vm.dropdown.combobox('get record', v);
                            if (item) { vm.selectedValues.push(item); }
                        });

                        const records: any[] = [];

                        _.each(vm.selectedValues, (item) => {

                            if (_.indexOf(values, item.value) > -1) {
                                records.push(item);
                            }
                        });

                        vm.selectedValues = _.uniqBy(records, 'value');

                        vm.$emit('select', vm.selectedValues);

                    } else {
                        value = vm.strictValue(value);
                        vm.preventValueChange = !_.isEqual(value, vm.value);
                        vm.$emit('input', value);
                        vm.$emit('update:valueText', text);

                        if ($choice) {
                            vm.$emit('select', vm.dropdown.combobox('get record', value));
                        } else {
                            vm.$emit('select', null);
                        }
                    }
                }
            }
        };

        _.keys(this.settings).forEach((key) => {
            defaults[key] = this.settings[key];
        });

        return defaults;
    }

    get isDisabled(): boolean {
        return ((this.disabled !== undefined && this.disabled !== false) || (this as any).isReadonly);
    }

    get isLoading(): boolean {
        return (this.loading !== undefined ? this.loading : false);
    }



    get isMultiselect(): boolean {
        return this.multiselect !== undefined && this.multiselect !== false;
    }

    get isSearchable(): boolean {
        return this.searchable !== undefined && this.searchable !== false;
    }

    get isEmpty(): boolean {
        return this.empty !== undefined && this.empty !== false;
    }

    get isRemote(): boolean {
        return !!this.remote;
    }

    get apiSettings(): any {
        if (!this.remote) {
            return false;
        }
        const that = this;

        return {
            throttleFirstRequest: false,
            interruptRequests: true,
            url: this.remote + '?query={query}',
            cache: false,
            beforeSend(settings) {
                settings.data = that.apiParams();
                return settings;
            }
        };
    }

    get isforceSelection(): boolean {
        return this.forceSelection !== undefined && this.forceSelection !== false;
    }

    get isallowAdditions(): boolean {
        return this.allowAdditions !== undefined && this.allowAdditions !== false;
    }

    public validation: any = {
        validated: false,
        $error: ""
    };

    @Prop() public options: any[];
    @Prop() public value!: any;
    @Prop({default: false}) public loading: boolean;
    @Prop({default: false}) public multiselect: boolean;
    @Prop({default: false}) public searchable: boolean;
    @Prop({default: 'exact'}) public searchMode: any;
    @Prop() public remote: string;
    @Prop({default: 0}) public minCharacters: number;
    @Prop({default: 3}) public remoteMinCharacters: number;
    @Prop() public dependency: any;
    @Prop({default: ""}) public param: any;
    @Prop() public empty: boolean;
    @Prop({default: true}) public strictType: boolean;
    @Prop({default: ""}) public valueText: string;
    @Prop({default: false}) public forceSelection: boolean;
    @Prop({default: false}) public allowAdditions: boolean;


    // Name des Primary Keys
    @Prop({default: "value"}) public remotePrimary: string;

    protected dropdown: any;
    protected initParams: any;
    protected initOptions: boolean = true;
    protected noResults: boolean = false;
    protected currentParam: any;
    protected preventValueChange: boolean = false;
    protected selectedValues: any[] = [];

    public apiParams(): any {
        return _.merge({}, this.param, this.initParams);
    }

    public paramChange(value, oldvalue) {
        const hashedParam = hash(this.param);

        if (!this.currentParam || this.currentParam !== hashedParam) {
            this.currentParam = hashedParam;
            this.dropdown.combobox('remove message');
            this.dropdown.combobox('remove items');
        }
    }

    public optionsChange() {
        this.initOptions = true;
        const difference = this.findValue();

        if (this.options && this.dropdown.combobox('change values', this.options) && this.initOptions) {

            this.dropdown.combobox(this.isMultiselect ? 'set exactly' : 'set selected', this.isMultiselect ? this.convertToString(difference) : this.value);

            if (this.isMultiselect) {
                const values = this.value && this.value.length ? _.map(difference, (v) => {
                    return this.convert(v);
                }) : [];

                this.$emit('input', values);


            } else {
                const obj = _.find(this.options, (item) => {
                    return item.value == this.value;
                });
                this.$emit('input', this.strictValue(this.value));
                if (obj && obj.name) {
                    this.$emit('update:valueText', obj.name);
                }
            }

            this.initOptions = false;
        }
    }

    public valueChange(value, old) {
        if (this.preventValueChange) {
            this.preventValueChange = false;
            return;
        }
        if (!this.initValue()) {
            const selected = this.strictValue(this.dropdown.combobox('get value'));
            if (this.value !== undefined &&
                (_.isArray(this.value) ? this.value.join(',') : this.value) !==
                (_.isArray(selected) ? selected.join(',') : selected)) {
                this.dropdown.combobox(this.isMultiselect ? 'set exactly' : 'set selected', this.convertToString(this.value));
            }
        }

        if (this.value === undefined || this.value === null || this.value === '') {
            this.dropdown.combobox('clear');
        }


    }

    public dependencyChange() {
        // Todo: reagieren auf Abhängigen Wert
        return true;
    }

    protected findValue() {
        let difference: any[] = [];
        if (this.isMultiselect) {
            difference = !this.options ? [] : _.difference(this.value, this.options);
        } else {
            if (this.value !== null && this.value !== undefined) {
                const idx = _.findIndex(this.options, ['value', this.value]);
                if (idx === -1) {
                    _.findIndex(this.options, (item): boolean => {
                        if (item.value.toString() === this.value.toString()) {
                            difference.push(item.value);
                            return true;
                        }

                        return false;
                    });
                } else {
                    difference.push(this.options[idx]);
                }
            }
        }

        return _.isArray(difference) ? difference : [];
    }

    protected strictValue(value: any): any {
        if (this.isMultiselect) {
            return value;
        }

        return this.convert(value);
    }

    protected convert(value: any): any {
        if (this.strictType && !isNaN(parseInt(value, 10))) {
            value = parseInt(value, 10);
        } else if (this.strictType && ((_.isString(value) && value.length === 0) || value === "null")) {
            value = null;
        } else if ((value && value.length === 0) || value === "null") {
            value = null;
        }
        return value;
    }

    protected convertToString(value: any): any {
        if (this.strictType && this.isMultiselect && _.isArray(value)) {
            return _.map(value, (v) => {
                return v.toString();
            });

        }
        return value;
    }

    protected initValue() {
        if (!this.remote) {
            return false;
        }

        let selected = this.strictValue(this.dropdown.combobox('get value'));
        let equal = true;

        if (this.isMultiselect) {
            selected = selected.length ? _.map(_.split(selected, ','), (v) => {
                return this.convert(v);
            }) : [];

            equal = _.isEqual(this.value, selected);
        } else {
            equal = this.value === selected;
        }

        const item: any = this.dropdown.combobox('get item',  this.value);

        if (this.value && !item && this.findValue().length) {
            this.dropdown.combobox('setup menu', {values: this.options});
        }


        // Versuchen den Wert zu setzen, oder remote zu laden
        if (this.findValue().length === 0 && ((!this.isMultiselect && this.value) || (this.isMultiselect && this.value.length > 0)) && !equal) {
            this.initParams = {};
            this.initParams[this.remotePrimary] = this.value;
            this.dropdown.combobox('queryRemote', '', (values) => {
                this.selectedValues = values;
                this.dropdown.combobox(this.isMultiselect ? 'set exactly' : 'set selected', this.isMultiselect ? this.convertToString(this.value) : this.value);
                this.initOptions = false;
                this.preventValueChange = false;
            });
            delete this.initParams;

        } else {
            if (this.value === undefined || this.value === null) {
                // Wenn value falsey ist, dann clear die Combobox!
                this.dropdown.combobox('clear');
            }
            this.initOptions = false;
            return false;
        }

        return true;
    }

    protected getOptionTemplate(option): any {
        return null;
    }

    public setFocus(focus?: boolean) {
        if (focus) {
            this.dropdown.combobox('show');
        }
    }

    public clear(e: Event) {
        e?.stopPropagation();
        this.dropdown.combobox('clear');
    }

}
