import _ from "lodash";

export class Watcher {

    public deep: boolean = true;
    public immediate: boolean = true;

    protected config: any = undefined;

    constructor(config?: any) {
        this.config = config;
    }

    public initConfig() {
        if (this.config) {
            _.merge(this, this.config);
        }
    }

    public init(view: any): Promise<boolean> {
        return Promise.resolve(true);
    }

    public onWatch(view: any, value: any) {
        // Abstrakte Methode, Implementierung in den spezifischen Watchern
        return;
    }

    public getConfig(): any {
        return {deep: this.deep, immediate: this.immediate};
    }

    // @ts-ignore
    public onLaunch(view: any): Promise<boolean> {
        // Abstrakte Methode, Implementierung in den spezifischen Watchern
        // ausführung nach der initialisierung des Watchers
        return Promise.resolve(true);
    }

}
