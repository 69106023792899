import * as _ from "lodash";

const popupRefresh = (el, binding, vnode, oldVnode) => {
    if ($(el).popup('get popup')) {
        $(el).popup('destroy');
    }

    if (binding.value) {
        const context = $(el).closest('.x-window');
        $(el).addClass('fw-popup');
        let config = {
            position: 'top left',
            content: binding.value,
            context: context ? context : 'body'
        };

        if (!_.isString(binding.value)) {
            config = _.assign(config, binding.value, {
                context: binding.value.context ? binding.value.context : (context ? context : 'body'),
                popup: _.isString(binding.value.popup) ? $(vnode.context.$refs[binding.value.popup]) : binding.value.popup
            });
        }
        $(el).popup((config as any));
    }
};

export default {
    inserted(el, binding, vnode, oldVnode) {
        popupRefresh.apply(this, [el, binding, vnode, oldVnode]);
    },

    update(el, binding, vnode, oldVnode) {
        popupRefresh.apply(this, [el, binding, vnode, oldVnode]);
    },

    unbind(el, binding, vnode, oldVnode) {
        if ($(el).popup('get popup')) {
            $(el).popup('destroy');
        }
    }
};
