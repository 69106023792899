import KundenreklamationOpenDetail from "@/app/pages/Kundenreklamation/Grid/OpenDetail";

const kundenreklamation = [
    {
        path: '/kundenreklamation',
        name: 'Kundenreklamationen',
        meta: {
            requiresAuth: true,
            resource: "kundenreklamation"
        },
        component: () => import(/* webpackChunkName: "kundenreklamationen" */ './Grid/Kundenreklamationen.vue'),
        children: [
            {
                path: 'open/:kundenreklamation_seq',
                beforeEnter: KundenreklamationOpenDetail
            }
        ]
    },
    {
        path: '/kundenreklamation/filter',
        name: 'Kundenreklamation Filter',
        meta: {
            requiresAuth: true,
            resource: "kundenreklamation"
        },
        component: () => import(/* webpackChunkName: "kundenreklamationen" */ './Grid/Filters/Kundenreklamationen.vue')
    },
    {
        path: '/kundenreklamation/detail/:kundenreklamation_seq?/:fw_ownership?',
        name: 'Kundenreklamation',
        meta: {
            requiresAuth: true,
            resource: "kundenreklamation"
        },
        component: () => import(/* webpackChunkName: "kundenreklamationdetail" */ './Details/Kundenreklamation.vue'),
    },
    {
        path: '/kundenreklamation/detail_kopf/:kundenreklamation_seq?/:fw_ownership?',
        name: 'Kundenreklamation Kopf',
        meta: {
            requiresAuth: true,
            resource: "kundenreklamation"
        },
        component: () => import(/* webpackChunkName: "kundenreklamationdetail" */ './Details/KundenreklamationKopf.vue')
    }

];

export default kundenreklamation;