import PermissionRequestOpenDetail from "@/app/pages/PermissionRequest/Grid/OpenDetail";

const permissionrequest = [
    {
        path: '/permissionrequest',
        name: 'Permission_Request',
        component: () => import(/* webpackChunkName: "permissionrequest" */ './PermissionRequest.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/administration/permissionrequest',
        name: 'Permission Request',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "permissionrequestadmin" */ './Grid/PermissionRequest.vue'),
        children: [
            {
                path: 'open/:request_id',
                beforeEnter: PermissionRequestOpenDetail
            }
        ]
    },
    {
        path: '/administration/permissionrequest/detail/:request_id?',
        name: 'Permission Request Detail',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "permissionrequestadmin" */ './Details/PermissionRequest.vue')
    },
    {
        path: '/administration/permissionrequest/filter',
        name: 'Permission Request Filter',
        meta: {
            requiresAuth: true,
            resource: "registration"
        },
        component: () => import(/* webpackChunkName: "permissionrequestadmin" */ './Grid/Filters/PermissionRequest.vue')
    }
];

export default permissionrequest;