declare var FW: any;

Ext.define('FW.grid.plugin.ExpandWidget', {
    extend: 'Ext.grid.plugin.RowWidget',

    alias: 'plugin.expandwidget',

    onWidgetAttach(plugin, bodyComponent, record) {
        bodyComponent.setHideHeaders(true);
        bodyComponent.setMinHeight(75);
        bodyComponent.show();
        if (bodyComponent.getStore() && bodyComponent.getStore().loadCount > 0) {
            bodyComponent.getStore().setData([]);
            bodyComponent.getStore().reload();
        }
    },

    getHeaderConfig() {
        const res = FW.grid.plugin.ExpandWidget.superclass.getHeaderConfig.apply(this, arguments);
        const me = this;

        return _.merge(res, {
            renderer: (v, meta, record) => {
                if (record.data.hasOwnProperty('fw_hasexpander') && (!record.data.fw_hasexpander || parseInt(record.data.fw_hasexpander, 10) === 0)) {
                    return '';
                } else {
                    return '<div class="' + Ext.baseCSSPrefix + 'grid-row-expander" role="presentation" tabIndex="0"></div>';
                }
            },
            processEvent: (type, view, cell, rowIndex, cellIndex, e, record) => {
                if (record.data.hasOwnProperty('hasExpander') && record.data.hasExpander === false) {
                    e.preventDefault();
                    return false;
                }

                const isTouch = e.pointerType === 'touch';
                const isExpanderClick = !!e.getTarget('.' + Ext.baseCSSPrefix + 'grid-row-expander');

                if ((type === "click" && isExpanderClick) || (type === 'keydown' && e.getKey() === e.SPACE)) {

                    // Focus the cell on real touch tap.
                    // This is because the toggleRow saves and restores focus
                    // which may be elsewhere than clicked on causing a scroll jump.
                    if (isTouch) {
                        cell.focus();
                    }
                    me.toggleRow(rowIndex, record, e);
                    e.stopSelection = !me.selectRowOnExpand;
                } else if (e.type === 'mousedown' && !isTouch && isExpanderClick) {
                    e.preventDefault();
                }
            }
        });
    }
});
