
const mails = [
    {
        path: '/administration/mails',
        name: 'MailsGrid',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "mails" */ './Grid/Mails.vue')
    },
    {
        path: '/administration/mails/details/:mail_seq?',
        name: 'MailsDetail',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "mails" */ './Details/Mails.vue')
    }
];

export default mails;
