
Ext.define('FW.grid.column.TextColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.textcolumn'],
    alternateClassName: 'FW.grid.TextColumn',
    tdCls: 'wordwrap',
    variableRowHeight: true,

    renderer(value, metaData, record, rowIndex, colIndex, store, view) {
        return Ext.util.Format.nl2br(value);
    },
});
