
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {mapActions, mapState, mapGetters} from "vuex";
    import DropdownMenu from '@/framework/components/Navigation/DropdownMenu/DropdownMenu.vue';

    @Component({
        computed: {
            ...mapState('authentication', ['status', 'user']),
            ...mapGetters('permissions', {
                isAdmin: 'isAdmin'
            })
        },



        components: {
            DropdownMenu
        },

        methods: {
            ...mapActions('authentication', {
                logout: 'logout'
            })
        }

    })
    export default class UserMenu extends Vue {
    }
