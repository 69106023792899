import ProblemloesungOpenDetail from "@/app/pages/Problemloesung/Grid/OpenDetail";

const problemloesung = [
    {
        path: '/problemloesung',
        name: 'Probleme',
        meta: {
            requiresAuth: true,
            resource: "7step"
        },
        component: () => import(/* webpackChunkName: "probleme" */ './Grid/Probleme.vue'),
        children: [
            {
                path: 'open/:problem_seq',
                beforeEnter: ProblemloesungOpenDetail
            }
        ]
    },
    {
        path: '/problemloesung/detail/:problem_seq?/:fw_ownership?',
        name: 'Problem',
        meta: {
            requiresAuth: true,
            resource: "7step"
        },
        component: () => import(/* webpackChunkName: "problemdetail" */ './Details/Problem.vue'),
        // component: () => import(/* webpackChunkName: "problemdetail" */ './Details/ProblemKopf.vue'),
    },
    {
        path: '/problemloesung/filter',
        name: 'Probleme Filter',
        meta: {
            requiresAuth: true,
            resource: "7step"
        },
        component: () => import(/* webpackChunkName: "probleme" */ './Grid/Filters/Probleme.vue')
    },
    {
        path: '/problemloesung/detail_kopf/:problem_seq?/:fw_ownership?',
        name: 'Problem Kopf',
        meta: {
            requiresAuth: true,
            resource: "7step"
        },
        component: () => import(/* webpackChunkName: "problemdetail" */ './Details/ProblemKopf.vue')
    }

];

export default problemloesung;