import GridConfig from "@/framework/extjs/grid/Config";

export const BemusterungenGridConfig: GridConfig = {
    id: 'Bemusterungen',
    title: 'bemusterungen'
};

export const TeileGridConfig: GridConfig = {
    id: 'TeileBemusterungen',
    title: 'teile'
};
