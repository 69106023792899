import i18n from '@/framework/locale';

Ext.define('FW.grid.plugin.StateReset', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.statereset',
    id: 'statereset',

    menuResetText: 'reset_column_sort',
    menuResetIcon: 'x-fa fa-times',

    menuResetColumnText: 'spalten',
    menuResetSortText: 'sortierung',

    menuResetColumnIcon:  'x-cols-icon',
    menuResetSortIcon: 'x-hmenu-sort-asc',


    showMenu: true,
    stateId: undefined,

    init(grid) {
        const me = this;
        let headerCt;

        this.grid = grid;

        headerCt = grid.headerCt;

        this.headerCtListeners = headerCt.on({
            destroyable: true,
            scope: this,
            add: this.onAdd,
            menucreate: this.onMenuCreate
        });

        // if menu is already created before filter is initialized
        if (headerCt.menu && !headerCt.menu.destroyed) {
            this.onMenuCreate(headerCt, headerCt.menu);
        }

        me.gridListeners = grid.on({
            destroyable: true,
            scope: me,
            reconfigure: me.onReconfigure
        });

    },



    onAdd(headerCt, column, index) {
        return;
    },

    /**
     * @private
     * Handle creation of the grid's header menu.
     */
    onMenuCreate(headerCt, menu) {
        const me = this;

        if (me.headerMenuListeners) {
            Ext.destroy(me.headerMenuListeners);
            me.headerMenuListeners = null;
        }

        me.headerMenuListeners = menu.on({
            beforeshow: me.onMenuBeforeShow,
            destroyable: true,
            scope: me
        });
    },

    /**
     * @private
     * Handle showing of the grid's header menu. Sets up the filter item and menu
     * appropriate for the target column.
     */
    onMenuBeforeShow(menu) {
        const me = this;
        let menuItem;
        let parentTable;
        let parentTableId;

        if (me.showMenu) {
            if (!me.exportMenuItem) {
                me.exportMenuItem = {};
            }

            parentTable = menu.up('tablepanel');
            parentTableId = parentTable.id;

            menuItem = me.exportMenuItem[parentTableId];

            if (!menuItem || menuItem.destroyed) {
                menuItem = me.createMenuItem(menu, parentTableId);
            }

        }
    },

    createMenuItem(menu, parentTableId) {
        const me = this;
        let item;

        // only add separator if there are other menu items
        if (menu.items.length) {
            me.sep = menu.add('-');
        }

        const submenu = Ext.create('Ext.menu.Menu', {

            items: [{
                itemId: 'reset_column',
                text: i18n.t(me.menuResetColumnText),
                iconCls: me.menuResetColumnIcon,
                handler: me.onResetState,
                scope: me,
                hideOnClick: me
            }, '-', {
                itemId: 'reset_filter',
                text: i18n.t(me.menuResetSortText),
                iconCls: me.menuResetSortIcon,
                handler: me.onResetSort,
                scope: me,
                hideOnClick: me
            }]
        });

        item = menu.add({
            itemId: 'reset',
            text: i18n.t(me.menuResetText),
            iconCls: me.menuResetIcon,
            menu: submenu,
            hideOnClick: false
        });

        return (me.exportMenuItem[parentTableId] = item);
    },

    onResetState() {
        Ext.state.Manager.clear(this.grid.getStateId());

        this.grid.reconfigure(this.grid.getStore(), this.grid.initialConfig.columns);
        this.grid.updateLayout();
    },

    onResetSort() {
        const sorters = this.grid.store.getSorters();
        sorters.removeAll();
        this.grid.store.reload();
    },

    destroy() {
        const me = this;
        const exportMenuItem = me.exportMenuItem;
        let item;

        Ext.destroy(me.headerCtListeners, me.gridListeners, me.headerMenuListeners);

        me.sep = Ext.destroy(me.sep);

        for (item in exportMenuItem) {
            if (item) {
                exportMenuItem[item].destroy();
            }

        }

    },

    getHeaders() {
        return this.grid.view.headerCt.columnManager.getColumns();
    },


    onReconfigure(grid, store, columns, oldStore) {
        return;
    }


});
