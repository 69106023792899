declare var FW: any;

Ext.define('FW.grid.template.FWTemplate', {
    extend: 'Ext.XTemplate',
    alias: 'template.fwtemplate',

    mixins: [
        'Ext.util.Observable'
    ],

    constructor(config) {
        FW.grid.template.FWTemplate.superclass.constructor.apply(this, arguments);
        this.mixins.observable.constructor.call(this);
    }
});
