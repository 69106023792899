
const verantwortungsbereiche = [
    {
        path: '/administration/stammdaten/verantwortungsbereiche',
        name: 'Verantwortungsbereiche',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_verantwortungsbereiche" */ './Grid/Verantwortungsbereiche.vue')
    },
    {
        path: '/administration/stammdaten/verantwortungsbereiche/details/:verantwortungsbereich?',
        name: 'Verantwortungsbereich',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_verantwortungsbereiche" */ './Details/Verantwortungsbereich.vue')
    }
];

export default verantwortungsbereiche;