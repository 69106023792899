import permissionService from "@/framework/services/Permissions";
import _ from "lodash";
import {NavigationEntries} from "@/app/navigation/NavigationEntries";

export interface iResource {
    name: string;
    permission: string[];
}

export interface iPermission {
    admin: boolean;
    supplier: boolean;
    resources: iResource[] | null;
    navigation: NavigationEntries;
    loaded: string[] | null;
    navigationLoaded: boolean;
}

class Resource implements iResource {
    public name;
    public permission;

    constructor(name: string, initialPermissions: string[]) {
        this.name = name;
        this.permission = initialPermissions;
    }
}

class Permission implements iPermission {
    public admin = false;
    public supplier = false;
    public resources;
    public navigation = new NavigationEntries();
    public loaded = [];
    public navigationLoaded = true;

    constructor(resources: iResource[]) {
        this.resources = resources;
    }
}

export const permissions = {
    namespaced: true,
    state: new Permission([new Resource('navigation', [])]),

    getters: {
        getResource: (state) => (name) => {
            return state.resources.find((resource) => {
                return resource && resource.name === name;
            });
        },

        isAdmin: (state) => {
            return state.admin;
        },

        isSupplier: (state) => {
            return state.supplier;
        },

        isAllowed: (state) => (name: string, qPermissions: string[]) => {
            if (state.admin) {
                return true;
            }

            const resource: iResource = state.resources.find((r: iResource) => {
                return r && r.name === name;
            });
            if (!resource) {
                return false;
            }

            if (!_.isArray(qPermissions)) {
                qPermissions = [qPermissions];
            }
            let allowed = false;
            _.forEach(qPermissions, (permission) => {
                if (_.indexOf(resource.permission, permission) > -1) {
                    allowed = true;
                    return false;
                }
            });

            return allowed;
        },

        isNavigationLoading: (state) => {
            return !state.navigationLoaded;
        }
    },

    actions: {

        setUser({commit}, user) {
            commit('setUser', user);
        },

        getNavigation({commit, state, rootState}) {
            commit('setNavigationLoaded', false);
            return permissionService.loadNavigation().then(
                (response) => {
                    if (response && response.data && response.data.body) {

                        commit('navigationChange', response.data.body);
                        commit('setNavigationLoaded', true);
                    }

                },
                (error) => {
                    commit('setNavigationLoaded', true);
                    return [];
                }
            );

        },

        changeNavigation({commit}, data) {
            commit('navigationChange', data);
        },

        loadPermission({commit, state, rootState}, resources) {
            const toLoad: string[] = [];
            const retval: iResource[] = [];

            _.forEach(resources, (resource) => {
                if (_.indexOf(state.loaded, resource) === -1) {
                    toLoad.push(resource);
                } else {
                    retval.push(_.find(state.resources, ['name', resource]));
                }
            });

            if (!toLoad.length) {
                return Promise.resolve(retval);
            }

            return permissionService.load(toLoad).then(
                (response) => {
                    if (response && response.data && response.data.body) {
                        _.forEach(response.data.body, (resource) => {
                            const res = new Resource(resource.name, resource.permission);
                            commit('permissionChange', res);
                        });
                    }

                },
                (error) => {
                    return [];
                }
            );


        }
    },
    mutations: {
        setNavigationLoaded(state: any, flag: boolean) {
            state.navigationLoaded = flag;
        },

        navigationChange(state: any, navigation: any) {
            state.navigation = navigation;
        },

        permissionChange(state: any, resource: iResource) {

            const res: iResource = _.find(state.resources, ['name', resource.name]);

            if (res) {
                res.permission = resource.permission;
            } else {
                if (state.resources === null) {
                    state.resources = [];
                }
                state.resources.push(resource);

                if (state.loaded === null) {
                    state.loaded = [];
                }
                state.loaded.push(resource.name);
            }


        },

        setUser(state: Permission, user: any) {
            if (!user) {
                state.admin = false;
                state.supplier = false;
                state.loaded = [];
                state.resources = [];
                state.navigation = new NavigationEntries();
                return;
            }

            state.admin = user && user.perm === 'admin';
            state.supplier = user && user.lieferant != null;
        }
    }
};
