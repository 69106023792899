
const gruppen = [
    {
        path: '/administration/stammdaten/gruppen',
        name: 'Gruppen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_gruppen" */ './Grid/Gruppen.vue')
    }
];

export default gruppen;
