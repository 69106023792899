import i18n from '@/framework/locale';
import * as _ from "lodash";
import {confirmSwal} from "@/framework/util/Swals";
import vStore from "@/app/store";

Ext.define('FW.grid.plugin.FilterSave', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.filtersave',
    id: 'filtersave',

    stateId: undefined,
    route: '/filtersave',

    newId: -1, // Id für neue Einträge - interne Verwendung

    input: undefined,
    store: undefined,
    button: undefined,
    configurations: [],
    currentConfiguration: undefined,
    surpressFilterChange: false,

    filterStateId: undefined,


    constructor() {
        this.configurations = [];
        this.currentConfiguration = undefined;
        this.surpressFilterChange = false;

        FW.grid.plugin.FilterSave.superclass.constructor.apply(this, arguments);
    },

    init(grid) {
        const me = this;
        let headerCt;

        this.grid = grid;

        grid.on({
            destroyable: true,
            scope: me,
            reconfigure: me.onReconfigure
        });

        this.filterStateId = grid.getStateId() + '-filtersave';
        this.loadConfigurations();

        headerCt = grid.headerCt;
        this.initializeButton();

        const store = grid.store;
        if (store.isEmptyStore) {
            return;
        }

        me.onBindStore(store);
    },

    initializeButton() {
        const me = this;
        this.button = Ext.create('Ext.button.Button', {
            menu: me.createMenu(),
            tooltip: i18n.t('filtersave'),
            iconCls: 'x-fa fa-save',
            arrowVisible: false
        });

        const filterToolbar = this.grid.getDockedItems('filtertoolbar')[0];
        if (filterToolbar) {
            filterToolbar.insert(-1, this.button);
        }
    },

    createMenuItems() {
        const items = this.configurations;
        this.grid.fireEvent('filtersave-beforeitemscreate', this, items);

        const mItems: any[] = [];
        _.each(items, (i) => {
            if (i !== '-') {
                mItems.push(this.generateListItem(i));
            } else {
                mItems.push('-');
            }
        });

        if (!mItems.length) {
            mItems.push({
                text: i18n.t('filtersave-emptylist').toString(),
                disabled: true
            });
        }

        return mItems;
    },

    createMenu() {
        const me = this;
        const mItems = this.createMenuItems();
        const saveBtn = Ext.create('Ext.button.Button', {
            height: 32,
            width: 40,
            disabled: true,
            handler: () => {
                me.onSaveButtonClick();
            },
            iconCls: 'x-fa fa-save'
        });

        this.input = Ext.create('Ext.form.field.Text', {
            placeholder: i18n.t('filtersaveinput').toString(),
            listeners: {
                change: (txt, newValue, oldValue, eOpts) => {
                    saveBtn.setDisabled(!newValue);
                }
            }
        });

        const config = {
            plain: true,
            items: [{
                xtype: 'buttongroup',
                title: i18n.t('filtersavemenu'),
                border: false,
                frame: false,
                padding: '0 0 0 0',
                margin: '0 0 0 0',
                columns: 2,
                items: [this.input, saveBtn]
            }, {
                reference: 'filtersaveitems',
                text: i18n.t('filtersaveitems').toString(),
                menu: {
                    items: mItems
                }
            }]
        };

        this.grid.fireEvent('filtersave-beforecreatemenu', this, config);
        return Ext.create('Ext.menu.Menu', config);
    },

    generateListItem(i) {
        const me = this;
        return {
            xtype: 'menucheckitem',
            checked: this.currentConfiguration === i.id,
            group: 'configurations',
            text: i.title + (i.deletable ? '<span class="x-fa fa-trash" style="position: absolute; right: 10px; margin-left: 14px;"></span>' : ''),
            onClick(e) {
                if (Ext.get(e.target).hasCls('fa-trash') && i.deletable) {
                    e.stopEvent();
                    e.preventDefault();

                    confirmSwal(i18n.t('loeschen').toString(), i18n.t('filtersavedeleteitem').toString()).then((result) => {
                        if (result && result.value) {
                            me.onDeleteButtonClick(i);
                        }
                    });
                    return false;
                }

                return Ext.menu.CheckItem.prototype.onClick.apply(this, [e]);
            },
            listeners: {
                checkchange: (item, checked) => {
                    if (checked) {
                        me.onCheckChange(i);
                    }
                }
            }
        };
    },

    onSaveButtonClick() {
        const configuration = {
            id: this.newId,
            title: this.input.getValue(),
            deletable: true,
            state: this.getFilterState()
        };

        this.input.setValue(null);
        this.configurations.push(configuration);
        this.currentConfiguration = this.newId;
        this.newId--;

        this.reloadMenu();
        this.saveConfigurations();
    },

    onDeleteButtonClick(i) {
        _.remove(this.configurations, (item: any) => {
            return item.id === i.id;
        });

        if (this.currentConfiguration === i.id) {
            this.currentConfiguration = undefined;
        }

        this.reloadMenu();
        this.saveConfigurations();
    },

    saveConfigurations() {
        vStore.dispatch('preferences/set', {key: this.filterStateId, value: this.configurations});
    },

    loadConfigurations() {
        const s = vStore.getters['preferences/get'](this.filterStateId);
        if (s && s.length) {
            this.configurations = s;
            this.configurations.forEach((i) => {
                if (i.id <= this.newId) {
                    this.newId = i.id - 1;
                }
            });
        }
    },

    onCheckChange(i) {
        this.currentConfiguration = i.id;
        this.surpressFilterChange = true;
        this.applyFilterState(i.state);
        this.surpressFilterChange = false;
    },

    applyFilterState(state) {
        if (state && state.filters) {
            const store = this.grid.getStore();
            store.getFilters().replaceAll(state.filters);
        }
    },

    getFilterState() {
        const store = this.grid.getStore();
        const filters = store.getFilters();
        const state: any = {filters: []};

        if (filters && filters.getCount()) {
            filters.getRange().forEach((filter) => {
                if (filter.getCurrentConfig) {
                    const cF = Ext.applyIf(filter.getCurrentConfig(), filter.config);
                    delete cF.filterFn;
                    state.filters.push(cF);
                } else {
                    state.filters.push(filter);
                }
            });
        }

        return state;
    },

    reloadMenu() {
        const rf = this.button.lookupReferenceHolder();
        if (!rf) return;
        const items = rf.lookup('filtersaveitems');
        if (items) {
            Ext.destroy(items.getMenu());
            items.setMenu(this.createMenuItems());
        }
    },

    unsetCurrentConfiguration() {
        this.currentConfiguration = undefined;
        this.reloadMenu();
    },

    onUnbindStore(store) {
        this.store = null;
    },

    onBindStore(store) {
        this.store = store;

        this.store.on('filterchange', (s, filters) => {
            if (this.surpressFilterChange !== true) {
                this.unsetCurrentConfiguration();
            }
        });
    },

    onReconfigure(grid, store, columns, oldStore) {
        const changed = oldStore !== store;
        if (store && changed) {
            this.onBindStore(store);
        }

        this.reloadMenu();
    }
})
;
