import {staticValues} from "@/framework/plugins/StaticValues";

Ext.define('FW.toolbar.filters.base', {

    config: {
        filter: null
    },

    render(label) {
        return `<span class="fw filter base"><span class="label">${ label }</span></span>`;
    },

    operator(o) {
        if (o === 'eq' || o === '==') {
            return '<i class="fitted outline light equals icon"></i>';
        }

        if (o === '!=') {
            return '<i class="fitted outline light not equal icon"></i>';
        }

        if (o === '>=') {
            return '<i class="fitted outline light greater than equal to icon"></i>';
        }

        if (o === '<=') {
            return '<i class="fitted outline light less than equal to icon"></i>';
        }

        if (o === 'lt' || o === '<') {
            return '<i class="fitted outline light chevron left icon"></i>';
        }

        if (o === 'gt' || o === '>') {
            return '<i class="fitted outline light chevron right icon"></i>';
        }

        if (o === 'like') {
            return '<i class="fitted outline light tilde icon"></i>';
        }

        if (o === 'empty') {
            return staticValues.null;
        }

        if (o === 'nempty') {
            return staticValues.notnull;
        }

        if (o === 'isnull') {
            return staticValues.null;
        }

        if (o === 'isnotnull') {
            return staticValues.notnull;

        }

        return ':';
    }

});
