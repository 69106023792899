
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({

})
export default class Tabs extends Vue {
    @Prop({ default: "ui top attached small tabular menu"}) public css: string;

    public mounted() {
        this.$nextTick(() => {
            $((this.$refs.tabs as any)).find('.item').tab({
                context: 'parent',
                onVisible: (tab) => {
                    this.$emit('change', tab);
                }
            });
        });
    }

    public updated(): void {
        this.$nextTick(() => {
            $((this.$refs.tabs as any)).find('.item').tab({
                context: 'parent',
                onVisible: (tab) => {
                    this.$emit('change', tab);
                }
            });
        });
    }

}
