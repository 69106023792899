import { AnfrageOpenDetail, AngebotOpenDetail } from '@/app/pages/Anfragen/Grid/OpenDetail';

const anfragen = [
    {
        path: '/anfragen',
        name: 'Anfragen',
        meta: {
            requiresAuth: true,
            resource: 'anfragen',
        },
        component: () => import(/* webpackChunkName: "Anfragen" */ './Grid/Anfragen.vue'),
        children: [
            {
                path: 'open/:anfrage_seq',
                beforeEnter: AnfrageOpenDetail,
            },
        ],
    },
    {
        path: '/anfragen/detail/:anfrage_seq?/:fw_ownership?',
        name: 'AnfragenDetail',
        meta: {
            requiresAuth: true,
            resource: 'anfragen',
        },
        component: () => import(/* webpackChunkName: "AnfragenDetail" */ './Details/Anfrage.vue'),
    },
    {
        path: '/anfragen/auktion/:anfrage_seq?/:fw_ownership?',
        name: 'AnfragenAuktion',
        meta: {
            requiresAuth: true,
            resource: 'anfragen',
        },
        component: () => import(/* webpackChunkName: "AnfragenDetail" */ './Auktion/Auktion.vue'),
    },
    {
        path: '/angebote',
        name: 'Angebote',
        meta: {
            requiresAuth: true,
            resource: 'angebote',
        },
        component: () => import(/* webpackChunkName: "Angebote" */ './Angebote/Angebote.vue'),
        children: [
            {
                path: 'open/:anfrage_seq',
                beforeEnter: AngebotOpenDetail,
            },
        ],
    },
    {
        path: '/angebote/detail/:anfrage_seq?/:fw_ownership?',
        name: 'AngeboteDetail',
        meta: {
            requiresAuth: true,
            resource: 'angebote',
        },
        component: () => import(/* webpackChunkName: "AngeboteDetail" */ './Angebot/Angebot.vue'),
    },
];

export default anfragen;
