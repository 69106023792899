import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import store from '@/app/store';
import {ErrorResponse, SemanticUIResponse} from "@/framework/services/Response";
import moment from "moment";
import {errorSwal} from "@/framework/util/Swals";

class SessionRefresher {
    protected lastRequest?: AxiosRequestConfig;
    protected sessionRefreshTimeout;

    public setLastRequest(r?: AxiosRequestConfig) {
        this.lastRequest = r;
        if (process.env.VUE_APP_SESSION_REFRESH_TIMEOUT) {
            if (this.sessionRefreshTimeout) {
                clearTimeout(this.sessionRefreshTimeout);
            }
            this.sessionRefreshTimeout = setTimeout(() => {
                const url = process.env.VUE_APP_SESSION_REFRESH_URL ? process.env.VUE_APP_SESSION_REFRESH_URL : 'authentication/index/sessionrefresh';
                api.post(url);
            }, ((process.env.VUE_APP_SESSION_REFRESH_TIMEOUT! as any) * 1000 * 60));
        }
    }
}

const refresher = new SessionRefresher();
refresher.setLastRequest();

export const base = process.env.VUE_APP_API_BASE_URL || "";

export const injectAPI = (semanticAPI: any) => {
    semanticAPI.settings.base = base;
    semanticAPI.settings.xhrFields = {withCredentials: true};
    semanticAPI.settings.cache = false;

    semanticAPI.settings.onResponse = (response: any) => {
        const resp = new SemanticUIResponse(response);
        return resp.data();
    };

    semanticAPI.settings.onComplete = (response: any) => {
        if (response && 401 === response.code) {
            store.dispatch('authentication/unauthorized');
        }
    };
};

class Api {

    constructor(config?: AxiosRequestConfig) {
        const that = this;
        if (!config) {
            config = {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            };
        }
        const instance = axios.create(config);
        instance.defaults.baseURL = base;
        instance.defaults.withCredentials = true;
        instance.interceptors.request.use((request) => {
            if (request.method === 'post' || request.method === 'get') {
                if (request.url && /\?/.test(request.url)) {
                    request.url = request.url + '&_dc=' + Math.random() + moment().unix();
                } else if (request.url) {
                    request.url = request.url + '?_dc=' + Math.random() + moment().unix();
                }
            }
            refresher.setLastRequest(request);
            return request;
        });

        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {

            if (error && error.response) {
                if (401 === error.response.status) {
                    store.dispatch('authentication/unauthorized');
                    return Promise.reject(error);
                } else if (423 === error.response.status) {
                    const data: string = error.response.data.body ? error.response.data.body : "";
                    return store.dispatch('authentication/twofactor', data);
                } else if (403 === error.response.status) {
                    const errorResponse = error.response.data && error.response.data.body ? error :  {
                        response: {
                            status: error.response.status,
                            data: {
                                code: error.response.status,
                                body: error.response.data
                            }
                        }
                    };
                    return Promise.reject(errorResponse);
                } else {
                    if (500 === error.response.status && process.env.VUE_APP_SHOW_NETWORK_ERRORS === '1') {
                        errorSwal('Error', error.response.data.body);
                    }
                    if (503 === error.response.status) {
                        store.commit('application/maintenance', true);
                    }
                    return Promise.reject(error);
                }
            } else {
                console.error(error);
                return Promise.reject(error);
            }
        });

        return instance;
    }
}

const api = (new Api() as AxiosInstance);
export default api;
