Ext.define('FW.grid.feature.Grouping', {
    extend: 'Ext.grid.feature.Grouping',
    alias: 'feature.fwgrouping',

    actions: undefined,


    groupRowTpl: [
        '{%',
        'var me = this.groupingFeature,',
        'colspan = "colspan=" + values.columns.length;',
        'values.actions = me.actions;',

        // If grouping is disabled or it's just a summary record, do not call setupRowData, and do not wrap
        'if (me.disabled || parent.rows.length === 1 && parent.rows[0].isSummary) {',
        'values.needsWrap = false;',
        '} else {',
        // setupRowData requires the index in the data source, not the index in the real store
        'me.setupRowData(values.record, values.rowIndex, values);',
        '}',
        '%}',
        '<tpl if="needsWrap">',
        '<tpl if="isFirstRow">',
        // MUST output column sizing elements because the first row in this table
        // contains one colspanning TD, and that overrides subsequent column width settings.
        '{% values.view.renderColumnSizer(values, out); %}',
        '<tr data-boundView="{view.id}" data-recordId="{record.internalId:htmlEncode}" data-recordIndex="{[values.isCollapsedGroup ? -1 : values.recordIndex]}" class="{groupHeaderCls}">',
        '<td class="{[me.ctCls]}" {[colspan]}>',
        '{%',
        // Group title is visible if not locking, or we are the locked side, or the locked side has no columns/
        // Use visibility to keep row heights synced without intervention.
        'var groupTitleStyle = (!values.view.lockingPartner || (values.view.ownerCt === values.view.ownerCt.ownerLockable.lockedGrid) || (values.view.lockingPartner.headerCt.getVisibleGridColumns().length === 0)) ? "" : "visibility:hidden",',
        'tooltip = "";',

        // Only display a tooltip if the group is collapsible
        'if (me.collapsible) {',
        'tooltip = Ext.String.format(\'data-qtip="{0}"\', values.isCollapsedGroup ? me.expandTip : me.collapseTip);',
        '}',
        '%}',
        // TODO. Make the group header tabbable with tabIndex="0" and enable grid navigation "Action Mode"
        // to activate it.
        '<div data-groupname="{groupName:htmlEncode}" class="', Ext.baseCSSPrefix, 'grid-group-hd {collapsibleCls}" nottabindex="0" hidefocus="on" {ariaCellInnerAttr}>',
        '<div class="', Ext.baseCSSPrefix, 'grid-group-title" style="{[groupTitleStyle]}" {ariaGroupTitleAttr} {[tooltip]}>',

        '<tpl if="actions">',
            '<span class="grid-group-title-action-icons">',
            '<tpl for="actions">',
                    '<i class="{iconCls}" data-action="{action}"></i>',
            '</tpl>',
            '</span>',
        '</tpl>',
        '{[values.groupHeaderTpl.apply(values.groupRenderInfo, parent) || "&#160;"]}',
        '</div>',
        '</div>',
        '</td>',
        '</tr>',
        '</tpl>',

        // Only output the first row if this is *not* a collapsed group
        '<tpl if="!isCollapsedGroup">',
        '{%',
        'values.itemClasses.length = 0;',
        'this.nextTpl.applyOut(values, out, parent);',
        '%}',
        '</tpl>',
        '<tpl if="summaryRecord">',
        '{%me.outputSummaryRecord(values.summaryRecord, values, out, parent);%}',
        '</tpl>',

        '<tpl else>',
        '{%this.nextTpl.applyOut(values, out, parent);%}',
        '</tpl>', {
            priority: 200,

            beginRowSync(rowSync) {
                let groupingFeature = (this as any).groupingFeature;

                rowSync.add('header', groupingFeature.eventSelector);
                rowSync.add('summary', groupingFeature.summaryRowSelector);
            },

            syncContent(destRow, sourceRow, columnsToUpdate) {
                destRow = Ext.fly(destRow, 'syncDest');
                sourceRow = Ext.fly(sourceRow, 'syncSrc');

                // eslint-disable-next-line vars-on-top
                let groupingFeature = (this as any).groupingFeature,
                    destHd = destRow.down(groupingFeature.eventSelector, true),
                    sourceHd = sourceRow.down(groupingFeature.eventSelector, true),
                    destSummaryRow = destRow.down(groupingFeature.summaryRowSelector, true),
                    sourceSummaryRow = sourceRow.down(groupingFeature.summaryRowSelector, true);

                // Sync the content of header element.
                if (destHd && sourceHd) {
                    Ext.fly(destHd).syncContent(sourceHd);
                }

                // Sync just the updated columns in the summary row.
                if (destSummaryRow && sourceSummaryRow) {

                    // If we were passed a column set, only update them
                    if (columnsToUpdate) {
                        (this as any).groupingFeature.view.updateColumns(
                            destSummaryRow, sourceSummaryRow, columnsToUpdate
                        );
                    } else {
                        Ext.fly(destSummaryRow).syncContent(sourceSummaryRow);
                    }
                }
            }
        }
    ],

    onGroupClick(view, rowElement, groupName, e) {
        let me = this,
            metaGroupCache, groupIsCollapsed, g;

        if (e.target.dataset.action) {
            me.fireEvent('grouping_' + e.target.dataset.action, view, rowElement, groupName);
            return false;
        }

        FW.grid.feature.Grouping.superclass.onGroupClick.apply(this, arguments);
    },

});
