
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import $ from 'jquery';

    @Component({
        mounted() {
            $(this.$el).dropdown();
        }
    })
    export default class Dropdown extends Vue {


    }
