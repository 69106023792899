const logs = [
    {
        path: '/administration/logbuch',
        name: 'Logbuch',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "logbuch" */ './Grid/Logbuch.vue')
    },
    {
        path: '/administration/logbuch/detail/:id',
        name: 'Logbuch Detail',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "logbuch" */ './Details/Logbuch.vue')
    },
    {
        path: '/administration/logbuchemail',
        name: 'Logbuch Email',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "logemail" */ './Grid/Email.vue')
    },
    {
        path: '/administration/logbuchemail/detail/:id',
        name: 'Logbuch Email Detail',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "logemail" */ './Details/Email.vue')
    }

];

export default logs;