const commoditycodes = [
    {
        path: '/administration/stammdaten/commoditycodes',
        name: 'Commoditycodes',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_commoditycodes" */ './Grid/Commoditycodes.vue')
    },
];

export default commoditycodes;

