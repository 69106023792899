
import { Component, Prop, Vue } from 'vue-property-decorator';
import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";
import _ from "lodash";
import i18n from '@/framework/locale';

@Component({})
export default class IconSelect extends InputSelect {
    @Prop({default: "/inforum/lists/icons"}) public remote: string;
    @Prop({default: false}) public clearable: boolean;
    @Prop({default: false}) public searchable: boolean;
    @Prop({default: 'id'}) public remotePrimary: string;

    // protected template: any =  _.template('<i class="<%= name %>"></i>');
    //
    // protected getOptionTemplate(option): any {
    //     const html = this.template(option);
    //     return html;
    // }
}
