
import {Component, Prop, Watch} from 'vue-property-decorator';
import i18n from "@/framework/locale";
import InputText from "@/framework/components/Form/Elements/Input/InputText.vue";
import {CurrencyDirective, getValue, setValue} from "vue-currency-input";

@Component({
    directives: {currency: CurrencyDirective}
})
export default class InputMoney extends InputText {

    protected get currencySymbol() {
        if (this.prefix || this.suffix) {
            return {
                prefix: this.prefix,
                suffix: this.suffix
            };
        }

        if (!this.currency) {
            return {
                prefix: '',
                suffix: ''
            };
        }

        return this.currency;
    }

    protected get currencyConfig() {
        return {
            locale: i18n.locale,
            precision: this.precision,
            currency: this.currencySymbol,
            prefix: this.prefix,
            suffix: this.suffix,
            distractionFree: false
        };
    }
    @Prop() public prefix;
    @Prop() public suffix;
    @Prop() public currency;
    @Prop({default: 2}) public precision;

    protected isFocused: boolean = false;
    protected numberValue: any = null;

    public mounted() {
        this.onValueChange();
    }

    public updateValue() {

    }

    @Watch('value')
    protected onValueChange() {
        setValue((this.$refs as any).input, (this as any).value);
    }

    protected onFocus() {
        const me: any = this;
        if (me.isReadonly) {
            return;
        }
        this.isFocused = true;
    }

    protected onBlur() {
        const me: any = this;
        if (me.isReadonly) {
            return;
        }
        this.$emit('inputblur', (this as any).$refs.input);
        this.isFocused = false;

        (this as any).$emit('input', getValue((this as any).$refs.input));
    }
}
