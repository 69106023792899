import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {PermissionRequestGridConfig} from "@/app/pages/PermissionRequest/Grid/Config";

export default function PermissionRequestOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.request_id) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(PermissionRequestGridConfig.id, to.params.request_id),
            title: i18n.t(PermissionRequestGridConfig.title),
            url: '/administration/permissionrequest/detail/' + to.params.request_id
        }));
    }
}