import i18n from "@/framework/locale";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import vStore from "@/app/store";
import moment from "moment";
import _ from "lodash";
import {localDate} from "@/framework/plugins/Localization";
import {confirmSwal, errorSwal, savedSwal} from "@/framework/util/Swals";


declare var Cal: any;

Ext.define('FW.calendar.controller.Calendar', {
    extend: 'FW.grid.controller.Base',
    alias: 'controller.fwcalendar',

    initFilter() {
        const getFilter = vStore.getters["filter/get"];
        let savedFilter = getFilter(this.getView().getId());

        if (savedFilter === undefined) {
            savedFilter = [];
        }

        this.getView().getStore().setFilters(savedFilter);

        if (!this.getView().getStore().isLoaded() &&  !this.getView().getStore().isLoading()) {
            this.getView().getStore().load();
        }

    },

    setMode(mode) {
        this.getViewModel().set('mode', mode);
        this.getView().setMode(mode);
    },

    shiftPrevious() {
        this.getView().shiftPrevious();
    },

    shiftNext() {
        this.getView().shiftNext();
    },

    setDate(date) {
        this.getView().setDate(date);
    },

    onStoreBeforeLoad(store, operation) {
        this.getView().mask(i18n.t('loading'));
    },

    onStoreWrite(store, operation) {
        this.getView().unmask();
        if (operation.wasSuccessful()) {
            savedSwal();
        } else {
            errorSwal(i18n.t('fehler').toString(), operation.getError());
            store.reload();
        }
    },

    onStoreFailure(batch, options) {
        if (batch && _.isObject(batch.operations[0]) && (batch.operations[0] as any).getError) {
            errorSwal(i18n.t('fehler').toString(), (batch.operations[0] as any).getError());

            this.getView().getStore().reload();
        }
    },

    onStoreLoad(store, operation) {
        this.getView().unmask();
    },

    onEventDrop(view, records, isCopy) {
        return false;
    },

    onConfirmEventDrop(view, dragContext) {
        confirmSwal(i18n.t('termin_verschieben').toString(), i18n.t('termin_verschieben_text').toString()).then((res) => {
            if (res.value) {
                this.getView().mask(i18n.t('speichern'));
                dragContext.finalize(true);
            } else {
                dragContext.finalize(false);

            }
        });

        return false;
    },

    onConfirmEventResize(view, dragContext) {
        confirmSwal(i18n.t('termin_verschieben').toString(), i18n.t('termin_verschieben_text').toString()).then((res) => {
            if (res.value) {
                this.getView().mask(i18n.t('speichern'));
                dragContext.finalize(true);
            } else {
                dragContext.finalize(false);

            }
        });

        return false;
    },

    onEventpartialResize(view, record, startDate, endDate, element, eOpts) {
    },

    onBeforeeventdrag(view, record, e, eOpts) {

    },

    onDragCreateEvent( view, newEventRecord, resourceRecord, e, el, eOpts ) {
        let von, bis;

        const viewcomp = this.getView();
        const store = viewcomp.getStore();
        const rec = store.createModel(this.createModel());

        if (view instanceof Cal.view.Month) {
            von = Ext.Date.format(newEventRecord.getStartDate(), 'Y-m-d');
            bis = Ext.Date.format(newEventRecord.getEndDate(), 'Y-m-d');
        } else {
            von = localDate.toMomentUTC(newEventRecord.getStartDate()).format('YYYY-MM-DD HH:mm:ss');
            bis = localDate.toMomentUTC(newEventRecord.getEndDate()).format('YYYY-MM-DD HH:mm:ss');
        }

        rec.set('start', von);
        rec.set('end', bis);
        rec.setAllDay(newEventRecord.getAllDay());

        this.openDetail(rec);

        return false;
    },

    onDetailButtonClick(grid, eventRecord) {
        const view = this.getView();
        const store = view.getStore();
        const rec = eventRecord;
        const detailRoute = new Ext.XTemplate(view.detailRoute).apply(rec);
        const task = new Task({
            taskId: WindowManager.generateID(view.getId(), rec.getId()),
            title: view.detailTitle,
            url: detailRoute
        });

        vStore.dispatch('tasks/open', task).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent) {
                const comp = win.vueComponent;
                comp.$on('fwreload', () => {
                    store.reload();
                });
            }
        });

        return false;
    },

    onNewButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const rec = store.createModel(this.createModel());

        const von = Ext.Date.format(new Date(), 'Y-m-d') + localDate.toMoment(new Date).format(" HH:00:00");
        const bis = moment(von).add(2, 'h').format("YYYY-MM-DD HH:00:00");

        rec.set('start', von);
        rec.set('end', bis);

        this.openDetail(rec);
        return false;
    },

    onNewCalendarClick(view, date, index) {
        const von = Ext.Date.format(date, 'Y-m-d') + localDate.toMoment(new Date).format(" HH:00:00");
        const bis = moment(von).add(2, 'h').format("YYYY-MM-DD HH:00:00");

        const viewcomp = this.getView();
        const store = viewcomp.getStore();
        const rec = store.createModel(this.createModel());

        rec.set('start', von);
        rec.set('end', bis);
        this.openDetail(rec);
        return false;
    },

    openDetail(rec) {
        const view = this.getView();
        if (!view.newRoute) {
            throw Error('newRoute missing');
        }

        const store = view.getStore();

        const detailRoute = new Ext.XTemplate(view.newRoute).apply({data: rec.getData({serialize:true})});
        const task = new Task({
            taskId: WindowManager.generateID(view.getId(), 'new' + Math.random()),
            title: view.detailTitle,
            url: detailRoute
        });


        vStore.dispatch('tasks/open', task).then((t) => {
            const win = Ext.getCmp(t.windowId);
            if (win && win.vueComponent) {
                const comp = win.vueComponent;
                comp.$on('fwreload', () => {
                    store.reload();
                });
            }
        });
    },

    createModel() {
        return {};
    },

    onReload() {
        const view = this.getView();
        const store = view.getStore();
        store.reload();
    },

    onFilterResources(cmp, selectedResourceIds) {
        const view = this.getView();
        const store = view.getStore();
        const model = store.createModel({});
        const filters = store.getFilters();

        const filter = new Ext.util.Filter({
            id: 'resource_' + model.resourceIdField,
            property: model.resourceIdField,
            operator: 'in',
            locked: true,
            hidden: true,
            temporary: true,
            value: _.values(selectedResourceIds)
        });

        let idx = filters.indexOf(filter),
            existing = idx !== -1 ? filters.getAt(idx) : null;

        // If the filter being added doesn't exist in the collection we should add it.
        // But if there is a filter with the same id (indexOf tests for the same id), we should
        // check if the filter being added has the same properties as the existing one
        if (!existing || !Ext.util.Filter.isEqual(existing, filter)) {
            filters.add(filter);
        }



    }

});
