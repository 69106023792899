class Unload {
    protected unloadFn: any = null;
    protected count: number = 0;

    public register(fn: any): void {
        if (this.unloadFn !== null) {
            window.removeEventListener('beforeunload', this.unloadFn);
        }

        this.count++;

        this.unloadFn = fn;
        window.addEventListener('beforeunload', this.unloadFn);
    }

    public clear(): any {
        if (this.count > 0) {
            this.count--;
        } else {
            this.count = 0;
        }

        if (this.unloadFn !== null && this.count === 0) {
            window.removeEventListener('beforeunload', this.unloadFn);
            const fn = this.unloadFn;
            this.unloadFn = null;
            return fn;
        }
        return null;
    }
}

const service = new Unload();
export default service;
