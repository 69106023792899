Ext.define('FW.window.Modal', {
    extend: 'Ext.window.Window',
    alternateClassName: 'FW.Modal',
    alias: 'widget.modal',
    maximized: true,
    minimizable: true,
    maximizeable: true,
    scrollable: true,
    layout: 'fit',
    onEsc: Ext.emptyFn, // close on Esc off
    resizable: false,

    getTaskbarHeight() {
        return 50;
    },

    listeners: {
        onfwinit() {
            (this as any).updateLayout();
            (this as any).center();
        },

        minimize() {
            (this as any).hide();
        }
    }
});
