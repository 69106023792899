export default {

    props: {
      readonly: {
          type: Boolean,
          default: false
      }
    },

    data() {
        return {
            parentReadonly : false
        };
    },

    mounted() {
        // @ts-ignore
        this.$nextTick(() => {
            (this as any).initReadonly();
        });
    },

    computed: {
        isReadonly() {
            return (this as any).readonly !== undefined && (this as any).readonly !== false || (this as any).parentReadonly === true;
        }
    },

    methods: {
        initReadonly() {
            const that = this;
            if ((this as any).$parent && ((this as any).$parent as any).isReadonly !== undefined) {
                (this as any).$watch('$parent.isReadonly', (newValue, oldValue) => {
                    (that as any).parentReadonly = !!newValue;
                }, {immediate: true});
            }

        }
    }
};