import {staticValues} from "@/framework/plugins/StaticValues";

Ext.define('Ext.grid.plugin.filterbar.filters.Status', {
    extend: 'Ext.grid.plugin.filterbar.filters.List',
    alias: 'grid.filterbar.status',

    constructor(cfg) {
        if (!cfg.options) {
            cfg.options = [
                [1, staticValues.green], [2, staticValues.yellow], [3, staticValues.red]
            ];
        }
        Ext.grid.plugin.filterbar.filters.Status.superclass.constructor.apply(this, [cfg]);
    }
});
