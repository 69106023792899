declare var window: any;


const resize = (el) => {
    let minHeight = parseFloat(getComputedStyle(el).getPropertyValue("min-height"));
    el.style.height = "auto";
    let border = el.style.borderTopWidth * 2;
    let height = (( el.scrollHeight < minHeight ? minHeight : el.scrollHeight ) + border);

    if (height > 0) {
        el.style.setProperty("height",  height + "px", "important");
    }
}

export default {
    name: 'autogrow',

    update (el, binding) {
        if (binding.value === false) {
            return;
        }

        resize(el);

    },

    inserted(el, binding) {
        let observe, minHeight;

        if (binding.value === false) {
            return;
        }

        // When the textarea is being shown / hidden
        var respondToVisibility = (element, callback) => {
            let intersectionObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) callback(element);
                    });
                }, {
                    root: document.documentElement
                }
            );

            intersectionObserver.observe(element);
        };

        respondToVisibility(el, resize);

        resize(el);
    }
};
