import hash from "hash-it";
import * as _ from "lodash";

const hashdata = (row) => {
    const d = {};
    _.forEach(row, (i, k) => {
        if (k !== 'fw_dirty') {
            d[k] = i;
        }
    });

    return d;
};

export default {
    inserted(el, binding) {
        el._row = binding.value;
        el._hash = hash(hashdata(el._row));
    },

    update(el, binding) {
        if (binding.oldValue && binding.oldValue !== binding.value) {
            el._row = binding.value;
            el._hash = hash(hashdata(el._row));
        }

        if (binding.value && !el._row.fw_dirty && (el._hash !== hash(hashdata(el._row)))) {
            el._row.fw_dirty = true;
        }
    }
};
