import i18n from "@/framework/locale";
import * as _ from "lodash";
import {FilterConfig} from "@/framework/components/Grid/FilterData";
import FWFilterFactory from "@/framework/extjs/grid/toolbar/filters/Factory";

declare var FW: any;

Ext.define('FW.grid.toolbar.Filter', {
    extend: 'Ext.toolbar.Toolbar',
    xtype: 'filtertoolbar',

    mixins: [
        'Ext.util.StoreHolder'
    ],

    config: {
        enableFilterButton: true,
        extFilterButton: []
    },

    flex: 1,
    padding: '6 0 6 0',
    enableSearchtext: true,
    defaultBindProperty: 'store',
    _clearButton: undefined,
    _filterButton: undefined,
    _tagField: undefined,
    _filterRemoved: false,


    constructor() {
        this.enableSearchtext = true;
        this.defaultBindProperty = 'store';
        this._clearButton = undefined;
        this._tagField = undefined;
        this._filterButton = undefined;
        this._filterRemoved = false;

        FW.grid.toolbar.Filter.superclass.constructor.apply(this, arguments);
    },


    initComponent() {
        this.bindStore(this.store || 'ext-empty-store', true);

        FW.grid.toolbar.Filter.superclass.initComponent.apply(this, arguments);

        this.initializeTagField();

        if (this.enableFilterButton) {
            this.initializeFilterBarButton();

            // if (this.extFilterButton) {
            //     this.add(this.extFilterButton);
            // }
        }


        this.initializeClearButton();
    },

    initializeClearButton() {
        const me = this;
        this._clearButton = Ext.create('Ext.button.Button', {
            handler() {
                me.clearFilter();
            },
            tooltip: i18n.t('clearfilter'),
            iconCls: 'x-fa fa-filter-circle-x-mark'
        });
        this.add(this._clearButton);
    },

    initializeFilterBarButton() {
        const me = this;
        this._filterButton = Ext.create(this.extFilterButton && this.extFilterButton.length ? 'Ext.button.Split' : 'Ext.button.Button', {
            toggleHandler(comp, pressed) {
                me.toggleFilter(comp, pressed);
                this.fireEvent('press');
            },
            tooltip: i18n.t('togglefilter'),
            iconCls: 'x-fa fa-filter',
            enableToggle: true,
            stateful: true,

            stateEvents: ['press'],
            stateId: 'filterbarButton',

            getState: function() {
                return { pressed: this.pressed };
            },

            applyState: function(state) {
                this.toggle(state.pressed);
            },
            menu: this.extFilterButton && this.extFilterButton.length ? new Ext.menu.Menu({
                items: this.extFilterButton ? this.extFilterButton : []
            }) : null
        });
        this.add(this._filterButton);
    },

    initializeTagField() {
        const me = this;
        this._tagField = Ext.create('Ext.form.field.Tag', {
            cls: 'ext-filtertagfield',
            multiSelect: true,
            forceSelection: false,
            hideTrigger: true,
            flex: 1,
            valueField: 'id',
            displayField: 'text',
            editable: this.enableSearchtext,
            focusable: true,
            selectOnFocus: this.enableSearchtext,
            emptyText: i18n.t('suche'),
            tipTpl: new Ext.XTemplate(
                '<p>{filter.tooltip}</p>' // use current array index to autonumber
            ),
            store: {
                proxy: 'memory'
            },
            listeners: {
                // focus() {
                //     me._tagField.blur();
                // },
                expand() {
                    me._tagField.collapse();
                }
            }
        });
        this._tagField.pickerSelectionModel.on('beforedeselect', this.onFilterRemoved, this);

        Ext.override(this._tagField, {

            getMultiSelectItemMarkup() {
                const me = this,
                    childElCls = (me._getChildElCls && me._getChildElCls()) || ''; // hook for rtl cls

                if (!me.multiSelectItemTpl) {
                    if (!me.labelTpl) {
                        me.labelTpl = '{' + me.displayField + '}';
                    }

                    me.labelTpl = me.lookupTpl('labelTpl');

                    if (me.tipTpl) {
                        me.tipTpl = me.lookupTpl('tipTpl');
                    }

                    /* eslint-disable indent, max-len */
                    me.multiSelectItemTpl = new Ext.XTemplate([
                        '<tpl for=".">',
                        '<li data-selectionIndex="{[xindex - 1]}" data-recordId="{internalId}" role="presentation" class="x-tagfield-filter ' + me.tagItemCls + childElCls,
                        '<tpl if="this.isLocked(values.data)">',
                        ' filter-locked',
                        '</tpl>',
                        '{%',
                        'values = values.data;',
                        '%}',
                        '"',
                        '<tpl if="this.isLocked(values) && values.filter.tooltip">',
                        me.tipTpl ? ' data-qtip="{[this.getTip(values)]}" ' : '',
                        '</tpl>',
                        '>',
                        '<div role="presentation" class="' + me.tagItemTextCls + '">{[this.render(values)]}</div>',
                        '<tpl if="!this.isLocked(values)">',
                        '<div role="presentation" class="' + me.tagItemCloseCls + childElCls + '"></div>',
                        '</tpl>',
                        '</li>',
                        '</tpl>',
                        {
                            isSelected(rec) {
                                return me.selectionModel.isSelected(rec);
                            },
                            getItemLabel(values) {
                                return Ext.String.htmlEncode(me.labelTpl.apply(values));
                            },
                            getTip(values) {
                                return Ext.String.htmlEncode(me.tipTpl.apply(values));
                            },
                            isLocked(values) {
                                return values.filter.locked;
                            },
                            render(values) {
                                return FWFilterFactory.create(values.filter).render(this.getItemLabel(values));
                            },
                            strict: true
                        }
                    ]);
                    /* eslint-enable indent, max-len */
                }

                if (!me.multiSelectItemTpl.isTemplate) {
                    me.multiSelectItemTpl = this.lookupTpl('multiSelectItemTpl');
                }
                return me.multiSelectItemTpl.apply(me.valueCollection.getRange());
            },

            assertValue() {
                const rawValue = this.inputEl.dom.value;

                me.onTagfieldChange.apply(me, [me._tagField, undefined]);

                this.inputEl.dom.value = '';
                this.collapse();
                this.refreshEmptyText();
            },
            autoSize() {
                const that = this;
                let inputEl;
                let width;
                let value;

                if (that.grow && that.rendered) {
                    inputEl = that.inputEl;

                    value = Ext.util.Format.htmlEncode(
                        that.getGrowWidth() || (that.hasFocus ? '' : that.emptyText) || ''
                    );
                    value += 'WWW';

                    width = inputEl.getTextWidth(value) +
                        that.inputWrap.getBorderWidth('lr') +
                        that.triggerWrap.getBorderWidth('lr');

                    width = Math.min(Math.max(width, 200), 800);

                    that.inputEl.setWidth(width);

                    that.updateLayout();

                    that.fireEvent('autosize', that, width);
                }
            },
            onKeyUp(e, t) {
                const that = this;
                const inputEl = that.inputEl;
                const preventKeyUpEvent = that.preventKeyUpEvent;

                if (that.preventKeyUpEvent) {
                    e.stopEvent();
                    if (preventKeyUpEvent === true || e.getKey() === preventKeyUpEvent) {
                        delete that.preventKeyUpEvent;
                    }
                    return;
                }

                // Block entfernt, woduch mit delimiter neuer Wert gesetzt wurde.

                Ext.form.field.Tag.superclass.onKeyUp.apply(that, [e, t]);
            },
            onKeyDown(e) {
                // Nichts zu tun, da wir eigentlich keine Eingabe haben
            }
        });

        if (this.enableSearchtext) {
            this._tagField.on({
                specialkey: {
                    scope: this,
                    buffer: 300,
                    fn: this.onTagfieldChange
                },
                keyup: {
                    fn: () => {
                        this._tagField.autoSize();
                    }
                }
            });
        }
        this.add(this._tagField);
    },

    onTagfieldChange(field, e) {
        if (!this.enableSearchtext) {
            this._tagField.inputEl.dom.value = '';
            this._tagField.refreshEmptyText();
            return false;
        }

        const searchText = field.inputEl.getValue();

        if (!searchText.length) {
            return false;
        }

        if (e && e.isSpecialKey() && e.getKey() !== e.ENTER) {
            return false;
        }

        const searchTextFilter: FilterConfig = {
            property: 'searchtext',
            type: 'string',
            value: searchText,
            displayValue: searchText,
            label: i18n.t('suchtext').toString()
        };

        const extFilter = new Ext.util.Filter(searchTextFilter);
        extFilter.setId('searchtext');
        this.store.addFilter(extFilter);

        this._tagField.inputEl.dom.value = '';
        this._tagField.refreshEmptyText();
    },

    toggleFilter(comp: any, pressed: any) {
        const grid: any = this.lookupReferenceHolder().getView();

        if (!grid) {
            return;
        }

        const plugin: any = grid.getPlugin('gridfilterbar');

        if (pressed) {

            if (grid.showFilterBar) {
                grid.showFilterBar();
            } else if (plugin) {
                plugin.hidden = false;
            }

        } else {
            if (grid.hideFilterBar) {
                grid.hideFilterBar();
            } else if (plugin) {
                plugin.hidden = true;
            }

        }

    },

    clearFilter() {
        this.lookupController().clearFilter();
        const me = this;
        // this._suspendEvents();
        // me.clearTagField();
        // this._resumeEvents();
        const filter = me.store.getFilters();

        const toRemove: any = _.clone(filter.items);
        _.each(toRemove, (item) => {
            if (item && item.locked !== true) {
                me.store.removeFilter(item);
            }

        });

        // me.store.clearFilter();


        const grid = me.lookupReferenceHolder().getView();
        if (!grid) {
            return;
        }
        if (grid.getColumnManager) {
            grid.getColumnManager().getColumns().forEach((col) => {
                if (col && col.filter && _.isObject(col.filter)) {
                    col.filter.setColumnActive(false);
                    col.filter.setActive(false);
                }
            });
        }

    },

    onAdded(owner) {
        const me = this;

        const grid = me.lookupReferenceHolder().getView();

        const oldStore = me.store;
        let autoStore = me._autoStore;
        let listener: any = null;
        let store: any = null;

        // When we are added to our first container, if we have no meaningful store,
        // switch into "autoStore" mode:
        if (autoStore === undefined) {
            me._autoStore = autoStore = !(oldStore && !oldStore.isEmptyStore);
        }

        if (autoStore) {
            listener = me._storeChangeListener;

            if (listener) {
                listener.destroy();
                listener = null;
            }

            store = grid && grid.getStore();
            if (store) {
                listener = grid.on({
                    destroyable: true,
                    scope: me,
                    storechange: 'onOwnerStoreChange'
                });
            }

            me._storeChangeListener = listener;
            me.onOwnerStoreChange(grid, store);
        } else {
            this.onFilterChange();
        }

        FW.grid.toolbar.Filter.superclass.onAdded.apply(this, arguments);

    },

    onOwnerStoreChange(owner, store) {
        this.setStore(store || Ext.getStore('ext-empty-store'));
        this.onFilterChange();
    },

    getStoreListeners() {
        return {
            filterchange: this.onFilterChange
        };
    },

    onFilterChange() {
        const me = this;
        this._suspendEvents();
        me.clearTagField();
        me.syncFilters();
        this._resumeEvents();
    },

    onFilterRemoved(field, record) {
        const me = this;
        if (record.data && record.data.filter && record.data.filter.locked === true) {
            return false;
        }
        const property = record.data.filter.getProperty();

        me.store.removeFilter(record.data.filter);
        const grid = me.lookupReferenceHolder().getView();
        if (!grid) {
            return;
        }
        if (grid.getColumnManager) {
            const col = grid.getColumnManager().getHeaderByDataIndex(record.data.filter._property);
            if (col && col.filter && col.filter.setActive) {

                if (_.findIndex(me.store.getFilters().items, ['_property', property]) === -1) {
                    col.filter.setColumnActive(false);
                    col.filter.setActive(false);
                } else {
                    col.filter.setActive(true);
                }
            }
        }

        return false;
    },

    _suspendEvents() {
        const me = this;
        me._tagField.valueCollection.suspendEvent('remove');
        this._tagField.pickerSelectionModel.suspendEvent('beforedeselect');
    },

    _resumeEvents() {
        const me = this;
        me._tagField.valueCollection.resumeEvent('remove');
        this._tagField.pickerSelectionModel.resumeEvent('beforedeselect');
    },

    syncFilters() {
        const me = this;
        const grid = me.lookupReferenceHolder().getView();
        if (!grid) {
            return;
        }
        const valStore = me._tagField.valueStore;

        me.store.getFilters().each((filter) => {
            if (grid.getColumnManager) {
                let column = grid.getColumnManager().getHeaderByDataIndex(filter.getProperty());

                // if no column -> try to find in filterConfig dataIndex
                if (!column) {
                    try {
                        grid.getColumnManager().getColumns().forEach((header) => {
                            if (header.filter && _.isObject(header.filter) && header.filter.dataIndex === filter.getProperty()) {
                                throw header;
                            }
                        });
                    } catch (header) {
                        column = header;
                    }
                }

                if (column && column.filter && _.isObject(column.filter) && column.filter.label) {
                    filter.label = column.filter.label;
                }

                if (!filter.label && column && column.text) {
                    filter.label = column.text;
                }
            }

            if (!filter.label) {
                filter.label = filter.getProperty();
            }

            if (filter.label && filter.hidden !== true) {
                const label = _.isFunction(filter.label) ? filter.label(filter) : filter.label;

                me._tagField.addValue(valStore.createModel({
                    id: filter.getId(),
                    text: i18n.t(label).toString(),
                    filter
                }));
            }
        });
    },

    clearTagField() {
        const me = this;
        me._tagField.setValue(null);
        me._tagField.value = null;
        me._tagField.valueStore.removeAll();
        me._tagField.valueCollection.clear();
    },

    doDestroy() {
        const me = this;
        const listener = me._storeChangeListener;

        if (listener) {
            listener.destroy();
            me._storeChangeListener = null;
        }

        me.bindStore(null);

        FW.grid.toolbar.Filter.superclass.doDestroy.apply(this, arguments);
    }
});
