
import {Component, Vue} from 'vue-property-decorator';
import {mapActions, mapState} from "vuex";

@Component({
    computed: {
        ...mapState('messages', ['messages', 'unread', 'sidebar'])
    },

    methods: {
        ...mapActions('messages', ['toggleSidebar']),
    },

})
export default class Messages extends Vue {

    protected toggleSidebar: any;

    protected showSidebar() {
        this.toggleSidebar(!(this as any).sidebar);
    }

}
