import store from '@/app/store';
import Task from '@/framework/components/Taskbar/Task';
import WindowManager from '@/framework/util/WindowManager';
import { AuftraegeGridConfig }  from '@/app/pages/Lims/Grid/Config';
import i18n from '@/framework/locale';

export function AuftragOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.auftrag_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(AuftraegeGridConfig.id, to.params.auftrag_seq),
            title: i18n.t(AuftraegeGridConfig.title),
            url: '/lims/auftraege/detail/' + to.params.auftrag_seq,
        }));
    }
}

