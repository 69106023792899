import * as _ from 'lodash';
import i18n from "@/framework/locale";

Ext.define('FW.grid.plugin.Views', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.gridviews',

    mixins: [
        'Ext.util.StoreHolder'
    ],

    id: 'gridviews',
    stateId: 'gridviews',

    columns: [],
    views: [],
    _currentView: null,
    selectLabel: null,
    defaultView: null,
    viewsStore: null,

    constructor() {
        this._currentView = null;
        this.defaultView = null;
        this.viewsStore = null;
        this.columns = [];
        this.views = [];

        FW.grid.plugin.Views.superclass.constructor.apply(this, arguments);
    },

    setCurrentView(v) {
        this._currentView = v;
    },

    getCurrentView() {
        if (!this._currentView && this.defaultView) {
            this._currentView = this.defaultView;
        }

        // Prüfen ob die ausgewählte view in der Menge von möglichen Views ist
        if (this.viewsStore.find('id', this._currentView) === -1) {
            this._currentView = this.defaultView;
        }

        return this._currentView;
    },

    init(grid) {
        const me = this;
        me.grid = grid;

        if (!this.selectLabel) {
            this.selectLabel = i18n.t('ansichten');
        }

        const headerCt = grid.headerCt;
        me.columns = grid.getColumns();

        me.gridListeners = grid.on({
            destroyable: true,
            scope: me
        });

        me.initViews();
        me.initToolbar();
        me.updateColumns(headerCt);
    },

    initViews() {
        this.viewsStore = Ext.create('Ext.data.Store', {
            data: this.views
        });
    },

    initToolbar() {
        const docked = this.grid.getDockedItems('toolbar[dock="top"]');
        const tbar = docked[0];

        tbar.add({
            xtype: 'tbfill'
        }, {
            xtype: 'combo',
            fieldLabel: this.selectLabel,
            allowBlank: true,
            store: this.viewsStore,
            forceSelection: true,
            triggerAction: 'all',
            queryMode: 'local',
            displayField: 'text',
            valueField: 'id',
            value: this.getCurrentView(),
            listeners: {
                change: (cmb, value, old) => {
                    this.setCurrentView(value);
                    this.updateColumns(this.grid.getHeaderContainer());
                }
            }
        });
    },

    getState() {
        return this.getCurrentView();
    },

    applyState(state) {
        this.setCurrentView(state);
    },

    getCurrentColumns() {
        if (this.viewsStore.count() === 0) {
            return this.columns;
        }

        if (!this.getCurrentView()) {
            return this.columns;
        }

        const columns: any[] = [];
        _.each(this.columns, (c) => {
            if (c && c.views) {
                if (c.views.indexOf(this.getCurrentView()) >= 0) {
                    columns.push(c);
                }
            } else {
                columns.push(c);
            }
        });

        return columns;
    },

    updateColumns(container) {
        container.removeAll(false);
        this.getCurrentColumns().forEach((c) => {
            container.insert(container.getColumnCount(), c);
        });

        this.grid.reconfigure();
        setTimeout(() => {
            Ext.GlobalEvents.fireEvent('resize');
        }, 50);
    }
});
