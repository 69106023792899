import ReviewsOpenDetail from "@/app/pages/Reviews/Grid/OpenDetail";
import ReviewpunktOpenDetail from "@/app/pages/Reviews/Review/OpenDetail";

const reviews = [
    {
        path: '/reviews',
        name: 'Reviews',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        component: () => import(/* webpackChunkName: "reviews" */ './Grid/Reviews.vue'),
        children: [
            {
                path: 'open/:review_seq',
                beforeEnter: ReviewsOpenDetail
            },
            {
                path: 'openrevpunkt/:reviewpunkt_seq',
                beforeEnter: ReviewpunktOpenDetail
            }
        ]
    },
    {
        path: '/reviewslieferant',
        name: 'Reviewslieferant',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        component: () => import(/* webpackChunkName: "reviews" */ './Grid/Reviewslieferant.vue'),
    },
    {
        path: '/reviews/detail/:review_seq?/:fw_ownership?',
        name: 'Review',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        component: () => import(/* webpackChunkName: "review" */ './Details/Review.vue')
    },
    {
        path: '/reviews/punkt/:reviewpunkt_seq?/:fw_ownership?',
        name: 'ReviewPunkt',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        component: () => import(/* webpackChunkName: "review" */ './Details/Punkt.vue'),
    },
    {
        path: '/reviews/review/:review_seq?/:fw_ownership?',
        name: 'ReviewPunkte',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        props: true,
        component: () => import(/* webpackChunkName: "review" */ './Review/Punkte.vue')
    },
    {
        path: '/reviews/filter',
        name: 'Review Filter',
        meta: {
            requiresAuth: true,
            resource: "reviews"
        },
        component: () => import(/* webpackChunkName: "review" */ './Review/Filters/Reviews.vue')
    },
];

export default reviews;
