const verteiler = [
    {
        path: '/administration/stammdaten/verteiler',
        name: 'Verteiler',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "verteiler" */ './Grid/Verteiler.vue'),
    },
    {
        path: '/administration/stammdaten/verteilerdetail/:verteilerliste_seq?/:fw_ownership?',
        name: 'Verteilerliste',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "verteiler_detail" */ './Details/Verteiler.vue')
    },
];

export default verteiler;
