class Factory {

    public create(filter) {
        const fclass = filter.getType() ? filter.getType() : 'base';
        return Ext.create('FW.toolbar.filters.' + fclass).setFilter(filter);
    }

}

const FWFilterFactory = new Factory();

export default FWFilterFactory;
