const reviewarten = [
    {
        path: '/administration/stammdaten/reviewarten',
        name: 'Reviewarten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_reviewarten" */ './Grid/Reviewarten.vue')
    },
];

export default reviewarten;

