import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import {BesprechungenGridConfig} from "@/app/pages/Besprechungen/Grid/Config";
import i18n from "@/framework/locale";

export default function BesprechungenOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.besprechung_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(BesprechungenGridConfig.id, to.params.besprechung_seq),
            title: i18n.t(BesprechungenGridConfig.title),
            url: '/besprechungen/detail/' + to.params.besprechung_seq
        }));
    }
}
