import BaseItem from "@/framework/components/Item/BaseItem";
import Task from "@/framework/components/Taskbar/Task";
import {ReklamationenGridConfig} from "@/app/pages/Lieferantenreklamation/Grid/Config";
import {ProblemeGridConfig} from "@/app/pages/Problemloesung/Grid/Config";
import WindowManager from "@/framework/util/WindowManager";
import {KundenreklamationenGridConfig} from "@/app/pages/Kundenreklamation/Grid/Config";
import {AktivitaetenGridConfig} from "@/app/pages/Aktivitaeten/Grid/Config";
import {BesprechungenGridConfig} from "@/app/pages/Besprechungen/Grid/Config";
import {AuditsGridConfig} from "@/app/pages/Audits/Grid/Config";
import {ReviewPunkteGridConfig} from "@/app/pages/Reviews/Review/Config";
import {ReviewsGridConfig} from "@/app/pages/Reviews/Grid/Config";
import { BemusterungenGridConfig } from '@/app/pages/Bemusterungen/Grid/Config';
import { AuftraegeGridConfig } from '@/app/pages/Lims/Grid/Config';

export default class Verweis extends BaseItem {

    get uniqueIdName(): string {
        return "verweis_seq";
    }

    public verweis_seq: number|null = null;
    public id: number|null = null;
    public quelle: number|null = null;
    public quelle_bezeichnung: string|null = null;

    public nr: string|null = null;
    public fortschritt: string|null = null;
    public bezeichnung: string|null = null;
    public projekt_bezeichnung: string|null = null;
    public werk_bezeichnung: string|null = null;
    public verantwortlicher_voller_name: string|null = null;
}


class VerweisTaskFectory {

    public getTask(item: Verweis): Task|null {

        let path = "";
        let viewID = "";
        let title = "";

        if (item.quelle === 1) {
            path = '/lieferantenreklamation/detail/' + item.id;
            viewID = ReklamationenGridConfig.id;
            title = ReklamationenGridConfig.title;
        }

        if (item.quelle === 2) {
            path = '/kundenreklamation/detail/' + item.id;
            viewID = KundenreklamationenGridConfig.id;
            title = KundenreklamationenGridConfig.title;
        }


        if (item.quelle === 3) {
            path = '/problemloesung/detail/' + item.id;
            viewID = ProblemeGridConfig.id;
            title = ProblemeGridConfig.title;
        }

        if (item.quelle === 4) {
            path = '/aktivitaeten/detail/' + item.id;
            viewID = AktivitaetenGridConfig.id;
            title = AktivitaetenGridConfig.title;
        }

        if (item.quelle === 5) {
            path = '/besprechungen/detail/' + item.id;
            viewID = BesprechungenGridConfig.id;
            title = BesprechungenGridConfig.title;
        }

        if (item.quelle === 6) {
            path = '/audits/detail/' + item.id;
            viewID = AuditsGridConfig.id;
            title = AuditsGridConfig.title;
        }

        if (item.quelle === 7) {
            path = '/reviews/review/' + item.id;
            viewID = ReviewPunkteGridConfig.id;
            title = ReviewPunkteGridConfig.title;
        }

        if (item.quelle === 8) {
            path = '/reviews/detail/' + item.id;
            viewID = ReviewsGridConfig.id;
            title = ReviewsGridConfig.title;
        }

        if (item.quelle === 9) {
            path = '/bemusterungen/detail/' + item.id;
            viewID = BemusterungenGridConfig.id;
            title = BemusterungenGridConfig.title;
        }

        if (item.quelle === 12) {
            path = '/lims/auftraege/detail/' + item.id;
            viewID = AuftraegeGridConfig.id;
            title = AuftraegeGridConfig.title;
        }

        if (!path) { return null; }

        return new Task({
            taskId: WindowManager.generateID(viewID, item.id),
            title,
            url: path
        });
    }
}

export const VerweisTask = new VerweisTaskFectory();
