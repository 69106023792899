import api from '@/framework/services/Api';
import Axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import * as _ from 'lodash';

Ext.define('Ext.data.proxy.Api', {
    extend: 'Ext.data.proxy.Server',

    requires: ['Ext.data.operation.Expand'],

    alias: 'proxy.api',
    batchActions: false,
    config: {
        actionMethods: {
            create: 'POST',
            read: 'GET',
            update: 'POST',
            destroy: 'POST',
            expand: 'POST'
        },

        reader: {
            type: 'json',
            rootProperty: 'data.body.data',
            totalProperty: 'data.body.total',
            successProperty: 'data.body.success',
            messageProperty: 'data.body.message'
        },

        writer: {
            allowSingle: true
        }
    },

    constructor(cfg) {
        const me = this;
        if (!cfg) {
            cfg = {};
        }

        const apicfg = cfg.api || this.config.api;
        cfg.batchOrder = _.join(_.keys(apicfg), ',');

        Ext.data.proxy.Api.superclass.constructor.apply(this, [cfg]);
    },

    expand() {
        return this.doRequest.apply(this, arguments);
    },

    custom() {
        return this.doRequest.apply(this, arguments);
    },

    export() {
        return this.doRequest.apply(this, arguments);
    },

    doRequest(operation) {
        const me = this;
        const writer = me.getWriter();
        let request = me.buildRequest(operation);

        if (writer && operation.allowWrite()) {
            request = writer.write(request);
        }

        const cb = this.createRequestCallback(request, operation);

        if (operation) {
            const records = operation.getRecords() || [];
            if (records.length > 0) {
                let data = request.getJsonData();
                if (data && records[0].data && records[0].data.fw_ownership !== undefined) {
                    data = Ext.Object.merge({}, data, {
                        fw_ownership: records[0].data.fw_ownership
                    });
                    request.setJsonData(data);
                } else if (data === undefined) {
                    request.setJsonData(records[0].data);
                }
            }

        }

        const currentConfig = request.getCurrentConfig();
        const method = this.getMethod(request);

        let params = currentConfig.params;
        let jsonData = currentConfig.jsonData;

        if (method.toUpperCase() !== 'GET') {
            if (params) {
                if (jsonData) {
                    jsonData = Ext.Object.merge({}, jsonData, params);
                } else {
                    jsonData = params;
                }
                params = undefined;
            }
        }

        const CancelToken = Axios.CancelToken;
        const ct: CancelTokenSource = CancelToken.source();

        const config: AxiosRequestConfig = {
            url: currentConfig.url,
            method,
            headers: currentConfig.headers,
            params,
            data: jsonData,
            cancelToken: ct.token
        };

        if (me.responseType != null && Ext.supports.XHR2) {
            config.responseType = request.getOperation().responseType || me.responseType;
        }

        const axios = api.request(config);
        request.setRawRequest(ct);

        axios.then((result) => {
            cb(result, true);
        }).catch((result) => {
            cb(result, false);
        });
        this.lastRequest = request;

        return request;
    },

    abort(request) {
        request = request || this.lastRequest;

        if (request && request.getRawRequest()) {
            const raw: CancelTokenSource = request.getRawRequest();
            raw.cancel('Abort called');
        }
    },

    createRequestCallback(request, operation) {
        const me = this;
        return (response, success) => {
            if (request === me.lastRequest) {
                me.lastRequest = null;
            }

            if (!me.destroying && !me.destroyed) {
                me.processResponse(success, operation, request, response);
            }
        };
    },

    getMethod(request) {
        const actions = this.getActionMethods();
        const action = request.getAction();
        let method;

        if (actions) {
            method = actions[action];
        }

        return method || 'POST';
    }

});
