const FW: any = {
    FW_TRUE: 'j',
    FW_FALSE: 'n',


    isFirefox() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    },

    nl2br(value: string) {
        const breakTag = '<br>';
        return value ? value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2') : value;
    }

}
export default FW;
