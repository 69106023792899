import * as _ from "lodash";

export default class Task {
    public taskId: string | null = null;
    public url: string = "";
    public openUrl: string | undefined = undefined; // copy link url .../open
    public title: string = "";
    public tooltip: string = "";
    public windowId: string = "";
    public minimizable: boolean = true;
    public dirty: boolean = false;
    public iconCls: string = "window restore outline icon";
    public config: object = {};

    constructor(data?: any) {
        _.assign(this, data);
    }
}
