import {localDate} from "@/framework/plugins/Localization";
import {encode} from "html-entities";
import _ from "lodash";

Ext.define('FW.toolbar.filters.daterange', {
    extend: 'FW.toolbar.filters.base',

    render(label) {
        const operation = this.operator(this.getFilter().getOperator());
        let rawValue = this.getFilter().getValue();
        if (!_.isArray(rawValue)) {
            rawValue = [rawValue];
        }

        let value: any = [];
        _.each(rawValue, (v: any) => {
            value.push(v ? localDate.toDate(v) : '');
        });
        value = _.join(value, ' - ');
        return `<span class="fw filter date"><span class="label">${label}</span><span class="operation">${operation}</span><span class="value">${encode(value)}</span></span>`;
    }
});
