import {Component, Vue} from 'vue-property-decorator';
import $ from 'jquery';
import {mapActions, mapState} from "vuex";
import DropdownMenu from '@/framework/components/Navigation/DropdownMenu/DropdownMenu.vue';
import languages from "@/app/components/Languages";
import _ from "lodash";

@Component({
    computed: {
        ...mapState('preferences', ['settings'])

    },

    components: {
        DropdownMenu
    },
    methods: {
        ...mapActions('preferences', ['setLocale', 'set']),
    },

})
export default class LanguageSelection extends Vue {
    public user: any;
    public settings: any;
    public setLocale: any;

    get languages(): any[] {
        return languages;
    }

    get language(): any {
        return _.find(languages, {'value': this.settings.locale})?.displayValue ?? this.settings.locale;
    }

    set language(value: any) {
        this.setLocale(value);
    }

    public isSelected(key: string) {
        return this.language === key;
    }
}
