
import { Component, Prop, Vue } from 'vue-property-decorator';
import AutoTooltip from '@/framework/mixins/AutoTooltip';

@Component({
    directives: {
        autotooltip: AutoTooltip
    }
})
export default class InputReadonly extends Vue {
    @Prop() public value!: string|number;

    get hasExtraLabel() {
        return !!this.$slots.left || !!this.$slots.right;
    }

    get extraCss(): string {
        if (this.hasExtraLabel) {
            return this.extra;
        }

        return "";
    }


    get isPlain(): boolean {
        return this.plain !== undefined && this.plain !== false;
    }

    get isHideLabel(): boolean {
        return this.hideLabel !== undefined && this.hideLabel !== false;
    }

    @Prop() public label: string;
    @Prop() public tooltip: string;
    @Prop({default: true}) public autotooltip: string;
    @Prop({default: false}) public plain: boolean;
    @Prop({default: false}) public hideLabel: boolean;
    @Prop({default: "ui right labeled input"}) public extra: string;

}
