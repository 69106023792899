
import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";

@Component({})
export default class InputLocalSearch extends BaseElement {

    public get source(): any[] {
        return this.data;
    }

    get extraCss(): string {
        let css = "";
        if (this.transparent && !this.isFocused) {
            css = css + " inverted ";
            css = css + " transparent ";
        }

        if (this.isFocused) {
            css = css + " focused ";
        }

        if (this.hasExtraLabel) {
            css = css + this.extra;
        }

        return css;
    }

    get cssClasses(): any {
        return {
            error: this.validationObj.$error,
            field: !this.isPlain,
            right: this.position === 'right',
            aligned: this.position === 'right',
            category: this.category
        };
    }
    @Prop() public value!: string | number;
    @Prop({default: "left"}) public position: string;
    @Prop({default: false}) public category: boolean;
    @Prop({default: false}) public transparent: boolean;
    @Prop({default: () => ([])}) public data: any;
    @Prop({default: 'value'}) public valueField: string;

    @Prop({default: () => ({})}) public fields!: any;
    @Prop({default: () => ([])}) public searchfields!: string[];
    protected type: string = "text";
    protected isFocused: boolean = false;
    protected searchField: any;

    public mounted() {
        if (this.source) {
            this.searchField = ($(this.$el) as any).search({
                source: this.source,
                fields: this.fields,
                searchFields: this.searchfields,
                fullTextSearch: true,
                type: this.category ? 'category' : undefined,
                onSelect: this.updateValue
            });
        }
    }

    public updateValue(selection) {
        if (selection) {
            (this as any).$emit('select', selection);
            (this as any).$emit('input', selection[this.valueField]);
        }
    }

    public setFocused(event) {
        this.isFocused = true;
    }

    public setBlur(event) {
        this.isFocused = false;
    }

    public setValue(value) {
        ($(this.$el) as any).search('set value', value);
    }

    @Watch('source')
    protected onSourceChange(v) {
        if (this.searchField) {
            this.searchField.search('destroy');
        }

        this.searchField = ($(this.$el) as any).search({
            source: this.source,
            fields: this.fields,
            searchFields: this.searchfields,
            fullTextSearch: true,
            type: this.category ? 'category' : undefined,
            onSelect: this.updateValue
        });
    }

}
