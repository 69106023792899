import GridConfig from "@/framework/extjs/grid/Config";

export const ReviewPunkteGridConfig: GridConfig = {
    id: 'ReviewPunkte',
    title: 'reviewpunkte'
};

export const ReviewTeileTreeConfig: GridConfig = {
    id: 'ReviewTeileTree',
    title: 'teile'
};
