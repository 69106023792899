import {AuftragOpenDetail} from "@/app/pages/Lims/Grid/OpenDetail";

const lims = [
    {
        path: '/lims/auftraege',
        name: 'LimsAuftraege',
        meta: {
            requiresAuth: true,
            resource: "lims"
        },
        component: () => import(/* webpackChunkName: "lims_auftraege" */ './Grid/Auftraege.vue'),
        children: [
            {
                path: 'open/:auftrag_seq',
                beforeEnter: AuftragOpenDetail
            },
        ],
    },
    {
        path: '/lims/auftraege/filter',
        name: 'LimsAuftraegeFilter',
        meta: {
            requiresAuth: true,
            resource: 'lims',
        },
        component: () => import(/* webpackChunkName: "lims_auftraege" */ './Grid/Filters/Auftraege.vue')
    },
    {
        path: '/lims/auftraege/auftragsarten',
        name: 'LimsAuftraege Auftragsarten',
        meta: {
            requiresAuth: true,
            resource: "lims"
        },
        component: () => import('./Grid/Einstellungen/AuftragsartenForm.vue')
    },
    {
        path: '/lims/auftraege/kopieren',
        name: 'LimsAuftraege Kopieren',
        meta: {
            requiresAuth: true,
            resource: "lims"
        },
        component: () => import('./Grid/Einstellungen/AuftragsartenFormCopy.vue')
    },
    {
        path: '/lims/auftraege/detail/:auftrag_seq?/:fw_ownership?',
        name: 'LimsAuftragDetail',
        meta: {
            requiresAuth: true,
            resource: 'lims',
        },
        component: () => import('./Details/Auftrag.vue'),
    },
    {
        path: '/lims/auftragnew/:auftragsart_seq/:fw_ownership?',
        name: 'LimsAuftragNeu',
        meta: {
            requiresAuth: true,
            resource: 'lims',
        },
        props: true,
        component: () => import('./Details/Auftrag.vue'),
    },
];

export default lims;

