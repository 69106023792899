const nltobr = (el, binding?, vnode?, oldVnode?) => {
    let context = binding.value;
    if (!context) {
        context = el.innerHTML;
        if (el.origInnerHTML) {
            context = el.origInnerHTML;
        } else {
            el.origInnerHTML = context;
        }
    }

    if (context.length) {
        const breakTag = '<br>';
        el.innerHTML = (context + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
};

export default {
    inserted(el, binding?, vnode?, oldVnode?) {
        nltobr(el, binding, vnode, oldVnode);
    },
    update(el, binding?, vnode?, oldVnode?) {
        nltobr(el, binding, vnode, oldVnode);
    },


};
