import {localDate} from "@/framework/plugins/Localization";

Ext.override(Ext.data.field.Date, {
    dateReadFormat: "Y-m-d",
    dateWriteFormat: "Y-m-d",

    convert(v) {
        if (!v) {
            return null;
        }

        // instanceof check ~10 times faster than Ext.isDate. Values here will not be
        // cross-document objects
        if (v instanceof Date) {
            return v;
        }

        /* eslint-disable-next-line vars-on-top */
        let dateFormat = this.dateReadFormat || this.dateFormat,
            parsed;

        if (dateFormat) {
            // remove 00:00:00
            return Ext.Date.parse(v.length > 10 ? v.substr(0, 10) : v, dateFormat, this.useStrict);
        }

        parsed = Date.parse(v);

        return parsed ? new Date(parsed) : null;
    }
});



Ext.define('FW.data.field.DateTime', {
    extend: 'Ext.data.field.Date',
    alias: [
        'data.field.datetime',
        'data.field.fw_datetime'
    ],

    dateWriteFormat: "YYYY-MM-DD HH:mm:ss", // moment format

    convert(v) {
        if (!v) {
            return null;
        }

        if (v instanceof Date) {
            return v;
        }

        const date = localDate.toMomentTz(v);
        // @ts-ignore
        return date.isValid ? date.toDate() : null;

    },

    serialize(value) {
        let result = null;

        if (Ext.isDate(value)) {
            result = this.dateWriteFormat ? localDate.toMomentUTC(value).format(this.dateWriteFormat) : value;
        }

        return result;
    }
});
