declare var FW: any;

Ext.define('FW.data.ApiStore', {
    extend: 'Ext.data.JsonStore',
    requires: [
        'Ext.data.*',
    ],
    autoLoad: true,
    autoSync: false,
    asynchronousLoad: true,
    pageSize: 50,
    remoteSort: true,
    remoteFilter: true,
    proxy: {
        type: 'api'
    },

    dirty: false,

    constructor(cfg) {

        this.dirty = false;

        FW.data.ApiStore.superclass.constructor.apply(this, arguments);

        if (cfg && cfg.idProperty) {
            this.model.idProperty = this.model.prototype.idProperty = cfg.idProperty;
        }

        this.getProxy().on("exception", (store, response, operation) => {
            this.fireEvent('exception', this, response, operation);
        }, this);

        this.exports = [];

        // Bestehende Operation abbrechen
        this.on('filterchange', (store, filters, eOpts) => {
            store.getProxy().abort();
        });

        this.on('beforeload', (extstore, operation) => {
            operation.getProxy().abort();
        });
    },

    sort(field, direction, mode) {
        if (!field) {
            return;
        }

        FW.data.ApiStore.superclass.sort.apply(this, arguments);
    },

    getExports() {
        return this.exports;
    },

    setExports(exports) {
        this.exports = exports;
    },

    isDirty() {
        return this.dirty === true;
    },

    onException(e) {
        // TODO: check warum hier onException auslöst - aufgetreten mit ext-all-debug und Request abort
    }
});
