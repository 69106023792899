import GridConfig from "@/framework/extjs/grid/Config";

export const AktivitaetenGridConfig: GridConfig = {
    id: 'Aktivitaeten',
    title: 'aktivitaeten'
};

export const AktivitaetenTreeConfig: GridConfig = {
    id: 'AktivitaetenTree',
    title: 'struktur'
};
