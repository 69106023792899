import i18n from '@/framework/locale';

const origInit = Ext.grid.column.Column.prototype.initComponent;
Ext.override(Ext.grid.column.Column, {

    config: {
        sortIndex: null,
        lockable: false
    },


    initComponent() {
        const me = this;

        if (me.filter && !me.filterType) {
            me.filterType = me.filter;
            delete me.filter;
        }

        origInit.apply(this, arguments);

        // Wenn wir keine stateId in der Spalte haben, dann versuchen wir eine aus DataIndex, DisplayIndex oder ExportIndex zu erzeugen
        if (!this.stateId && (this.dataIndex || this.displayIndex || this.exportIndex)) {
            this.stateId = 'state-' + (this.dataIndex || (this.displayIndex ? 'display-' + this.displayIndex : false) || (this.exportIndex ? 'export-' + this.exportIndex : false));
        }

        if (me.text) {
            me.text = i18n.t(me.text);
        }

        if (me.tooltip) {
            me.tooltip = i18n.t(me.tooltip);
        }

        if (me.displayIndex) {
            const origRenderer = me.renderer || me.defaultRenderer;
            if (origRenderer) {
                // Verwende den Originalen Renderer wenn es bereits einen gibt
                me.renderer = function(v, grid, rec) {
                    return origRenderer.apply(this, [rec.get(me.displayIndex), grid, rec]);
                };
            } else {
                me.renderer = (v, grid, rec) => {
                    return rec.get(me.displayIndex);
                };
            }
        }
    },

    getSortParam() {

        if (this.sortIndex) {
            return this.sortIndex;
        }

        if (this.config.filter?.dataIndex) {
            return this.config.filter.dataIndex;
        }

        return this.dataIndex;
    },

});
