
    import {Component, Vue} from 'vue-property-decorator';
    import {mapActions, mapState} from "vuex";


    @Component({
        computed: {
            ...mapState('authentication', ['status', 'user'])
        }

    })
    export default class LoginMenu extends Vue {

    }

