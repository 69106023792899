import store from "@/app/store";
import Task from "@/framework/components/Taskbar/Task";
import WindowManager from "@/framework/util/WindowManager";
import i18n from "@/framework/locale";
import {ZertifikateFreigabeGridConfig} from "./Config";

export default function ZertifikateFreigabeOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.zertifikat_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(ZertifikateFreigabeGridConfig.id, to.params.zertifikat_seq),
            title: i18n.t(ZertifikateFreigabeGridConfig.title),
            url: '/zertifikate/freigabe/detail/' + to.params.zertifikat_seq
        }));
    }
}