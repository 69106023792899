import i18n from "@/framework/locale";
import {confirmSwal, dirtySwal, errorSwal, savedSwal} from "@/framework/util/Swals";
import _ from "lodash";
import router from "@/app/router";
import Unload from "@/framework/services/Unload";

Ext.define('FW.grid.controller.MultiEdit', {
    extend: 'FW.grid.controller.Panel',
    alias: 'controller.fwmultieditgrid',

    initViewModel() {
        const view = this.getView();

        let unload: boolean = false;
        if (view.getViewModel()) {
            const viewmodel = view.getViewModel();
            const fn = router.beforeEach((to, from, next) => {
                try {
                    if (viewmodel && viewmodel.get('celleditdirty')) {
                        dirtySwal().then((res) => {
                            if (res.dismiss) {
                                fn();
                                Unload.clear();
                                next();
                            } else {
                                next(false);
                            }
                        });
                    } else {
                        fn();
                        Unload.clear();
                        next();
                    }
                } catch (e) {
                    fn();
                    Unload.clear();
                    next();
                }
            });

            view.getViewModel().bind('{celleditdirty}', (value) => {
                if (value) {
                    unload = true;
                    Unload.register((event) => {
                        const msg = i18n.t('dirty_confirm_msg').toString();
                        event.preventDefault();
                        event.returnValue = msg;
                        return msg;
                    });
                } else if (unload) {
                    Unload.clear();
                }
            });
        }
    },

    onBeforeEdit(editor, context) {
        const view = this.getView();

        let dIdx = context.column.dataIndex;

        if (dIdx) {
            dIdx = _.split(dIdx, '.').pop();
        }

        context.column.dataIndex = dIdx;

        if (!view.getViewModel().get('saveBtn.visible')) {
            return false;
        }
        view.getViewModel().set('editRecord', context.record);
    },

    onEdit(editor, context) {
        const originalValue: string = _.isString(context.value) && _.isNull(context.originalValue) ? '' : context.originalValue;

        if (context.value !== originalValue) {
            const view = this.getView();
            const store = view.getStore();
            view.getViewModel().set('gridStore.dirty', true);
            store.fireEvent('datachanged', store);
        }
    },

    onCancelEdit(editor, context) {
        this.onEdit(editor, context);
    },

    onValidateEdit(editor, context) {
        const originalValue: string = _.isString(context.value) && _.isNull(context.originalValue) ? '' : context.originalValue;

        if (context.value === originalValue) {
            context.cancel = true;
            return false;
        }

        if (context && context.column && context.column.displayIndex && (context.column.getEditor() instanceof FW.form.field.ComboBox || context.column.getEditor() instanceof FW.form.field.Tag)) {
            const displayValue = context.column.getEditor().getDisplayValue();
            context.record.set(context.column.displayIndex, displayValue);
        }

        if (context && (!context.column.getEditor().allowBlank && context.column.getEditor().allowBlank !== undefined) && !context.value) {
            return false;
        }

    },

    onMultieditButtonClick() {
        this.getViewModel().set('multiediting.enabled', !this.getViewModel().get('multiediting.enabled'));
        if (this.getViewModel().get('multiediting.enabled')) {
            this.getView().showMultieditBar();
        } else {
            this.getView().hideMultieditBar();
        }
    },

    onCancelButtonClick() {
        this.getViewModel().set('multiediting.enabled', false);
        this.getView().hideMultieditBar();
        this.lookup('grid.buttons.multiedit')?.toggle(false);
        this.getViewModel().set('multiediting.data', {});

        const v = this.getView();
        v.getSelectionModel().deselectAll(true);
    },

    onCellEditCancelButtonClick() {
        const view = this.getView();
        const store = view.getStore();

        view.findPlugin('cellediting').cancelEdit();
        store.rejectChanges();
    },

    onCellEditSaveButtonClick() {
        const view = this.getView();
        const store = view.getStore();
        const rejectedRecs = store.getRejectRecords();
        if (rejectedRecs.length) {
            let rec: any = null;
            _.forEach(rejectedRecs, (record, index, modifiedArray) => {
                if (!record.isValid()) {
                    rec = record;
                }
            });

            if (rec && rec.validation) {
                let col = -1;
                _.forEach(rec.validation.data, (value, key) => {

                    if (value !== true) {
                        const headerIndex = view.getColumnManager().getHeaderByDataIndex(key).fullColumnIndex;
                        col = (col == -1 || col > headerIndex) ? headerIndex : col;
                    }
                });

                if (col > -1) {
                    setTimeout(() => {
                        view.findPlugin('cellediting').startEditByPosition(
                            new Ext.grid.CellContext(view.getView()).setPosition(rec, col)
                        );
                    }, 0);

                    return false;
                }
            }
        }

        store.on('beforesync', () => {
            view.mask(i18n.t('speichern'));
        }, this, {single: true});

        store.sync({
            callback: () => {
                view.unmask();
            },
            success: () => {
                savedSwal();
            },
            failure: (batch, options) => {
                if (batch && _.isObject(batch.operations[0]) && (batch.operations[0] as any).getError) {
                    errorSwal(i18n.t('fehler').toString(), (batch.operations[0] as any).getError());
                }
            }
        });
    },

    onSaveButtonClick() {
        const view = this.getView();
        const cm = view.getColumnManager();
        const store = view.getStore();
        const proxy = store.getProxy();
        const selection = view.getSelection();

        if (!selection || selection.length === 0) {
            errorSwal('', i18n.t('swal_multiedit_no_selection').toString());
            return;
        }

        const rows: any = [];
        _.forEach(selection, (row) => {
            rows.push(row.getData({serialize: true}));
        });

        const mData = this.getViewModel().get('multiediting.data');
        const mModel = store.createModel(mData);
        const sData = mModel.getData({serialize: true});
        const dData = {};

        _.map(_.keys(mData), (k) => {
            const nullField = k + '_null';
            const column = cm.getHeaderByDataIndex(k);

            if (/_null$/.test(k)) {
                if (sData[k] === true) {
                    dData[k.replace(/_null$/, '')] = null;
                }
            } else {
                if (/_null$/.test(k) || (sData.hasOwnProperty(nullField) && sData[nullField])) {
                    // Nothing to do
                } else if (mModel.getIdProperty() !== k && sData[k] !== null && sData[k] !== undefined && sData[k].toString().length > 0) {

                    if (column?.editor) {
                        const editor = column.editor;
                        console.info('Editor found');
                        if (editor.submitFormat && editor.xtype == 'datefield') {
                            // Konvertiere value, wenn nicht schon durch model passiert
                            const v = Ext.Date.format(sData[k], editor.submitFormat);
                            if (v && v.length) {
                                sData[k] = v;
                            }
                        }
                    }
                    dData[k] = sData[k];
                }
            }
        });

        const data: any = {
            rows,
            data: dData
        };

        confirmSwal(i18n.t('swal_multiedit').toString(), i18n.t('swal_multiedit_text').toString()).then((result) => {
            if (result && result.value) {
                view.mask(i18n.t('speichern').toString());
                proxy.batch({
                    operations: {
                        multiedit: [Ext.create('Ext.data.Model', data)]
                    },
                    callback: (res) => {
                        view.unmask();
                        if (res.exception === true) {
                            errorSwal('', i18n.t('swal_multiedit_error').toString());
                            // store.reload();
                        } else {
                            this.getViewModel().set('multiediting.enabled', false);
                            this.getView().hideMultieditBar();
                            this.lookup('grid.buttons.multiedit')?.toggle(false);
                            this.getViewModel().set('multiediting.data', {});
                            store.reload();
                            savedSwal();
                        }
                    }
                });
            }
        });
    },

});
