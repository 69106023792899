
import {Component, Prop, Vue} from 'vue-property-decorator';
import $ from "jquery";
import * as _ from "lodash";

@Component({
})
export default class Modal extends Vue {
    @Prop({default: () => ({})}) public settings: any;
    @Prop({default: "small"}) public size: string;
    protected modalObj: any = null;
    protected contentCSS: string = "scrolling content";
    protected hidden: boolean = true;

    get elementSettings(): any {
        const defaults = {
            btnOK: 'uebernehmen',
            btnCancel: 'abbrechen',
            btnNew: 'uebernehmen_neu',
            modal: {
                autofocus: false,
                closable: false,
                inverted: true,
                detachable: true,
                allowMultiple: true,
                onShow: this.onShow,
                onHide: this.onHide,
                onHidden: this.onHidden,
                onApprove: this.onApprove,
                onDeny: this.onDeny,
                onVisible: this.onVisible,
                defaultFitScreen: false
            }
        };

        _.keys(this.settings).forEach((key) => {

            if (key === 'modal') {
                _.assignIn(defaults[key], this.settings[key]);
            } else {
                defaults[key] = this.settings[key];
            }

        });

        return defaults;
    }

    public onShow() {
        this.hidden = false;
        this.$emit('onShow');
    }

    public onVisible() {
        this.$emit('onVisible');
        this.calcHeight();
    }

    public mounted() {
        this.$nextTick(() => {
            $(window).on("resize" as any, this.calcHeight);
        });
    }

    public updated() {
        this.calcHeight();
    }

    public calcHeight() {

        const windowHeight: any = $(window).height() ? ($(window).height() as any * 0.8) - 140 : 0;
        const modalHeight: any = $((this.$refs.modalContent as any)).prop('scrollHeight') + 60;

        if (windowHeight < modalHeight) {
            this.contentCSS = "scrolling content";
        } else {
            this.contentCSS = "scrolling content noscrolling";
        }

    }

    public destroyed() {
        $(window).off("resize" as any);
    }

    public onHide(element) {
        this.$emit('onHide', element);
    }

    public onHidden() {
        this.$emit('onHidden');
        this.hidden = true;
    }

    public onApprove(element) {
        this.$emit('onApprove', element);
        return false;
    }

    public onDeny(element) {
        this.$emit('onDeny', element);
        return true;
    }

    public show(options) {
        if (!this.modalObj) {
            this.init(options);
        }
        this.modalObj.modal('show');
    }

    public hide() {
        this.modalObj.modal('hide');
    }

    public modal(behavior, ...args) {
        this.modalObj.modal(behavior, args);
    }

    protected init(options) {
        let settings = this.elementSettings.modal;
        if (options) { settings = _.assignIn(settings, options); }
        // @ts-ignore
        this.modalObj = ($(this.$refs.modal) as any).modal(settings);
    }
}
