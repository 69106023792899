import * as _ from 'lodash';


Ext.override(Ext.grid.plugin.filterbar.filters.Date, {
    alias: ['grid.filterbar.date', 'grid.filterbar.datenull'],

    config: {
        operators: ['==', '!=', '>', '>=', '<', '<=', 'empty', 'nempty']

    },

    getSerializer() {
        return (data) => {
            let value = data.value;

            if (_.isString(value)) {
                const tmp = new Date(value);
                if (!isNaN(tmp.getFullYear())) {
                    value = tmp;
                }
            }

            if (value && value !== true) {
                const dateFormat = 'Y-m-d';
                data.value = Ext.Date.format(value, dateFormat);
            }
        };
    }

});
