import router from "@/app/router";
import authService from '@/framework/services/Authentication';
import moment from "moment";
import {arrayBufferToBase64, recursiveBase64StrToArrayBuffer} from "@/framework/util/Conversions";
import {Response} from "@/framework/services/Response";

const initialState = {status: {
        sessionExpired: false,
        loggedIn: false,
        loggingIn: false,
        profileUpdate: false,
        twoFactor: false
    }, user: null};

export const authentication = {
    namespaced: true,
    state: initialState,

    getters: {
        loggedIn: (state) => {
            return state.status.loggedIn;
        },

        user: (state) => {
            return state.user;
        },

        profileUpdate: (state) => {
            return state.status.profileUpdate;
        },

        webauthnAvailable: (state) => {
            return process.env.VUE_APP_EXTANDED_LOGIN === '1' && !!window.PublicKeyCredential;
        },

        twoFaAvailable: (state) => {
            return process.env.VUE_APP_EXTANDED_LOGIN === '2';
        }
    },

    actions: {
        start({state, commit, dispatch}) {
            return authService.start().then((response) => {

                if (!state.status.loggedIn && response && response.data && response.data.body) {
                    commit('loginSuccess', response.data.body);
                    dispatch('permissions/setUser', state.user, {root: true});
                    dispatch('permissions/getNavigation', null, {root: true});
                    dispatch('preferences/getPreferences', null, {root: true});
                    dispatch('messages/load', null, {root: true});
                    dispatch('messages/startWorker', null, {root: true});
                }

            }).catch((response) => {
                if ((state.user || state.status.loggedIn) && !state.status.sessionExpired) {
                    dispatch('unauthorized', null);
                } else {
                    dispatch('resetLogin', null);
                    if (router.currentRoute.meta?.requiresAuth !== undefined && router.currentRoute.meta?.requiresAuth) {
                        router.push('/');
                    }

                }
            });
        },

        clear({commit, dispatch}) {
            dispatch('permissions/setUser', null, {root: true});
            dispatch('messages/stopWorker', null, {root: true});
            dispatch('messages/reset', null, {root: true});
            dispatch('tasks/closeAll', null, {root: true});
            dispatch('tasks/removeAll', null, {root: true});
            dispatch('preferences/reset', null, {root: true});
            dispatch('filter/reset', null, {root: true});
        },


        resetLogin({commit, dispatch}) {
            dispatch('clear').then(() => {
                commit('resetLogin');
            });

        },

        authdata({dispatch, commit}) {

            return authService.authdata()
                .then(
                    (response) => {
                        if (response && response.data && response.data.body) {
                            commit('authdata', response.data.body);
                        }
                    },
                    (error) => {
                        throw error;
                    }
                );
        },

        sso() {
            authService.sso();
        },

        webauthn({dispatch, commit, state}, {route}) {

            return authService.webauthnGetArgs().then(
                (data: any) => {
                    const resp: any = Response.factory(data).dispatch();
                    const publicKeyCredentialRequestOptions: any = resp.data().publicKey;
                    recursiveBase64StrToArrayBuffer(publicKeyCredentialRequestOptions);

                    const abortController = new AbortController();

                    return navigator.credentials.get({
                        publicKey: publicKeyCredentialRequestOptions,
                        signal: abortController.signal,
                        mediation: 'conditional'
                    } as any).then((cred: any) => {
                        commit('loginRequest');
                        const authenticatorAttestationResponse = {
                            id: cred.rawId ? arrayBufferToBase64(cred.rawId) : null,
                            clientDataJSON: cred.response.clientDataJSON  ? arrayBufferToBase64(cred.response.clientDataJSON) : null,
                            authenticatorData: cred.response.authenticatorData ? arrayBufferToBase64(cred.response.authenticatorData) : null,
                            signature: cred.response.signature ? arrayBufferToBase64(cred.response.signature) : null,
                            userHandle: cred.response.userHandle ? arrayBufferToBase64(cred.response.userHandle) : null
                        };

                        return authService.webauthnLogin(authenticatorAttestationResponse).then(
                            (response) => {
                                if (response && response.data && response.data.body) {
                                    commit('loginSuccess', response.data.body);
                                    dispatch('permissions/setUser', state.user, {root: true});
                                    dispatch('permissions/getNavigation', null, {root: true});
                                    dispatch('preferences/getPreferences', null, {root: true});
                                    dispatch('messages/load', null, {root: true});
                                    dispatch('messages/startWorker', null, {root: true});

                                    if (route.query.redirect) {
                                        router.push(route.query.redirect);
                                    } else {
                                        router.push('/');
                                    }

                                } else {
                                    const error = 'Error in login';
                                    commit('loginFailure');
                                    throw error;
                                }
                            },
                            (error) => {
                                commit('loginFailure');
                                throw error;
                            }
                        );

                    })
                }
            );
        },


        login({dispatch, commit, state}, {username, password, route}) {

            commit('loginRequest');

            return authService.login(username, password)
            .then(
                (response) => {
                    if (response && response.data && response.data.body) {
                        commit('loginSuccess', response.data.body);
                        dispatch('permissions/setUser', state.user, {root: true});
                        dispatch('permissions/getNavigation', null, {root: true});
                        dispatch('preferences/getPreferences', null, {root: true});
                        dispatch('messages/load', null, {root: true});
                        dispatch('messages/startWorker', null, {root: true});

                        if (route && route.query.redirect) {
                            router.push(route.query.redirect);
                        } else {
                            router.push('/');
                        }

                    } else if(!state.status.twoFactor) {
                        const error = 'Error in login';
                        commit('loginFailure');
                        throw error;
                    }
                },
                (error) => {
                    commit('loginFailure');
                    throw error;
                }
            );
        },

        loginExpire({dispatch, commit, state}, {username, password}) {
            return authService.login(username, password)
                .then(
                    (response) => {
                        if (response && response.data && response.data.body) {
                            commit('loginSuccess', response.data.body);
                            dispatch('permissions/setUser', state.user, {root: true});
                            dispatch('permissions/getNavigation', null, {root: true});
                            dispatch('preferences/getPreferences', null, {root: true});
                            dispatch('messages/load', null, {root: true});
                            dispatch('messages/startWorker', null, {root: true});

                        } else if(!state.status.twoFactor) {
                            const error = 'Error in login';
                            throw error;
                        }
                    },
                    (error) => {
                        throw error;
                    }
                );
        },

        loginAs({dispatch, commit, state}, {user_id}) {
            return authService.loginAs(user_id)
                .then(
                    (response) => {
                        if (response && response.data && response.data.body) {

                            const p1 = commit('loginSuccess', response.data.body);
                            const p2 = dispatch('permissions/setUser', state.user, {root: true});
                            const p3 = dispatch('permissions/getNavigation', null, {root: true});
                            const p4 = dispatch('preferences/getPreferences', null, {root: true});
                            const p5 = dispatch('messages/load', null, {root: true});
                            const p6 = dispatch('messages/startWorker', null, {root: true});

                            return Promise.all([p1, p2, p3, p4, p5, p6]);

                        } else {
                            const error = 'Error in login';
                            throw error;
                        }
                    },
                    (error) => {
                        throw error;
                    }
                );
        },

        logout({commit, dispatch, state}) {
            commit('application/loading', true, {root: true});
            authService.logout().then(() => {
                dispatch('clear').then(() => {
                    commit('logout');
                    commit('application/loading', false, {root: true});
                });

                if (router.currentRoute.name != 'home') {
                    router.push({ name: 'home'});
                }

            }).catch(() => {
                commit('application/loading', false, {root: true});
            });

        },

        unauthorized({commit, dispatch, state}) {
            if (state.status.loggedIn) {
                dispatch('messages/stopWorker', null, {root: true});
                commit('sessionExpired');
            } else if (!state.sessionExpired) {

                if (router.currentRoute.meta?.requiresAuth === true) {
                    const path = router.currentRoute.fullPath;
                    router.push({ path: '/login', query: { redirect: path }});
                } else {
                    dispatch('resetLogin', null);
                }

            }

        },


        twofactor({commit, dispatch, state}, username) {
            return commit('startTwoFa', username);
        }

    },
    mutations: {

        sessionExpired(state: any) {
            state.status.sessionExpired = true;
        },
        resetLogin(state: any) {
            state.status = {loggedIn: false, loggingIn: false, sessionExpired: false, profileUpdate: false, twoFactor: false};
            state.user = null;
        },
        loginRequest(state: any) {
            state.status = {loggingIn: true, loggedIn: false, sessionExpired: false, profileUpdate: false, twoFactor: false};
            state.user = null;
        },
        loginSuccess(state: any, user: any) {

            state.status = {loggedIn: true, loggingIn: false, sessionExpired: false, twoFactor: false, profileUpdate: !user.profil_aktualisiert_am};
            state.user = user;

            if (state.user.zeitzone) { moment.tz.setDefault(state.user.zeitzone); }
        },
        loginFailure(state: any) {
            state.status = {loggedIn: false, loggingIn: false, sessionExpired: false, profileUpdate: false, twoFactor: false};
            state.user = null;
        },
        logout(state: any) {
            state.status = {loggedIn: false, loggingIn: false, sessionExpired: false, profileUpdate: false, twoFactor: false};
            state.user = null;
            localStorage.setItem('fwlogout', 'logout' + Math.random());
        },
        authdata(state: any, user: any) {
            state.user = user;
            state.status.profileUpdate = !user.profil_aktualisiert_am;
            if (state.user.zeitzone) { moment.tz.setDefault(state.user.zeitzone); }
        },

        startTwoFa(state: any, data: any) {
            state.status.twoFactor = data;
        }
    }
};
