const auditsysteme = [
    {
        path: '/administration/stammdaten/auditsysteme',
        name: 'Auditsysteme',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_auditsysteme" */ './Grid/Auditsysteme.vue')
    },
];

export default auditsysteme;

