import { Component, Prop, Vue } from 'vue-property-decorator';
import LieferantenSelect from "@/app/components/Form/Elements/LieferantenSelect.vue";
import ProjekteSelect from "@/app/components/Form/Elements/ProjekteSelect.vue";
import ProjekteASILSelect from "@/app/components/Form/Elements/ProjekteASILSelect.vue";
import WerkSelect from "@/app/components/Form/Elements/WerkSelect.vue";
import RegionSelect from "@/app/components/Form/Elements/RegionSelect.vue";
import BenutzerSelect from "@/app/components/Form/Elements/BenutzerSelect.vue";
import KundenSelect from "@/app/components/Form/Elements/KundenSelect.vue";
import SubProjekteSelect from "@/app/components/Form/Elements/SubProjekteSelect.vue";
import IconSelect from "@/app/components/Form/Elements/IconSelect.vue";
import TeileSelect from "@/app/components/Form/Elements/TeileSelect.vue";

Vue.component('LieferantenSelect', LieferantenSelect);
Vue.component('ProjekteSelect', ProjekteSelect);
Vue.component('BenutzerSelect', BenutzerSelect);
Vue.component('WerkSelect', WerkSelect);
Vue.component('RegionSelect', RegionSelect);
Vue.component('KundenSelect', KundenSelect);
Vue.component('ProjekteASILSelect', ProjekteASILSelect);
Vue.component('SubProjekteSelect', SubProjekteSelect);
Vue.component('IconSelect', IconSelect);
Vue.component('TeileSelect', TeileSelect);
