import moment from "moment";

Ext.override(Ext.util.Format, {

    date(value, format) {
        if (!value) {
            return "";
        }
        if (!Ext.isDate(value)) {
            value = moment(value).toDate();
        }
        return Ext.Date.dateFormat(value, format || Ext.Date.defaultFormat);
    }
});
