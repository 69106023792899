
const checklisten = [
    {
        path: '/administration/stammdaten/checklisten',
        name: 'Checklisten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Grid/Checklisten.vue')
    },
    {
        path: '/administration/stammdaten/checklisten/details/:checkliste_seq?',
        name: 'Checkliste',
        params: {
            copy: true
        },
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Details/Checkliste.vue')
    },
    {
        path: '/administration/stammdaten/checklisten/kopieren/:checkliste_seq/:copy',
        name: 'Checkliste Copy',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Details/Checkliste.vue')
    },
    {
        path: '/administration/stammdaten/checklistenpunkte/:checkliste_seq(\\d+)?/:bezeichnung?',
        name: 'Checklistenpunkte',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        props: true,
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Grid/Checklistenpunkte.vue')
    },
    {
        path: '/administration/stammdaten/checklistenpunkte/details/:checklistenpunkt_seq?',
        name: 'Checklistenpunkt',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Details/Checklistenpunkt.vue')
    },
    {
        path: '/administration/stammdaten/checklistenpunkte/new/:checkliste_seq?',
        name: 'Checklistenpunkt Neu',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Details/Checklistenpunkt.vue')
    },
    {
        path: '/administration/stammdaten/checklistenarten',
        name: 'Checklistenarten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Grid/Checklistenarten.vue')
    },
    {
        path: '/administration/stammdaten/checklistengruppen',
        name: 'Checklistengruppen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Grid/Checklistengruppen.vue')
    },
    {
        path: '/administration/stammdaten/checklistencodes',
        name: 'Checklistencodes',
        meta: {
            requiresAuth: true,
            resource: 'stammdaten',
        },
        component: () => import(/* webpackChunkName: "stammdaten_checklisten" */ './Grid/Checklistencodes.vue'),
    },
];

export default checklisten;
