import Framework from '@/framework/util/FW';

export const FW = Framework;

const VueFramework = {

    install(Vue, options) {
        Vue.prototype.$FW = FW;
    }
};

export default VueFramework;
