import i18n from "@/framework/locale";
import _ from "lodash";
import router from "@/app/router";
import {dirtySwal} from "@/framework/util/Swals";
import Unload from "@/framework/services/Unload";

Ext.define('FW.grid.mixins.MultiEdit', {
    extend: 'Ext.Mixin',

    mixinConfig: {
        before: {
            constructor: "beforeConstructor"
        },

        after: {
            constructor: "afterConstructor"
        }
    },

    beforeConstructor(cfg) {

        let controller = 'fwmultieditgrid';
        let ct: any = null;

        if (_.isString(this.config.controller)) {
            ct = Ext.create('controller.' + this.config.controller);
        } else if (this.config.controller.hasOwnProperty('xclass')) {
            ct = Ext.create(this.config.controller.xclass);
        }

        if (ct !== null && ct instanceof FW.grid.controller.MultiEdit) {
            controller = this.config.controller;
        }

        Ext.destroy(ct);
        ct = null;

        cfg = Ext.Object.merge(cfg, {plugins: this.plugins});

        if (cfg.plugins) {
            cfg.plugins['fwmultiedit'] = {};
        } else {
            cfg.plugins = {fwmultiedit: {}};
        }

        if (this.enableCellEdit) {
            cfg.plugins['cellediting'] = {
                clicksToEdit: 1
            };
        }

        Ext.apply(cfg, {
            controller,
        });

        Ext.Object.merge(this.config,
            {
                viewModel: {
                    data: {
                        cellEditDirty: false,
                        multiediting: {
                            enabled: false,
                            data: {}
                        }
                    },
                    formulas: {
                        mdmBtn: function(get) {
                            return !get('multiediting.enabled');
                        },

                        mdmDirty: {
                            bind: {
                                bindTo: '{multiediting.data}',
                                deep: true
                            },
                            get(value) {
                                if(_.isEmpty(value)) {
                                    return false;
                                }else{
                                    const filtered = _.filter(value, (v, k) => {
                                        if(_.isDate(v)) {
                                            return !_.isEmpty(v.toString());
                                        }

                                        if(_.isArray(v) || _.isObject(v)) {
                                            return !_.isEmpty(v);
                                        }

                                        return _.toString(v).length !== 0;
                                    });
                                    return filtered.length !== 0;
                                }
                            }
                        }
                    }
                }
            });
    },

    afterConstructor(cfg) {
        if (this.enableCellEdit) {
            this.on('beforeedit', (grid, newStore, oldStore) => {
                const vm = grid.getCmp().getViewModel();
                const enabled = vm.get('multiediting.enabled');

                if (enabled) {
                    return false;
                }

                vm.set('celleditdirty', true);
            }, this);

            this.on('storechange', (grid, newStore, oldStore) => {
                if (!newStore || newStore.isEmptyStore || newStore == oldStore) {
                    return;
                }

                newStore.on('datachanged', (store) => {
                    let dirty = false;

                    try {
                        _.forEach(store.getData().items, (item) => {
                            if (item.isDirty() || item.isPhantom()) {
                                dirty = true;
                                return false;
                            }
                        });
                    } catch (e) {
                        console.error(e);
                    } finally {
                        store.dirty = dirty;
                    }
                });
            }, this);

        }
    },

    buttons(cmp, cfg) {
        cfg.tbar.push({
            reference: "grid.buttons.multiedit",
            text: i18n.t('btn_mdv'),
            tooltip: i18n.t('btn_multiedit'),
            bind: {
                disabled: '{!saveBtn}',
                visible: '{saveBtn.visible}'
            },
            iconCls: 'x-fa fa-list-alt',
            cls: 'extjs button secondary',
            handler: 'onMultieditButtonClick',
            enableToggle: true,
            sort: 21
        });

        cfg.tbar.push({
            reference: "grid.buttons.cancel",
            tooltip: cmp.cancelBtnTooltip || cmp.cancelBtnText,
            text: cmp.cancelBtnText,
            bind: {
                visible: '{!mdmBtn && saveBtn.visible}'
            },
            cls: 'extjs button secondary',
            handler: 'onCancelButtonClick',
            sort: 23
        });

        cfg.tbar.push({
            reference: "grid.buttons.save",
            text: cmp.saveBtnText,
            tooltip: cmp.saveBtnTooltip || cmp.saveBtnText,
            bind: {
                disabled: '{!(mdmDirty)}',
                visible: '{!mdmBtn && saveBtn.visible}'
            },
            cls: 'extjs button secondary',
            handler: 'onSaveButtonClick',
            sort: 22
        });

        if (cmp.enableCellEdit) {
            cfg.tbar.push({
                reference: "grid.buttons.saveedit",
                text: cmp.saveBtnText,
                tooltip: cmp.saveBtnTooltip || cmp.saveBtnText,
                disabled: true,
                bind: {
                    disabled: '{!celleditdirty}',
                    visible: '{saveBtn.visible && !multiediting.enabled}'
                },
                cls: 'extjs button secondary',
                handler: 'onCellEditSaveButtonClick',
                sort: 24
            });

            cfg.tbar.push({
                reference: "grid.buttons.canceledit",
                text: cmp.cancelBtnText,
                tooltip: cmp.cancelBtnTooltip || cmp.cancelBtnText,
                bind: {
                    visible: '{saveBtn.visible && !multiediting.enabled}'
                },
                cls: 'extjs button secondary',
                handler: 'onCellEditCancelButtonClick',
                sort: 25
            });
        }
    }
});
