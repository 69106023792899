import {Vue} from 'vue-property-decorator';
import InputCheckbox from "@/framework/components/Form/Elements/Input/InputCheckbox.vue";
import InputRadiobox from "@/framework/components/Form/Elements/Input/InputRadiobox.vue";
import InputText from "@/framework/components/Form/Elements/Input/InputText.vue";
import InputEmail from "@/framework/components/Form/Elements/Input/InputEmail.vue";
import InputNumber from "@/framework/components/Form/Elements/Input/InputNumber.vue";
import InputPassword from "@/framework/components/Form/Elements/Input/InputPassword.vue";
import InputDate from "@/framework/components/Form/Elements/Input/InputDate.vue";
import InputSelect from "@/framework/components/Form/Elements/Select/InputSelect.vue";
import InputRadioGroup from "@/framework/components/Form/Elements/RadioGroup/InputRadioGroup.vue";
import InputTextarea from "@/framework/components/Form/Elements/Input/InputTextarea.vue";
import InputFile from "@/framework/components/Form/Elements/Input/InputFile.vue";
import InputMultiFile from "@/framework/components/Form/Elements/Input/InputMultiFile.vue";
import DetailLink from "@/framework/components/Form/Table/DetailLink.vue";
import Dateianlagen from "@/framework/components/Form/Table/Dateianlagen.vue";
import InputTree from "@/framework/components/Form/Elements/Tree/InputTree.vue";
import InputReadonly from "@/framework/components/Form/Elements/Input/InputReadonly.vue";
import Dropdown from "@/framework/components/Form/Elements/Dropdown/Dropdown.vue";
import InputLocalSearch from "@/framework/components/Form/Elements/Input/InputLocalSearch.vue";
import InputWysiwyg from "@/framework/components/Form/Elements/Wysiwyg/InputWysiwyg.vue";
import InputColor from "@/framework/components/Form/Elements/Input/InputColor.vue";
import Tabs from "@/framework/components/Form/Tab/Tabs.vue";
import InputList from "@/framework/components/Form/Elements/List/InputList.vue";
import InputMoney from "@/framework/components/Form/Elements/Input/InputMoney.vue";
import InputTime from "@/framework/components/Form/Elements/Input/InputTime.vue";

import LazyTab from "@/framework/components/Form/Tab/LazyTab.vue";
import LazyTabs from "@/framework/components/Form/Tab/LazyTabs.vue";
import LazyTabSegment from "@/framework/components/Form/Tab/LazyTabSegment.vue";

import VerticalTabs from "@/framework/components/Form/verticalTabs/VerticalTabs.vue";
import VerticalTab from "@/framework/components/Form/verticalTabs/VerticalTab.vue";
import VerticalTabSegment from "@/framework/components/Form/verticalTabs/VerticalTabSegment.vue";

Vue.component('InputCheckbox', InputCheckbox);
Vue.component('InputRadiobox', InputRadiobox);
Vue.component('InputText', InputText);
Vue.component('InputLocalSearch', InputLocalSearch);
Vue.component('InputReadonly', InputReadonly);
Vue.component('InputEmail', InputEmail);
Vue.component('InputNumber', InputNumber);
Vue.component('InputPassword', InputPassword);
Vue.component('InputDate', InputDate);
Vue.component('InputSelect', InputSelect);
Vue.component('InputRadioGroup', InputRadioGroup);
Vue.component('InputTextarea', InputTextarea);
Vue.component('InputFile', InputFile);
Vue.component('InputMultiFile', InputMultiFile);
Vue.component('InputWysiwyg', InputWysiwyg);
Vue.component('InputColor', InputColor);
Vue.component('DetailLink', DetailLink);
Vue.component('Dateianlagen', Dateianlagen);
Vue.component('InputTree', InputTree);
Vue.component('Dropdown', Dropdown);
Vue.component('Tabs', Tabs);
Vue.component('LazyTab', LazyTab);
Vue.component('LazyTabs', LazyTabs);
Vue.component('LazyTabSegment', LazyTabSegment);
Vue.component('InputList', InputList);
Vue.component('InputMoney', InputMoney);
Vue.component('InputTime', InputTime);
Vue.component('VerticalTab', VerticalTab);
Vue.component('VerticalTabs', VerticalTabs);
Vue.component('VerticalTabSegment', VerticalTabSegment);
