export class NavigationEntries  {
    public auswertungen: boolean = false;
    public cockpit: boolean = false;
    public lb: boolean = false;
    public lb_stammdaten: boolean = false;
    public lb_jahr_uebersicht: boolean = false;
    public lb_jahr_freigabe: boolean = false;
    public reklamationen: boolean = false;
    public problemloesung: boolean = false;
    public zertifikate: boolean = false;
    public zertifikate_freigabe: boolean = false;
    public benutzer: boolean = false;
    public registrierung: boolean = false;
    public stammdaten: boolean = false;
    public administration: boolean = false;
    public kundenreklamationen: boolean = false;
}
