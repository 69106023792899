import StoreWatcher from "@/framework/extjs/watcher/StoreWatcher";
import * as _ from "lodash";

export default class FilterWatcher extends StoreWatcher {

    public filter: any = undefined;

    public init(view: any): Promise<boolean> {

        if (_.isFunction(this.filter)) {
            this.filter = this.filter();
        }

        return Promise.resolve(true);
    }

    public onWatch(view: any, value: any) {
        const store = view.getStore();

        if (this.filter.property) {
            const extFilter = new Ext.util.Filter(this.filter);
            extFilter.setId('filterwatcher_' + this.filter.property);
            extFilter.setValue(value);
            store.filter(extFilter);
        } else {
            store.reload();
        }
    }
}
