
const lims = [
    {
        path: '/administration/stammdaten/ausfuehrendestellen',
        name: 'LimsAusfuehrendeStellen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import('./Grid/Ausfuehrendestellen.vue')
    },

    {
        path: '/administration/stammdaten/pruefnormen',
        name: 'LimsPruefnormen',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import('./Grid/Pruefnormen.vue')
    },

    {
        path: '/administration/stammdaten/ksus',
        name: 'LimsKsus',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import('./Grid/Ksus.vue')
    },

    {
        path: '/administration/stammdaten/dienstleister',
        name: 'LimsDienstleister',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import('./Grid/Dienstleister.vue')
    },
];

export default lims;
