
const downloads = [
    {
        path: '/administration/downloads',
        name: 'DownloadGrid',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "downloads" */ './Grid/Downloads.vue')
    },
    {
        path: '/administration/downloads/details/:download_seq?',
        name: 'Download',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "downloads" */ './Details/Download.vue')
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: () => import(/* webpackChunkName: "homedownloads" */ './Frontend/Downloads.vue'),
        meta: {
            requiresAuth: true
        }
    },
];

export default downloads;
