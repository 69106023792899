
const sap = [
    {
        path: '/sap/servicelogs',
        name: 'servicelogs',
        meta: {
            requiresAuth: true,
            resource: "sap"
        },
        component: () => import(/* webpackChunkName: "sap" */ './ServiceLogs/Grid/ServiceLogs.vue')
    },
    {
        path: '/sap/servicelogs/details/:log_id?',
        name: 'servicelogsdetail',
        meta: {
            requiresAuth: true,
            resource: "sap"
        },
        component: () => import(/* webpackChunkName: "sap" */ './ServiceLogs/Details/ServiceLogs.vue')
    },

];

export default sap;
