
Ext.define('FW.grid.column.TextActionColumn', {
    extend: 'Ext.grid.column.Action',
    alias: ['widget.textactioncolumn'],
    alternateClassName: 'FW.grid.TextActionColumn',
    iconCls: 'fw-textaction-column',
    filterType: 'string',

    renderer(value: any, metaData: any, rec: any) {
        return value;
    },

    defaultRenderer (v, cellValues, record, rowIdx, colIdx, store, view) {
        const me:any = this;
        let scope:any = me.origScope || me;
        let items:any = me.items;
        let len:any = items.length;
        let i:any;
        let item: any;
        let ret: any;
        let disabled: any;
        let tooltip: any;
        let altText: any;
        let tabIndex: any;



        // Allow a configured renderer to create initial value (And set the other values
        // in the "metadata" argument!)
        // Assign a new variable here, since if we modify "v" it will also modify the arguments
        // collection, meaning we will pass an incorrect value to getClass/getTip
        ret = Ext.isFunction(me.origRenderer) ? me.origRenderer.apply(scope, arguments) || '' : '';

        // wenn kein Wert vom Renderer zurück kommt dann geben wir auch keinen aus.
        if (!ret) {
            return;
        }

        cellValues.tdCls += ' ' + Ext.baseCSSPrefix + 'action-col-cell';

        /* eslint-disable max-len */
        for (i = 0; i < len; i++) {
            item = items[i];

            disabled = item.disabled || (item.isActionDisabled ? Ext.callback(item.isActionDisabled, item.scope || me.origScope, [view, rowIdx, colIdx, item, record], 0, me) : false);
            tooltip = item.tooltip || (item.getTip ? Ext.callback(item.getTip, item.scope || me.origScope, arguments, 0, me) : null);
            altText = item.getAltText ? Ext.callback(item.getAltText, item.scope || me.origScope, arguments, 0, me) : item.altText || me.altText;

            // Only process the item action setup once.
            if (!item.hasActionConfiguration) {
                // Apply our documented default to all items
                item.stopSelection = me.stopSelection;
                item.disable = Ext.Function.bind(me.disableAction, me, [i], 0);
                item.enable = Ext.Function.bind(me.enableAction, me, [i], 0);
                item.hasActionConfiguration = true;
            }


            // Pull in tabIndex and ariarRols from item, unless the item is this, in which case
            // that would be wrong, and the icon would get column header values.
            tabIndex = (item !== me && item.tabIndex !== undefined) ? item.tabIndex : me.itemTabIndex;

            ret = '<div' +
                (typeof tabIndex === 'number' ? ' tabIndex="' + tabIndex + '"' : '') +
                ' class="' + me.actionIconCls + ' ' + Ext.baseCSSPrefix + 'action-col-' + String(i) + ' ' +
                (disabled ? me.disabledCls + ' ' : ' ') + (item.iconCls || me.iconCls || '') + ' ' +
                (item.hidden ? Ext.baseCSSPrefix + 'hidden-display ' : '') +
                (item.getClass ? Ext.callback(item.getClass, item.scope || me.origScope, arguments, undefined, me) : '') + '"' +

                '>' + ret + '</div>';
        }
        /* eslint-enable max-len */

        return ret;
    },
});
