declare var FW: any;
Ext.define('FW.widget.form.ResourceFilter', {
    extend: 'Cal.widget.form.ResourceFilter',

    alias: [
        'widget.fw_calendarresourcefilter',
        'widget.fw_calendar_resourcefilter'
    ],

    filterEvents: function(cmp, selectedResourceIds) {
        const controller = this.lookupController();
        controller.onFilterResources(cmp, selectedResourceIds);
    }
})
