
import {Component, Prop, Watch} from 'vue-property-decorator';
import InputText from "@/framework/components/Form/Elements/Input/InputText.vue";
import moment from "moment";
import _ from "lodash";
import {localDate} from "@/framework/plugins/Localization";
import {mask} from 'vue-the-mask';


@Component({
    directives: {mask}
})
export default class InputDate extends InputText {

    @Prop() public datetime: boolean;
    @Prop({default: 1}) public firstDayOfWeek: number;
    @Prop({default: 'ui right action left icon input'}) declare public extra: string;
    @Prop({default: true}) declare public clearable: boolean;

    public hidden: boolean = true;
    public text: any = {};
    public date: any = localDate.moment();
    public mode: string = 'day';
    public forceOpen: boolean = false;
    public inputValue: any = '';
    public fromInput: boolean = false;
    public doBlur: boolean = true;

    get isEmpty(): boolean {
        return _.isNull(this.value) || _.isUndefined(this.value)
    }

    get inputMask(): string {
        return this.mask ? this.mask : localDate.inputMask(this.datetime);
    }

    get extraCss(): string {
        if (this.hasExtraLabel) {
            return this.extra;
        }

        return "ui input left icon";
    }

    get today(): any {
        return localDate.moment();
    }

    get modeDay(): boolean {
        return this.mode === 'day';
    }

    get modeMonth(): boolean {
        return this.mode === 'month';
    }

    get modeTime(): boolean {
        return this.mode === 'time';
    }

    get modeMinutes(): boolean {
        return this.mode === 'minutes';
    }

    get day(): string {
        return localDate.toMomentTz(this.date).format('LL');
    }

    get month(): string {
        return this.date ? this.text.months[this.date.month()] : '';
    }

    get year(): string {
        return this.date ? this.date.year() : '';
    }

    get days(): any[] {
        return _.concat(_.drop(this.text.days, this.firstDayOfWeek), _.dropRight(this.text.days, 7 - this.firstDayOfWeek));
    }

    get weeks(): any[] {
        let tage: any[] = [];

        const firstDay = localDate.moment().year(this.date.year()).month(this.date.month()).date(1);
        const firstDayText = this.text.days[firstDay.day()];
        const firstDayindex = _.indexOf(this.days, firstDayText);

        for (let i = firstDayindex; i > 0; i--) {
            const d = localDate.moment().year(firstDay.year()).month(firstDay.month()).date(firstDay.date() -i);

            tage.push({
                date: d,
                text: d.date(),
                class: 'link adjacent disabled'
            });
        }

        let index = 1;
        let anzahlTageErsteWoche = 7 - firstDayindex;

        const now: any = localDate.moment();

        while ( localDate.moment().year(this.date.year()).month(this.date.month()).date(index).month() === this.date.month()) {

            for (let i = 0; i < anzahlTageErsteWoche; i++) {
                let css = 'link';
                let d: any = null;

                if (this.isDateTime) {

                    d = localDate.moment().year(this.date.year()).month(this.date.month()).date(index).hours(this.date.hours()).minutes(this.date.minutes());

                } else {
                    d = localDate.moment().year(this.date.year()).month(this.date.month()).date(index);
                }

                const sameMonth = d.month() === this.date.month();

                if (d.year() === now.year() && d.month() === now.month() && d.date() === now.date()) {
                    css = 'link today';
                }

                if (d.year() === this.date.year() && d.month() === this.date.month() && d.date() === this.date.date()) {
                    css += ' active';
                }

                tage.push({
                    date: d,
                    text: d.date(),
                    disabled: !sameMonth,
                    class: sameMonth ? css : 'link adjacent disabled'
                });

                index++;
            }
            anzahlTageErsteWoche = 7;
        }

        const retval: any[] = [];

        while (tage.length) {
            const obj = {
                days: tage.slice(0, 7)
            };
            tage = _.drop(tage, 7);
            retval.push(obj);
        }


        return retval;
    }

    get months(): any[] {
        const retval: any[] = [];

        let month = 0;

        const now = localDate.moment();

        for (let q = 0; q < 4; q++) {

            const obj: any = {
                month: []
            };

            for (let m = 0; m < 3; m++) {
                let css = 'link';


                const d = localDate.moment().year(this.date.year()).month(month).date(1);

                if (d.year() === now.year() && d.month() === now.month()) {
                    css = 'link today';
                }

                obj.month.push({
                    date: d,
                    text: d.format('MMM'),
                    class: css
                });


                month++;

            }

            retval.push(obj);
        }

        return retval;
    }

    get hours(): any[] {

        const retval: any[] = [];
        let hour = 0;

        for (let i = 0; i < 6; i++) {
            const obj: any = {
                hour: []
            };
            for (let h = 0; h < 4; h++) {
                let css = 'link';

                const d = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date()).hours(hour).minutes(0).seconds(0);

                if (d.hours() === this.date.hours()) {
                    css = 'link active';
                }

                obj.hour.push({
                    date: d,
                    text: d.format('LT'),
                    class: css
                });

                hour++;

            }
            retval.push(obj);
        }

        return retval;
    }

    get minutes(): any[] {
        const retval: any[] = [];
        let minutes = 0;

        for (let i = 0; i < 4; i++) {
            const obj: any = {
                minute: []
            };
            for (let h = 0; h < 3; h++) {
                let css = 'link';

                const d = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date()).hours(this.date.hours()).minutes(minutes).seconds(0);

                if (d.minutes() === this.date.minutes()) {
                    css = 'link active';
                }

                obj.minute.push({
                    date: d,
                    text: d.format('LT'),
                    class: css
                });

                minutes += 5;

            }
            retval.push(obj);
        }

        return retval;
    }

    protected get isDateTime(): boolean {
        return (this.datetime !== undefined && this.datetime);
    }

    public displayValue(date) {

        if (!date) {
            this.inputValue = "";
            return;
        }

        this.inputValue = this.isDateTime ? localDate.toDateTime(date) : localDate.toDate(date);

    }

    public created() {
        this.text = {
            days: moment.weekdaysMin(),
            months: moment.months(),
            monthsShort: moment.monthsShort(),
            today: this.$t('heute'),
            now: this.$t('jetzt'),
            am: 'AM',
            pm: 'PM'
        };
    }

    public mounted() {

    }

    public clickOutside($event) {
        if (!this.$el.contains($event.target) && !this.forceOpen) {
            this.close();
        } else {
            this.setFocus(true);
        }

        this.forceOpen = false;
    }

    public destroyed() {
        document.body.removeEventListener('click', this.clickOutside);
    }

    public open() {
        if ((this as any).isReadonly) {
            return;
        }

        if (this.hidden) {
            this.hidden = false;
            document.body.addEventListener('click', this.clickOutside);
        }

        setTimeout(() => {
            this.setFocus(true);
        }, 0);

    }

    public onFocus() {
        if ((this as any).isReadonly) {
            return;
        }

        if (document.activeElement == this.$refs.input && !this.hidden) {
            return;
        }

        if (this.hidden) {
            this.hidden = false;
            document.body.addEventListener('click', this.clickOutside);
        }
    }

    public close() {
        this.reset();
        this.hidden = true;
        this.doBlur = false;
        (this.$refs.input as any).blur();
        document.body.removeEventListener('click', this.clickOutside);
    }

    public prevDay() {
        this.date = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date() - 1).hours(this.date.hours()).minutes(this.date.minutes());


    }

    public nextDay() {
        this.date = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date() + 1).hours(this.date.hours()).minutes(this.date.minutes());
    }


    public prevMonth() {
        this.date = localDate.moment().year(this.date.year()).month(this.date.month() -1).date(1);
    }

    public nextMonth() {
        this.date = localDate.moment().year(this.date.year()).month(this.date.month() +1).date(1);
    }

    public prevYear() {
        this.date = localDate.moment().year(this.date.year() - 1).month(this.date.month()).date(1);
    }

    public nextYear() {
        this.date = localDate.moment().year(this.date.year() + 1).month(this.date.month()).date(1);
    }

    public setFocus(focus?: boolean) {
        if (focus && this.$refs.input) {
            (this.$refs.input as any).focus();
        }
    }

    public clickDate(date, disabled) {

        if ((this as any).isReadonly) {
            this.close();
            return;
        }

        if (disabled) {
            this.forceOpen = true;
            return;
        }

        this.fromInput = false;
        const retval: any = this.toDbDate(date);
        this.displayValue(date);

        if (!this.isDateTime) {
            this.$emit('input', retval);
            this.close();
            return;
        }
        this.date = date;
        this.forceOpen = true;
        this.switchTime();
    }

    public clickHour(date) {
        this.fromInput = false;
        const retval: any = this.toDbDate(date);
        this.displayValue(date);
        this.date = date;
        this.forceOpen = true;
        this.switchMinutes();
    }

    public clickMinute(date) {

        this.fromInput = false;
        const retval: any = this.toDbDate(date);
        this.displayValue(date);
        this.$emit('input', retval);
        this.close();
    }

    public switchMonth() {
        this.mode = 'month';
    }

    public switchDay() {
        this.mode = 'day';
    }

    public switchTime() {
        this.mode = 'time';
    }

    public switchMinutes() {
        this.mode = 'minutes';
    }

    public clickMonth(date) {
        this.forceOpen = true;
        this.date = date;
        this.switchDay();
    }

    public reset() {
        this.switchDay();
    }


    public inputDate() {

        if ((this as any).isReadonly) {
            return;
        }

        this.fromInput = true;
    }

    public tabInput() {
        this.hidden = true;
        this.onBlur();
    }

    public parseDate(text): any {
        if(this.isDateTime && moment.utc(text, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
            return localDate.toMomentTz(moment.utc(text, 'YYYY-MM-DD HH:mm:ss', true));
        }
        if(moment(text, 'YYYY-MM-DD',true).isValid()) {
            return moment(text, 'YYYY-MM-DD', true);
        }
        if (this.isDateTime && moment(text, 'L LT').isValid()) {
            return moment(text, 'L LT');
        }
        if (moment(text, 'L').isValid()) {
            return moment(text, 'L');
        }
        if (this.isDateTime && moment(text).isValid()) {
            return moment.utc(text);
        }
        if (moment(text).isValid()) {
            return moment(text);
        }
        return null;
    }

    public parsefromInput(text): any {

        if (this.isDateTime && moment(text, 'L LT').isValid()) {
            return moment(text, 'L LT');
        }
        if (moment(text, 'L').isValid()) {
            return moment(text, 'L');
        }

        return null;
    }

    @Watch("value", {immediate: true})
    public valueChange(newValue, oldValue) {
        if (newValue === oldValue) {
            return false;
        }

        if (!newValue) {
            this.date = localDate.moment();
            this.date.minutes(5 * _.floor(this.date.minutes() / 5));
            this.inputValue = "";
        } else {
            this.date = this.parseDate(newValue);

            if (this.fromInput) {
                this.fromInput = false;

            }
            this.displayValue(this.date);
        }
    }

    @Watch("datetime")
    public datetimechange(newValue, oldValue) {

        if (newValue === oldValue) {
            return false;
        }

        if (!newValue && oldValue) {

            this.date = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date());

        } else {
            const n: any = localDate.moment();
            this.date = localDate.moment().year(this.date.year()).month(this.date.month()).date(this.date.date()).hours(n.hours()).minutes(5 * _.floor(n.minutes() / 5));
        }

        this.displayValue(this.date);
        const retval: any = this.toDbDate(this.date);
        this.$emit('input', retval);
    }

    protected onBlur() {
        if (this.fromInput) {

            const text = (this.$refs.input as any).value;
            this.date = this.parsefromInput(text);

            const retval: any = this.toDbDate(this.date);
            this.inputValue = text;
            this.$emit('input', retval);

            this.date = this.date ? this.date : localDate.moment();
        }

        this.displayValue(this.value);
    }

    protected toDbDate(data): string | null {
        if (!data) {
            return null;
        }

        if (this.isDateTime) {
            return localDate.toMomentUTC(data).format("YYYY-MM-DD HH:mm:00");
        } else {
            return data.format("YYYY-MM-DD");
        }
    }


}
