import store from '@/app/store';
import Task from '@/framework/components/Taskbar/Task';
import WindowManager from '@/framework/util/WindowManager';
import { SelbstauskuenfteGridConfig } from '@/app/pages/Selbstauskuenfte/Grid/Config';
import i18n from '@/framework/locale';

export default function SelbstauskunftOpenDetail(to, from, next) {
    next(to.matched[0].path);
    if (to.params.auskunft_seq) {
        store.dispatch('tasks/open', new Task({
            taskId: WindowManager.generateID(SelbstauskuenfteGridConfig.id, to.params.auskunft_seq),
            title: i18n.t(SelbstauskuenfteGridConfig.title),
            url: '/selbstauskuenfte/detail/' + to.params.auskunft_seq,
        }));
    }
}
