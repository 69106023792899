
import {Component, Prop} from 'vue-property-decorator';
import InputText from "@/framework/components/Form/Elements/Input/InputText.vue";
import _ from 'lodash';
import {mask} from 'vue-the-mask';
import masker from 'vue-the-mask/src/masker'; // bad practice ? but vue-the-mask doesn't export masker anymore
import tokens from 'vue-the-mask/src/tokens';

@Component({
    directives: {mask}
})
export default class InputNumber extends InputText {
    @Prop() public min: number;
    @Prop() public max: number;
    protected type: string = "number";

    public cancelEvent(e: any) {
        e.preventDefault();
    }

    public updateValue() {
        let value: any = (this as any).$refs.input.value;

        value = value === "" ? null : value;

        if (value !== undefined && value !== null) {
            value = parseFloat(value);
        }

        if (value && !_.isNumber(value)) {
            value = null;
            this.validationObj.$error = this.$t('validation_type').toString();
            this.validationObj.$error = this.validationObj.$error.replace('{rule}', this.$t('nummer'));
        } else {
            this.validationObj.$error = null;
        }

        (this as any).$emit('input', value);
    }
}
