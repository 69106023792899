import * as _ from "lodash";
import {base} from "@/framework/services/Api";

Ext.define('FW.grid.column.FileColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.filecolumn'],
    alternateClassName: 'FW.grid.FileColumn',
    filter: false,
    sortable: false,
    producesHTML: true,


    defaultRenderer(value) {
        if (!value || !value.url) { return ""; }

        const compiled: any = _.template('<a href="<%= url %>" target="_blank"><%= name %></a>');

        value.url = base + value.url;

        return compiled(value);
    }
});