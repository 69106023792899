import {staticValues} from "@/framework/plugins/StaticValues";

Ext.define('FW.grid.column.StatusColumn', {
    extend: 'Ext.grid.column.Column',
    alias: ['widget.statuscolumn'],
    alternateClassName: 'FW.grid.StatusColumn',
    defaultFilterType: 'status',
    producesHTML: false,

    renderer(value, metaData, rec) {
        metaData.tdCls = staticValues.statusCss(value);
        return staticValues.status(value);
    }
});
