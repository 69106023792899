
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({
    })
    export default class DetailLink extends Vue {

        public click($event) {
            this.$emit('click', $event);
        }

    }
