
    import Component from "vue-class-component";
    import apiMixin from "@/framework/mixins/Api";
    import Vue from "vue";
    import {Response} from "@/framework/services/Response";
    import {ResponseInterface} from "../../services/Response";

    @Component({
        mixins: [apiMixin]

    })
    export default class Widget extends Vue {
        get apiParams(): object {
            return {};
        }

        public data: any = {};
        public loading: boolean = false;

        protected load() {
            this.loading = true;
            this.$http.post(this.$api.load, this.apiParams).then((data) => {
                (this as any).handleResponse(Response.factory(data).dispatch());
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
        }
    }
