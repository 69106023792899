import store from "@/app/store";
import i18n from "@/framework/locale";


export default function initExtLocale() {

    return new Promise( (resolve, reject) => {
        const locale = (store.state as any).hasOwnProperty('preferences') ? (store.state as any).preferences.settings.locale : i18n.locale;

        if (locale === 'de') {

            const tranlations = ['assets/ext/classic/locale/locale-de.js', 'assets/ext-locale/locale-de.js'];
            if (Ext.ClassManager.get('Cal.panel.Calendar') !== undefined) {
                tranlations.push('assets/ext/calendar/locale/base-de.js', 'assets/ext/calendar/locale/de.js');
            }
            Ext.Loader.loadScript({url: tranlations, onLoad: () => {
                    resolve(true);
                }, onError: () => {
                    resolve(false);
                }});
        } else if (locale === 'zh') {
            Ext.Loader.loadScript({url: ['assets/ext/classic/locale/locale-zh_CN.js'], onLoad: () => {
                    resolve(true);
                }, onError: () => {
                    resolve(false);
                }});
        } else if (locale === 'sl') {
            Ext.Loader.loadScript({url: ['assets/ext/classic/locale/locale-sl.js'], onLoad: () => {
                    resolve(true);
                }, onError: () => {
                    resolve(false);
                }});
        } else {
            resolve(true);
        }
    });


}
