
const users = [
    {
        path: '/administration/users',
        name: 'Users',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "users" */ './Grid/Users.vue')
    },
    {
        path: '/administration/users/filter',
        name: 'Users Filter',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "users" */ './Grid/Filters/Users.vue')
    },
    {
        path: '/administration/users/detail/:user_id?',
        name: 'User',
        meta: {
            requiresAuth: true,
            resource: "benutzer"
        },
        component: () => import(/* webpackChunkName: "usersdetail" */ './Details/User.vue')
    }

];

export default users;