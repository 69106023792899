import swal from "sweetalert2";
import messagesService from "@/framework/services/Messages";
import _ from "lodash";
import {MessageInterface} from "@/framework//components/Messages/Message/MessageInterface";
import {workers} from "@/framework/services/Workers";
import {Response} from "@/framework/services/Response";

const initialState = {
    messages: [],
    unread: 0,
    sidebar: false
};

export const messages = {
    namespaced: true,
    state: initialState,
    actions: {
        startWorker({dispatch, commit}) {
            workers.messages = setInterval(() => {
                // console.info('message worker load')
                dispatch('load');
            }, ((process.env.VUE_APP_WORKER_TIMEOUT! as any) * 1000 * 60));

        },

        stopWorker() {
            // console.info('stopWorker')
            clearInterval(workers.messages);
            workers.messages = null;
        },

        load({dispatch, commit}) {
            return messagesService.load().then(
                (response) => {
                    const resp = Response.factory(response).dispatch();
                    commit('messageChange', resp.data());

                },
                (error) => {
                    return [];
                }
            );
        },

        read({dispatch, commit, state}, message) {

            const index: any = _.findIndex(state.messages, {object_id: message.object_id, object_name: message.object_name});

            if (index > -1) {
                commit('messageRead', message);
            }
        },

        remove({dispatch, commit}, message) {
            return messagesService.remove(message).then(
                (response) => {
                    commit('messageRemove', message);
                },
                (error) => {
                    return [];
                }
            );
        },

        error({dispatch, commit}, {msg}) {
            swal('error', msg);
        },

        toggleSidebar({dispatch, commit}, visible) {
            commit('toggleSidebar', visible);
        },

        reset({commit}) {
            commit('reset');
        }



    },
    mutations: {
        messageChange(state: any, changedMessages: MessageInterface[]) {
            state.messages = changedMessages;
            state.unread = _.filter(changedMessages, ['read', 'n']).length;
        },

        messageRead(state: any) {
            state.unread = state.unread - 1 >= 0 ? state.unread - 1 : 0;
        },

        messageRemove(state: any, message: MessageInterface) {
            state.unread = 0;
            state.messages = _.remove(state.messages, (msg: MessageInterface) => {
                return msg.message_id !== message.message_id;
            });
        },

        toggleSidebar(state: any, visible: boolean) {
            state.sidebar = visible;
        },

        reset(state: any) {
            state = {
                messages: [],
                unread: 0,
                sidebar: false
            };
        }


    }
};
