import {localDate} from "@/framework/plugins/Localization";

Ext.define('FW.grid.column.Date', {
    override: "Ext.grid.column.Date",
    config: {
        filterType: 'daterange',
    },
    minWidth: 210,

    defaultRenderer: function(value) {
        if (!value) return '';
        return localDate.toDate(value);
    },

});
