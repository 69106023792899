import {ResponseInterface} from "@/framework/services/Response";

export default {
    api: {
        load: "",
        save: "",
        copy: "",
        destroy: "",
        haschanged: "",
        import: "",
        validator: null
    },

    beforeCreate() {
        // @ts-ignore
        this.$api = this.$options.api;
    },

    methods: {
        handleResponse(response: ResponseInterface) {
            // @ts-ignore
            this.data = response.body || {};
        }
    }


};
