const auditarten = [
    {
        path: '/administration/stammdaten/auditarten',
        name: 'Auditarten',
        meta: {
            requiresAuth: true,
            resource: "stammdaten"
        },
        component: () => import(/* webpackChunkName: "stammdaten_auditarten" */ './Grid/Auditarten.vue')
    },
];

export default auditarten;

