const reminders = [
    {
        path: '/administration/reminders',
        name: 'Reminders',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "reminders" */ './Grid/Reminders.vue'),
    },
    {
        path: '/administration/reminders/details/:reminder_id?',
        name: 'Reminder',
        meta: {
            requiresAuth: true,
            resource: "logbuch"
        },
        component: () => import(/* webpackChunkName: "reminder" */ './Details/Reminder.vue')
    },
];

export default reminders;
