
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {base} from "../../services/Api";
    import * as _ from "lodash";

    @Component({
        mixins: [],
        model: {
            prop: 'url',
            event: 'change'
        },

        mounted() {
            window.addEventListener('message', (this as any).messageListener);
            $((this.$refs.iframe as any)).on('load', () => {
                $((this.$refs.iframe as any).contentWindow).on('beforeunload', (this as any).onIframeUnload);
            });
        },

        beforeDestroy() {
            window.removeEventListener('message', (this as any).messageListener);
        },

        watch: {
            $route: 'urlChange',
            url: 'urlChange'
        }
    })
    export default class IFrame extends Vue {
        get iframeUrl(): string {

            // TODO: axios 0.19.0 getURI verwenden!!!
            let url = base + (base.endsWith('/') ? "" : "/") + decodeURIComponent(this.url || (this.$route.params as any).url);
            if (this.$route.query) {
                // Wir haben eine Query miterhalten, diese durchreichen
                _.each(this.$route.query, (v, k) => {
                    url = this.addParam(url, k, v);
                });
            }

            return url;
        }

        @Prop({default: ""}) public url: string;
        protected isLoading: boolean = true;
        protected currentIframeId: number = 0;

        public urlChange() {
            this.isLoading = true;
        }

        protected messageListener(event) {
            if ((event as any).data === 'beforeunload-' + this.currentIframeId) {
                this.urlChange();
            }
        }

        protected onIframeLoad() {
            try {
                this.currentIframeId = Math.floor(Math.random() * 1000000);
                (this.$refs.iframe as any).contentWindow.postMessage(['beforeunloadhook', this.currentIframeId], '*');
            } catch (error) {
                console.log(error);
            }
            this.isLoading = false;
        }

        protected onIframeUnload() {
            this.isLoading = true;
        }

        protected addParam(url, param, value) {
            const a: any = document.createElement('a');
            const regex: any = /(?:\?|&amp;|&)+([^=]+)(?:=([^&]*))*/g;
            const str: any[] = [];
            a.href = url;
            param = encodeURIComponent(param);
            let match = regex.exec(a.search);
            while (match) {
                if (param !== match[1]) {
                    str.push(match[1] + (match[2] ? "=" + match[2] : ""));
                }
                match = regex.exec(a.search);
            }
            str.push(param + (value ? "=" + encodeURIComponent(value) : ""));
            a.search = str.join("&");
            return a.href;
        }
    }
