import {Component, Vue} from 'vue-property-decorator';
// @ts-ignore
import Navigation from '@/app/navigation/Navigation.vue';
// @ts-ignore
import Taskbar from '@/framework/components/Taskbar/Taskbar.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import swal from "sweetalert2";
import {confirmSwal} from "@/framework/util/Swals";

@Component({

    computed: {
        ...mapState('application', {version: 'version', splashScreen: 'loading', maintenance: 'maintenance', lastRoute: 'lastRoute'}),
        ...mapState('authentication', ['status', 'user']),
        ...mapState('preferences', ['settings']),
        ...mapState('permissions', ['admin', 'supplier', 'navigation']),
        ...mapState('tasks', ['tasks']),
        ...mapState('messages', ['sidebar']),
        ...mapGetters('preferences', ['getLocale', 'getTimezone']),
    },

    methods: {
        ...mapActions('messages', ['startWorker', 'stopWorker', 'load', 'toggleSidebar']),
        ...mapActions('application', {versionStart: 'startWorker', versionStop: 'stopWorker', rerollVersion: 'rerollVersion'}),
        ...mapActions('authentication', ['start', 'resetLogin', 'loginExpire', 'login']),
        ...mapMutations('application', ['loginFailure']),
        ...mapMutations('application', {loading: 'loading', initApplication: 'initialized'})
    },

    components: {
        Navigation,
        Taskbar
    },

    watch: {
        'status.sessionExpired': 'sessionExpired',
        'status.twoFactor': 'twoFactor',
        'settings.locale': 'onLocalChange',
        'settings.zeitzone': 'onTimezoneChange',
        'version': 'onVersionChange',
        'maintenance': 'onMaintenanceChange',
    }
})
export default class App extends Vue {


    public sidebar: any;

    /* Mapped Functions */
    protected version: any;
    protected start: any;
    protected load: any;
    protected startWorker: any;
    protected stopWorker: any;
    protected versionStart: any;
    protected versionStop: any;
    protected resetLogin: any;
    protected status: any;
    protected toggleSidebar: any;
    protected loginExpire: any;
    protected user: any;
    protected settings: any;
    protected getLocale: any;
    protected getTimezone: any;
    protected rerollVersion: any;
    protected rerollVersionDone: boolean = false;
    protected loading: any;
    protected initApplication: any;
    protected lastRoute: any;
    protected login: any;
    protected loginFailure: any;


    public mounted() {
        this.loading(true);
        const locale = this.getLocale;
        const timezone = this.getTimezone;
        this.onLocalChange(locale);
        this.onTimezoneChange(timezone);

        this.$nextTick(() => {
            this.versionStart();
            this.start().then(() => {
                if (this.status.loggedIn && !this.status.sessionExpired) {
                    this.load();
                    this.startWorker();
                } else {
                    this.stopWorker();
                }
            }).catch(() => {
                this.stopWorker();
            }).finally(() => {
                this.initApplication(true);
                this.$router.replace(this.lastRoute ? this.lastRoute : '/');
                this.loading(false);
            });
        });
    }

    public onLocalChange(locale) {
        if (!locale) { return; }
        this.$locale.setLocale(locale);
        this.$i18n.locale = locale;
    }

    public onTimezoneChange(timezone) {
        this.$locale.setTimezone(timezone);
    }

    public onVersionChange(version) {

        if (this.rerollVersionDone) { return; }
        const me = this;

        confirmSwal(me.$t('new_version').toString(), me.$t('new_version_text').toString()).then((res) => {
            if (res.value) {
                (document.location as any).reload();
            } else {
                this.rerollVersionDone = true;
                this.rerollVersion();
            }
        });

        this.versionStop();
    }

    public onMaintenanceChange(value) {
        if (!value) {
            return;
        }

        this.$router.replace('/maintenance');
    }

    public sessionExpired() {
        this.toggleSidebar(false);
        if (!this.status.sessionExpired) { return; }

        if (this.$router.currentRoute.meta?.requiresAuth === true) {
            const path = this.$router.currentRoute.fullPath;
            const me = this;
            const username = me.user.username;

            swal({
                title: me.$t('sessionabgelaufen_titel').toString(),
                text: me.$t('sessionabgelaufen').toString(),
                input: 'password',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: me.$t('passwort').toString(),
                },
                inputAutoTrim: true,
                showCancelButton: true,
                confirmButtonText: me.$t('login').toString(),
                cancelButtonText: me.$t('abbrechen').toString(),
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: (password) => {
                    return me.loginExpire({username, password}).then((res) => {
                        if (me.$router.currentRoute.name === 'Login') {
                            me.$router.push('/');
                        }
                    }).catch((error) => {
                        swal.showValidationMessage(
                            me.$t('login_error_text').toString()
                        );
                    });


                }
            }).then((result) => {
                if (result.dismiss) {
                    me.$router.push({ path: '/login', query: { redirect: path }});
                }
            });

        } else {
            this.resetLogin();
        }

    }

    public twoFactor(value: any) {
        this.toggleSidebar(false);
        if (!value) { return; }

        const username = value;

        swal({
            title: this.$t('twofa_login_titel').toString(),
            html: '<i class="mobile outline thin massive icon"></i>',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: this.$t('code').toString(),
            },
            inputAutoTrim: true,
            showCancelButton: true,
            confirmButtonText: this.$t('login').toString(),
            cancelButtonText: this.$t('abbrechen').toString(),
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: (password) => {
                return this.login({username, password}).then((res) => {
                    if (this.$router.currentRoute.name === 'Login') {
                        this.$router.push('/');
                    }
                }).catch((error) => {
                    swal.showValidationMessage(
                        this.$t('login_error_text').toString()
                    );
                });


            }
        }).then((result) => {
            if (result.dismiss) {
                this.loginFailure();
            }
        });
    }
}
