
import {Component, Prop} from 'vue-property-decorator';
import BaseElement from "@/framework/components/Form/Elements/BaseElement";
/* Fix für unmasked input wenn mask verwendet wird */
import {mask} from 'vue-the-mask';
import masker from 'vue-the-mask/src/masker'; // bad practice ? but vue-the-mask doesn't export masker anymore
import tokens from 'vue-the-mask/src/tokens';

@Component({
    directives: {mask}
})
export default class InputText extends BaseElement {

    @Prop() public value!: string | number;
    @Prop({default: false}) public mask: any;
    @Prop({default: ""}) public maxlength!: string | number;
    protected type: string = "text";

    public setFocus(focus?: boolean) {
        if (focus) {
            (this as any).$refs.input.focus();
        }
    }

    public updateValue() {
        let v = (this as any).$refs.input.value;

        if (this.mask && this.mask.length) {
            v = masker(v, this.mask, false, tokens);
        }

        (this as any).$emit('input', v);
    }

    protected onBlur() {
        this.$emit('inputblur', (this as any).$refs.input);
    }

    protected onFocus() {
      this.$emit('inputfocus', (this as any).$refs.input);
    }

    get inputEl(): any {
        return this.$refs.input;
    }

}
