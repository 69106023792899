import _ from 'lodash';
import Task from "@/framework/components/Taskbar/Task";
import '@/framework/extjs/window/Modal';
import WindowManager from "@/framework/util/WindowManager";

const initialState = {
    tasks: [],
    activeTask: null,
    toTop: null,
};

export const tasks = {
    namespaced: true,
    state: initialState,

    actions: {
        open({dispatch, commit, state}, task: Task) {
            const openedTask = state.tasks.find((t) => t.taskId === task.taskId);

            if (openedTask) {
                task = openedTask;
            }

            return WindowManager.open(task, dispatch).then((t) => {
                commit('taskOpen', t);
                return t;
            });
        },

        remove({dispatch, commit}, task: Task) {
            commit('taskRemove', task);
        },

        removeAll({dispatch, commit}) {
            commit('taskRemoveAll');
        },

        closeAll({state, commit}) {
            const stateTasks = _.cloneDeep(state.tasks);
            _.forEach(stateTasks, (task) => {
                WindowManager.close(task);
            });
            commit('taskRemoveAll');
        },

        hideAll({state, commit}) {
            _.forEach(state.tasks, (task) => {
                WindowManager.hide(task);
            });
        },

        update({dispatch, commit}, task: Task) {
            commit('taskUpdate', task);
        },

        scroll({state, getters}) {
            if (!getters.getActiveTask) {
                return;
            }

            const comp = Ext.getCmp(state.activeTask.windowId);
            comp.fireEvent('fwscroll');
        },

        setActiveByWindowId({state, commit}, id: any) {
            if (!state.tasks) {
                return;
            }

            const task = _.find(state.tasks, ['windowId', id]);
            commit('taskActive', task);
        }
    },

    getters: {
        getTaskById: (state) => (id) => {
            return state.tasks.find((t) => t.taskId === id);
        },

        getActiveTask: (state) => {
            if (state.activeTask && state.activeTask.windowId && Ext.getCmp(state.activeTask.windowId)) {
                return state.activeTask;
            }

            return null;
        },

        isScrollable: (state) => {
            if (state.activeTask && state.activeTask.windowId && Ext.getCmp(state.activeTask.windowId)) {
                return true;
            }

            return false;
        }
    },

    mutations: {
        taskOpen(state: any, task: Task) {
            if (_.findIndex(state.tasks, ['taskId', task.taskId]) === -1) {
                state.tasks.push(task);
            }
            state.activeTask = task;
        },

        taskActive(state: any, task: any) {
            state.activeTask = task;
        },

        taskRemove(state: any, task: Task) {
            state.tasks = _.remove(state.tasks, (item: Task) => {
                return item.taskId !== task.taskId;
            });

            if (state.activeTask && state.activeTask === task) {
                state.activeTask = null;
            }
        },

        taskUpdate(state: any, task: Task) {
            _.map(state.tasks, (t: Task) => {
                if (t === task) {
                    t = task;
                }
            });
        },

        taskRemoveAll(state: any) {
            state.tasks = [];
            state.activeTask = null;
        },

        toTop(state: any) {
            state.toTop = new Date().getTime();
        }
    }
};
