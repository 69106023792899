import {Component, Vue} from 'vue-property-decorator';
import {mapActions, mapState} from "vuex";
import router from "@/app/router";

@Component({
    computed: mapState('authentication', {
        loginStatus: 'status'
    }),

    mounted() {
        this.$nextTick(() => {
            this.$store.dispatch('authentication/resetLogin');
        });
    },
})
export default class Login extends Vue {
    public username: string = '';
    public password: string = '';
    public showError: boolean = false;

    public login(): void {
        this.$store.dispatch('authentication/login', {
            username: this.username, password: this.password, route: this.$route
        }).catch((reason) => this.showError = true);
    }

    public cancel(): void {
        this.$router.push('/');
    }
}
