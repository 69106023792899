import i18n from "@/framework/locale";

class StaticValues {

    public get yes(): string {
        return i18n.t('ja').toString();
    }

    public get no(): string {
        return i18n.t('nein').toString();
    }

    public get green(): string {
        return i18n.t('gruen').toString();
    }

    public get yellow(): string {
        return i18n.t('gelb').toString();
    }

    public get red(): string {
        return i18n.t('rot').toString();
    }

    public get null(): string {
        return i18n.t('filter_null').toString();
    }

    public get notnull(): string {
        return i18n.t('filter_not_null').toString();
    }

    public yesno(value): string {
        if (value === 'j') {
            return this.yes;
        }

        if (value === 'n') {
            return this.no;
        }

        return "";
    }

    public status(value): string {
        value = parseInt(value, 10);

        switch (value) {
            case 1: return this.green;
            case 2: return this.yellow;
            case 3: return this.red;

            default: return "";
        }

    }

    public statusCss(value): string {
        value = parseInt(value, 10);

        switch (value) {
            case 1: return 'positive';
            case 2: return 'warning';
            case 3: return 'negative';

            default: return "";
        }

    }

    public statusIcon(value): string {
        value = parseInt(value, 10);

        switch (value) {
            case 1: return 'check icon';
            case 2: return 'exclamation triangle icon';
            case 3: return 'xmark icon';

            default: return "";
        }

    }

}

export const staticValues = new StaticValues();

const VueStaticValues = {

    install(Vue, options) {
        Vue.prototype.$val_yn = (data) => {
            if (!data || data.length === 0) { return ''; }

            return staticValues.yesno(data);
        };

        Vue.prototype.$val_status = (data) => {
            if (!data || data.length === 0) { return ''; }

            return staticValues.status(data);
        };

        Vue.prototype.$val_statuscss = (data) => {
            if (!data || data.length === 0) { return ''; }

            return staticValues.statusCss(data);
        };

    }
};

export default VueStaticValues;
